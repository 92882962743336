import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import QUDOServer from '../../helpers/QUDOServerConnection';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const timeframes = {
    '1 Day': 1 * 24 * 60 * 60 * 1000,
    '3 Days': 3 * 24 * 60 * 60 * 1000,
    '5 Days': 5 * 24 * 60 * 60 * 1000,
    '1 Week': 7 * 24 * 60 * 60 * 1000,
    '2 Weeks': 2 * 7 * 24 * 60 * 60 * 1000,
    '3 Weeks': 3 * 7 * 24 * 60 * 60 * 1000,
    '1 Month': 30 * 24 * 60 * 60 * 1000
}

const defaultPieChartOptions = {
    chart: {
        backgroundColor: 'transparent',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: 'Browser market shares in January, 2018'
    },
    tooltip: {
        pointFormat: '{series.data.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        enabled: false,
        // point: {
        //     valueSuffix: '%'
        // }
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false
            },
            showInLegend: true
        }
    },
    series: [{
        name: 'Brands',
        colorByPoint: true,
        data: [{
            name: 'Chrome',
            y: 61.41,
            sliced: true,
            selected: true
        }, {
            name: 'Internet Explorer',
            y: 11.84
        }, {
            name: 'Firefox',
            y: 10.85
        }, {
            name: 'Edge',
            y: 4.67
        }, {
            name: 'Safari',
            y: 4.18
        }, {
            name: 'Other',
            y: 7.05
        }]
    }]
}

const totalPlayTimeTooltip = (
    <Tooltip placement="right" className="">
        <span className="text-light"><small>
        This metric refers to the total duration you are logged into the game, including both active gameplay and inactive periods.
        </small></span>
    </Tooltip>
);

export default function GuildStatistics() {
    const currentUser = useSelector(state => state.info);

    const [loading, setLoading] = useState(false);
    const [statsByGame, setStatsByGame] = useState([]);
    const [statsByMember, setStatsByMember] = useState([]);
    const [totalActivity, setTotalActivity] = useState(0);
    const [statsByGameChart, setStatsByGameChart] = useState(null);
    const [statsByMemberChart, setStatsByMemberChart] = useState(null);
    const [selectedTimeframe, setSelectedTimeframe] = useState(Object.keys(timeframes)[3]);

    useEffect(() => {
        if (currentUser) {
            getGuildStatistics();
        }
    }, [selectedTimeframe]);

    useEffect(() => {
        const chartOptions = JSON.parse(JSON.stringify(defaultPieChartOptions));

        chartOptions.title = {
            text: 'Activity by Game'
        }

        chartOptions.series = [{
            name: 'Activity by Game',
            colorByPoint: true,
            data: statsByGame.map(e => {
                return {
                    name: e.game,
                    y: e.activity
                }
            })
        }];

        setStatsByGameChart(chartOptions);
    }, [statsByGame]);

    useEffect(() => {
        const chartOptions = JSON.parse(JSON.stringify(defaultPieChartOptions));

        chartOptions.title = {
            text: 'Top 10 Most Active Members'
        }

        chartOptions.series = [{
            name: 'Top 10 Most Active Members',
            colorByPoint: true,
            data: statsByMember.sort((a, b) => {
                return b.totalActivity - a.totalActivity;
            }).slice(0, 10).map(e => {
                return {
                    name: e.display_name ? e.display_name : e.username,
                    y: e.totalActivity
                }
            })
        }];

        setStatsByMemberChart(chartOptions);
    }, [statsByMember]);

    function getGuildStatistics() {
        setLoading(true);
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/guild/stats`, {
            params: {
                startTimestamp: Date.now() - timeframes[selectedTimeframe]
            },
            withCredentials: true
        })
            .then(response => {
                if (Array.isArray(response.data)) {
                    let gameStats = [];
                    let totalActivityCounter = 0;

                    for (let i = 0; i < response.data.length; i++) {
                        const member = response.data[i];

                        totalActivityCounter += member.totalActivity;

                        for (let j = 0; j < member.activityData.length; j++) {
                            const activity = member.activityData[j];

                            //totalActivityCounter += activity.activity;

                            const gameExists = gameStats.find(e => {
                                return e.game === activity.game;
                            });

                            if (gameExists) {
                                gameExists.activity += activity.activity;
                            } else {
                                gameStats.push({
                                    game: activity.game,
                                    activity: activity.activity
                                });
                            }
                        }
                    }

                    setStatsByGame(gameStats);
                    setStatsByMember(response.data);
                    setTotalActivity(totalActivityCounter);
                }
            })
            .catch(error => {
                //console.log(error);
            }).finally(() => {
                setLoading(false);
            });
    }

    return (
        <div className='mt-3 mb-5'>
            <div className='row'>
                <div className='col-12'>
                    <div>
                        Timeframe: {' '}

                        <select
                            onChange={(e) => { setSelectedTimeframe(e.target.value) }}
                            value={selectedTimeframe}
                        >
                            {Object.keys(timeframes).map((key) => {
                                return (
                                    <option
                                        key={key}
                                        value={key}
                                    >
                                        {key}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                {loading ?
                    <div className='mx-auto mt-4'>
                        <div className="loading mx-auto my-4"></div>
                    </div>
                    :
                    <div className='col-12'>
                        <div className='row mt-4'>
                            <div className='col-12 col-md-6 my-2'>
                                <h5 style={{ fontWeight: 'bold' }}>
                                    {`Active Members (${statsByMember.length})`}
                                </h5>
                                <div className='row mb-1' style={{ fontWeight: 'bold' }}>
                                    <div className='col-5'>
                                        Member
                                    </div>
                                    <div className='col-4'>
                                        Total Play Time
                                        <OverlayTrigger placement="right" overlay={totalPlayTimeTooltip}>
                                            <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                                        </OverlayTrigger>
                                    </div>

                                    <div className='col-3'>
                                        Activity %
                                    </div>
                                </div>
                                <div
                                    className='with-scroll'
                                    style={{
                                        overflowX: 'hidden',
                                        maxHeight: '475px'
                                    }}
                                >
                                    {statsByMember.length === 0 ? (
                                        `No active members`
                                    ) : (
                                        statsByMember.sort((a, b) => {
                                            return b.totalActivity - a.totalActivity;
                                        }).map((e, index) => {
                                            const activityTooltip = (
                                                <Tooltip placement="right" className="">
                                                    <span className="text-light"><small>
                                                        <b>{e.totalActivity}</b> Activity reported / minutes
                                                    </small></span>
                                                </Tooltip>
                                            );

                                            return (
                                                <div className='row mb-1' key={index}>
                                                    <div className='col-5'>
                                                        {e.display_name ? e.display_name : e.username}
                                                    </div>
                                                    <div className='col-4'>
                                                        {`${Number(e.hoursPlayed).toFixed(2)} hours`}
                                                    </div>
                                                    <div className='col-3'>
                                                        <OverlayTrigger placement="right" overlay={activityTooltip}>
                                                            <span>
                                                                {`${Number(e.totalActivity / totalActivity * 100).toFixed(2)}%`}
                                                            </span>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='my-2'>
                                    {statsByMemberChart && (
                                        <div>
                                            <HighchartsReact highcharts={Highcharts} options={statsByMemberChart} />
                                        </div>
                                    )}
                                </div>
                                <div className='my-2'>
                                    {statsByGameChart && (
                                        <div>
                                            <HighchartsReact highcharts={Highcharts} options={statsByGameChart} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
