import React, {useState, useEffect} from 'react';
import QUDOServer from "../../../../../../helpers/QUDOServerConnection";
import {MessageModal} from "../../../../../components/modals/modals";
import {useDispatch} from "react-redux";
import {NumberInput} from '../../../../../components/forms/number';
import { ButtonInput } from "../../../../../components/forms/buttons";

import ReactTooltip from 'react-tooltip';

import * as actions from "../../../../../../actions";
import '../bugsCenterColumn.scss';

const helpButton = require('../../../../../../images/definitive/help-button-1.png');

export default function BugReward(props)
{
    const dispatch = useDispatch();

    const [modalMessage, setModalMessage] = useState("");
    const [successModal, setSuccessModal] = useState(false);
    const [failureModal, setFailureModal] = useState(false);

    const [newMaxReward, setMaxReward] = useState(props.game.bugSpotting.maxReward);
    const [newMinReward, setMinReward] = useState(props.game.bugSpotting.minReward);
    const [activeBugSpotting, setActiveBugSpotting] = useState(props.game ? props.game.bugSpotting.active : false);

    useEffect(() =>
    {
        if (props.game)
        {
            setActiveBugSpotting(props.game.bugSpotting.active);
        }
    }, [props.game]);

    const editBugReward = value =>
    {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/bugspotting/edit/rewards `, {
            game: props.game.name,
            minReward: newMinReward,
            maxReward: newMaxReward
        }, {
            withCredentials: true
        })
        .then(resp =>
            {
                setSuccessModal(true);
                setModalMessage(`Updated Bug Spotting Rewards value`)
            })
            .catch(err =>
            {
                setFailureModal(true);
                setMinReward(props.game.bugSpotting.minReward);
                setMaxReward(props.game.bugSpotting.maxReward);
                setModalMessage(err.errorMessage)
            }); 
    }

    const updateGameInfo = () =>
    {
        dispatch(actions.fetchUser());
    }

	const buttons = (props.game.bugSpotting.minReward !== newMinReward || props.game.bugSpotting.maxReward !== newMaxReward) && (
		<div className="row">
			<div className="col-6 pr-0 mr-0">
				<ButtonInput 
					label="Cancel"
					trigger
					set={() => {
						setMinReward(props.game.bugSpotting.minReward);
                        setMaxReward(props.game.bugSpotting.maxReward);
					}}
					className="editgamebtn w-100"
					style={{border: '1px solid #b2b2b2', backgroundColor: 'white', color: '#f44336'}}
				/>
			</div>
			<div className="col-6 ml-0 pl-0">
				<ButtonInput
					label="Save"
					set={() => editBugReward()}
					trigger
					className="editgamebtn w-100"
					style={{border: '1px solid #b2b2b2', backgroundColor: 'white', color: '#00cc69'}}
				/>
			</div>
		</div>
	)

    const getColumn = () =>
    {
        if (activeBugSpotting)
        {
            return (
                <>
                    <div className="col-10 pt-3">
                        <strong>Reward per Bug</strong>
                    </div>
                    <div className="col-2 pt-3">
                        <img
							src={helpButton}
							className="w-100 enable-click"
                            style={{minWidth: '1rem', maxWidth: '2rem'}}
							alt="help button"
                            data-tip
                            data-for='rewards'
						/>
                        <ReactTooltip id='rewards' type='light'>
                            <span>
                                This is the range of values you are willing to offer for each valid bug report.
                            </span>
                        </ReactTooltip>
                    </div>
                    <div className="col-12 mt-3 rewards numbers">
                        {props.game.bugSpotting.minReward ? props.game.bugSpotting.minReward.toFixed(2) : 0}
                    </div>
                    <div className="col-12 mt-1 rewards type">
                        Min Reward
                    </div>
                    <div className="col-12 mt-2">
                        <NumberInput
                            unit="QUDO"
                            min={0}
                            step={1}
                            initial={newMinReward}
                            value={newMinReward}
                            setValue={setMinReward}
                            classes="yellow-qudo"
                        />
                    </div>
                    <div className="col-12 mt-4 rewards numbers">
                        {props.game.bugSpotting.maxReward ? props.game.bugSpotting.maxReward.toFixed(2) : 0}
                    </div>
                    <div className="col-12 mt-1 rewards type">
                        Max Reward
                    </div>
                    <div className="col-12 mt-2">
                        <NumberInput
                            unit="QUDO"
                            min={newMinReward}
                            step={1}
                            initial={newMaxReward}
                            value={newMaxReward}
                            setValue={setMaxReward}
                            classes="yellow-qudo"
                        />
                    </div>
                </>
            )
        }
        else{
            return (
                <div></div>
            )
        }
    }

    return (
        <div className="borders">
            <div className="row mx-auto">
                {getColumn()}
                
                <>
                    <MessageModal
                        show={successModal} 
                        message={modalMessage}
                        hide={() => {setSuccessModal(!successModal); updateGameInfo()}}
                    />
                    <MessageModal
                        show={failureModal} 
                        message={modalMessage}
                        hide={() => setFailureModal(!failureModal)}
                    />
                </>
            </div>
            {buttons}
        </div>
    );
};