import React from "react";
import {Form, InputGroup} from "react-bootstrap";

import regexes from "../../helpers/validationRegexes";
import QUDOServer from "../../helpers/QUDOServerConnection";

import {DisplayNameInput, EmailInput} from '../components/forms/text';
import {ConfirmPasswordModal, MessageModal} from "../components/modals/modals.js";

export default class EditDetailsForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            Email: props.info.email,
            Website: props.info.website,
            Description: props.info.description,
            PendingDisplayName: props.info.pendingDisplayName,
            PendingDisplayNameFlag: props.info.pendingDisplayNameFlag,
            DisplayName: props.info.display_name,
            DisplayNameValid: false,
            btnDisabled: true,
            update: false,
            modalMessage: ''
        }

        //console.log(this.state);
    }

    confirmValid = () => {
        if(this.state.Password) {
            this.setState({
                isValid: true
            })
        } else {
            this.setState({
                isValid: false
            })
        }
    }

    btnDisabled = () => {
        /*
        if(this.props.info.type === process.env.REACT_APP_GAMEDEV_TYPE) {
            if(this.state.Email === this.props.info.email) {
                this.setState({
                    btnDisabled: true
                })
            } else {
                this.setState({
                    btnDisabled: false
                })
            }
        } else {
            */
            if(
                this.state.Email === this.props.info.email
                //&& this.props.newImageID === this.props.info.imageID
                && this.state.Website === this.props.info.website
                && this.state.Description === this.props.info.description
                && this.state.DisplayName === this.props.info.display_name
                && ((this.state.DisplayName === this.props.info.display_name) || !this.state.DisplayNameValid)
            ) {
                this.setState({
                    btnDisabled: true
                })
            } else {
                this.setState({
                    btnDisabled: false
                })
            }
        //}
    }

    change = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if(form.checkValidity() === false) {
            await this.setState({
                [`${event.target.name}Invalid`]: true,
                [event.target.name]: event.target.value
            })
        } else {
            await this.setState({
                [`${event.target.name}Invalid`]: false,
                [event.target.name]: event.target.value
            })
        }

        this.confirmValid();
        this.btnDisabled();
    }

    async changeEmail(email) {
        if(email !== this.state.Email) {
            //console.log(email);
            await this.setState({
                Email: email
            });

            this.confirmValid();
            this.btnDisabled();
        }
    }

    async changeDisplayName(value) {
        await this.setState({
            DisplayName: value
        });

        this.btnDisabled();
    }

    async changeDisplayNameValid(value) {
        await this.setState({
            DisplayNameValid: value
        });

        this.btnDisabled();
    }

    closeAllModals = () => {
        this.setState({
            confirmModal: false,
            successModal: false,
            failureModal: false
        })
    }



    toggleFailureModal = () => {
        this.closeAllModals();
        this.setState({
            failureModal: !this.state.failureModal
        })
    }

    toggleSuccessModal = () => {
        this.closeAllModals();
        this.setState({
            successModal: !this.state.successModal
        })
    }

    toggleConfirmModal = () => {
        //console.log(!this.state.confirmModal);
        this.setState({
            confirmModal: !this.state.confirmModal
        })
    }


    submit = async (e) => {
        e.preventDefault();

        if(!this.state.Password) {
            return;
        }

        this.toggleConfirmModal();

        let body = {
            password: this.state.Password,
            email: this.state.Email !== this.props.info.email ? this.state.Email : null,
            display_name: ((this.state.DisplayName !== this.props.info.display_name) && this.state.DisplayNameValid) ? this.state.DisplayName : null
        }

        if(this.props.info.type !== process.env.REACT_APP_USER_TYPE) {
            body.description = this.state.Description !== this.props.info.description ? this.state.Description : null;
            body.website = this.state.Website !== this.props.info.website ? this.state.Website : null;
        }

        //console.log(body);
        //return;

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/profile/change`, body, {
            headers: {
                'Content-Type': 'application/json',
            },
            json: true,
            withCredentials: true
        })
        .then(result => {
            this.setState({
                isValid: false,
                Password: "",
                update: true,
                modalMessage: 'Success'
            });

            //this.toggleSuccessModal();

            /*
            setTimeout(() => {
                this.props.fetchNewInfo();
            }, 2500);
            */
        })
        .catch((error) => {
            this.setState({
                isValid: false,
                Password: "",
                update: false,
                modalMessage: error.errorMessage
            });

            //this.toggleFailureModal();

            /*
            setTimeout(() => {
                this.props.fetchNewInfo();
            }, 2500);
            */
        });
    }

    /*
    modal = () => {
        return (
            <>
                <ConfirmPasswordModal 
                    confirmModal={this.state.confirmModal} 
                    toggleConfirmModal={this.toggleConfirmModal} 
                    submit={this.submit} 
                    change={this.change}
                    isValid={this.state.isValid}
                />
                <SuccessOrNeedToConfirm
                    successModal={this.state.successModal}
                    toggleSuccessModal={this.toggleSuccessModal}
                    emailInvalid={this.state.emailInvalid}
                />
                <FailureModal
                    show={this.state.failureModal}
                    hide={this.toggleFailureModal}
                />
            </>
        )
    }
    */

    render() {
        return (
            <div className="edit-profile-personal-info-box col-12 col-lg-10">
                <EditProfileForm 
                    type={this.props.info.type}
                    username={this.props.info.username}
                    email={this.state.Email}
                    EmailInvalid={this.state.EmailInvalid}
                    website={this.state.Website}
                    WebsiteInvalid={this.state.WebsiteInvalid}
                    description={this.state.Description}
                    DescriptionInvalid={this.state.DescriptionInvalid}
                    change={this.change}
                    changeEmail={this.changeEmail.bind(this)}
                    disabled={this.state.btnDisabled} 
                    toggleConfirmModal={this.toggleConfirmModal}
                    pendingDisplayName={this.state.PendingDisplayName}
                    pendingDisplayNameFlag={this.state.PendingDisplayNameFlag}
                    displayName={this.state.DisplayName}
                    changeDisplayName={this.changeDisplayName.bind(this)}
                    changeDisplayNameValid={this.changeDisplayNameValid.bind(this)}
                />

                {/* this.modal() */}

                <ConfirmPasswordModal
                    confirmModal={this.state.confirmModal} 
                    toggleConfirmModal={this.toggleConfirmModal} 
                    submit={this.submit} 
                    change={this.change}
                    isValid={this.state.isValid}
                />

				<MessageModal
					show={this.state.modalMessage} 
					message={this.state.modalMessage}
					hide={() => {
                        if(this.state.update) {
                            this.props.fetchNewInfo();
                        }

                        this.setState({
                            update: false,
                            modalMessage: ''
                        });
                    }}
				/>
            </div>
        )
    }
}

function EditProfileForm(props) {
    // const referralLink = `https://games.qudo.io?register=true&referral=${props.username}`;

    // const [copied, setCopied] = useState(false);

    /*
	function CopyToClipboard(value) {
		var tempInput = document.createElement("input");
		tempInput.value = value;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand("copy");
		document.body.removeChild(tempInput);

		setCopied(true);
		setTimeout(() => setCopied(false), 3000);
	}
    */

    return(
        <Form /*ref={(el) => this.clear = el} onSubmit={e => { e.preventDefault(); }}*/>
            <div style={{fontWeight: "bold"}}>
                Personal Information
            </div>

            <div className="edit-field col-12">
                <div className="edit-field-title">
                    Username
                </div>
                <div style={{color: "#656565"}}>
                    {props.username}
                </div>
            </div>

            {/*}
            <div
				data-tip
                data-for='referralLink'
                className="col-12 edit-field"
                onClick={() => {
                    CopyToClipboard(referralLink);
                }}
            >
                <div className="edit-field-title">
                    Referral Link
                </div>
                <div style={{color: "#656565"}}>
                    {referralLink}
                </div>
				<ReactTooltip id='referralLink' type='light'>
					<span>
						{copied ? 'Copied' : 'Click to copy to clipboard'}
					</span>
				</ReactTooltip>
            </div>
            {*/}

            {!props.pendingDisplayNameFlag ?
			(
                <DisplayNameInput
                    title="Display Name"
                    placeholder="Display name (optional)"
                    initial={props.displayName}
                    value={props.displayName}
                    set={props.changeDisplayName}
                    setValid={props.changeDisplayNameValid}
                    userType={props.type}
                    className={`edit-field col-12`} 
                    style={{height: "4.5rem"}}
                    showValid={true}
                    disabled={props.pendingDisplayNameFlag}
                />
            ) : (
                <div
                    className="mx-4"
                    style={{fontSize: '0.9rem', /* marginTop: '-15px', */ marginBottom: '30px'}}
                >
                    You already made a request to change your public display name.
                    The QUDO team has already been notified and must first confirm this change
                    before you can change your display name again.
                </div>
            )}

            {/*}
            <InputGroup style={{marginTop: '-1rem'}}>
                <Form.Label className="edit-input-title"  >
                    Email
                </Form.Label>
                <Form.Control
                    type="email"
                    name="Email"
                    style={{height: "4.5rem"}}
                    className={`edit-field col-12`} 
                    onChange={props.change}
                    value={props.email}
                    // isInvalid={this.state.EmailInvalid}
                    isValid={props.EmailInvalid === false}
                    placeHolder="Email"
                />
                    <Form.Control.Feedback type="invalid">
                        Please choose an email
                    </Form.Control.Feedback>
            </InputGroup>
            {*/}

			<EmailInput
                title={'Email'}
                style={{height: "4.5rem"}}
                className={`edit-field col-12`}
                initial={props.email}
				validate={true}
			    set={props.changeEmail}
				userType={props.type}
                placeholder={'Change your email'}
			/>

            {props.type !== process.env.REACT_APP_USER_TYPE && 
                GamedevAndProviderSpecific(props)
            }

            <div
                className={`col-12 edit-profile-save-btn ${props.disabled ? "disabled" : ""}`}
                onClick={props.toggleConfirmModal}
            >
                Save
            </div>
        </Form>
    );
}

function GamedevAndProviderSpecific(props) {
    return(
        <>
            <InputGroup>
                <Form.Label className="edit-input-title">
                    Website
                </Form.Label>
                <Form.Control
                    className={`edit-field col-12`}
                    style={{height: '4.5rem'}}
                    type="text"
                    name="Website"
                    value={props.website}
                    pattern={regexes.website}
                    onChange={props.change}
                    isValid={props.WebsiteInvalid === false}
                />
                <Form.Control.Feedback type="invalid">
                    Please write a valid website
                </Form.Control.Feedback>
            </InputGroup>
            <InputGroup>
                <Form.Label className="edit-input-title"  >
                    Description
                </Form.Label>
                <Form.Control
                    className={`edit-field col-12`}
                    style={{height: '8rem'}}
                    as="textarea"
                    id="edit-game-description"
                    type="text"
                    value={props.description}
                    name="Description"
                    onChange={props.change}
                    isValid={props.DescriptionInvalid === false} />
            </InputGroup>
        </>
    );
}
