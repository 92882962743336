import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedium, faYoutube, faTelegram, faDiscord, faFacebook, faInstagram, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";

const logo = require("../../images/definitive/logo-colour-horizontal.svg");

function Footer(props) {
    const userInfo = useSelector(state => state.info);

    /*
    useEffect(() => {
        console.log(userInfo);
    }, [userInfo]);
    */

	//render() {
		return (
            <div id="footer-container" style={{paddingBottom: "1rem"}} className="mt-2">
                <div id="lb-title-box" className="row justify-content-between mb-5" style={{color: "white"}}>
                    <div className="lb-title col-6 col-lg-9">
                        <img src={logo} alt="logo"/>
                        <div className="mt-3" style={{fontSize: '0.9rem', color: '#fcd902'}}>
                            <div>
                                Get ready for the (beta) main net launch by joining QUDO alpha phase.
                            </div>
                            <div>
                                We're already running on the Telos test net.
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3">
                        <div className="lb-date" style={{marginBottom: "6rem"}}>
                            {!userInfo && (
                                <div>
                                    {/*}
                                    <a rel="noopener noreferrer" style={{color: "white"}} href="?login=true">
                                        Log In
                                    </a>
                                    {*/}

                                    <Link to={"?login=true"} style={{color: "white"}}>
                                        Log In
                                    </Link>
                                </div>
                            )}

                            <div>
                                <a target="_blank" rel="noopener noreferrer" style={{color: "white"}} href="https://qudo.io">
                                    About QUDO
                                </a>
                            </div>

                            <div>
                                <a target="_blank" rel="noopener noreferrer" style={{color: "white"}} href="https://qudo.io#faq">
                                    FAQ
                                </a>
                            </div>

                            <div>
                                <a target="_blank" rel="noopener noreferrer" style={{color: "white"}} href="https://qudo.io/contacts#contact">
                                    Support
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="lb-date">
                            <div className="mb-2">
                                Follow us on
                            </div>

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://medium.com/block-bastards"
                                className="social-media-icon"
                                style={{color: '#ffffff' /* '#c4cccc' */}}
                            >
                                <FontAwesomeIcon icon={faMedium}/>
                            </a>

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.youtube.com/channel/UC1nPwT6NEIKP7_LdYsxJHVA/featured"
                                className="social-media-icon"
                                style={{color: '#c4302b'}}
                            >
                                <FontAwesomeIcon icon={faYoutube}/>
                            </a>

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://t.me/qudocoin"
                                className="social-media-icon"
                                style={{color: '#0088cc'}}
                            >
                                <FontAwesomeIcon icon={faTelegram}/>
                            </a>

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://discord.com/invite/UK9MHRZ"
                                className="social-media-icon"
                                style={{color: '#7289da'}}
                            >
                                <FontAwesomeIcon icon={faDiscord}/>
                            </a>

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/qudorewards"
                                className="social-media-icon"
                                style={{color: '#4267B2'}}
                            >
                                <FontAwesomeIcon icon={faFacebook}/>
                            </a>

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.instagram.com/blockbastards/"
                                className="social-media-icon"
                                style={{color: '#C13584'}}
                            >
                                <FontAwesomeIcon icon={faInstagram}/>
                            </a>

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://twitter.com/Qudocoin"
                                className="social-media-icon"
                                style={{color: '#55acee'}}
                            >
                                <FontAwesomeIcon icon={faTwitterSquare}/>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="lb-title-box" className="row justify-content-between" style={{color: "#656565", marginBottom: "0px"}}>
                    <div className="col-12">
                        <div
                            className="enable-click d-inline-block mr-3"
                            onClick={() => window.open('https://qudo.io/documents/terms_and_conditions.html', '_blank')}
                        >
                            Terms and Conditions
                        </div>

                        <div
                            className="enable-click d-inline-block mr-3"
                            onClick={() => window.open('https://qudo.io/documents/privacy_policy.html', '_blank')}
                        >
                            Privacy Policy
                        </div>

                        <div
                            className="enable-click d-inline-block mr-3"
                            onClick={() => window.open('https://qudo.io/documents/cookie_policy.html', '_blank')}
                        >
                            Cookie Policy
                        </div>
                    </div>
                    
                    <div className="col-12 mt-1">
                        ©{new Date().getFullYear()} QUDO All Rights Reserved.
                    </div>
                </div>
            </div>
		);
	//}
}

export default Footer;
