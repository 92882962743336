import React, {useEffect, useState} from 'react';
import BugsCenterColumn from './bugsCenterColumn/bugsCenterColumn';
import BugsLeftColumn from './bugsLeftColumn/bugsLeftColumn';
import BugsRightColumn from './bugsRightColumn/bugsRightColumn';


export default function Bugs(props)
{
    //console.log(props);
    return (
        <>
            {props.type === 'right' && (
                <BugsRightColumn 
                    submenu={props.submenu}
                    game={props.game}
                />
            )}
            {props.type === 'center' && (
                <BugsCenterColumn 
                    submenu={props.submenu}
                    game={props.game}
                    reportChosen={props.reportChosen}
                    setReportChosen={props.setReportChosen}
                    update={props.update}
                    setUpdate={props.setUpdate}
                />
            )}
            {props.type === 'left' && (
                <BugsLeftColumn
                    submenu={props.submenu}
                    game={props.game}
                    activeFilterBugs={props.activeFilterBugs}
                    reportChosen={props.reportChosen}
                    setReportChosen={props.setReportChosen}
                    update={props.update}
                    setUpdate={props.setUpdate}
                />
            )}
        </>
    )
};