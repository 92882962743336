import React, {useEffect, useState} from "react";
import ReactTooltip from "react-tooltip";

import useWindowSize from '../../helpers/useWindowSize';
import QUDOServer from "../../helpers/QUDOServerConnection";

import {ButtonInput} from '../components/forms/buttons';
import {TopRewardingDropdown, periodToString} from '../components/forms/dropdowns';

const helpButton = require("../../images/definitive/help-button-1.png");

const gold = require("../../images/definitive/icn-trophy-gold.png");
const silver = require("../../images/definitive/icn-trophy-silver.png");
const bronze = require("../../images/definitive/icn-trophy-bronze.png");
const medal = require("../../images/definitive/icn-medal-updated.png");

export default function TopPlayers(props) {
    /*
        Although not being used, this next statement forces the component to rerender whenever the window size
        changes (so don't delete it or the floats won't update when the screen size changes)
    */
    const windowSize = useWindowSize();

    const [mostActiveFilter, setMostActiveFilter] = useState("1week");
	const [mostActivePlayers, setMostActivePlayers] = useState(null);
    const [mostActiveAvailableFilters, setMostActiveAvailableFilters] = useState([]);

    const [mostEffectiveFilter, setMostEffectiveFilter] = useState("1week");
	const [mostEffectivePlayers, setMostEffectivePlayers] = useState(null);
    const [mostEffectiveAvailableFilters, setMostEffectiveAvailableFilters] = useState([]);

    const PlayerLoadNr = 5;

    const [showAllActive, setShowAllActive] = useState(false);
    const [showAllActiveButton, setShowAllActiveButton] = useState(false);

    const [showAllEffective, setShowAllEffective] = useState(false);
    const [showAllEffectiveButton, setShowAllEffectiveButton] = useState(false);

    useEffect(() => {
		QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/top/players`)
		.then(result => {
			//console.log(result.data);

            let mostActiveFilters = [];
            let mostEffectiveFilters = [];

            for(let period in result.data.mostActivePlayers) {
                if(result.data.mostActivePlayers[period].length !== 0) {
                    mostActiveFilters.push(period);

                    for(let i = 0; i < result.data.mostActivePlayers[period].length; i++) {
                        result.data.mostActivePlayers[period][i].ranking = i + 1;
                    }
                }
            }

            for(let period in result.data.mostEffectivePlayers) {
                if(result.data.mostEffectivePlayers[period].length !== 0) {
                    mostEffectiveFilters.push(period);

                    for(let i = 0; i < result.data.mostEffectivePlayers[period].length; i++) {
                        result.data.mostEffectivePlayers[period][i].ranking = i + 1;
                    }
                }
            }

            setMostActiveAvailableFilters(mostActiveFilters);
			setMostActivePlayers(result.data.mostActivePlayers);
            setMostActiveFilter(mostActiveFilters.length > 0 ? mostActiveFilters[mostActiveFilters.length - 1] : '');

            setMostEffectiveAvailableFilters(mostEffectiveFilters);
			setMostEffectivePlayers(result.data.mostEffectivePlayers);
            setMostEffectiveFilter(mostEffectiveFilters.length > 0 ? mostEffectiveFilters[mostEffectiveFilters.length - 1] : '');
		});
    }, []);

    useEffect(() => {
        if(mostActivePlayers && Array.isArray(mostActivePlayers[mostActiveFilter])) {
            if(mostActivePlayers[mostActiveFilter].length <= PlayerLoadNr) {
                setShowAllActiveButton(false);
            } else {
                setShowAllActiveButton(true);
            }
        }
    }, [mostActivePlayers, mostActiveFilter]);

    useEffect(() => {
        if(mostEffectivePlayers && Array.isArray(mostEffectivePlayers[mostEffectiveFilter])) {
            if(mostEffectivePlayers[mostEffectiveFilter].length <= PlayerLoadNr) {
                setShowAllEffectiveButton(false);
            } else {
                setShowAllEffectiveButton(true);
            }
        }
    }, [mostEffectivePlayers, mostEffectiveFilter]);

    return (
        <div className="main-inner-container tp-container">
            <div className="row mb-4">
                <div className="col-12 col-md-6 col-lg-12 col-xl-6 mt-4">
                    <div className="row mb-3">
                        <div className="col-12 col-sm-8 col-md-12 col-lg-8 main-inner-title h4">
                            Most Effective Players
                            <img
                                src={helpButton}
                                className="w-100 enable-click ml-2"
                                style={{minWidth: '1rem', maxWidth: '2rem'}}
                                alt="help button"
                                data-tip
                                data-for='MostEffectivePlayers'
                            />
                            <ReactTooltip id='MostEffectivePlayers' type='light'>
                                <span>
                                    These are the top players that earned more QUDO tokens through performance rewards (Achievements and Highscores).
                                </span>
                            </ReactTooltip>
                        </div>
                        <div className="col-12 col-sm-4 col-md-12 col-lg-4 mt-1">
                            <TopRewardingDropdown
                                id="qudo-now-dropdown"
                                currentFilter={mostEffectiveFilter}
                                setCurrentFilter={setMostEffectiveFilter}
                                availableFilters={mostEffectiveAvailableFilters}
                            />
                        </div>
                    </div>
                    <div className="row tp-list">
                        <div id="most_effective_row" className="col-12 px-0 mx-0">
                            <>
                            {
                            mostEffectivePlayers && Array.isArray(mostEffectivePlayers[mostEffectiveFilter])
                            && mostEffectivePlayers[mostEffectiveFilter].slice(0, (showAllEffective ? mostEffectivePlayers[mostEffectiveFilter].length : PlayerLoadNr)).map(i => {
                                let counter = 0;
                                let rewards = parseFloat(i.totalRewards).toFixed(2);

                                return (
                                    <div key={i.username} className="col-12">
                                        <div key={i.username} className="top-players-card align-top d-flex " style={{ alignItems: "center" }}>
                                            <div className="col-2 col-lg-2 d-inline-block top-players-trophy">
                                                <img
                                                    src={i.ranking <= 3 ? (i.ranking === 1 ? gold : (i.ranking === 2 ? silver : bronze)) : medal}
                                                    className="icon-32px"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-7 col-lg-8 flex-column justify-content-center text-wrap">
                                                <div className="text-wrap" style={{display: 'inline-block'}}>
                                                    <div id="top-players-info-top" className="text-wrap">
                                                        #{i.ranking}
                                                    </div>
                                                    <div id="top-players-info-bottom" className="text-wrap">
                                                        {i.display_name ? i.display_name : i.username}
                                                    </div>
                                                </div>
                                                <div
                                                    id="top-players-score"
                                                    className="text-wrap"
                                                    style={
                                                        document.getElementById('most_effective_row')
                                                        && document.getElementById('most_effective_row').clientWidth < 500
                                                        ? {} : {float: 'right'}
                                                    }
                                                >
                                                    {`${rewards} QUDO`}
                                                </div>
                                            </div>
                                            <div className="col-3 col-lg-2 position-relative">
                                                <img
                                                    src={
                                                        i.imageURL ? i.imageURL :
                                                            `${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyuser?user=${i.username}&size=xS`
                                                    }
                                                    className="rounded-circle position-absolute"
                                                    id="top-players-games"
                                                    alt="games avatar"
                                                    key={counter}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                            {showAllEffectiveButton && (
                                <div className="col-12">
                                    <ButtonInput
                                        trigger
                                        label={showAllEffective ? "Show Less" : "Show More"}
                                        set={() => setShowAllEffective(!showAllEffective)}
                                        style={{backgroundColor: "#fbd731"}}
                                    />
                                </div>
                            )}
                            </>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-12 col-xl-6 mt-4">
                    <div className="row mb-3">
                        <div className="col-12 col-sm-8 col-md-12 col-lg-8 main-inner-title h4">
                            Most Active Players
                            <img
                                src={helpButton}
                                className="w-100 enable-click ml-2"
                                style={{minWidth: '1rem', maxWidth: '2rem'}}
                                alt="help button"
                                data-tip
                                data-for='MostActivePlayers'
                            />
                            <ReactTooltip id='MostActivePlayers' type='light'>
                                <div>These are the players that actively played more QUDO adherent games.</div>
                                <div>(Only your in-game activity will be converted into minutes. Therefore, the time</div>
                                <div>you spend in the game menus and AFK/IDLE will not be accountable).</div>
                            </ReactTooltip>
                        </div>
                        <div className="col-12 col-sm-4 col-md-12 col-lg-4 mt-1">
                            <TopRewardingDropdown
                                id="qudo-now-dropdown"
                                currentFilter={mostActiveFilter}
                                setCurrentFilter={setMostActiveFilter}
                                availableFilters={mostActiveAvailableFilters}
                            />
                        </div>
                    </div>
                    <div className="row tp-list">
                        <div id="most_active_row" className="col-12 px-0 mx-0">
                            <>
                            {
                            mostActivePlayers && Array.isArray(mostActivePlayers[mostActiveFilter])
                            && mostActivePlayers[mostActiveFilter].slice(0, (showAllActive ? mostActivePlayers[mostActiveFilter].length : PlayerLoadNr)).map(i => {
                                let counter = 0;
                                let increases = Number(parseFloat(i.score).toFixed(0));
                                // let timePlayed = Number(Number(increases * (10 / 20)).toFixed(0)); //Block time of 10 minutes with max increases per block of 20
                                let timePlayed = increases;

                                return (
                                    <div key={i.username} className="col-12">
                                        <div key={i.username} className="top-players-card align-top d-flex " style={{ alignItems: "center" }}>
                                            <div className="col-2 col-lg-2 d-inline-block top-players-trophy">
                                                <img
                                                    src={i.ranking <= 3 ? (i.ranking === 1 ? gold : (i.ranking === 2 ? silver : bronze)) : medal}
                                                    className="icon-32px"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-7 col-lg-8 flex-column justify-content-center text-wrap">
                                                <div className="text-wrap" style={{display: 'inline-block'}}>
                                                    <div id="top-players-info-top" className="text-wrap">
                                                        #{i.ranking}
                                                    </div>
                                                    <div id="top-players-info-bottom" className="text-wrap">
                                                        {i.display_name ? i.display_name : i.username}
                                                    </div>
                                                </div>
                                                <div
                                                    id="top-players-score"
                                                    className="text-wrap"
                                                    style={
                                                        document.getElementById('most_active_row')
                                                        && document.getElementById('most_active_row').clientWidth < 500
                                                        ? {} : {float: 'right'}
                                                    }
                                                >
                                                    {/* {increases} {increases === 1 ? 'increase' : 'increases'} */}
                                                    {timePlayed} {timePlayed === 1 ? 'minute' : 'minutes'}
                                                </div>
                                            </div>
                                            <div className="col-3 col-lg-2 position-relative">
                                                <img
                                                    src={
                                                        i.imageURL ? i.imageURL :
                                                        `${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyuser?user=${i.username}&size=xS`
                                                    }
                                                    className="rounded-circle position-absolute"
                                                    id="top-players-games"
                                                    alt="games avatar"
                                                    key={counter}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                            {showAllActiveButton && (
                                <div className="col-12">
                                    <ButtonInput
                                        trigger
                                        label={showAllActive ? "Show Less" : "Show More"}
                                        set={() => setShowAllActive(!showAllActive)}
                                        style={{backgroundColor: "#fbd731"}}
                                    />
                                </div>
                            )}
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
