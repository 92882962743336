import React, { useEffect } from 'react';
import BugReport from './bugReport/bugReport';
import BugReward from './rewardBug/rewardBug';

export default function BugsCenterColumn(props)
{

    if (props.submenu === 'setup')
    {
        return (
            <>
                <BugReward game={props.game}/>
            </>
        );
    }

    return (
        <BugReport
            game={props.game}
            reportChosen={props.reportChosen}
            setReportChosen={props.setReportChosen}
            update={props.update}
            setUpdate={props.setUpdate}
        />
    )
};