import React from "react";
import Highcharts from "highcharts";

import UserGuildSettings from "../guild/userGuildSettings";

import QUDOServer from "../../helpers/QUDOServerConnection";

const moment = require("moment");

class NetworkRewards extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			reward: 0,
			overall: 0,
			total: []
		}
	}

	update = async () => {
		QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/profile/pendingrewardinfo`, {
			withCredentials: true
		})
		.then(data => {
			const userProfile = data.data;

			if(!userProfile) {
				this.setState({
					reward: 0,
					overall: 0,
					total: []
				});
			} else {
				const lastRewards = userProfile.pending_reward_data[userProfile.pending_reward_data.length - 1];

				if(lastRewards) {
					const lastReward = lastRewards.reward;

					this.setState({
						reward: Number(lastReward.split(' ')[0]),
						overall: Number(userProfile.pending_reward.split(' ')[0]),
						total: userProfile.pending_reward_data
					});

					this.chart();
				} else {
					this.setState({
						reward: 0,
						overall: 0,
						total: []
					});
				}
			}
		})
		.catch(error => {
			this.setState({
				reward: 0,
				overall: 0,
				total: []
			});
		});
	}

	async componentDidMount(props) {
		await this.update();

		//this.chart();

		/*
		this.refresh = setInterval(() => {
			this.update();
		}, 30 * 1000);
		*/
	}

	/*
	componentWillUnmount() {
		clearInterval(this.refresh);
	}
	*/

	chart = () => {
		if(this.state.total) {
			const values = []; 
			const categories = [];

			/*
			this.state.total.forEach((i) => {
				categories.push(moment(i.timestamp).format("DD MMM @ HH:mm"));
				values.push(Number(i.reward.split(' ')[0]));
			});
			*/

			for(var j = 0; j < this.state.total.length; j++) {
				const i = this.state.total[j];

				//categories.push(moment(i.timestamp).format("DD MMM @ HH:mm"));
				categories.push(moment(i.timestamp).format("DD MMM"));
				values.push(Number(i.reward.split(' ')[0]));
			}

			return Highcharts.chart('network-rew-chart', {
				chart: {
					type: 'area',
					backgroundColor: "transparent",
					height: "225rem"
				},
				title: {
					text: ""
				},
				subtitle: {
					align: 'right',
					verticalAlign: 'bottom'
				},
				legend: {
					enabled: false,
				},
				colors: ["#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000"],
				xAxis: {
					categories: [...categories],
					lineColor: "transparent",
					labels: {
						style: {
							color: "#000000"
						}
					},
					showLastLabel: false,
					showFirstLabel: false
				},
				yAxis: {
					title: {
						text: ''
					},
					gridLineColor: "transparent",
					opposite: true,
					labels: {
						style: {
							color: "#000000"
						},
						x: -25
					}
				},
				plotOptions: {
					area: {
						fillOpacity: 0.5,
						fillColor: {
							linearGradient: [0, 0, 0, 175],
							stops: [
								[0, "#b2b2b2"],
								[1, "#fafafa"]
							]
						}
					}
				},
				credits: {
					enabled: false
				},
				series: [{
					name: 'balance',
					data: [...values],
					marker: {
						fillColor: "#00cc69"
					}
				}]
			});
		}
		
	}

	render() {
		return (
			<div className="text-center">
				<div className="network-rew-title text-left">Gaming Guild</div>
				<div className="text-left mt-2 mb-5">
					<UserGuildSettings/>
				</div>
				<div className="network-rew-title text-left">Network Rewards</div>
				<div className="mt-5">
					<div className="network-rew-sm">Last pending reward</div>
					<div className="network-rew-bg">{this.state.reward}</div>
				</div>
				<div className="mt-5">
					<div className="network-rew-sm">Overall pending rewards</div>
					<div className="network-rew-bg">{this.state.overall}</div>
				</div>
				<div id="network-rew-chart" className="network-rew-chart mt-5"/>
			</div>
		);
	}
}

export default NetworkRewards;
