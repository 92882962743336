const regexes = {
    email: "^\\S+@\\S+\\.\\S+$",
    username: "^[a-z][a-z0-9]{3,19}$",
    password: "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])\\S{6,64}$",
    website: "^https?:\\/\\/(www\\.)?[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\\.[a-zA-Z0-9()]{1,6}([-a-zA-Z0-9()@:%+.~#?&\\/\\/=]*)$",
    gameName: "^[a-zA-Z][a-z0-9A-Z]{3,19}$",
    achievementName: "^[a-z0-9A-Z].{4,35}$",
    achievementAlias: "^[a-zA-Z][a-z0-9A-Z]{4,20}$",
    highscoreName: "^[a-z0-9A-Z].{4,35}$",
    highscoreAlias: "^[a-zA-Z][a-z0-9A-Z]{4,20}$",
    productName: "^[a-z0-9A-Z].{4,35}$",
    productAlias: "^[a-zA-Z][a-z0-9A-Z]{4,20}$"
}

export default regexes;
