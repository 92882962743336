import React from "react";
import Moment from "moment-timezone";

import QUDOServer from "../../helpers/QUDOServerConnection";

const fallBackAvatar = require("../../images/definitive/icon-avatar.png")
//const achievement = require("../../images/definitive/transaction-achievement.png");

class Achievements extends React.Component {
	constructor(props) {
		super(props);
		this.state = { list: [] };
	}

	componentDidMount() {
		const timezone = Moment.tz.guess();

        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/profile/achievements/all`, {
            withCredentials: true
        })
        .then(result => {
            let parsedList = [];

            result.data.map(ach => {
                parsedList.push({
                    lastEarned: ach.lastEarned,
                    from: ach.game,
                    gameName: ach.gameName,
					name: ach.name,
					description: ach.description,
					reward: ach.reward
                });

				return parsedList;
            });

            parsedList = parsedList.sort((a,b) => {
                return new Date(b.lastEarned).getTime() - new Date(a.lastEarned).getTime();
            });

            this.setState({
				list: parsedList,
                timezone: timezone
			});
        });
    }

	render() {
		return (
			<div className="achievements-container">
                {/* <div className="text-center" id="title">Achievements</div> */}
                {this.state.list.length !== 0 ?
                    <>
                        <div className="text-center">
                            {/* <p className="pl-1" style={{float: "left"}}>Achievements</p> */}
                            {/* <p style={{float: "right"}}>See all</p> */}
                        </div>
                        <div style={{clear: "both"}} className="">
                        {this.state.list.length !== 0 && this.state.list.map(i => (
                            <div key={Math.random()} className="achievements-ach-card row shadow-sm">
                                <div className="col-2 pl-0">
                                    <div>
                                        {/*}
                                        <img src={achievement} alt="achievement icon" className="rounded-circle img-fluid arrowimage-nobg"/>
                                        <img
                                            src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${i.from}`}
                                            style={{height: "1rem", position: "absolute", marginTop: "1.25rem", marginLeft: "1.25rem"}}
                                            className="rounded-circle"
                                            alt="senders avatar"
                                            onError={i => i.target.src=fallBackAvatar}
                                        />
                                        {*/}
                                        <img
                                            alt="Game avatar"
                                            src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${i.from}`}
                                            className="img-fluid rounded-circle"
                                            style={{maxWidth: "45px", maxHeight: "45px"}}
                                            onError={i => i.target.src=fallBackAvatar}
                                        />
                                    </div>
                                </div>

                                <div className="row col-9">	
                                    <div className="col-10">
                                        <div className="achievements-ach-name">{i.name}</div> 
                                        <div className="achievements-game-name">{i.gameName ? i.gameName : i.from}</div>
                                        <div className="achievements-game-name">Earned: {Moment(new Date(i.lastEarned)).tz(this.state.timezone).format("DD MMM YYYY @ HH:mm")}</div>
                                    </div> 
                                </div>

                                <div className="row col-1">	
                                    <div className="col-10">
                                        <div className="achievements-ach-name" style={{color: "#00cc69"}}>
                                            {i.reward}
                                        </div> 
                                        <div className="achievements-game-name">
                                            QUDO
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        ))}
                        </div>  
                    </>
                :
                <div className="text-center">
                    Seems like you don't have any achievement yet!
                    Go play something!
                </div>}
			</div>
		);
	}
}

export default Achievements;
