import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import useWindowSize from '../../helpers/useWindowSize';

const ChallengesIcon = require('../../images/definitive/icn-challenges-warn.svg');

export default function PartnerBanner() {
    const windowSize = useWindowSize();
    const isMobile = (windowSize && windowSize.width < 768);

    return (
        <a target='_blank'
            rel='noopener noreferrer'
            href="https://challenges.qudo.io"
            className='main-inner-container w-100 enable-click py-4'
            style={{
                display: 'block',
                color: '#FCD902',
                backgroundColor: '#282725',
                textDecoration: 'none'
            }}
        >
            <div className='row'>
                <div
                    className={`col-12 col-md-5 align-self-center ${isMobile ? 'text-center mb-3' : 'text-right'}`}
                    style={{ fontSize: '2rem', fontWeight: 'bold' }}
                >
                    <div>Claim Points</div>
                </div>
                <div className='col-12 col-md-2 align-self-center text-center'>
                    <img
                        src={ChallengesIcon}
                        alt='Join guild icon'
                        style={{ maxWidth: '5rem', maxHeight: '5rem' }}
                    />
                </div>
                <div
                    className={`col-12 col-md-5 align-self-center ${isMobile ? 'text-center mt-2' : 'text-left'}`}
                    style={{ fontSize: '2rem', fontWeight: 'bold' }}
                >
                    <div className='d-inline-block'>Win Rewards</div>
                </div>
            </div>
        </a>
    );
}
