import React, { useState, useEffect } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

import QUDOServer from '../../helpers/QUDOServerConnection';

import useDebounce from '../../helpers/useDebounce';
import {ServiceTypes, getDefaultServiceImage} from '../../helpers/serviceTypes';

import {ImageUploadModal} from "../components/modals/ImageUploadModal";
import {ServiceGalleryModal} from "../components/modals/GalleryModals";
import {/* SuccessModal, FailureModal, */ MessageModal} from "../components/modals/modals.js";

import { NumberInput} from "../components/forms/number";
import { ButtonInput} from "../components/forms/buttons";
import { DropdownInput} from "../components/forms/dropdowns";
import { CheckboxInput} from "../components/forms/checkboxes";
import { DisplayNameInput, TextAreaInput, WebsiteInput} from "../components/forms/text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
const homeIcon = require("../../images/definitive/icn-home.png");
const walletIcon = require("../../images/definitive/icn-wallet.png");

const defaultServiceIcon = require("../../images/definitive/icn-service-default.png");

export default function Service(props) {
    const [valid, setValid] = useState(false);

    const [types, setTypes] = useState([]);
    const [defaultServiceLogo, setDefaultServiceLogo] = useState(defaultServiceIcon);

	//const [name, setName] = useState("");
	const [tempName, setTempName] = useState(props.service ? props.service.name : '');
	const name = useDebounce(tempName, 250);
    
    const [type, setType] = useState(props.service && props.service.type ? props.service.type : 'other');
    const [defaultIcon, setDefaultIcon] = useState(props.service && props.service.defaultIcon ? props.service.defaultIcon : 'other');
    const [displayName, setDisplayName] = useState(props.service ? props.service.display_name : '');
    const [description, setDescription] = useState(props.service ? props.service.description : '');
    const [website, setWebsite] = useState(props.service ? props.service.website : '');
    const [price, setPrice] = useState(props.service ? props.service.price : 0);
    const [estimatedTime, setEstimatedTime] = useState(props.service ? props.service.estimatedTime : 0);
    const [servicePublic, setServicePublic] = useState(props.service ? props.service.public : false);
    const [serviceEnabled, setServiceEnabled] = useState(props.service ? typeof props.service.enabled === 'boolean' ? props.service.enabled : true: true);

    const [form, setForm] = useState(null);

    const [galleryModal, setGalleryModal] = useState(false);

    const [uploading, setUploading] = useState(false);
    const [deletedImage, setDeletedImage] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(null);

    //const [successModal, setSuccessModal] = useState(false);
    //const [failureModal, setFailureModal] = useState(false);
	const [update, setUpdate] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [editing, setEditing] = useState(true);

    const [submitting, setSubmitting] = useState(false);

    const [indexedPrice, setIndexedPrice] = useState(props.service ? props.service.fiatPrice !== 0 : false);

    /*
    useEffect(() => {
        console.log(props);
    });
    */

    useEffect(() => {
        var typesArr = [];

        const keys = Object.keys(ServiceTypes);

        for(var i = 0; i < keys.length; i++) {
            const key = keys[i];

            typesArr.push({
                key: key,
                label: ServiceTypes[key]
            });
        }

        setTypes(typesArr);
    }, []);

    useEffect(() => {
        const defaultLogo = getDefaultServiceImage(
            ServiceTypes[type] !== ServiceTypes.other ? ServiceTypes[type] : ServiceTypes[defaultIcon]
        );

        setDefaultServiceLogo(defaultLogo);
    }, [props.service, type, defaultIcon]);

    useEffect(() =>
    {
        if (props.service)
        {
            setType(props.service && props.service.type ? props.service.type : 'other');
            setDefaultIcon(props.service && props.service.defaultIcon ? props.service.defaultIcon : 'other');
            setDisplayName(props.service.display_name);
            setDescription(props.service.description);
            setWebsite(props.service.website);
            setPrice(props.service.price);
            setEstimatedTime(props.service.estimatedTime);
            setServicePublic(props.service.public);
            setServiceEnabled(props.service.enabled ? props.service.enabled : true);
            setEditing(false);
        }
    }, [props.service]);

    /*
	useEffect(() => {
		if(!props.service && name) {
			QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/service/exists?name=${name}`)
			.then(data => {
				if(data.data.exists) {
                    setNameErrorMessage('A service with this name already exists!');
                    setNameInvalid(true);
                    setValid(false);
				} else {
                    setNameInvalid(false);
				}
			});
		}
	}, [props.service, name]);
    */

    useEffect(() => {
        if(!displayName || !price || !estimatedTime || !description) {
            setValid(false);
        } else {
            setValid(true);
        }
    }, [props.service, name, displayName, price, estimatedTime, description, website]);

    function deleteItem() {
        setSubmitting(true);
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/service/delete`, {
            service: props.service.name
        }, {
            withCredentials: true
        })
        .then((result) => {
            setSubmitting(false);
            props.refresh();
        });
    }

    // value ignore this, not needed, but just in case it passes a value to no cry about it :P
    // owPublic = overwrite public state, to force a specific value, used in button's call to avoid useState async
    // owEnabled = overwrite enabled state, to force a specific value, used in button's call to avoid useState async
    // from_auto_public = overwrite isUpdate, to controll the call (submit), used in auto_public
    // owBody = overwrite body, to force a specific object
    const submit = ({value = false, owPublic = false, owEnabled = false, from_auto_public = false, owBody}) => {
        setSubmitting(true);

        console.log(owBody);

        const body = owBody ? owBody : {
            service: name,
            type: type,
            defaultIcon: defaultIcon,
            display_name: displayName,
            website: website,
            description: description,
            public: owPublic ? true : servicePublic,
            price: price,
            estimatedTime: estimatedTime,
            indexedPrice: indexedPrice,
            enabled: owEnabled ? true : serviceEnabled
        }

        var url = '';

        const is_auto_public = body.public && !props.service && !from_auto_public; // new service but wants to be public outright

        const isUpdate = from_auto_public ? true : 
                         props.service ? true : false;

        if(isUpdate) {
            url = `${process.env.REACT_APP_QUDO_SERVER}/service/update`;
        } else {
            url = `${process.env.REACT_APP_QUDO_SERVER}/service/create`;
        }

        QUDOServer.post(url, body, { withCredentials: true })
        .then(result => {
            setSubmitting(false);
            //Show pending state message if user changed from private to public
            setUpdate(true);
            if(props.service && !props.service.public && servicePublic) {
                setModalMessage(
                    "Success! The QUDO team has received a public state change request for this service that's waiting to be confirmed."
                    + " You will be notified when a decision about the public state your service has been made."
                );
                return;
            }
            if(result.data && result.data.message){
                let out = result.data.message;

                if(result.data.code === 0){
                    if(is_auto_public) {
                        body.service = result.data.serviceID;
                        submit({owPublic: true, from_auto_public: true, owBody: body})
                        console.log("Auto public called update 0");
                        return;
                    }

                    if(from_auto_public){
                        out = "Success! Service created and is pending approval to public.";
                    }
                    console.log("success update 0");
                }

                if(result.data.code === 1){ // data.code 1 should be 'insufficient stake'
                    const msg = owEnabled 
                     ? <>Not enough <b>stake</b> to create this service.<br/></>
                     : <>Service {isUpdate ? 'updated' : "created"}, but it's <b>not enabled</b> due to <u>insufficient stake</u>.<br/>
                         If you are unfamiliar with this process, contact QUDO support team for help. Tips below. <br/> 
                        </>;
                    const h = {
                        id: "HomeIcon_messageModel",
                        href: process.env.REACT_APP_HOME,
                        src: homeIcon,
                        alt: "Home/Community on MessageModel"
                    }
                    const b = {
                        id: "WalletIcon_messageModel",
                        href: process.env.REACT_APP_USER_WALLET,
                        src: walletIcon,
                        alt: "Wallet"
                    }
                    out = <>
                        {msg}
                        <hr/>
                        <div style={{maxWidth:"fit-content", margin:"auto", textAlign:"left", display:"flex", flexDirection:"column"}}>
                            <Link to={h.href} target='_blank' rel='noopener noreferrer' key={h.src}
                             className="hover-underline" style={{textDecoration: 'none', color: 'inherit', display:"flex", alignItems:"flex-end"}}>
                                <img id={h.id} src={h.src} alt={h.alt} className="icon-32px" style={{marginRight: "12px"}}/>
                                <div className="hover-underline">Check the service <u>minimum</u> stake at the home page.</div>
                            </Link>
                            
                            <Link to={b.href} target='_blank' rel='noopener noreferrer' key={b.src}
                             className="hover-underline" style={{textDecoration: 'none', color: 'inherit', display:"flex", alignItems:"flex-end"}}>
                                <img id={b.id} src={b.src} alt={b.alt} className="icon-32px" style={{marginRight: "12px"}}/>
                                <div className="hover-underline">Check your <u>current and minimum</u> stake at the wallet page.</div>
                            </Link>
                        </div>
                        

                    </>;
                }

                setModalMessage(<>
                        {out}
                        <hr/>
                        <div className="d-inline-block mt-0" style={{maxWidth: '30%', width:"100%", minWidth: '200px'}}>
                            <ButtonInput label={`${"Close"}`} trigger disabled={false}
                                set={() => {setModalMessage('');props.refresh();}}
                                style={{backgroundColor: "#fbd731", maxHeight:"48px"}} />
                        </div>
                </>);
            return;} 
            setModalMessage('Success'); 
        })
        .catch(error => {
            setSubmitting(false);
            setUpdate(false);
            setModalMessage(error.errorMessage);
        });
    }

    function saveImage(e) {
        //console.log(image);

        //setUploadedImage(URL.createObjectURL(e));

        const formData = new FormData();
        formData.append('image', e);
        formData.append('service', props.service.name);

        const config = {
            withCredentials: true
        }

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/service/image/replace`, formData, config)
        .then(data => {
            setUpdate(true);
            setModalMessage('Success');

            //setUploadedImage(URL.createObjectURL(e));

            //reset();
            //props.refresh();
        })
        .catch(error => {
            setUpdate(false);
            setModalMessage(error.errorMessage);
        });
    }

    function deleteImage() {
        const body = {
            service: props.service.name
        }

        const config = {
            withCredentials: true
        }

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/service/image/delete`, body, config)
        .then(data => {
            setUpdate(true);
            setModalMessage('Success');

            //setDeletedImage(true);

            //reset();
            //props.refresh();
        })
        .catch(error => {
            setUpdate(false);
            setModalMessage(error.errorMessage);
        });
    }

    const fiatTooltip = (
        <Tooltip placement="right" className="">
            <span className="text-light"><small>
                Link the value of this product to USD. The qudo value of the product will be adjusted
                periodically to match the USD value set during creation/modification.
            </small></span>
        </Tooltip>
    );

    const enabledTooltip = (
        <Tooltip placement="right" className="">
            <span className="text-light"><small>
                The minium stake per service can be found on the home page,
                and your current minium stake can be found on the wallet page.<br/>
                If you are unfamiliar with this process, contact QUDO support team, we'll gladly help.
            </small></span>
        </Tooltip>
    );

    const publicTooltip = (
        <Tooltip placement="right" className="">
            <span className="text-light"><small>
                Will make the service public. Enabling will require QUDO's team approval.
            </small></span>
        </Tooltip>
    );

    const editService = (editing) && (
            <Form ref={(el) => setForm(el)}>
                <DropdownInput
                    label="Service Type"
                    initial={ServiceTypes[type]}
                    set={setType}
                    choices={types}
                />
    
                        {/*}
                        {
                        ((!props.service) || (props.service && !props.service.imageID))
                        && ServiceTypes[type] === ServiceTypes.other
                        && (
                            <DropdownInput
                                label="Service Icon"
                                initial={ServiceTypes[defaultIcon]}
                                set={setDefaultIcon}
                                choices={types}
                            />
                        )}
                        {*/}
    
                        {/*}
                        <NameInput
                            //placeholder="Name of the product (unique)"
                            placeholder="Unique ID"
                            disabled={props.service}
                            initial={tempName}
                            set={setTempName}
                            invalid={nameInvalid}
                            errorMessage={nameErrorMessage}
                        />
                        {*/}
    
                <DisplayNameInput
                    type="service"
                    initial={displayName ? displayName : ''}
                    value={displayName ? displayName : ''}
                    set={setDisplayName}
                    maxLength={50}
                    //placeholder="Display name (optional)"
                    placeholder="Service Title"
                />
    
                <TextAreaInput
                    placeholder="Description"
                    initial={description ? description : ''}
                    value={description ? description : ''}
                    set={setDescription}
                    maxLength={1000}
                />
    
                <WebsiteInput 
                    name="Website"
                    placeholder="Website (optional)"
                    initial={website}
                    set={setWebsite}
                    ignoreValidation={true}
                />
    
                <NumberInput
                    title="Average Estimated Completion Time"
                    unit="hours"
                    min={0}
                    step={0.5}
                    initial={estimatedTime}
                    value={estimatedTime}
                    setValue={setEstimatedTime}
                />
    
                <NumberInput
                    title="Price"
                    unit="QUDO"
                    min={0}
                    max={3_000_000}
                    initial={price}
                    value={price}
                    setValue={setPrice}
                />

                {! (props.service) && (<div style={{marginBottom:"4px"}}>
                    <CheckboxInput
                        id="servicePublic"
                        initial={props.service ? props.service.public : false}
                        set={setServicePublic}
                        value={servicePublic}
                        label="Service is already available for Hire by Game Developers"
                    />
                    <small>
                        <span>Sets to Public</span>
                        <OverlayTrigger placement="right" overlay={publicTooltip}>
                            <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                        </OverlayTrigger>
                    </small> </div>
                ) }

                <div className="row mt-1 mb-3 mx-2 user-select-none">
                    <div className="col text-right" onClick={() => setIndexedPrice(!indexedPrice)}>
                        <small>
                            <span>Index value to USD</span>
                            <OverlayTrigger placement="right" overlay={fiatTooltip}>
                                <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                            </OverlayTrigger>
                        </small>
                        <input
                            className="ml-2 align-middle"
                            type="checkbox"
                            checked={indexedPrice}
                            onChange={() => setIndexedPrice(!indexedPrice)}
                        />
                    </div>
                </div>
                
    
                {props.service && (
                    <div className="mt-2 mb-3">
                        <div className="mb-3">
                            <ButtonInput
                                trigger
                                label="Edit Gallery"
                                set={setGalleryModal}
                                disabled={!props.service}
                                style={{backgroundColor: "#fbd731"}}
                            />
                        </div>

                        { props.service.enabled === false ? ( 
                            <>
                            <CheckboxInput
                                id="servicePublic"
                                initial={true}
                                set={setServiceEnabled}
                                value={serviceEnabled}
                                label="Enable Service"
                            />
                            <small>
                                <span>Make sure to have enough Stake</span>
                                <OverlayTrigger placement="right" overlay={enabledTooltip}>
                                    <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                                </OverlayTrigger>
                            </small>
                            </>
                        ) : props.service.publicStatePending ? (
                            <div>
                                The QUDO team has received a public state change request for this service that's waiting to be confirmed.
                                You will be notified when a decision about the public state your service has been made.
                            </div>
                        ) : (
                            <>
                            <CheckboxInput
                                id="servicePublic"
                                initial={props.service ? props.service.public : false}
                                set={setServicePublic}
                                value={servicePublic}
                                label="Service is available for Hire by Game Developers"
                            />
                            <small>
                                <span>Sets to Public</span>
                                <OverlayTrigger placement="right" overlay={publicTooltip}>
                                    <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                                </OverlayTrigger>
                            </small>
                            </>
                        )}
                    </div>
                )}
    
                <ButtonInput 
                    label={!submitting ? "Submit" : "Submitting..."} 
                    set={submit} 
                    disabled={!valid || submitting}
                    style={
                        (!valid || submitting)
                        ? {cursor: 'initial'}
                        : {backgroundColor: "#fbd731"}
                    }
                    trigger
                />
    
                <ButtonInput 
                    label="Cancel"
                    set={() => {
                        props.refresh();
                    }}
                    trigger
                />
    
                {
                    props.service && (
                        <div className="mt-4">
                            <ButtonInput
                                label="Delete service"
                                disabled={submitting}
                                set={deleteItem} 
                                style={{backgroundColor: "#f44336"}}
                                trigger
                            />
                        </div>
                    )
                }
            </Form>
    );

    const viewService = (props.service && !editing) && (
        <div className="borders">
            <div className="mb-3 mt-4 pt-2 col-12 border-bottom cursor-pointer"
                onClick={() => setEditing(true)}>
                <h1 className="custom-header mb-4">Service Type</h1>
                <p>{ServiceTypes[type]}</p>
            </div>
            <div className="mb-3 mt-4 col-12 border-bottom cursor-pointer"
                onClick={() => setEditing(true)}>
                <h1 className="custom-header mb-4">Name</h1>
                <p>{displayName}</p>
            </div>
            <div className="mb-3 mt-4 col-12 border-bottom cursor-pointer"
                onClick={() => setEditing(true)}>
                <h1 className="custom-header mb-4">Description</h1>
                <p>{description}</p>
            </div>
            {website ? (
            <div className="mb-3 mt-4 col-12 border-bottom cursor-pointer"
                onClick={() => setEditing(true)}>
                <h1 className="custom-header mb-4">Website</h1>
                <p>{website}</p>
            </div>
            ) : null}
            <div className="mt-4 mb-3 col-12 border-bottom cursor-pointer"
                onClick={() => setEditing(true)}>
                <h1 className="custom-header mb-4">Estimated Time</h1>
                <p>{estimatedTime}</p>
            </div>
            <div className="mt-4 col-12 border-bottom cursor-pointer"
                onClick={() => setEditing(true)}>
                <h1 className="custom-header mb-4">Price</h1>
                <p>{price}</p>
            </div>
        </div>
    );

    return (
        <div>
            <div className="col-12 mx-auto">
                {/* TITLE */}
                <div className={`text-break edit-screen-menu`}>
                    {props.service ? "Edit" : "Add"} Service
                </div>

                <>
                    <div className='mb-4 text-center'>
                        <div>
                            <img
                                alt="Service logo"
                                style={{maxWidth: '35%', minWidth: '200px'}}
                                src={
                                    uploadedImage && !deletedImage ? (
                                        uploadedImage
                                    ) : (
                                        props.service && props.service.imageID && !deletedImage ? (
                                            `${process.env.REACT_APP_QUDO_SERVER}/api/images/getbyservice?service=${props.service.name}&rand=${Date.now()}`
                                        ) : (
                                            defaultServiceLogo
                                        )
                                    )
                                }
                                onError={(i) => {
                                    i.target.src = defaultServiceLogo;
                                }}
                            />
                        </div>

                        {
                        props.service &&
                        <>  
                            <div className="mt-1">
                                <div>
                                    <div className="d-inline-block mt-1" style={{maxWidth: '50%', width:"100%", minWidth: '200px'}}>
                                        <ButtonInput 
                                            trigger
                                            //label="Change Image"
                                            label="Change Logo"
                                            set={setUploading}
                                            disabled={!props.service}
                                            style={{backgroundColor: "#fbd731E0", maxHeight: '40px'}}
                                        />
                                    </div>

                                    {((uploadedImage && !deletedImage) || (props.service && props.service.imageID && !deletedImage)) && (
                                    <div className="d-inline-block mt-1" style={{maxWidth: '50%', width:"100%", minWidth: '200px', paddingLeft: '8px'}}>
                                        <ButtonInput
                                            trigger
                                            label="Delete Logo"
                                            set={() => deleteImage()}
                                            disabled={!props.service}
                                            style={{backgroundColor: "rgba(244, 67, 54, 80)", maxHeight: '40px'}}
                                        />
                                    </div> )}
                                </div>
                                <div>
                                { props.service.enabled === false ? (
                                    <div className="d-inline-block mt-2" style={{maxWidth: '50%', width:"100%", minWidth: '200px'}}>
                                        <ButtonInput 
                                            trigger
                                            label={!submitting ? "Enable Service" : "Trying to enable..."}
                                            set={() => {submit({owEnabled: true})}}
                                            disabled={!props.service}
                                            style={!submitting ? {backgroundColor: "#fbd731"}: {}}
                                        />
                                    </div>
                                ) : !props.service.public ? (
                                    <div className="d-inline-block mt-2" style={{maxWidth: '50%', width:"100%", minWidth: '200px'}}>
                                        <ButtonInput 
                                            trigger
                                            label={`${!props.service.publicStatePending ? !submitting ? "Set to Public" : 'Updating...' : "Waiting approval"}`}
                                            set={() => {submit({owPublic: true})}}
                                            disabled={props.service.publicStatePending}
                                            style={!props.service.publicStatePending && !submitting ? {backgroundColor: "#fbd731"} : {} }
                                        />
                                    </div>
                                ) : ( <> </> )}
                                </div>
                            </div>
                        </>
                        }
                    </div>
                </>

                {/* FORM */}
                {(props.service) ?
                    (!editing ?
                        viewService
                        :
                        editService
                    ) :
                    editService
                }

                {/* MODALS */}
                <>
                    {/*}
                    <SuccessModal 
                        show={successModal} 
                        hide={() => {
                            reset();
                            props.refresh();
                            setSuccessModal(!successModal);
                        }}
                    />
                    <FailureModal
                        show={failureModal}
                        hide={() => {
                            reset();
                            props.refresh();
                            setFailureModal(!failureModal);
                        }}
                    />
                    {*/}

                    <MessageModal
                        show={!!modalMessage}
                        message={modalMessage}
                        hide={() => {
                            if(update) {
                                props.refresh();
                            }

                            setUpdate(false);
                            setModalMessage('');
                        }}
                    />

                    {props.service && uploading && (
                        <ImageUploadModal
                            title='Service Logo'
                            show={uploading}
                            hide={() => setUploading(false)}
                            saveImage={saveImage}
                            saveFormat={'image/png'}
                            //maxSizeMB={2}
                            maxWidth={1024}
                            maxHeight={1024}
                            aspectRatios={[
                                {
                                    name: '1:1',
                                    ratio: Number(1/1)
                                }
                            ]}
                        />
                    )}

                    {props.service && galleryModal &&
                        <ServiceGalleryModal
                            isEditing={true}
                            show={galleryModal}
                            hide={() => setGalleryModal(false)}
                            service={props.service.name}
                        />
                    }
                </>
            </div>
        </div>
    );
}
