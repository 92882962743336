import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
//import Swiper from 'react-id-swiper';
//import 'swiper/css/swiper.css';

import { TopRewardingDropdown, periodToString } from "../components/forms/dropdowns";

import useDebounce from "../../helpers/useDebounce";
import useWindowSize from "../../helpers/useWindowSize";

import QUDOServer from "../../helpers/QUDOServerConnection";

const helpButton = require("../../images/definitive/help-button-1.png");

//const sliderForward = require("../../images/definitive/icn-slider-forward.png");

const blackCorner = require('../../images/banners/QUDO_Corner_Black.png');
const yellowCorner = require('../../images/banners/QUDO_Corner_Yellow.png');

const fallBack = require("../../images/fallbackAvatar.png");
//const arrowDown = require("../../images/definitive/icn-down.png");
//const arrowDownWhite = require("../../images/definitive/icn-down-white.png");

export function TopRewardingTotals() {
    const [filter, setFilter] = useState("1week");
    const [availableFilters, setAvailableFilters] = useState([]);

    const [networkRewards, setNetworkRewards] = useState(null);
    const [performanceRewards, setPerformanceRewards] = useState(null);

    useEffect(() => {
        getTotalRewards();
    }, []);

    function getTotalRewards() {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/totalRewards`)
            .then(async data => {
                let availableFilters = [];

                for (let period in data.data.networkRewards) {
                    const exists = await availableFilters.find(e => {
                        return e === period;
                    });

                    if (!exists) {
                        availableFilters.push(period);
                    }
                }

                setAvailableFilters(availableFilters);
                setFilter(availableFilters[availableFilters.length - 1]);

                setNetworkRewards(data.data.networkRewards);
                setPerformanceRewards(data.data.performanceRewards);
            });
    }

    return (
        networkRewards && performanceRewards &&
        <>
            <div className="row col-6 mr-2 px-0">
                <div className="col-auto qudo-now-text mt-2 mr-2 px-0">
                    Rewards
                </div>
                <div className="col px-0 position-relative">
                    <TopRewardingDropdown
                        id="qudo-now-dropdown"
                        color="grey"
                        currentFilter={filter}
                        setCurrentFilter={setFilter}
                        availableFilters={availableFilters}
                    />
                </div>
            </div>

            <div className="row col-6">
                <div className="col-7">
                    <div className="qudo-now-total">
                        <div className="qudo-now-text-xs qudo-now-total-title mt-1">
                            Total Number
                        </div>
                        <div className="qudo-now-text-sm mt-1">
                            {Number(performanceRewards[filter].count).toFixed(0)}
                        </div>
                    </div>
                </div>
                <div className="col-5">
                    <div className="qudo-now-total">
                        <div className="qudo-now-text-xs qudo-now-total-title mt-1">
                            Total qudo
                        </div>
                        <div
                            className="qudo-now-text-sm mt-1"
                            style={{ color: "#fcd902" }}
                        >
                            {Number(performanceRewards[filter].value).toFixed(0)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export function TopRewardingGames(props) {
    //const windowSize = useWindowSize(true);
    const windowSize = useDebounce(useWindowSize(true), 250);

    const [mostPlayed, setMostPlayed] = useState(null);
    const [mostPlayedFilter, setMostPlayedFilter] = useState("1week");
    const [mostPlayedAvailableFilters, setMostPlayedAvailableFilters] = useState([]);
    const [selectedMostPlayed, setSelectedMostPlayed] = useState(null);
    const [selectedMostPlayedModal, setSelectedMostPlayedModal] = useState(false);

    const [mostRewarding, setMostRewarding] = useState(null);
    const [mostRewardingFilter, setMostRewardingFilter] = useState("1week");
    const [mostRewardingAvailableFilters, setMostRewardingAvailableFilters] = useState([]);
    const [selectedMostRewarding, setSelectedMostRewarding] = useState(null);
    const [selectedMostRewardingModal, setSelectedMostRewardingModal] = useState(false);

    const [renderImages, setRenderImages] = useState(true);
    const [renderDetails, setRenderDetails] = useState(true);

    useEffect(() => {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/top/games`)
            .then(async (result) => {
                let mostPlayedFilters = [];
                let mostRewardingFilters = [];

                for (let period in result.data.mostPlayedGames) {
                    if (result.data.mostPlayedGames[period].length !== 0) {
                        result.data.mostPlayedGames[period] = result.data.mostPlayedGames[period].slice(0, 5);
                        mostPlayedFilters.push(period);

                        for (var i = 0; i < result.data.mostPlayedGames[period].length; i++) {
                            result.data.mostPlayedGames[period][i].index = i;
                        }
                    }
                }

                for (let period in result.data.mostRewardingGames) {
                    if (result.data.mostRewardingGames[period].length !== 0) {
                        mostRewardingFilters.push(period);

                        for (var j = 0; j < result.data.mostRewardingGames[period].length; j++) {
                            result.data.mostRewardingGames[period][j].index = j;
                        }
                    }
                }

                setTimeout(() => {
                    setMostPlayedAvailableFilters(mostPlayedFilters);
                    setMostRewardingAvailableFilters(mostRewardingFilters);
                    setMostPlayedFilter(mostPlayedFilters.length > 0 ? mostPlayedFilters[mostPlayedFilters.length - 1] : '');
                }, 500);

                setTimeout(() => {
                    setMostPlayed(result.data.mostPlayedGames);
                    setMostRewarding(result.data.mostRewardingGames);

                    if (
                        result.data.mostPlayedGames
                        && result.data.mostPlayedGames[mostPlayedFilter]
                        && result.data.mostPlayedGames[mostPlayedFilter][0]
                    ) {
                        setSelectedMostPlayed(result.data.mostPlayedGames[mostPlayedFilter][0]);
                    }

                    if (
                        result.data.mostRewardingGames
                        && result.data.mostRewardingGames[mostRewardingFilter]
                        && result.data.mostRewardingGames[mostRewardingFilter][0]
                    ) {
                        setSelectedMostRewarding(result.data.mostRewardingGames[mostRewardingFilter][0]);
                    }

                    //console.log(mostPlayed, electedMostPlayed, mostRewarding, selectedMostRewarding);
                }, 500);

                setTimeout(() => {
                    //Force the window size hook to run
                    window.dispatchEvent(new Event('resize'));
                }, 1500);
            });
    }, []);

    useEffect(() => {
        //Reset/Rerender once the filter changes
        const copy = mostPlayed;

        setMostPlayed(null);

        setTimeout(() => {
            setMostPlayed(copy);

            if (copy && copy[mostPlayedFilter] && copy[mostPlayedFilter][0]) {
                setSelectedMostPlayed(copy[mostPlayedFilter][0]);
            }
        }, 250);
    }, [mostPlayedFilter]);

    useEffect(() => {
        //Reset/Rerender once the filter changes
        const copy = mostRewarding;

        setMostRewarding(null);

        setTimeout(() => {
            setMostRewarding(copy);

            if (copy && copy[mostRewardingFilter] && copy[mostRewardingFilter][0]) {
                setSelectedMostRewarding(copy[mostRewardingFilter][0]);
            }
        }, 250);
    }, [mostRewardingFilter]);

    /*
    useEffect(() => {
        setSelectedMostPlayedModal(true);
    }, [selectedMostPlayed]);

    useEffect(() => {
        setSelectedMostRewardingModal(true);
    }, [selectedMostRewarding]);
    */

    useEffect(() => {
        checkWindowSize();
    }, [windowSize]);

    function checkWindowSize() {
        const element = document.getElementById('mostRewardingRow');

        if (element && element.clientWidth) {
            if (windowSize.width < 850 || (windowSize.width >= 992 && windowSize.width < 1115)) {
                setRenderDetails(false);
            } else {
                setRenderDetails(true);
            }

            setTimeout(() => {
                if (element.clientWidth < 450) {
                    setRenderImages(false);
                } else {
                    setRenderImages(true);
                }
            }, 500);
        }

        //console.log(windowSize, element);
    }

    return (
        <>
            {mostRewarding && (
                <div className="main-inner-container py-4" style={{ position: "relative" }}>
                    <div className="row mx-0 my-4">
                        <div className="main-inner-title col-12 col-sm-8">
                            Most Rewarding Games
                            <img
                                src={helpButton}
                                className="w-100 enable-click ml-2"
                                style={{ minWidth: '1rem', maxWidth: '2rem' }}
                                alt="help button"
                                data-tip
                                data-for='MostRewardingGames'
                            />
                            <ReactTooltip id='MostRewardingGames' type='light'>
                                <span>
                                    These are the games that are currently rewarding more QUDO tokens.
                                </span>
                            </ReactTooltip>
                        </div>
                        <div className="col-12 col-sm-4">
                            <TopRewardingDropdown
                                id="qudo-now-dropdown"
                                currentFilter={mostRewardingFilter}
                                setCurrentFilter={setMostRewardingFilter}
                                availableFilters={mostRewardingAvailableFilters}
                            />
                        </div>
                    </div>
                    <div className="row row-default">
                        <div id="mostRewardingRow" className={renderDetails ? "col-5" : "col-12"}>
                            {mostRewarding[mostRewardingFilter].map((game, index) => {
                                if (index >= 5) {
                                    return null;
                                }

                                function set(game) {
                                    setSelectedMostRewarding(game);

                                    if (!renderDetails) {
                                        setSelectedMostRewardingModal(true);
                                    }
                                }

                                return renderRow(
                                    game,
                                    game.index,
                                    set,
                                    { color: 'white', backgroundColor: '#fbd731' },
                                    renderImages
                                );
                            })}
                        </div>
                        <div className="col-7">
                            {selectedMostRewarding && (
                                renderDetails ? (
                                    renderGameCard(
                                        selectedMostRewarding,
                                        mostRewardingFilter,
                                        false
                                    )
                                ) : (
                                    renderGameModal(
                                        selectedMostRewardingModal,
                                        setSelectedMostRewardingModal,
                                        selectedMostRewarding,
                                        mostRewardingFilter,
                                        false
                                    )
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}

            {mostPlayed && (
                <div className="main-inner-container py-4" style={{ position: "relative" }}>
                    <div className="row mx-0 my-4">
                        <div className="main-inner-title col-12 col-sm-8">
                            Most Played Games
                            <img
                                src={helpButton}
                                className="w-100 enable-click ml-2"
                                style={{ minWidth: '1rem', maxWidth: '2rem' }}
                                alt="help button"
                                data-tip
                                data-for='MostPlayedGames'
                            />
                            <ReactTooltip id='MostPlayedGames' type='light'>
                                <span>
                                    These are the most played games at the moment.
                                </span>
                            </ReactTooltip>
                        </div>
                        <div className="col-12 col-sm-4">
                            <TopRewardingDropdown
                                id="qudo-now-dropdown"
                                currentFilter={mostPlayedFilter}
                                setCurrentFilter={setMostPlayedFilter}
                                availableFilters={mostPlayedAvailableFilters}
                            />
                        </div>
                    </div>
                    <div className="row row-default">
                        <div id="mostPlayedRow" className={renderDetails ? "col-5" : "col-12"}>
                            {(mostPlayed && mostPlayedFilter) && mostPlayed[mostPlayedFilter].map((game, index) => {
                                function set(game) {
                                    setSelectedMostPlayed(game);

                                    if (!renderDetails) {
                                        setSelectedMostPlayedModal(true);
                                    }
                                }

                                return renderRow(
                                    game,
                                    game.index,
                                    set,
                                    { color: '#fbd731', backgroundColor: '#282725' },
                                    renderImages
                                );
                            })}
                        </div>
                        <div className="col-7">
                            {selectedMostPlayed && (
                                renderDetails ? (
                                    renderGameCard(
                                        selectedMostPlayed,
                                        mostPlayedFilter,
                                        true
                                    )
                                ) : (
                                    renderGameModal(
                                        selectedMostPlayedModal,
                                        setSelectedMostPlayedModal,
                                        selectedMostPlayed,
                                        mostPlayedFilter,
                                        true
                                    )
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

function renderRow(i, index, set, style, renderImage) {
    return (
        <div
            key={i.game}
            className="row top-games-list-card enable-click mb-2"
            style={{ overflow: 'hidden' }}
            onClick={() => set(i)}
        >
            <div
                className="col-3 games-text-bg text-center"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    color: style && style.color ? style.color : 'white',
                    backgroundColor: style && style.backgroundColor ? style.backgroundColor : '#fbd731'
                }}
            >
                <div style={{ flex: 'auto' }}>
                    #{index + 1}
                </div>
            </div>
            {renderImage && (
                <div
                    className="col-3 text-center"
                    style={{
                        minHeight: "95px",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <img
                        alt={i.game}
                        src={
                            i.smallImageURL ? i.smallImageURL :
                                i.imageURL ? i.imageURL :
                                    `${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${i.game}&size=S`
                        }
                        onError={i => i.target.src = fallBack}
                        className="my-1"
                        style={{
                            maxWidth: '90px',
                            maxHeight: '90px',
                            //height: '90px'
                        }}
                    />
                </div>
            )}
            <div
                className={`col-${renderImage ? '6' : '9'}`}
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <div>
                    <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                        {i.game_display_name ? i.game_display_name : i.game}
                    </div>
                    <div style={{ fontSize: '1rem' }}>
                        {i.gamedev_display_name ? i.gamedev_display_name : i.gamedev}
                    </div>
                </div>
            </div>
        </div>
    );
}

function renderGameCard(i, filter, dark) {
    let imageURL = i.imageURL ? i.imageURL : `${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${i.game}&size=M`

    return (
        <div
            className="top-games-details-card"
            style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25) 25%, rgba(0, 0, 0, 1)), url(${imageURL})` }}
        >
            <img
                alt="Ranking"
                src={dark ? blackCorner : yellowCorner}
                style={{ zIndex: '1', width: '125px' }}
            />

            <div
                className="d-inline-block games-text-bg"
                style={{
                    zIndex: '2',
                    position: 'absolute',
                    top: '25px',
                    marginLeft: '-105px',
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    color: dark ? '#fbd731' : 'white'
                }}
            >
                #{i.index + 1}
            </div>

            {/* <div className="d-inline-block mx-3"> */}
            <div className="float-right py-2 mx-4">
                <div className="games-text-bg" style={{ fontSize: "2.5rem" }}>
                    Rewards
                </div>
                <div className="games-text-sm" style={{ fontSize: "1rem", marginTop: '-10px' }}>
                    ({periodToString(filter)})
                </div>
            </div>

            <div className="row mt-4 mx-5">
                <div className="col-6 text-left mb-3">
                    <div className="trg-text-sm mb-2">
                        Total number
                    </div>
                    <div className="qudo-now-text-huge mb-3" style={{ fontSize: "3rem" }}>
                        {i.count}
                    </div>
                </div>

                <div className="col-6 text-left mb-3">
                    <div className="trg-text-sm mb-2">
                        Total qudo
                    </div>
                    <div className="qudo-now-text-huge mb-3" style={{ fontSize: "3rem" }}>
                        {Number(i.total).toFixed(0)}
                    </div>
                </div>
            </div>

            <div className="py-3 mx-3">
                <div className="games-text-bg" style={{ fontSize: "2rem" }}>
                    {i.game_display_name ? i.game_display_name : i.game}
                </div>
                <div className="games-text-sm" style={{ fontSize: "1rem", marginTop: '-5px' }}>
                    {i.gamedev_display_name ? i.gamedev_display_name : i.gamedev}
                </div>
            </div>
        </div>
    );
}

function renderGameModal(show, set, i, filter, dark) {
    return (
        <Modal show={show} onHide={() => set(false)}>
            <Modal.Header
                closeButton
                className='modal-header-position'
            />
            <Modal.Body className="p-0">
                <div>
                    <img
                        alt="Ranking"
                        src={dark ? blackCorner : yellowCorner}
                        style={{ zIndex: '1', width: '125px' }}
                    />

                    <div
                        className="d-inline-block games-text-bg"
                        style={{
                            zIndex: '2',
                            position: 'absolute',
                            top: '25px',
                            marginLeft: '-105px',
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                            color: dark ? '#fbd731' : 'white'
                        }}
                    >
                        #{i.index + 1}
                    </div>

                    <div
                        className="row mx-auto"
                        style={{ maxWidth: '90%', marginTop: '-100px' }}
                    >
                        <div className="col-12">
                            <div
                                className="games-text-bg text-right"
                                style={{ color: "#282725", fontSize: "2.5rem" }}
                            >
                                Rewards
                            </div>
                            <div
                                className="games-text-sm text-right"
                                style={{ color: "#282725", fontSize: "1rem", marginTop: '-10px' }}
                            >
                                ({periodToString(filter)})
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <img
                                alt="Game banner"
                                className="img-fluid my-2"
                                style={{ maxHeight: '250px' }}
                                src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${i.game}&size=xM`}
                                onError={i => i.target.src = fallBack}
                            />
                        </div>
                        <div className="col-12">
                            <div className="row my-4">
                                <div className="col-6 text-left">
                                    <div className="trg-text-sm mb-2" style={{ color: "#282725" }}>
                                        Total number
                                    </div>
                                    <div className="qudo-now-text-huge" style={{ color: "#282725", fontSize: "2.5rem" }}>
                                        {i.count}
                                    </div>
                                </div>

                                <div className="col-6 text-left">
                                    <div className="trg-text-sm mb-2" style={{ color: "#282725" }}>
                                        Total qudo
                                    </div>
                                    <div className="qudo-now-text-huge" style={{ color: "#282725", fontSize: "2.5rem" }}>
                                        {Number(i.total).toFixed(0)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-3">
                                <div className="games-text-bg" style={{ color: "#282725", fontSize: "2rem" }}>
                                    {i.game_display_name ? i.game_display_name : i.game}
                                </div>
                                <div className="games-text-sm" style={{ color: "#282725", fontSize: "1rem", marginTop: '-5px' }}>
                                    {i.gamedev_display_name ? i.gamedev_display_name : i.gamedev}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
