import React, {useState} from 'react';

import classes from './titleContainer.scss';

const fallBack = require("../../../../images/fallbackAvatar.png");

export default function TitleContainer(props)
{
    const [forceUpdateImage, setForceUpdateImage] = useState(Date.now());

    return (
        <div>
            <h1 className="custom-h1 mt-3 align-middle ml-2">
                <img
                    className="game-icon"
                    src={
                        `${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${props.name}&rand=${forceUpdateImage}`
                    }
                    onError={(i) => (i.target.src = fallBack)}
                    alt="Game icon"
                />
                <span className="align-middle">{props.displayName}</span>         
            </h1>
        </div>
    )
}