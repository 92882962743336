import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";

import { topMetaModal } from "./MetaModals";
import { ButtonInput } from "../forms/buttons";
import { PasswordInput, UsernameInput } from "../forms/text";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import QUDOServer from '../../../helpers/QUDOServerConnection';
import useDebounce from '../../../helpers/useDebounce';

function tooManyRequests() {
    return (
        <>
            <div style={{ fontSize: '22px' }}>
                Too many verification email requests.
            </div>
            <div style={{ marginTop: "14px", fontWeight: 'lighter', fontSize: "20px" }}>
                Please <a href="mailto:qudo.support@blockbastards.io">contact us</a> directly and we will help.
            </div>
        </>
    );

}

function ResendEmailComponent(props) {
    const { msg, data } = props;
    const [hideEmail, setHideEmail] = useState(true);
    const [loading, setLoading] = useState(false);


    async function submit() {
        //props.failure("resending");
        setLoading(true);
        console.log("resend email");
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/auth/verification/resend`, {
            email: data.fullEmail
        })
            .then(resp => {
                if (!resp.errorMessage) {
                    props.success("Email sent successfully");
                }
            })
            .catch(err => {
                if (err.errorMessage === "Too many verification email requests. Please contact us directly and we will help.") {
                    props.failure(tooManyRequests());
                    return;
                }
                props.failure(err.errorMessage);
            });
    }

    return (
        <>
            <div style={{ fontSize: '22px' }}>
                {msg}
            </div>
            {(data && data.fullEmail && data.censoredEmail && (
                <div style={{ marginTop: "14px" }}>
                    <div style={{ fontWeight: 'lighter', fontSize: "20px" }}>
                        <div>
                            Verification email
                            <div onClick={() => { setHideEmail(!hideEmail) }}
                                className="d-inline-block enable-click"
                                style={{ marginLeft: '8px', fontSize: '18px', zIndex: '2' }}
                            > <FontAwesomeIcon icon={hideEmail ? faEyeSlash : faEye} />
                            </div>
                        </div>
                        <div style={{ fontSize: "17px" }}>
                            <b>{hideEmail ? data.censoredEmail : data.fullEmail}</b>
                        </div>
                    </div>

                    {!loading && (
                        <div onClick={submit} style={{
                            cursor: 'pointer',
                            fontSize: '20px', marginTop: '36px', display: "flex", justifyContent: "center",
                            backgroundColor: "#fbd731", borderRadius: ".5rem", padding: '8px'
                        }}>
                            Resend
                        </div>
                    )}
                    {loading && (<>
                        <div style={{
                            fontSize: '20px', marginTop: '36px', display: "flex", justifyContent: "center",
                            backgroundColor: "#fbd731", borderRadius: ".5rem", padding: '8px'
                        }}>
                            <div className="loading-spinning-image" >
                                Loading...
                            </div>
                        </div>
                    </>
                    )}

                    <div style={{ fontSize: '12px', fontWeight: 'lighter', marginTop: '24px', marginBottom: '-12px' }}>
                        If your email address is wrong or you don't receive the email, please <a href="mailto:qudo.support@blockbastards.io">contact us</a>.
                    </div>
                </div>
            ))}
        </>
    );
}


export default function ResendEmail(props) {
    
    return (
        <Modal
            show={props.show}
            onHide={() => props.setShow(null)}
            dialogClassName="meta-box">
            <Modal.Body className="scroll-100 pt-0">
                <div className="row meta-body">
                    <ResendEmailComponent msg={props.message}
                        data={props.data} failure={props.failure} success={props.success} />
                </div>
            </Modal.Body>
        </Modal>
    );
}
