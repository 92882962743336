import React, {useState, useEffect} from 'react';

import {Loading} from '../../components/App';
import QUDOServer from '../../helpers/QUDOServerConnection';
import Register from './t_register_component';

function message(status, msg) {
    console.log(`${status}@Register: ${msg}`);
    if (status === "failure") {
        alert(`Error: ${msg}`);
    }
}

export default function TelegramRegister() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 200); // This is here for the loading screen to show up for a bit, a cool effect
    }, []);

    return(
        <div>
            {loading ? (
                <Loading/>
            ) : (
                <>
                    <div>
                        <Register
                            success={(msg) => message("success", msg)}
                            failure={(msg) => message("failure", msg)}
                        />	
                    </div>
                </>
            )}
        </div>
    );
}
