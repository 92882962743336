import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import QUDOServer from "../../../helpers/QUDOServerConnection";

import * as actions from "../../../actions";

import GameDetailsEdition from "./gameEditDetails";
import GameImageUpload from "./images/gameImageUpload";
import UserAvatarsUpload from "./images/userAvatarsUpload";
import AchievementsList from "./achievementsList";
import HighscoresList from "./highscoresList";
import ProductsList from "./productsList";
import Achievement from "./achievement";
import Highscore from "./highscore";
import Product from "./product";
import TitleContainer from "./titleContainer/titleContainer";
//import BugsCenterColumn from './bugs/bugsCenterColumn/bugsCenterColumn';
//import BugsLeftColumn from './bugs/bugsLeftColumn/bugsLeftColumn';

import { GoBackArrow } from "../../navigation/arrows";
import { ButtonInput } from "../../components/forms/buttons";
import { GameGalleryModal } from "../../components/modals/GalleryModals";
import Earnings from "./earnings/earnings";
import GameBalance from "./gameBalance/balances";
import GameGalleryImages from "./gameGalleryImage/gameGalleryImages";
import RemoveGame from "./removeGame/removeGame";
import BugsNavBar from './bugs/bugsNavBar';
//import BugsRightColumn from "./bugs/bugsRightColumn/bugsRightColumn";
import Bugs from "./bugs/bugs";
import ChallengesList from "./challengesList";
import Challenge from "./challenge";

function PageSelector({ activepage, setActivePage, showEarnings }) {
    const subMenu = (
        <div className="col-lg-4 col-md-4 col-sm col text-center mb-0 pl-2 ml-4">
            <span
                className={`edit-screen-menu${activepage === "achievements" ? "" : "-disabled"} sub-menu`}
                style={activepage === "achievements" ? { cursor: "default" } : { cursor: "pointer" }}
                onClick={() => setActivePage("achievements")}>
                Achievements
            </span>
            <span
                className={`edit-screen-menu${activepage === "highscores" ? "" : "-disabled"} ml-3 sub-menu`}
                style={activepage === "highscores" ? { cursor: "default" } : { cursor: "pointer" }}
                onClick={() => setActivePage("highscores")}>
                Highscores
            </span>
            <span
                className={`edit-screen-menu${activepage === "challenges" ? "" : "-disabled"} ml-3 sub-menu`}
                style={activepage === "challenges" ? { cursor: "default" } : { cursor: "pointer" }}
                onClick={() => setActivePage("challenges")}>
                Challenges
            </span>
        </div>
    );

    const subMenuCheck = () => {
        return activepage === 'highscores' || activepage === 'achievements' || activepage === 'challenges';
    }

    return (
        <>
            <div className="row ml-4 pl-2">
                {showEarnings && (
                    <span
                        className={`edit-screen-menu${activepage === "earnings" ? "" : "-disabled"} mr-3`}
                        style={activepage === "earnings" ? { cursor: "default" } : { cursor: "pointer" }}
                        onClick={() => setActivePage("earnings")}>
                        Earnings
                    </span>
                )}
                <span
                    className={`edit-screen-menu${activepage === "about" ? "" : "-disabled"} mr-3`}
                    style={activepage === "about" ? { cursor: "default" } : { cursor: "pointer" }}
                    onClick={() => setActivePage("about")}>
                    Setup
                </span>
                <span
                    className={`edit-screen-menu${subMenuCheck() ? "" : "-disabled"} mr-3`}

                    style={subMenuCheck() ? { cursor: "default" } : { cursor: "pointer" }}
                    onClick={() => setActivePage("achievements")}>
                    Rewards
                </span>
                <span
                    className={`edit-screen-menu${activepage === "products" ? "" : "-disabled"} mr-3`}
                    style={activepage === "products" ? { cursor: "default" } : { cursor: "pointer" }}
                    onClick={() => setActivePage("products")}>
                    Purchases
                </span>

                <span
                    className={`edit-screen-menu${activepage === "bugs" ? "" : "-disabled"}`}
                    style={activepage === "bugs" ? { cursor: "default" } : { cursor: "pointer" }}
                    onClick={() => setActivePage("bugs")}>
                    Bugs
                </span>
            </div>
            <div className="row col-12 col col-md col-lg-4">
                {
                    (subMenuCheck()) &&
                    subMenu
                }
            </div>
        </>
    )
}

function LeftColumn(props) {
    const dispatch = useDispatch();

    if (props.activepage === "about" || !props.game) {
        return (
            <GameDetailsEdition
                game={props.game}
                //increases={props.increases}
                //stake={props.stake}
                setDummyName={props.setDummyName}
                toggleEdit={props.setEditing}
                fetchNewInfo={() => dispatch(actions.fetchUser())}
            />
        );
    } else if (props.activepage === "earnings") {
        return (
            <Earnings
                //name={props.game.name}
                activePage={props.activepage}
                gameAccount={props.game}
                type='left'
            />
        );
    } else if (props.activepage === "achievements") {
        return (
            <AchievementsList
                refresh={props.refresh}
                refreshAchievementsList={props.achievementAdded}
                editAchievement={(i) => i === props.achievement ? props.setAchievement(null) : props.setAchievement(i)}
                highlight={props.achievement}
                key={props.refreshKeys}
                game={props.game}
            />
        );
    } else if (props.activepage === "highscores") {
        return (
            <HighscoresList
                refresh={props.refresh}
                editHighscore={(i) => i === props.highscore ? props.setHighscore(null) : props.setHighscore(i)}
                highlight={props.highscore}
                key={props.refreshKeys}
                game={props.game}
            />
        );
    } else if (props.activepage === "challenges") {
        return (
            <ChallengesList
                refresh={props.refresh}
                refreshKeys={props.refreshKeys}
                game={props.game}
                selected={props.challenge ? props.challenge.name : ''}
                editChallenge={(i) => i.name === props.challenge?.name ? props.setChallenge(null) : props.setChallenge(i)}
            />
        );
    } else if (props.activepage === "bugs") {
        return (
            <Bugs
                submenu={props.submenuBugs}
                game={props.game}
                activeFilterBugs={props.activeFilterBugs}
                setReportChosen={props.setReportChosen}
                reportChosen={props.reportChosen}
                type="left"
                update={props.update}
                setUpdate={props.setUpdate}
            />
        );
    } else {
        return (
            <ProductsList
                refreshProductList={props.productAdded}
                editProduct={(product) =>
                    product.alias === props.product?.alias ? props.setProduct(null) : props.setProduct(product)}
                highlight={props.product}
                key={props.refreshKeys}
                game={props.game}
            />
        );
    }
}

function RightColumn(props) {
    const [gameBalance, setGameBalance] = useState(0);

    useEffect(() => {
        if (props.game) {
            QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/game/balance?game=${props.game.name}`, {
                withCredentials: true
            })
                .then((response) => {
                    setGameBalance(response.data.balance);
                })
        } else {
            setGameBalance(0);
        }
    }, []);

    function verifyAlias(type, alias) {
        if (type === 'achievement') {
            const exists = props.game.achievements.find(e => e.alias === alias);

            if (exists) {
                return false;
            } else {
                return true;
            }
        }

        if (type === 'highscore') {
            const exists = props.game.highscores.find(e => e.alias === alias);

            if (exists) {
                return false;
            } else {
                return true;
            }
        }

        if (type === 'product') {
            const exists = props.game.products.find(e => e.alias === alias);

            if (exists) {
                return false;
            } else {
                return true;
            }
        }
    }

    function deleteAlias(type, alias) {
        if (type === 'product') {
            props.game.products = props.game.products.filter(p => p.alias !== alias);
        }
    }

    if (props.type === 'generic' && props.activepage !== 'earnings' && props.activepage !== 'about' && props.submenuBugs !== 'manage') {
        return (
            <>
                <GameBalance
                    gameAccount={props.game}
                    activePage={props.activepage}
                //gameBalance={gameBalance}
                />
                <Earnings
                    //gameBalance={gameBalance}
                    gameAccount={props.game}
                    //name={props.game.name}
                    type='overall'
                />
            </>
        )
    }

    if (props.type === 'generic' && props.activepage === 'earnings') {
        return (
            <Earnings
                //gameBalance={gameBalance}
                gameAccount={props.game}
                //name={props.game.name}
                type='right'
            />
        )
    }

    if (props.submenuBugs === 'manage' && props.activepage === 'bugs' && props.type === 'generic') {
        return (
            <Bugs
                submenu={props.submenuBugs}
                game={props.game}
                activeFilterBugs={props.activeFilterBugs}
                type='right'
                setReportChosen={props.setReportChosen}
                reportChosen={props.reportChosen}
                update={props.update}
                setUpdate={props.setUpdate}
            />
        )
    }

    if (props.activepage === "earnings") {
        return (
            <Earnings
                //gameBalance={gameBalance}
                gameAccount={props.game}
                //name={props.game.name}
                type='center'
            />
        )
    } else if (props.activepage === "about") {
        return (
            <>
                <GameBalance
                    gameAccount={props.game}
                    activePage={props.activepage}
                //gameBalance={gameBalance}
                />
                <RemoveGame
                    gameAccount={props.game}
                />
            </>
        )
    } else if (props.activepage === "achievements") {
        return (
            <Achievement
                key={Math.random()}
                refresh={props.refresh}
                balance={gameBalance}
                achievement={props.achievement}
                achievementAdded={props.achievementAdded}
                game={props.game}
                verifyAlias={verifyAlias}
            />
        )
    } else if (props.activepage === "highscores") {
        return (
            <Highscore
                key={Math.random()}
                refresh={props.refresh}
                balance={gameBalance}
                highscore={props.highscore}
                game={props.game}
                verifyAlias={verifyAlias}
            />
        )
    } else if (props.activepage === 'challenges') {
        return (
            <Challenge
                game={props.game}
                challenge={props.challenge}
                refresh={() => props.refresh()}
            />
        )
    } else if (props.activepage === 'bugs') {
        return (
            <Bugs
                submenu={props.submenuBugs}
                game={props.game}
                activeFilterBugs={props.activeFilterBugs}
                type='center'
                setReportChosen={props.setReportChosen}
                reportChosen={props.reportChosen}
                update={props.update}
                setUpdate={props.setUpdate}
            />
        )
    } else {
        return (
            <Product
                key={Math.random()}
                refresh={props.refresh}
                productAdded={props.productAdded}
                product={props.product}
                game={props.game}
                verifyAlias={verifyAlias}
                deleteAlias={deleteAlias}
            />

        )
    }
}

export default function GameEditScreen(props) {
    //const dispatch = useDispatch();

    //const games = useSelector(state => state.games);
    const games = useSelector(state => state.info.games);

    const game = games.find((element) => element.name === props.game);
    const [activepage, setActivePage] = useState(game && game.accepted && game.account_name ? 'earnings' : 'about');
    const [editing, setEditing] = useState(false);
    const [refreshKeys, setRefreshKeys] = useState(Math.random());
    const [galleryModal, setGalleryModal] = useState(false);

    const [submenuBugs, setSubmenuBugs] = useState('setup');
    const [activeFilterBugs, setActiveFilterBugs] = useState('all');
    const [reportChosen, setReportChosen] = useState();
    const [update, setUpdate] = useState(false);

    const [product, setProduct] = useState();
    const [highscore, setHighscore] = useState();
    const [achievement, setAchievement] = useState();
    const [challenge, setChallenge] = useState();

    const [dummyName, setDummyName] = useState("");
    const [stake, setStake] = useState(0);

    const [productAdded, setProductAdded] = useState(false);
    const [achievementAdded, setAchievementAdded] = useState(false);

    //const minIncreases = 600 / parseFloat(process.env.REACT_APP_INCREASESPERBLOCKMINLIMIT);
    //const maxIncreases = 600 / parseFloat(process.env.REACT_APP_INCREASESPERBLOCKMAXLIMIT);
    //const [increases, setIncreases] = useState(minIncreases);

    function refresh() {
        setRefreshKeys(Math.random())
        setProduct(null)
        setHighscore(null)
        setAchievement(null)
        setChallenge(null)
    }

    useEffect(() => {
        setEditing(false);

        if (activepage !== 'bugs') {
            setSubmenuBugs('setup');
        }

        return (() => {
            refresh();
        });
    }, [activepage]);

    function saveImage(e, callback) {
        const formData = new FormData();
        formData.append('image', e);
        formData.append('gamename', game ? game.name : dummyName);

        const config = {
            withCredentials: true
        }

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/image/replace`, formData, config)
            .then(data => {
                //dispatch(actions.fetchUser());
                callback(false);
            });
    }

    /*
    function deleteGameImage(callback) {
        const body = {
            gamename: game.name
        }

        const config = {
            withCredentials: true
        }

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/image/delete`, body, config)
        .then(data => {
            //dispatch(actions.fetchUser());
            callback(true);
        });
    }
    */

    /*
    async function saveGalleryImage(e) {
        const formData = new FormData();
        formData.append('image', e);
        formData.append('gamename', game ? game.name : dummyName);

        const config = {
            withCredentials: true
        }

        await axios.post(`${process.env.REACT_APP_QUDO_SERVER}/game/media/image/replace`, formData, config);
    }
    */

    /*
    async function saveGalleryVideos(videoList) {
        const body = {
            gamename: game.name,
            videos: videoList
        }

        const config = {
            withCredentials: true
        }

        await axios.post(`${process.env.REACT_APP_QUDO_SERVER}/game/media/videos/replace`, body, config);
    }
    */
    const titleContainer = (
        <div className="row col-12 mb-3">
            <GoBackArrow customClass='col-custom align-self-center mt-2' />
            <TitleContainer name={game.name} displayName={game.display_name ? game.display_name : game.name} imageID={game.imageID} />
        </div>
    );

    const firstColumn = activepage === 'about' ?
        (
            <>
                <GameImageUpload
                    isEditing={editing || true}
                    name={game.name}
                    displayName={game.display_name}
                    imageID={game.imageID}
                    saveImage={saveImage}
                //deleteImage={deleteGameImage}
                />

                <UserAvatarsUpload
                    isEditing={editing}
                    name={game.name}
                    displayName={game.display_name}
                //avatars={game.avatars}
                />

                <div className="col-12 my-3">
                    <div className="section-title mb-2">
                        Game Gallery
                    </div>

                    <div className="ml-0 pl-0 mr-0 pr-0 row">
                        <ButtonInput
                            trigger
                            label={editing || true ? "Edit Gallery" : "Open Gallery"}
                            set={setGalleryModal}
                            className="editgamebtn w-100 my-2"
                            style={{ backgroundColor: "#fbd731" }}
                        />
                    </div>

                    <div className="ml-0 pl-0 mr-0 pr-0">
                        <GameGalleryImages
                            game={game.name}
                        />
                    </div>

                    {galleryModal &&
                        <GameGalleryModal
                            isEditing={editing || true}
                            show={galleryModal}
                            hide={() => setGalleryModal(false)}
                            game={game.name}
                        //saveImage={saveGalleryImage}
                        //saveVideos={saveGalleryVideos}
                        />
                    }
                </div>
            </>
        ) :
        (
            <>
                <LeftColumn
                    activepage={activepage}
                    refresh={refresh}
                    achievement={achievement}
                    achievementAdded={achievementAdded}
                    product={product}
                    productAdded={productAdded}
                    highscore={highscore}
                    setAchievement={setAchievement}
                    setHighscore={setHighscore}
                    setProduct={setProduct}
                    refreshKeys={refreshKeys}
                    game={game}
                    setEditing={setEditing}
                    setDummyName={setDummyName}
                    //increases={increases}
                    stake={stake}
                    submenuBugs={submenuBugs}
                    activeFilterBugs={activeFilterBugs}
                    reportChosen={reportChosen}
                    setReportChosen={setReportChosen}
                    update={update}
                    setUpdate={setUpdate}
                    challenge={challenge}
                    setChallenge={setChallenge}
                />
            </>
        );

    const secondColumn = activepage === 'about' ?
        (
            <LeftColumn
                activepage={activepage}
                refresh={refresh}
                achievement={achievement}
                product={product}
                highscore={highscore}
                setAchievement={setAchievement}
                setHighscore={setHighscore}
                setProduct={setProduct}
                refreshKeys={refreshKeys}
                game={game}
                setEditing={setEditing}
                setDummyName={setDummyName}
                //increases={increases}
                stake={stake}
            />
        ) :
        (
            <RightColumn
                activepage={activepage}
                game={game}
                achievement={achievement}
                achievementAdded={setAchievementAdded}
                product={product}
                productAdded={setProductAdded}
                //increases={increases}
                //setIncreases={setIncreases}
                stake={stake}
                setStake={setStake}
                highscore={highscore}
                refresh={refresh}
                submenuBugs={submenuBugs}
                reportChosen={reportChosen}
                setReportChosen={setReportChosen}
                update={update}
                setUpdate={setUpdate}
                challenge={challenge}
            />
        )

    return (
        <div>
            {
                (game) &&
                titleContainer
            }
            {
                (props.game) &&
                <div className="mb-2">
                    <PageSelector
                        activepage={activepage}
                        setActivePage={setActivePage}
                        showEarnings={game && game.accepted && game.account_name}
                    />
                </div>
            }
            {activepage === 'bugs' &&
                <div className="col-12 mb-3">
                    <BugsNavBar
                        submenuBugs={submenuBugs}
                        setSubmenuBugs={setSubmenuBugs}
                        game={game}
                        activeFilterBugs={activeFilterBugs}
                        setActiveFilterBugs={setActiveFilterBugs}
                        setReportChosen={setReportChosen}
                    />
                </div>
            }
            <div className="gamedevact-edit-screen-container row">
                <div className="col-12 col-md-12 col-lg-4 mx-auto row">
                    <div className="col-12">
                        {firstColumn}
                    </div>
                </div>

                {!props.game && (
                    <div className="col-12 col-md-4 row edit-screen-right-column mx-auto mb-4">
                        <GoBackArrow />
                    </div>
                )}

                <div className="col-12 col-md-12 col-lg-8 row edit-screen-right-column mb-5 mx-auto pt-0">
                    <div className="col-12 col-lg-6 mb-5">
                        {secondColumn}
                    </div>

                    <div className="col-12 col-lg-6">
                        <RightColumn
                            activepage={activepage}
                            game={game}
                            achievement={achievement}
                            product={product}
                            //increases={increases}
                            //setIncreases={setIncreases}
                            stake={stake}
                            setStake={setStake}
                            highscore={highscore}
                            refresh={refresh}
                            type="generic"
                            submenuBugs={submenuBugs}
                            reportChosen={reportChosen}
                            setReportChosen={setReportChosen}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
