import { GET_PENDING_REWARD } from "../actions/types";

export default function(state = "", action) {
	switch (action.type) {
		case GET_PENDING_REWARD:
			return action.payload || false;

		default:
			return state;
	}
}
