import React, {useState} from "react";

import {GoBackArrow} from "../../../navigation/arrows";
import {ImageUploadModal} from "../../../components/modals/ImageUploadModal";

const fallBack = require("../../../../images/fallbackAvatar.png");
const gradient = require("../../../../images/definitive/gradient.png");

export default function GameImageUpload (props) {
  const [uploading, setUploading] = useState(false);
  const [deletedImage, setDeletedImage] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [forceUpdateImage, setForceUpdateImage] = useState(Date.now());

  function saveImage(image) {
    //console.log(image);
    setUploadedImage(URL.createObjectURL(image));
    props.saveImage(image, updateImage);
  }

  /*
  function deleteImage() {
    props.deleteImage(updateImage);
  }
  */

  function updateImage(imageDeleted) {
    setDeletedImage(imageDeleted);
    setForceUpdateImage(Date.now());
  }

  return (
    <>
      <div className="col-12">
        <div className="col-12 edit-screen-image-container mx-auto" style={{ padding: 0 }}>
          <img
            className="edit-screen-image"
            /* style={ props.isEditing ? { width: "70%" } : { width: "100%" }} */
            src={
              (uploadedImage && !deletedImage)
              ? uploadedImage
              : `${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${props.name}&rand=${forceUpdateImage}`
            }
            onError={(i) => (i.target.src = fallBack)}
            alt="Game website"
          />

          <img
            className="edit-screen-gradient"
            /*
            style={ props.isEditing
                ? { width: "70%" }
                : { width: "100%", overflow: "hidden" }
            }
            */
            src={gradient}
            alt="Game website"
          />

          {props.isEditing && (
            <>
              <div className="editcoverbtn" onClick={() => setUploading(true)}>
                <div className="editcovertext">
                  {String('Edit Cover').toUpperCase()}
                </div>
              </div>

              {/*
              (uploadedImage || props.imageID) && !deletedImage && (
                <div
                  style={{
                    position: 'relative',
                    zIndex: '2',
                    marginLeft: '-20px'
                  }}
                >
                  <div
                    onClick={() => deleteImage()}
                    className='enable-click'
                    style={{
                      display: 'inline-block',
                      color: 'red',
                      fontWeight: 'bold',
                      fontSize: '2rem',
                      lineHeight: '20px'
                    }}
                  >
                    x
                  </div>
                </div>
              )
              */}
            </>
          )}
        </div>

        {uploading && (
          <ImageUploadModal
            title='Game Logo'
            show={uploading}
            hide={() => setUploading(false)}
            saveImage={saveImage}
            saveFormat={'image/png'}
            //maxSizeMB={2}
            maxWidth={1024}
            maxHeight={1024}
            aspectRatios={[
              {
                name: '1:1',
                ratio: Number(1/1)
              }
            ]}
          />
        )}
      </div>
    </>
  );
}
