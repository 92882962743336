import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';

import useWindowSize from '../../helpers/useWindowSize';

const GuildIcon = require('../../images/definitive/GuildIcon.png');

export default function JoinGuildBanner() {
    const windowSize = useWindowSize();
    const isMobile = (windowSize && windowSize.width < 768);

    const currentUser = useSelector(state => state.info);

    if(
        currentUser
        && !currentUser.guild
        && !currentUser.pendingGuildRequest
        && (
            currentUser.type === process.env.REACT_APP_USER_TYPE
            || currentUser.type === process.env.REACT_APP_GAMEDEV_TYPE
            || currentUser.type === process.env.REACT_APP_PROVIDER_TYPE
        )
    ) {
        return(
            <div
                className='main-inner-container enable-click py-4'
                style={{
                    color: '#fbd731',
                    backgroundColor: '#282725'
                }}
                onClick={() => {
                    window.location.href = process.env.REACT_APP_USER_GAME_ACTIVITY;
                }}
            >
                <div className='row'>
                    <div
                        className={`col-12 col-md-5 align-self-center ${isMobile ? 'text-center mb-3' : 'text-right'}`}
                        style={{fontSize: '1.25rem'}}
                    >
                        {isMobile ? (
                            <>
                            <div>Don't have a GUILD?</div>
                            </>
                        ): (
                            <>
                            <div>Don't have</div>
                            <div>a GUILD?</div>
                            </>
                        )}
                    </div>
                    <div className='col-12 col-md-2 align-self-center text-center'>
                        <img
                            src={GuildIcon}
                            alt='Join guild icon'
                            style={{maxWidth: '5rem', maxHeight: '5rem'}}
                        />
                    </div>
                    <div
                        className={`col-12 col-md-5 align-self-center ${isMobile ? 'text-center mt-2' : 'text-left'}`}
                        style={{fontSize: '2rem', fontWeight: 'bold'}}
                    >
                        <div className='d-inline-block'>Join one!</div>
                        <div className='d-inline-block ml-4'>
                            <FontAwesomeIcon icon={faArrowRight}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}
