import React from 'react';
import PaginationDot from './PaginationDot';

const styles = {
	root: {
        position: 'fixed',
        bottom: '15px',
        left: '50%',
        margin: 'auto'
	}
}

class Pagination extends React.Component {
	handleClick = (event, index) => {
		this.props.onChangeIndex(index);
	}

	render() {
		const { index, dots } = this.props;

		const children = [];

		for (let i = 0; i < dots; i += 1) {
			children.push(
				<PaginationDot
					key={i}
					index={i}
					active={i === index}
					onClick={this.handleClick}
				/>
			);
		}

		return (
			<div
				className={this.props.className ? this.props.className : ''}
				style={this.props.style ? this.props.style : styles.root}
			>
				{children}
			</div>
		);
	}
}

export default Pagination;
