import React, {useEffect, useState} from 'react';
import Highcharts, { format } from 'highcharts';
import moment from 'moment';

export default function EarningsExpensesChart(props)
{
    const [activeFilter, setActiveFilter] = useState('1d');
    const allBalances = props.allBalances;
    const allRewards = props.allRewards;

    const daysInterval = 15;
    const weeksInterval = 15;
    const monthsInterval = 12;

    useEffect(() =>
    {
        if (allBalances && allRewards)
        {
            chart();
        }
    }, [allRewards, allBalances]);

    useEffect(() =>
    {
        if (allBalances && allRewards)
        {
            chart();
        }
    }, [activeFilter]);


    const generateDates = () =>
    {   
        let startDate = new Date();
        let endDate = new Date();

        if (activeFilter === '1d')
        {
            startDate.setDate(startDate.getDate() - daysInterval);
        }

        if (activeFilter === '1w')
        {
            startDate.setDate(startDate.getDate() - (7 * weeksInterval));
        }

        if (activeFilter === '1m')
        {
            startDate.setMonth(startDate.getMonth() - monthsInterval);
        }

        const dateArray = [];
        let currentDate = moment(startDate);
        const stopDate = moment(endDate);

        while (currentDate <= stopDate) 
        {
            if (activeFilter === '1d' || activeFilter === '1w')
            {
                dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
                currentDate = moment(currentDate).add(
                    activeFilter === '1d' ? 1 : 7, 'days');
            }
            else
            {
                dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
                currentDate = moment(currentDate).add(1, 'months');
            }
        }
        return dateArray;
    }

    const setupValues = dates =>
    {
        let values = [[],[]];

        if (allBalances && allRewards)
        {
            dates.forEach((date) =>
            {
                let rewardsDateArr = [];
                let balancesDateArr = [];
                let rewardToAdd = 0;
                let balanceToAdd = 0;
    
                rewardsDateArr = allRewards.filter(reward => moment(new Date(reward.timestamp)).format('YYYY-MM-DD') === date);
                balancesDateArr = allBalances.filter(balance => moment(new Date(balance.time)).format('YYYY-MM-DD') === date);

                if (activeFilter === '1w')
                {
                    const daysInAWeek = 7;
    
                    let dateInUse = new Date(date);
                    
                    for (let i = 0; i < daysInAWeek; i++)
                    {
                        dateInUse.setDate(dateInUse.getDate() - 1);
                        
                        const tempRewardsDateArr = allRewards.filter(reward =>
                            moment(new Date(reward.timestamp)).format('YYYY-MM-DD') === moment(dateInUse).format('YYYY-MM-DD'));
                    
                        if (tempRewardsDateArr.length > 0)
                        {
                            tempRewardsDateArr.forEach(reward => rewardsDateArr.push(reward));
                        }
        
                        if (balancesDateArr.length <= 0)
                        {
                            balancesDateArr = allBalances.filter(balance => 
                                moment(new Date(balance.time)).format('YYYY-MM-DD') === moment(dateInUse).format('YYYY-MM-DD'));
                        }
                    }
                }
    
                if (activeFilter === '1m')
                {
                    let dateInUse = new Date(date);

                    const tempRewardsDateArr = allRewards.filter(reward =>
                        moment(new Date(reward.timestamp)).format('YYYY-MM') === moment(dateInUse).format('YYYY-MM'));

                    if (tempRewardsDateArr.length > 0)
                    {
                        tempRewardsDateArr.forEach(reward => rewardsDateArr.push(reward));
                    }
                    
                    if (balancesDateArr.length <= 0)
                    {
                        balancesDateArr = allBalances.filter(balance => 
                            moment(new Date(balance.time)).format('YYYY-MM') === moment(dateInUse).format('YYYY-MM'));
                    }
                }

                if (balancesDateArr?.length <= 0 && values[0].length === 0)
                {
                    balanceToAdd = 0;
                }

                if (balancesDateArr?.length <= 0  && values[0].length > 0)
                {
                    balanceToAdd = values[0][values[0].length - 1];
                }
    
                if (balancesDateArr?.length > 0 )
                {
                    balanceToAdd = balancesDateArr[balancesDateArr.length - 1]?.balance.split(' ')[0];
                }
    
                if (rewardsDateArr?.length >= 1);
                {
                    rewardsDateArr.forEach(reward =>
                    {
                        rewardToAdd += reward.amount;
                    });
                }
    
                values[0].push(Number(balanceToAdd));
                values[1].push(Number(rewardToAdd.toFixed(2)));
            });

            return values;
        }

        return [];
    }

    const chart = () => 
    {
		const categories = [];

        const dateGenerated = generateDates();
        const [balanceValues, rewardValues] = setupValues(dateGenerated);

		dateGenerated.forEach((i) => 
        {
            if (activeFilter === '1m')
            {
                categories.push(moment(i).format('MMM YY'));  
            }
            else
            {
                categories.push(moment(i).format('DD MMM'));
            }
		});

		return Highcharts.chart('expensesBalance', {
			chart: {
				type: "Combination chart",
				backgroundColor: "transparent",
				height: "300rem",
			},
			title: {
				text: "",
			},
			subtitle: {
				align: "right",
				verticalAlign: "bottom",
			},
			legend: {
				enabled: false,
			},
			xAxis: {
                categories: [...categories],
			},
            credits: {
                enabled: false
            },
			plotOptions: {
				area: {
					fillOpacity: 0.5,
					fillColor: {
						linearGradient: [0, 0, 0, 300],
						stops: [
							[0, "#dedede"],
							[1, "#ffffff"],
						],
					},
				}
			},
            legend: {
                align: 'center',
                enabled: true
            },
			series: [
				{
					name: 'Balance',
					data: [...balanceValues],
                    type: 'spline',
                    marker: {
						fillColor: '#fcd902',
                        lineWidth: 2
					},
                    color: '#fcd902'
				},
                {
                    type: 'column',
					name: 'Performance Rewards',
					data: [...rewardValues],
					marker: {
						fillColor: "#b2b2b2",
					},
                    color: '#b2b2b2'
				},
			]
		});
	};

    return (
        <>
            <div className="borders">
                <div className="mb-3">
                    <div className="col-12 edit-screen-stakes-sm header remove-margin">
                        <strong>Overall Game Balance/</strong> Performance Rewards
                    </div>
                </div>
                <div className="col-12 edit-screen-stakes-sm">
                    <span className={`submenus  mr-2 cursor-pointer ${activeFilter === '1m' ? '' : 'not-active'}`}
                        onClick={() => setActiveFilter('1m')}>
                        1m |
                    </span>
                    <span className={`submenus mr-2 cursor-pointer ${activeFilter === '1w' ? '' : 'not-active'}`}
                        onClick={() => setActiveFilter('1w')}>
                        1w |
                    </span>
                    <span className={`submenus cursor-pointer ${activeFilter === '1d' ? '' : 'not-active'}`}
                        onClick={() => setActiveFilter('1d')}>
                        1d
                    </span>
                </div>
                <div className="col-12 text-right not-active">
                    QUDO
                </div>
                <div id="expensesBalance"></div>
            </div>
        </>
    )
}