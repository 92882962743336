import React, {useState} from "react";
import {Form, Dropdown} from "react-bootstrap";

const arrowDown = require("../../../images/definitive/icn-down.png");
const arrowDownWhite = require("../../../images/definitive/icn-down-white.png");

export function periodToString(period) {
	switch(period) {
		case "1hour":
			return "Last Hour";
		case "6hours":
			return "Last 6 Hours";
		case "24hours":
			return "Today";
		case "1week":
			return "This Week";
        case "14days":
            return "Last 14 Days";
		case "1month":
			return "This Month";
		default:
			//return "?";
			break;
	}
}

export function TopRewardingDropdown(props) {
    return(
        <Dropdown id={props.data ? props.data.id : ""}>
            <Dropdown.Toggle as="div">
                <div id={`trg-dropdown${props.color ? `-${props.color}` : ""}`}>
                    <img
                        src={props.color === "white" ? arrowDownWhite : arrowDown}
                        className="d-inline-block edit-profile-dropdown-arrow"
                        alt="arrow down"
                    />
                    <div className="d-inline-block">
                        {periodToString(props.currentFilter)}
                    </div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu popperConfig={{strategy: "fixed"}}>
                {props.availableFilters.map(period => {
                    return (
                        <Dropdown.Item
                            id="edit-profile-filter-items"
                            key={period}
                            eventKey={period}
                            onSelect={() => props.setCurrentFilter(period)}
                        >
                            {periodToString(period)}
                        </Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export function DropdownHighscoreRewards(props) {
    return(
        <Dropdown id={props.data ? props.data.id : ""} style={props.style? props.style: {}}>
            <Dropdown.Toggle as="div">
                <div className={`${props.classes ? props.classes : ''}`}
                    id={`trg-dropdown${props.color ? `-${props.color}` : ""}`}>
                    <img
                        src={props.color === "white" ? arrowDownWhite : arrowDown}
                        className="d-inline-block edit-profile-dropdown-arrow"
                        alt="arrow down"
                    />
                    <div className="d-inline-block">
                        {props.activeFilter}
                    </div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu
                    style={{ width: "100%" }}
                >
                    {props.choices.map((choice) => {
                        const key = choice.key ? choice.key : choice;
                        const label = choice.label ? choice.label : choice;
                        return (
                            <Dropdown.Item
                                key={key}
                                eventKey={key}
                                onSelect={(e) => {
                                    props.set(key);
                                }}
                            >
                                {label}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
        </Dropdown>
    );
}

export function DropdownInput(props) {
    const [value, setValue] = useState(props.initial ? props.initial : '');
    const style = props.style ? props.style : {};

    return (
        <Form.Group controlId="dropdown" style={style}>
            <Dropdown
                className="input-field-custom cursor-pointer"
                style={props.disabled ? {pointerEvents: 'none', backgroundColor: '#e9ecef'} : {}}
            >
                
                <Dropdown.Toggle
                    as="div"
                    className="edit-profile-field-area"
                    style={{ marginTop: "auto", marginLeft: "0.8rem", height: "100%", userSelect: "none" }}
                >
                    <div
                    className="edit-field-title"
                    style={{ marginTop: "auto", marginLeft: "0.8rem", minHeight: "1rem", userSelect: "none" }}
                >
                        {props.label}
                    </div>
                    <div style={{color: props.dark ? 'white': '#282725', overflowX: 'hidden' }}>
                        {value}
                    </div>
                    <img
                        src={props.dark ? arrowDownWhite : arrowDown}
                        className="edit-profile-dropdown-arrow"
                        alt="arrow down"
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu
                    style={{ width: "100%" }}
                    className="scroll-25"
                >
                    {props.choices.map((choice) => {
                        return (
                            <Dropdown.Item
                                key={choice.key}
                                eventKey={choice.key}
                                onSelect={(e) => {
                                    setValue(choice.label);
                                    props.set(choice.key);
                                }}
                            >
                                {choice.label}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </Form.Group>
    );
}
