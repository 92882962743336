import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";

import { topMetaModal } from "./MetaModals";
import { ButtonInput } from "../forms/buttons";
import { PasswordInput, UsernameInput } from "../forms/text";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import QUDOServer from '../../../helpers/QUDOServerConnection';
import useDebounce from '../../../helpers/useDebounce';

function tooManyRequests() {
    return (
        <>
            <div style={{ fontSize: '22px' }}>
                Too many verification email requests.
            </div>
            <div style={{ marginTop: "14px", fontWeight: 'lighter', fontSize: "20px" }}>
                Please <a href="mailto:qudo.support@blockbastards.io">contact us</a> directly and we will help.
            </div>
        </>
    );

}


export default function Login(props) {
    const [searchUsername, setSearchUsername] = useState('');
    const username = useDebounce(searchUsername, 250);

    const [password, setPassword] = useState();
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (username && password /* && type */) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [username, password/* , type */]);


    async function submit() {
        QUDOServer.post(
            `${process.env.REACT_APP_QUDO_SERVER}/auth/login`,
            {
                username: username,
                password: password,
                deviceID: process.env.REACT_APP_WEBAPPUUID
            },
            {
                headers: {
                    "Content-Type": "application/json"
                },
                json: true,
                withCredentials: true
            }
        )
            .then((result) => {
                props.success(`Welcome!`);
                // props.success(`Welcome ${String(username).toLowerCase()}!`);

                // setTimeout(() => {
                //     props.success(null);
                // }, 2500);
            })
            .catch((err) => {
                if (err.errorMessage === 'Account not verified') {
                    props.switchToResendEmail(err.errorMessage, err.data)
                } else {
                    props.failure(err.errorMessage)
                }
            });
    }

    return (
        <Modal
            show={props.show}
            onHide={() => props.setShow(null)}
            dialogClassName="meta-box">
            <Modal.Body className="scroll-100 pt-0">
                {topMetaModal(props.setShow)}
                <div className="row meta-body">
                    <div className="col-12 login-main-text">
                        Hello!
                        <br />
                        Welcome Back
                    </div>
                    <div className="col-12">
                        <Form onSubmit={submit}>
                            <UsernameInput
                                set={setSearchUsername}
                                ignoreValidation={true}
                                minLength={1}
                                maxLength={100}
                                placeholder={"Email / Username"}
                            />

                            <PasswordInput
                                set={setPassword}
                            />

                            <div
                                id="forgot-password-text"
                                className="row text-center mt-0"
                            >
                                <span
                                    className="col-12 col-sm-6 mt-3"
                                >
                                    <span className="enable-click"
                                        onClick={props.switchToForgotUsername}>
                                        Forgot username?
                                    </span>
                                </span>
                                <span
                                    className="col-12 col-sm-6 mt-3"
                                >
                                    <span className="enable-click"
                                        onClick={props.switchToForgotPassword}>
                                        Forgot password?
                                    </span>
                                </span>
                            </div>

                            {/*
                                props.ual && (
                                    <div
                                        id="forgot-password-text"
                                        className="d-block text-center"
                                    >
                                        <span
                                            className="enable-click"
                                            onClick={props.toggleLoginMode}
                                        >
                                            Login with wallet
                                        </span>
                                    </div>
                                )
                                */}

                            <ButtonInput
                                trigger
                                type="submit"
                                label="Login"
                                className="form-submit-btn text-center"
                                set={submit}
                                disabled={disabled}
                            />
                        </Form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
