import React from "react";
import {Form, InputGroup} from "react-bootstrap";

import QUDOServer from "../../helpers/QUDOServerConnection";

import ServiceCard from "./serviceCard";

export default class ServicesList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: "",
            products: []
        }
    }

    componentDidMount() {
        this.setState({
            refreshKeys: this.props.refreshKeys
        });

        this.getServices();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.refreshKeys !== this.state.refreshKeys) {
            this.getServices();
            //console.log('Keys updated!');
        }
    }

    getServices = () => {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/provider/services`, {withCredentials: true})
        .then(result => {
            //console.log(result.data);
            this.setState({
                services: result.data
            });
        });
    }

    search = async (event) => {
        event.preventDefault();
        await this.setState({
            searchTerm: event.target.value
        });
    }

    filter = async (event) => {
        await this.setState({
            filter: event
        });
    }

    filterAndSearch = (i) => {
        return (
            (
                i.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())
                || (i.display_name && i.display_name.toLowerCase().includes(this.state.searchTerm.toLowerCase()))
            ) && (
                <ServiceCard
                    key={i.name}
                    service={i}
                    highlight={this.props.highlight}
                    onClick={this.props.editService}
                    newStyle={this.props.newStyle}
                />
            )
        );
    }

    render() {
        return (
            <div className="col-12 mx-auto">
                <Form className="row">
                    <InputGroup className="col-12">
                        <Form.Control 
                            id="achievementsFilterField"
                            value={this.state.searchTerm}
                            placeholder="search" 
                            onChange={this.search}
                            />
                    </InputGroup>

                </Form>
                
                {this.state.services &&
                    <div className="row mt-3 scrollable">
                        {Array.isArray(this.state.services) && this.state.services.length > 0 ? (
                            this.state.services.map(i => this.filterAndSearch(i))
                        ) : (
                            <div className='col-12 mt-2 achievementtitle'>
                                You haven't created any services yet!
                            </div>
                        )}
                    </div>
                }
            </div>
        );
    }
}
