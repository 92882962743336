import React, {useState, useEffect} from "react";
import Cookies from 'universal-cookie';
import {Modal} from "react-bootstrap";

import {ButtonInput} from "../forms/buttons";

export default function AcceptCookiesModal(props) {
    const cookies = new Cookies();
    const [show, setShow] = useState(true);

    useEffect(() => {
        const accepted = cookies.get('QUDODashboardAcceptedCookies');

        if(accepted) {
            setShow(false);
        } else {
            setShow(true);
        }
    }, []);

    function accept() {
        cookies.set(
            'QUDODashboardAcceptedCookies',
            'true',
            {
                path: '/',
                maxAge: 365 * 24 * 60 * 60 //1 year
            }
        );

        setShow(false);
    }

    return (
        <Modal
            show={show}
            //onHide={accept}
            dialogClassName="meta-box scroll-100"
        >
            <Modal.Body>
                <div className="row meta-body">
                    <div className="col-12">
                        We use cookies to enhance your experience as a user. By using QUDO you consent to this policy. Click
                        
                        <a
                            href="https://qudo.io/documents/cookie_policy.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cookie_link mx-1"
                        >
                            here
                        </a>
                        
                        to learn more.
                    </div>
                </div>

                <div className="row meta-body pt-0">
                    <div className="col-12">
                        <ButtonInput
                            //trigger
                            set={accept}
                            className="form-submit-btn text-center m-0"
                            label="I understand"
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
