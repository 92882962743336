import React from "react";
import Moment from "moment-timezone";

import QUDOServer from "../../helpers/QUDOServerConnection";

const fallBackAvatar = require("../../images/definitive/icon-avatar.png")
//const achievement = require("../../images/definitive/transaction-achievement.png");

class Products extends React.Component {
	constructor(props) {
		super(props);
		this.state = { list: [] }
	}

	componentDidMount() {
		const timezone = Moment.tz.guess();

        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/profile/products/all`, {
            withCredentials: true
        })
        .then(result => {
            let parsedList = [];

            result.data.map(prod => {
                parsedList.push({
                    lastPurchased: prod.lastPurchased,
                    from: prod.game,
                    gameName: prod.gameName,
                    name: prod.name,
                    quantity: prod.quantity
                });

				return parsedList;
            });

            parsedList = parsedList.sort((a,b) => {
                return new Date(b.lastPurchased).getTime() - new Date(a.lastPurchased).getTime();
            });

            this.setState({
				list: parsedList,
                timezone: timezone
			});
        });
    }

	render() {
		return (
			<div className="achievements-container">
                {/* <div className="text-center" id="title">Products</div> */}
                {this.state.list && this.state.list.length !== 0 ?
                    <>
                        <div style={{clear: "both"}} className="">
                        {this.state.list.length !== 0 && this.state.list.map(i => (
                            <div key={Math.random()} className="achievements-ach-card row shadow-sm">
                                <div className="col-2 pl-0">
                                    <div>
                                        {/*}
                                        <img src={achievement} alt="achievement icon" className="rounded-circle img-fluid arrowimage-nobg"/>
                                        <img
                                            src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${i.from}`}
                                            style={{height: "1rem", position: "absolute", marginTop: "1.25rem", marginLeft: "1.25rem"}}
                                            className="rounded-circle"
                                            alt="senders avatar"
                                            onError={i => i.target.src=fallBackAvatar}
                                        />
                                        {*/}
                                        <img
                                            alt="Game avatar"
                                            src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${i.from}`}
                                            className="img-fluid rounded-circle"
                                            style={{maxWidth: "45px", maxHeight: "45px"}}
                                            onError={i => i.target.src=fallBackAvatar}
                                        />
                                    </div>
                                </div>

                                <div className="row col-9">	
                                    <div className="col-8">
                                        <div className="achievements-ach-name">{i.name}</div>
                                        <div className="achievements-game-name">{i.gameName ? i.gameName : i.from}</div>
                                        <div className="achievements-game-name">Last purchased: {Moment(new Date(i.lastPurchased)).tz(this.state.timezone).format("DD MMM YYYY @ HH:mm")}</div>
                                    </div> 
                                    {i.quantity && !isNaN(i.quantity) && (
                                        <div className="col-4 text-right">
                                            x {i.quantity}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                        </div>  
                    </>
                :
                    <div className="text-center">
                        Seems like you don't have any product yet! Go unlock something!
                    </div>
                }
			</div>
		);
	}
}

export default Products;
