import React, {useState, useEffect} from "react";

import ProviderDetailsModal from "../components/modals/ProviderDetailsModal";

import useWindowSize from '../../helpers/useWindowSize';
import {ServiceTypes, getDefaultServiceImage} from '../../helpers/serviceTypes';

//const fallBackAvatar = require("../../images/fallbackAvatar.png");
const defaultServiceIcon = require("../../images/definitive/icn-service-default.png");

export default function ServiceCard(props) {
    const windowSize = useWindowSize();

    const targetWidth = 450;
    const [smallScreen, setSmallScreen] = useState(false);

    const reduceSpacingsWidth = 280;
    const [reduceSpacings, setReduceSpacings] = useState(false);

    const [showProvider, setShowProvider] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(Date.now());
    const [serviceDescription, setServiceDescription] = useState('');
    const [defaultServiceLogo, setDefaultServiceLogo] = useState(defaultServiceIcon);

    useEffect(() => {
        //Process the description
        cropDescription();

        //Update the component after a while to render the columns with the correct size
        setTimeout(() => {
            setForceUpdate(Date.now());
        }, 500);
    }, []);

    useEffect(() => {
        if(props.service && props.service.type) {
            const defaultLogo = getDefaultServiceImage(ServiceTypes[props.service.type]);
            setDefaultServiceLogo(defaultLogo);
        }
    }, [props.service]);

    useEffect(() => {
        const serviceCard = document.getElementById("servicecard");
        setSmallScreen( serviceCard && serviceCard.clientWidth < targetWidth);
        setReduceSpacings(serviceCard && serviceCard.clientWidth < reduceSpacingsWidth);
    }, [windowSize]);

    useEffect(() => {
        cropDescription();
    }, [smallScreen]);

    function cropDescription() {
        const sliceLength = smallScreen ? 175 : 90;

        var description = String(props.service && props.service.description ? props.service.description : '');

        if(description.length > sliceLength) {
            description = description.slice(0, sliceLength) + '...';
        }

        setServiceDescription(description);
    }

    const newStyle = props.newStyle;

    const yellow = "#F7D548";
    const nsc = { // newStyle config
        bottomBackground: yellow, //'rgb(40,39,37)',
        moneyColor: 'black', //'rgb(252,217,2)',
        coinColor: 'black'
    }

    if(props.service) {
        return(
            <>
            <div
                id="servicecard"
                key={props.service.name}
                className={props.className ? props.className : "col-12 user-select-none"}
                style={{marginBottom: '20px'}}
            >
                {newStyle && (
            <div style={{display: "flex", justifyContent:"center"}}>
                <div style={{width:"100%", maxWidth: '450px', borderRadius: "8px", boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",}}>
                    <div className={`${!props.onClick ? 'disable-click' : ''}`}style={{height: "72px", borderRadius:"8px 8px 0 0", padding:"12px 20px",
                        display:"flex", border: `2px solid ${props.backgroundColor || "#f0f0f0"}`, borderBlock:"none",
                        backgroundColor: "white", opacity: props.highlight ? (props.highlight.name === props.service.name ? "1" : "0.5") : "1" }} 
                    onClick={() => {if(props.onClick) {props.onClick(props.service);}}}
                    >
                        <img alt="Service logo"
                            style={{maxWidth: '100%', maxHeight: '48px', minWidth:'48px', borderRadius: "8px"}}
                            src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbyservice?service=${props.service.name}&rand=${Date.now()}`}
                            onError={(i) => {i.target.src = defaultServiceLogo;}}
                        />

                        <div style={{paddingLeft:"20px"}}>
                            <div style={{fontSize: "1rem", fontWeight: "500"}}>
                                {props.service.display_name ? props.service.display_name : props.service.name}
                            </div>
                            <div style={{fontSize: "0.8rem", fontWeight: "400", color:"#b2b2b2"}}>
                                {ServiceTypes[props.service.type]}
                            </div>
                        </div>

                    </div>

                    <div style={{height: "28px", backgroundColor:nsc.bottomBackground, borderRadius:"0 0 8px 8px", padding: "0 16px 0", display:"flex", justifyContent:"flex-end", alignItems:"flex-end"}}>
                        <div style={{color: nsc.moneyColor, maxWidth: "220px", paddingRight: `${reduceSpacings?"6px":"24px"}`, fontWeight:"400", textAlign:"right", fontSize:"1rem"}}>
                            {props.service.price ? props.service.price : '???'} 
                        </div>
                        <div style={{color: nsc.coinColor, paddingRight: `${reduceSpacings?"2px":"8px"}`, fontWeight:"500", fontSize:"large"}}>
                            QUDO
                        </div>
                    </div>

                </div>
            </div>
                )}

                {!newStyle &&(
                <div
                    className={`w-100 h-100 servicecard${props.dark ? '-dark' : ''} ${!props.onClick ? 'disable-click' : ''}`}
                    style={{
                        backgroundColor: props.backgroundColor ? props.backgroundColor : "",
                        opacity: props.highlight ? (props.highlight.name === props.service.name ? "1" : "0.5") : "1"
                    }}
                    onClick={() => {
                        if(props.onClick) {
                            props.onClick(props.service);
                        }
                    }}
                >
                    <div className="w-100">
                        <div className="row h-100 mx-0">
                            <div
                                className={`${forceUpdate && smallScreen ? "col-4 mt-2" : "col-3 my-2"} px-0`}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <img
                                    alt="Service logo"
                                    style={{maxWidth: '100%', maxHeight: '100px'}}
                                    src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbyservice?service=${props.service.name}&rand=${Date.now()}`}
                                    onError={(i) => {
                                        i.target.src = defaultServiceLogo;
                                    }}
                                />
                            </div>

                            {smallScreen && (
                                <div className="col-8 py-0 my-auto">
                                    <div className={`servicecard-title${props.dark ? '-dark' : ''} mb-0`}>
                                        {props.service.display_name ? props.service.display_name : props.service.name}
                                    </div>
                                    <div className={`servicecard-description${props.dark ? '-dark' : ''} mb-0`}>
                                        {ServiceTypes[props.service.type]}
                                    </div>
                                    {props.service.provider && props.service.provider.username && (
                                        <div
                                            className={`servicecard-description${props.dark ? '-dark' : ''} mt-1 mb-0`}
                                            style={{fontSize: '1rem', fontWeight: 'bold'}}
                                        >
                                            <div
                                                className='d-inline-block'
                                                onClick={(event) => {
                                                    event.stopPropagation();

                                                    if(props.service.provider && props.service.provider.username) {
                                                        setShowProvider(true);
                                                    }
                                                }}
                                            >
                                                {
                                                    props.service.provider.display_name
                                                    ? props.service.provider.display_name
                                                    : props.service.provider.username
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className={`${forceUpdate && smallScreen ? "col-12 mb-2" : "col-6 mt-3 mb-2"}`}>
                                {!smallScreen && (
                                <>
                                    <div className={`servicecard-title${props.dark ? '-dark' : ''} mb-0`}>
                                        {props.service.display_name ? props.service.display_name : props.service.name}
                                    </div>
                                    <div className={`servicecard-description${props.dark ? '-dark' : ''} mb-0`}>
                                        {ServiceTypes[props.service.type]}
                                    </div>
                                    {props.service.provider && props.service.provider.username && (
                                        <div
                                            className={`servicecard-description${props.dark ? '-dark' : ''} mt-1 mb-0`}
                                            style={{fontSize: '1rem', fontWeight: 'bold'}}
                                        >
                                            <div
                                                className='d-inline-block'
                                                onClick={(event) => {
                                                    event.stopPropagation();

                                                    if(props.service.provider && props.service.provider.username) {
                                                        setShowProvider(true);
                                                    }
                                                }}
                                            >
                                                {
                                                    props.service.provider.display_name
                                                    ? props.service.provider.display_name
                                                    : props.service.provider.username
                                                }
                                            </div>
                                        </div>
                                    )}
                                </>
                                )}

                                <div className={`servicecard-description${props.dark ? '-dark' : ''} mt-2`}>
                                    {serviceDescription}
                                </div>

                                {props.footer && (
                                    <div className={`servicecard-description${props.dark ? '-dark' : ''} mt-2`}>
                                        {props.footer}
                                    </div>
                                )}
                            </div>

                            <div className={`${forceUpdate && smallScreen ? "col-12" : "col-3"} servicecard-price-section`}>
                                <div className="servicecard-price text-center my-2">
                                    <div style={{fontSize: `${
                                        String(props.service.price).length > 6 ?
                                        String(props.service.price).length > 8 ?
                                        String(props.service.price).length > 10 ?
                                        String(props.service.price).length > 12 ?
                                        '1rem' : '1.2rem' : '1.4rem' : '1.6rem' : 'inherit'}`}}>
                                    {
                                        props.service.price ? props.service.price : '???'
                                    }</div>
                                    <div style={{fontSize: '1.25rem'}}>qudo</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </div>

            {showProvider && (
                <ProviderDetailsModal
                    show={showProvider}
                    setShow={setShowProvider}
                    provider={props.service.provider.username}
                />
            )}
            </>
        );
    } else {
        return null;
    }

    /*
    if(props.service) {
        return(
            <div
                id="servicecard"
                key={props.service.name}
                className={props.className ? props.className : "col-12"}
            >
                <div
                    className={`w-100 achievementcard-border-faded ${!props.onClick ? 'disable-click' : ''}`}
                    style={
                        props.highlight ?
                        (
                            props.highlight.name === props.service.name ? {opacity: "1"} : {opacity: "0.5"}
                        )
                        : {opacity: "1"}
                    }
                    onClick={() => {
                        if(props.onClick) {
                            props.onClick(props.service);
                        }
                    }}
                >
                    <div className="w-100">
                    <div className="row">
                        <div
                            //className="col-3"
                            className={
                                forceUpdate && document.getElementById("servicecard")
                                && document.getElementById("servicecard").clientWidth < 450
                                ? "col-5" : "col-3"
                            }
                        >
                            <img
                                alt="Service logo"
                                style={{maxWidth: '100%'}}
                                src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbyservice?service=${props.service.name}&rand=${Date.now()}`}
                                onError={(i) => {
                                    i.target.src = defaultServiceLogo;
                                }}
                            />
                        </div>
                        <div
                            //className="col-9"
                            className={
                                forceUpdate && document.getElementById("servicecard")
                                && document.getElementById("servicecard").clientWidth < 450
                                ? "col-7" : "col-9"
                            }
                        >
                            <div className="servicecard-title mb-1">
                                {props.service.display_name ? props.service.display_name : props.service.name}
                            </div>
                            <div className="servicecard-description mb-2">
                                {ServiceTypes[props.service.type]}
                            </div>
                            <div className="servicecard-description my-2">
                                {serviceDescription}
                            </div>
                            <div className="servicecard-price mb-1 text-right">
                                {props.service.price ? props.service.price : '???'} QUDO
                            </div>
                        </div>
                    </div>

                    {props.showProvider && props.service.provider &&
                    <>
                    <div className="row mt-3">
                        <div className="col-8 col-sm-10 servicecard-description text-right">
                            <div>
                                Service provided by:
                            </div>
                            <div style={{fontWeight: 'bold'}}>
                                {props.service.provider.display_name ? props.service.provider.display_name : props.service.provider.username}
                            </div>
                        </div>
                        <div
                            className="col-4 col-sm-2"
                            //className="col-4 col-sm-2 enable-click"
                            //onClick={() => setShowProvider(true)}
                        >
                            <img
                                alt="Service Provider Banner"
                                className="details-modal-image img-fluid"
                                src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbyprovider?provider=${props.service.provider.username}&rand=${Date.now()}`}
                                onError={i => i.target.src = fallBackAvatar}
                            />
                        </div>
                    </div>

                    <ProviderDetailsModal
                        show={showProvider}
                        setShow={setShowProvider}
                        provider={props.service.provider.username}
                    />
                    </>
                    }
                    </div>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
    */
}
