import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';
import {ClipLoader} from 'react-spinners';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {faTelegram, faDiscord} from '@fortawesome/free-brands-svg-icons';

import QUDOServer from '../../../helpers/QUDOServerConnection';
import {ButtonInput} from '../forms/buttons';

export default function AirdropRankingsModal(props) {
	const info = useSelector(state => state.info);

    const [loading, setLoading] = useState(false);
    const [accountData, setAccountData] = useState(null);

    useEffect(() => {
        setLoading(true);

        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/airdrop/rankings/users`)
        .then((response) => {
            let accounts = response.data;

            for(let i = 0; i < accounts.length; i++) {
                accounts[i].totalActivity = Number(accounts[i].epoch1Activity) + Number(accounts[i].epoch2Activity) + Number(accounts[i].epoch3Activity);
            }

            accounts = accounts.sort((a, b) => {
                return b.totalActivity - a.totalActivity;
            });

            for(let i = 0; i < accounts.length; i++) {
                if(i < 10) {
                    accounts[i].tier = 'Tier 1';
                } else if(i < 50) {
                    accounts[i].tier = 'Tier 2';
                } else if(i < 250) {
                    accounts[i].tier = 'Tier 3';
                } else if(i < 1000) {
                    accounts[i].tier = 'Tier 4';
                } else if(i < 2500) {
                    accounts[i].tier = 'Tier 5';
                } else if(i < 10000) {
                    accounts[i].tier = 'Tier 6';
                }
            }

            const account = accounts.find((e) => {
                return e.account === info.account_name;
            });

            if(account) {
                setAccountData(account);
            } else {
                //props.hide();
            }
        })
        .catch((error) => {
            console.log(error);
            props.hide();
        })
        .finally(() => {
            setLoading(false);
        });
    }, []);

    if(
        info && (
            info.type === process.env.REACT_APP_USER_TYPE
            || info.type === process.env.REACT_APP_GAMEDEV_TYPE
            || info.type === process.env.REACT_APP_PROVIDER_TYPE
        )
    ) {
        return(
            <Modal
                show={props.show}
                //onHide={props.hide}
                onHide={() => {}}
                size='lg'
            >
                <Modal.Body id='no-padding'>
                    {loading ? (
                        <div className='migration-main-loading my-5'>
                            <div className='migration-fof-loading'>
                                <h1 className='m-0 ml-3'>
                                    <ClipLoader color='#000000'/>
                                </h1>
                            </div>
                        </div>
                    ) : (
                        <>
                        <div className='px-4 mt-4 mb-3'>
                            <h3>
                                Welcome to QUDO
                            </h3>
                        </div>
                        <div className='px-4 mt-2 mb-4'>
                            <div className='mb-2' style={{fontWeight: 'bold'}}>
                                Did you hear about the QUDO Airdrop?
                            </div>
                            <div className='mb-2'>
                                QUDO's Main-net Launch Airdrop rewards every member of the community that joined QUDO before its Main-net launch. Users will be prized with QUDO Tokens and QUDO NFTs right at Main-net Launch, based on total gameplay time and account age.
                            </div>
                            <div className='mb-2'>
                                Below you can find your Activity Rank and Account Age for your account. Know more by clicking on the link below.
                            </div>
                        </div>
                        <div
                            className='p-4 mt-4'
                            style={{color: '#fcd902', backgroundColor: '#282725', cursor: 'pointer'}}
                            onClick={() => window.open(`${window.location.origin}/airdrop/rankings/users?account=${info.account_name}`, '_blank')}
                        >
                            <h4 className='my-2'>
                                Your Standings

                                <div
                                    className='d-inline-block px-3 py-2 ml-3'
                                    style={{color: '#000000', backgroundColor: 'lightgray', fontWeight: 'bold', border: '1px solid #fcd902'}}
                                >
                                    ?
                                </div>
                            </h4>
                            <div className='row mt-2'>
                                <div className='col-12 col-sm-6 mt-2 text-center'>
                                    <div className='mb-1'>
                                        EPOCH
                                    </div>
                                    <h2>
                                        {accountData ? `#${accountData.firstTokenGrabEpoch}` : '?'}
                                    </h2>
                                </div>
                                <div className='col-12 col-sm-6 mt-2 text-center'>
                                    <div className='mb-1'>
                                        GAMING ACTIVITY
                                    </div>
                                    <h2>
                                        {accountData ? accountData.tier : '?'}
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div
                            className='px-4 py-3 text-center'
                            style={{color: '#ffffff', backgroundColor: '#1869fe', cursor: 'pointer'}}
                            onClick={() => window.open('https://medium.com/block-bastards/qudo-launch-airdrop-for-early-adopters-d6ca74614595', '_blank')}
                        >
                            <div
                                className='d-inline-block'
                                //onClick={() => window.open('https://medium.com/block-bastards/qudo-launch-airdrop-for-early-adopters-d6ca74614595', '_blank')}
                            >
                                Know more
                                <div className='d-inline-block ml-2'>
                                    <FontAwesomeIcon icon={faArrowRight}/>
                                </div>
                            </div>
                        </div>
                        <div className='px-4 mt-5 mb-4 text-center'>
                            <h5 className='my-2'>
                                Join us
                            </h5>
                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href='https://t.me/qudocoin'
                                className='social-media-icon'
                                style={{color: '#000000'}}
                            >
                                <FontAwesomeIcon icon={faTelegram}/>
                            </a>
                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href='https://discord.com/invite/UK9MHRZ'
                                className='social-media-icon'
                                style={{color: '#000000'}}
                            >
                                <FontAwesomeIcon icon={faDiscord}/>
                            </a>
                        </div>
                        <div className='px-4 my-4'>
                            <ButtonInput
                                trigger
                                label={'Close'}
                                set={props.hide}
                                style={{backgroundColor: '#fbd731', marginTop: '0px'}}
                            />
                        </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        );
    } else {
        return null;
    }
}
