import React from "react";
import { Form, Modal } from "react-bootstrap";

import QUDOServer from "../../helpers/QUDOServerConnection";

import {PasswordInput} from '../components/forms/text';

export default class UserPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            isValid: false,
            errorMessage: '',
            passwordsMatch: false
        }
    }

    confirmValid = () => {
        /*
        if(this.state.newPassword && this.state.passwordsMatch) {
            this.setState({
                isValid: true
            });
        } else {
            this.setState({
                isValid: false
            });
        }
        */

        //console.log(this.state.newPassword);

        if(this.state.isValid !== (this.state.newPassword && this.state.passwordsMatch)) {
            this.setState({
                isValid: (this.state.newPassword && this.state.passwordsMatch)
            });
        }
    }

    newPasswordConfirmation = (event) => {
        /*
        if(event.target.value === this.state.newPassword) {
            this.setState({
                passwordsMatch: true
            });
        } else {
            this.setState({
                passwordsMatch: false
            });
        }
        */

        if(
            this.state.newPassword
            && (this.state.passwordsMatch !== (event.target.value === this.state.newPassword))
        ) {
            this.setState({
                passwordsMatch: (event.target.value === this.state.newPassword)
            });
        }
    }

    changePassword = (event) => {
        if(event.target.value !== this.state.newPassword) {
            this.setState({
                newPassword: event.target.value
            });
            
            this.confirmValid();
        }
    }

    toggleChangePasswordModal = () => {
        this.setState({
            changePasswordModal: !this.state.changePasswordModal
        })
    }

    closeAllModals = () => {
        this.setState({
            confirmModal: false,
            changePasswordModal: false,
            successModal: false,
            failureModal: false
        })
    }

    toggleFailureModal = (errorMessage) => {
        this.closeAllModals();
        this.setState({
            failureModal: !this.state.failureModal,
            errorMessage: errorMessage
        })
    }

    toggleSuccessModal = async () => {
        this.closeAllModals();

        await this.setState({
            successModal: !this.state.successModal
        });

        //After it closed, update the user info
        if(!this.state.successModal) {
            this.props.fetchNewInfo();
        }
    }

    changePasswordSubmit = async e => {
        e.preventDefault();

        await this.setState({
            isValid: false
        });

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/auth/changepassword`, {
            oldpass: this.state.Password,
            newpass: this.state.newPassword
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
            json: true,
            withCredentials: true
        })
            .then(async result => {
                this.setState({
                    isValid: false,
                    Password: "",
                    newPassword: ""
                });

                this.toggleSuccessModal();

                /*
                setTimeout(() => {
                    this.props.fetchNewInfo();
                }, 2000);
                */
            })
            .catch((error) => {
                this.setState({
                    isValid: false,
                    Password: "",
                    newPassword: ""
                });

                this.toggleFailureModal(error.errorMessage);
            })
    }

    change = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            await this.setState({
                [`${event.target.name}Invalid`]: true,
                [event.target.name]: event.target.value
            })
        } else {
            await this.setState({
                [`${event.target.name}Invalid`]: false,
                [event.target.name]: event.target.value
            })
        }
        this.confirmValid();
    }

    closeConfirmModal = (e) => {
        //e.preventDefault();
        this.toggleChangePasswordModal();
    }

    modal = () => {
        return (
            <>
                <Modal show={this.state.changePasswordModal} onHide={this.closeConfirmModal}>
                    <Modal.Body className="confirm-modal">
                        <Form ref={(el) => this.clearPass = el} onSubmit={this.changePasswordSubmit} style={{ padding: "2rem 4rem 0rem" }}>
                            <div className="confirm-modal-text">
                                Write your old password please!
                        </div>
                            <input className={`edit-field col-12`} id="confirm-modal-password"
                                type="password"
                                name="Password"
                                onChange={this.change}
                                placeHolder="Password" />
                            <div className="row d-flex position-relative" style={{ margin: "1rem -4rem 0rem" }}>
                                <input value="Cancel" type="button" className={`text-center col-6 confirm-modal-button`} onClick={this.closeConfirmModal}/>
                                <input value="Confirm" type="submit" disabled={!this.state.isValid}
                                    className={`col-6 confirm-modal-button ${this.state.isValid ? "confirm-modal-button-confirm" : "confirm-modal-button-disabled"}`}/>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.successModal} onHide={this.toggleSuccessModal}>
                    <Modal.Body className="confirm-modal">
                        <div style={{ padding: "2rem 4rem" }}>
                            <div className="confirm-modal-text text-center">
                                {this.state.EmailInvalid === false ?
                                    "You need to confirm your e-mail"
                                    :
                                    "Success! Log in again with your new password."
                                }
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.failureModal} onHide={() => this.toggleFailureModal('')}>
                    <Modal.Body className="confirm-modal">
                        <div style={{ padding: "2rem 4rem" }}>
                            <div className="confirm-modal-text text-center">
                                {/* Oops! Some error occurred. Please try again or contact QUDO support. */}
                                {this.state.errorMessage}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

    render() {
        return(
            <Form ref={(el) => this.clearPass = el} onSubmit={e => e.preventDefault()}>
                <div style={{fontWeight: "bold"}}>
                    Change Password
                </div>

                {/*}
                <input
                    className={`edit-field col-12`}
                    type="password"
                    name="Password"
                    onChange={this.changePassword}
                    placeHolder="Password"
                />

                <input
                    className={`edit-field col-12`}
                    type="password"
                    name="Password"
                    onChange={this.newPasswordConfirmation}
                    placeHolder="Confirm Password"
                />
                {*/}

                <PasswordInput 
                    key={1}
                    placeholder="Password"
                    style={{height: "4.5rem"}}
                    className={`edit-field col-12`}
                    validate={true}
                    set={(value) => {
                        this.changePassword({
                            target: {value: value}
                        });
                    }}
                />

                <PasswordInput
                    key={2}
                    placeholder="Confirm password"
                    style={{height: "4.5rem"}}
                    className={`edit-field col-12`}
                    set={(value) => {
                        this.newPasswordConfirmation({
                            target: {value: value}
                        });
                    }}
                />

                <div
                    className={`col-12 edit-profile-save-btn ${this.state.passwordsMatch ? "" : "disabled"}`}
                    onClick={this.toggleChangePasswordModal}
                >
                    Change Password
                </div>

                {this.modal()}
            </Form>
        );
    }
}
