import React, { useState, useEffect } from "react"
import ReactSwitch from "react-switch";

// export function ToggleButtonInput(props) {
//     const [value, setValue] = useState(props.initial ? props.initial : false);
//     const disabled = props.disabled;

//     useEffect(() => { 
//         if(props.trigger) setValue(false);
//     }, [props.trigger]);

//     useEffect(() => {  
//         if(props.trigger) setValue(props.initial);
//     }, [value]);

//     return (
//         <button
//             type={props.type ? props.type : "button"}
//             name={props.label}
//             disabled={disabled}
//             className={props.className ? props.className : `col-12 add-achievement-toggle-btn`} 
//             value={value}
//             style={props.style ? props.style : (value ? { backgroundColor: "#fbd731" } : { backgroundColor: "#ededed" })}
//             onClick={(e) => {e.preventDefault(); setValue(!value); props.set(!value)}}
//         >
//             {props.label}
//         </button>
//     );
// }

export function ButtonInput(props) {
    const [value, setValue] = useState(props.initial ? props.initial : false);
    const disabled = props.disabled;

    useEffect(() => { 
        if(props.trigger) {
            setValue(false);
        }
    }, [props.trigger]);

    useEffect(() => {  
        if(props.trigger) {
            setValue(props.initial);
        }
    }, [value]);

    return (
        <button
            type={props.type ? props.type : "button"}
            name={props.label}
            disabled={disabled}
            className={props.className ? props.className : 'col-12 add-achievement-toggle-btn'}
            //className={`${props.className ? props.className : 'col-12 add-achievement-toggle-btn'} ${disabled ? 'grey-text' : ''}`}
            style={props.style ? props.style : (
                value ? {backgroundColor: "#fbd731"} : {backgroundColor: "#ededed"}
            )}
            value={value}
            onClick={(e) => {e.preventDefault(); setValue(!value); props.set(!value)}}
        >
            {props.label}
        </button>
    );
}

export function ToggleButtonInput(props) {
    const [value, setValue] = useState(props.initial ? props.initial : false);
    const disabled = props.disabled;

    return (
        <ReactSwitch 
            checked={value}
            onChange={(e) => {setValue(!value); props.set(!value)}}
            disabled={disabled}
            offColor={props.offColor ? props.offColor : ''}
            onColor={props.onColor ? props.onColor : ''}
        /> 
            
        // <button
        //     type={props.type ? props.type : "button"}
        //     name={props.label}
        //     disabled={disabled}
        //     className={props.className ? props.className : `col-12 add-achievement-toggle-btn`} 
        //     value={value}
        //     style={props.style ? props.style : (value ? { backgroundColor: "#fbd731" } : { backgroundColor: "#ededed" })}
        //     onClick={(e) => {e.preventDefault(); setValue(!value); props.set(!value)}}
        // >
        //     {props.label}
        // </button>
    );
}