import React, {useState, useEffect} from 'react';

import GuildCard from './guildCard';

import QUDOServer from '../../helpers/QUDOServerConnection';

export default function GuildList({filter}) {
    const [guildList, setGuildList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredGuildList, setFilteredGuildList] = useState([]);

    useEffect(() => {
        getGuilds();
    }, []);

    function getGuilds() {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/guilds?actives=true`)
        .then(response => {
            setGuildList(
                response.data.sort((a, b) => {
                    return b.members.length - a.members.length;
                })
            );
        })
        .catch(error => {
            //console.log(error);
        });
    }

    useEffect(() => {
        setFilteredGuildList(
            filter ? guildList.filter( (guild) => {
            if(guild.username.includes(searchTerm)) return true;
            if(!guild.display_name && guild.display_name.includes(searchTerm)) return true;
            return false;
        }) : guildList)
    }, [guildList, searchTerm])

    if(Array.isArray(guildList) && guildList.length > 0) {
        return(
            <>
            {filter && (
            <input
                className="col-12 send-to-input-box py-2"
                placeholder='Search...'
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value.toLowerCase());
                }}
            />
            ) }
            <div
                className='with-scroll'
                style={{
                    overflowX: 'hidden',
                    maxHeight: '350px'
                }}
            >
                {filteredGuildList.map(guild => {
                    return(
                        <div className='mb-1'>
                            <GuildCard
                                guild={guild.username}
                                guildName={guild.display_name ? guild.display_name : guild.username}
                                memberCount={Array.isArray(guild.members) ? guild.members.length : '???'}
                                showJoinButton={true}
                                showLeaveButton={false}
                                pendingApproval={false}
                            />
                        </div>
                    );
                })}
            </div>
            </>
        );
    } else {
        return(
            <></>
        );
    }
}
