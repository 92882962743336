import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap"

export function CheckboxInput(props) {
    const [value, setValue] = useState(props.initial ? props.initial : false);
    const disabled = props.disabled;

    useEffect(() => { 
        if (props.trigger) {
            setValue(false);
        }
    }, [props.trigger]);

    return (
        <Form.Check 
            id={props.id ? props.id : `default`}
            type="checkbox"
            disabled={disabled}
            checked={value}
            label={props.label}
            onChange={(e) => {setValue(!value); props.set(!value)}}
            className={props.className ? props.className : ''}
            style={props.style ? props.style : {}}
        />
    );
}
