import React from "react";
import {Form, Modal} from "react-bootstrap";

export function MessageModal(props) {
    return (
        <Modal show={props.show} onHide={props.hide}>
            <Modal.Body className="confirm-modal">
                <div style={{padding: "2rem 4rem"}}>
                    <div className="confirm-modal-text text-center">
                        {props.message}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export function ConfirmPasswordModal(props) {
    function close(event) {
        //console.log(event);
        //event.preventDefault();
        props.toggleConfirmModal();
    }

    return (
        <Modal show={props.confirmModal} onHide={props.toggleConfirmModal}>
            <Modal.Body className="confirm-modal">
                <Form onSubmit={props.submit} style={{ padding: "2rem 4rem 0rem" }}>
                    <div className="confirm-modal-text">
                        Insert your QUDO PASSWORD to confirm the changes to your profile!
                    </div>
                    <input
                        id="confirm-modal-password"
                        className={`edit-field col-12`}
                        type="password"
                        name="Password"
                        onChange={props.change}
                        placeHolder="Password"
                    />
                    <div className="row d-flex position-relative" style={{ margin: "1rem -4rem 0rem" }}>
                        <input value="Cancel" type="button" className={`col-6 confirm-modal-button`} onClick={close}/>
                        <input value="Confirm" type="submit" className={`col-6 confirm-modal-button ${props.isValid ? "" : "confirm-modal-button-disabled"}`}/>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export function SuccessModal(props) {
    return (
        <Modal show={props.show} onHide={props.hide}>
            <Modal.Body className="confirm-modal">
                <div style={{ padding: "2rem 4rem" }}>
                    <div className="confirm-modal-text text-center">
                        Success!
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export function FailureModal(props) {
    return (
        <Modal show={props.show} onHide={props.hide}>
            <Modal.Body className="confirm-modal">
                <div style={{padding: "2rem 4rem"}}>
                    <div className="confirm-modal-text text-center">
                        Oops! Some error occurred. Please try again or contact QUDO support.
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
