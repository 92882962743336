import React from "react";
import {Form, InputGroup} from "react-bootstrap";
import classes from './forms.scss';


export function NumberedRange(props) {
    const title = props.title;
    const unit = props.unit;
    const max = props.max;
    const min = props.min;

    const tooltip = props.tooltip;

	function confirmAmount(value) {
        //Replace commas with dots
        if(!value || isNaN(value)) {
            value = parseFloat(String(value).replace(',', '.'));
        }

        //Replace dots with commas
        if(!value || isNaN(value)) {
            value = parseFloat(String(value).replace('.', ','));
        }

        if(props.integer) {
            value = parseInt(value);
        } else {
            value = parseFloat(value);
        }

        if(!value || isNaN(value)) {
            value = min;
        } else {
            value = value > max ? max : value;
            value = value < min ? min : value;
        }

        value = Number(parseFloat(value).toFixed(4));
        props.setValue(value);
    }

    return(
        <InputGroup id="coefficientinput" className="mx-auto">
            <Form.Label className="edit-input-title">
                {title}
                {tooltip ? tooltip: ''}
            </Form.Label>
            <div className="d-flex justify-content-between align-items-end mb-4 mt-4">
                <div className="col-10">
                    <input
                        type="number"
                        className={`h1 numbered-range-input ${props.numberClass ? props.numberClass : ''}`}
                        min={min}
                        max={max}
                        step={props.step ? props.step : 0.01}
                        value={props.value}
                        onChange={(e) => props.setValue(e.target.value)}
                        onBlur={() => confirmAmount(props.value)}
                    />
                </div>
                <div className={`col-2 text-right ${props.classes ? props.classes : ''}`}>
                    {unit}
                </div>
            </div>
            <input
                required
                type="range"
                name="reward"
                title="Reward"
                className="custom-range"
                style={{clear: "both"}}
                min={min}
                max={max}
                disabled={props.disabled}
                step={props.step ? props.step : 0.01}
                value={props.value}
                onChange={(e) => confirmAmount(e.target.value)}
            />
        </InputGroup>
    );
}
