import React, {useState} from 'react';
import ReactPlayer from 'react-player';
import {useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';

import walletsIcon from '../../../images/WalletMigrationIcons/MigrateWallet.png';
import importIcon from '../../../images/WalletMigrationIcons/Import.png';

import mobileIcon from '../../../images/WalletMigrationIcons/Mobile.png';
import desktopIcon from '../../../images/WalletMigrationIcons/Desktop.png';

//import wombatIcon from '../../../images/WalletMigrationIcons/Wombat.png';
import anchorIcon from '../../../images/WalletMigrationIcons/Anchor.png';
//import scatterIcon from '../../../images/WalletMigrationIcons/Scatter.png';

import './walletMigration.scss';

export default function WalletImportTutorial(props) {
	const info = useSelector(state => state.info);

    const [step, setStep] = useState('introduction');
    //const [selectedWallet, setSelectedWallet] = useState('');
    const [selectedPlatform, setSelectedPlatform] = useState('');

    if(!info || !info.qudoManaged) {
        return(
            <Modal
                show={props.show}
                onHide={props.hide}
            >
                <Modal.Header id='migration-header'>
                    <div className='w-100 text-center'>
                        Import your TELOS account
                    </div>
                </Modal.Header>

                <Modal.Body id='migration-body'>
                    {step === 'introduction' && (
                        <>
                            <div className='my-3 text-center'>
                                Welcome
                            </div>

                            <div className='my-3 text-center'>
                                <img
                                    alt=''
                                    src={walletsIcon}
                                    className='img-fluid'
                                    style={{maxWidth: '75px'}}
                                />
                            </div>

                            <div style={{color: '#b2b2b2', fontSize: '0.9rem'}}>
                                In order to sign transactions, you must sign in with your TELOS account by using one of the wallets that's supported by QUDO.
                                {/* This process will guide you through the steps needed to import your TELOS account in some of the supported wallets. */}
                            </div>

                            <div className='mt-4'>
                                <Buttons
                                    back={() => props.hide()}
                                    next={() => setStep('platformPick')}
                                    allowNext={true}
                                />
                            </div>
                        </>
                    )}

                    {step === 'platformPick' && (
                        <>
                            <div className='my-3 text-center'>
                                Choose the Platform
                            </div>

                            <div className='row'>
                                <div className='col-6'>
                                    <div
                                        className="std-button migration-platform-button"
                                        onClick={() => {
                                            setStep('walletPick');
                                            setSelectedPlatform('desktop');
                                        }}
                                    >
                                        <div className="text-center">
                                            <img
                                                alt='Desktop'
                                                src={desktopIcon}
                                                className='img-fluid'
                                                style={{maxWidth: '75px'}}
                                            />
                                            <div>
                                                DESKTOP
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div
                                        className="std-button migration-platform-button"
                                        onClick={() => {
                                            setStep('walletPick');
                                            setSelectedPlatform('mobile');
                                        }}
                                    >
                                        <div className="text-center">
                                            <img
                                                alt='Mobile'
                                                src={mobileIcon}
                                                className='img-fluid'
                                                style={{maxWidth: '75px'}}
                                            />
                                            <div>
                                                MOBILE
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-2'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <button
                                            type="button"
                                            className="std-button"
                                            style={{backgroundColor: '#656565'}}
                                            onClick={() => setStep('introduction')}
                                        >
                                            <div
                                                className="text-center"
                                                style={{color: '#ededed'}}
                                            >
                                                BACK
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {step === 'walletPick' && (
                        <>
                            <div className='mt-3 mb-1 text-center'>
                                Choose the Wallet
                            </div>

                            <div
                                className='text-center'
                                style={{color: '#b2b2b2', fontSize: '0.9rem'}}
                            >
                                {/*}
                                <div>
                                    Download and install the desired wallet.
                                </div>
                                <div>
                                    After installed, continue the tutorial to import your TELOS account.
                                </div>
                                {*/}

                                <div>
                                    Choose one of the available walets and follow the instructions provided in their website.
                                </div>
                            </div>

                            {selectedPlatform === 'desktop' && (
                                <>
                                    <div className='row my-3 justify-content-center'>
                                        <div className='col-4'>
                                            <div
                                                className="std-button migration-wallet-button"
                                                onClick={() => {
                                                    //setStep('walletTutorial');
                                                    //setSelectedWallet('anchorDesktop');
                                                    window.open('https://greymass.com/en/anchor/', '_blank');
                                                }}
                                            >
                                                <div className="text-center">
                                                    <img
                                                        alt='Anchor Wallet'
                                                        src={anchorIcon}
                                                        className='img-fluid'
                                                        style={{maxWidth: '75px'}}
                                                    />
                                                    <div>
                                                        Anchor
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {selectedPlatform === 'mobile' && (
                                <>
                                    <div className='row my-3 justify-content-center'>
                                        <div className='col-4'>
                                            <div
                                                className="std-button migration-wallet-button"
                                                onClick={() => {
                                                    //setStep('walletTutorial');
                                                    //setSelectedWallet('anchorMobile');
                                                    window.open('https://greymass.com/en/anchor/', '_blank');
                                                }}
                                            >
                                                <div className="text-center">
                                                    <img
                                                        alt='Anchor Wallet'
                                                        src={anchorIcon}
                                                        className='img-fluid'
                                                        style={{maxWidth: '75px'}}
                                                    />
                                                    <div>
                                                        Anchor
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*}
                                        <div className='col-4'>
                                            <div
                                                className="std-button migration-wallet-button"
                                                onClick={() => {
                                                    //setStep('walletTutorial');
                                                    //setSelectedWallet('wombatMobile');
                                                    window.open('https://getwombat.io/', '_blank');
                                                }}
                                            >
                                                <div className="text-center">
                                                    <img
                                                        alt='Wombat Wallet'
                                                        src={wombatIcon}
                                                        className='img-fluid'
                                                        style={{maxWidth: '75px'}}
                                                    />
                                                    <div>
                                                        Wombat
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {*/}
                                    </div>
                                </>
                            )}

                            <div className='mt-2'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <button
                                            type="button"
                                            className="std-button"
                                            style={{backgroundColor: '#656565'}}
                                            onClick={() => {
                                                setStep('platformPick');
                                                setSelectedPlatform('');
                                            }}
                                        >
                                            <div
                                                className="text-center"
                                                style={{color: '#ededed'}}
                                            >
                                                BACK
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-4'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <ReactPlayer
                                            url={`https://youtu.be/ek4edRq0miA?si=Ui8xWHuK5T93GXZP`}
                                            controls={true}
                                            light={true}
                                            width="100%"
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {/*
                    step === 'walletTutorial' && (
                        <>
                            {selectedWallet === 'wombatMobile' && (
                                <WombatMobile
                                    cancel={() => {
                                        setStep('walletPick');
                                        setSelectedWallet('');
                                    }}
                                />
                            )}

                            {selectedWallet === 'anchorMobile' && (
                                <AnchorMobile
                                    cancel={() => {
                                        setStep('walletPick');
                                        setSelectedWallet('');
                                    }}
                                />
                            )}
                        </>
                    )
                    */}
                </Modal.Body>
            </Modal>
        );
    } else {
        return null;
    }
}

function Buttons(props) {
    return(
        <div className='row'>
            <div className='col-12 col-sm-6'>
                <button
                    type="button"
                    className="std-button"
                    style={{backgroundColor: '#656565'}}
                    onClick={() => props.back()}
                >
                    <div
                        className="text-center"
                        style={{color: '#ededed'}}
                    >
                        BACK
                    </div>
                </button>
            </div>
            <div className='col-12 col-sm-6'>
                <button
                    type="button"
                    className="std-button"
                    style={
                        props.allowNext
                        ? {backgroundColor: '#fcd902'}
                        : {cursor: 'initial', backgroundColor: '#656565', opacity: '0.5'}
                    }
                    onClick={() => {
                        if(props.allowNext) {
                            props.next();
                        }
                    }}
                >
                    <div
                        className="text-center"
                        style={props.allowNext ? {color: '#282725'} : {color: '#ededed'}}
                    >
                        NEXT
                    </div>
                </button>
            </div>
        </div>
    );
}

// function AnchorMobile(props) {
//     const [step, setStep] = useState(1);

//     return(
//         <>
//         </>
//     );
// }

// function WombatMobile(props) {
//     return(
//         <>
//             <div className='mt-3 mb-1 text-center'>
//                 Importing your Account
//             </div>

//             <div className='my-3 text-center'>
//                 <img
//                     alt=''
//                     src={importIcon}
//                     className='img-fluid'
//                     style={{maxWidth: '75px'}}
//                 />
//             </div>

//             <div
//                 className='text-center'
//                 style={{color: '#b2b2b2', fontSize: '0.9rem'}}
//             >
//                 Although Wombat integration has been fully added, since QUDO is still running on the TELOS
//                 testnet and Wombat doesn't support testnets, we don't recommend you choose Wombat at this moment.
//             </div>

//             <div className='mt-4'>
//                 <div className='row'>
//                     <div className='col-12'>
//                         <button
//                             type="button"
//                             className="std-button"
//                             style={{backgroundColor: '#656565'}}
//                             onClick={() => props.cancel()}
//                         >
//                             <div
//                                 className="text-center"
//                                 style={{color: '#ededed'}}
//                             >
//                                 BACK
//                             </div>
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }
