import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts';
import moment from 'moment';

export default function EarningsExpensesChart(props)
{
    const [activeFilter, setActiveFilter] = useState('1d');
    const allBalances = props.allBalances;
    const allPurchases = props.allPurchases;
    const allRewards = props.allRewards;

    const daysInterval = 15;
    const weeksInterval = 15;
    const monthsInterval = 12;

    useEffect(() =>
    {
        if (allBalances && allPurchases && allRewards)
        {
            chart();
        }
    }, [allRewards, allBalances, allPurchases]);

    useEffect(() =>
    {
        if (allBalances && allPurchases && allRewards)
        {
            chart();
        }
    }, [activeFilter]);


    const generateDates = () =>
    {   
        let startDate = new Date();
        let endDate = new Date();

        if (activeFilter === '1d')
        {
            startDate.setDate(startDate.getDate() - daysInterval);
        }

        if (activeFilter === '1w')
        {
            startDate.setDate(startDate.getDate() - (7 * weeksInterval));
        }

        if (activeFilter === '1m')
        {
            startDate.setMonth(startDate.getMonth() - monthsInterval);
        }

        const dateArray = [];
        let currentDate = moment(startDate);
        const stopDate = moment(endDate);

        while (currentDate <= stopDate) 
        {
            if (activeFilter === '1d' || activeFilter === '1w')
            {
                dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
                currentDate = moment(currentDate).add(
                    activeFilter === '1d' ? 1 : 7, 'days');
            }
            else
            {
                dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
                currentDate = moment(currentDate).add(1, 'months');
            }
        }
        return dateArray;
    }

    const setupValues = dates =>
    {
        let values = [[],[]];

        if (allBalances && allPurchases && allRewards)
        {
            dates.forEach((date) =>
            {
                let rewardsDateArr = [];
                let purchasesDateArr = [];
                let rewardToAdd = 0;
                let purchaseToAdd = 0;
    
                rewardsDateArr = allRewards.filter(reward => moment(new Date(reward.timestamp)).format('YYYY-MM-DD') === date);
                purchasesDateArr = allPurchases.filter(purchase => moment(new Date(purchase.timestamp)).format('YYYY-MM-DD') === date);

                if (activeFilter === '1w')
                {
                    const daysInAWeek = 7;
    
                    let dateInUse = new Date(date);
                    
                    for (let i = 0; i < daysInAWeek; i++)
                    {
                        dateInUse.setDate(dateInUse.getDate() - 1);
                        
                        const tempRewardsDateArr = allRewards.filter(reward =>
                            moment(new Date(reward.timestamp)).format('YYYY-MM-DD') === moment(dateInUse).format('YYYY-MM-DD'));
                    
                        if (tempRewardsDateArr.length > 0)
                        {
                            tempRewardsDateArr.forEach(reward => rewardsDateArr.push(reward));
                        }

                        const tempPurchasesDateArr = allPurchases.filter(purchase => 
                            moment(new Date(purchase.timestamp)).format('YYYY-MM-DD') === moment(dateInUse).format('YYYY-MM-DD'));
    
                        if (tempPurchasesDateArr.length > 0)
                        {
                            tempPurchasesDateArr.forEach(purchase => purchasesDateArr.push(purchase));
                        }
                    }
                }
    
                if (activeFilter === '1m')
                {
                    let dateInUse = new Date(date);

                    const tempRewardsDateArr = allRewards.filter(reward =>
                        moment(new Date(reward.timestamp)).format('YYYY-MM') === moment(dateInUse).format('YYYY-MM'));

                    if (tempRewardsDateArr.length > 0)
                    {
                        tempRewardsDateArr.forEach(reward => rewardsDateArr.push(reward));
                    }

                    const tempPurchasesDateArr = allPurchases.filter(purchase =>
                        moment(new Date(purchase.timestamp)).format('YYYY-MM') === moment(dateInUse).format('YYYY-MM')); 
                    
                    if (tempPurchasesDateArr.length > 0)
                    {
                        tempPurchasesDateArr.forEach(purchase => purchasesDateArr.push(purchase));
                    }
                }
    
                if (rewardsDateArr?.length >= 1);
                {
                    rewardsDateArr.forEach(reward =>
                    {
                        rewardToAdd += reward.amount;
                    });
                }
                
                if (purchasesDateArr?.length >= 1 )
                {
                    purchasesDateArr.forEach(purchase =>
                    {
                        purchaseToAdd += purchase.price;
                    });
                }
    
                values[0].push(Number(purchaseToAdd.toFixed(2)));
                values[1].push(-Number(rewardToAdd.toFixed(2)));
            });

            return values;
        }

        return [];
    }

    const chart = () => 
    {
		const categories = [];

        const dateGenerated = generateDates();
        const [purchaseValues, rewardValues] = setupValues(dateGenerated);

		dateGenerated.forEach((i) => 
        {
            if (activeFilter === '1m')
            {
                categories.push(moment(i).format('MMM YY'));  
            }
            else
            {
                categories.push(moment(i).format('DD MMM'));
            }
		});

		return Highcharts.chart('barChartEarnings', {
			chart: {
				type: "column",
				backgroundColor: "transparent",
				height: "300rem",
			},
			title: {
				text: "",
			},
			subtitle: {
				align: "right",
				verticalAlign: "bottom",
			},
			colors: [
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
			],
			legend: {
				enabled: false,
			},
			xAxis: {
                categories: [...categories],
			},
			yAxis: [{
                reversed: false,
                labels: {
                    step: 50,
                    formatter: function() {
                        return Math.abs(this.value)
                    }
                },
                accessibility: {
                    description: 'Earnings'
                }
            }, {
                opposite: true,
                reversed: false,
                labels: {
                    step: 50,
                    formatter: function() {
                        return Math.abs(this.value)
                    }
                },
                accessibility: {
                    description: 'Expenses'
                },
            }],
            credits: {
                enabled: false
            },
			plotOptions: {
				area: {
					fillOpacity: 0.5,
					fillColor: {
						linearGradient: [0, 0, 0, 300],
						stops: [
							[0, "#dedede"],
							[1, "#ffffff"],
						],
					},
				},
                series: {
                   stacking: 'normal'
                }
			},
            legend: {
                align: 'center',
                enabled: true
            },
			series: [
				{
					name: 'Earnings',
					data: [...purchaseValues],
					marker: {
						fillColor: "#fcd902",
					},
                    color: '#fcd902'
				},
                {
					name: 'Expenses',
					data: [...rewardValues],
					marker: {
						fillColor: 'rgba(0, 0, 0, 0.16)',
					},
                    color: 'rgba(0, 0, 0, 0.16)'
				},
			]
		});
	};

    return (
        <>
            <div className="borders">
                <div className="mb-3">
                    <div className="col-12 edit-screen-stakes-sm header pt-3">
                        <strong>Earnings/</strong> Expenses
                    </div>
                </div>
                {allRewards ? 
                <>
                    <div className="col-12 edit-screen-stakes-sm">
                        <span className={`submenus  mr-2 cursor-pointer ${activeFilter === '1m' ? '' : 'not-active'}`}
                            onClick={() => setActiveFilter('1m')}>
                            1m |
                        </span>
                        <span className={`submenus mr-2 cursor-pointer ${activeFilter === '1w' ? '' : 'not-active'}`}
                            onClick={() => setActiveFilter('1w')}>
                            1w |
                        </span>
                        <span className={`submenus cursor-pointer ${activeFilter === '1d' ? '' : 'not-active'}`}
                            onClick={() => setActiveFilter('1d')}>
                            1d
                        </span>
                    </div>
                    <div className="col-12 text-right not-active">
                        QUDO
                    </div>
                </>
                :
                <div className="col-12 edit-screen-stakes-sm">Chart Loading</div>
                }
                <div id="barChartEarnings"></div>
            </div>
        </>
    )
}