import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts';
import moment from 'moment';
import classes from './overallGameBalanceChart.scss';

export default function OverallGameBalanceChart(props)
{
    const allBalances = props.balances;
    const [activeFilter, setActiveFilter] = useState('1d');

    const daysInterval = 15;
    const weeksInterval = 15;
    const monthsInterval = 12;

    useEffect(() =>
    {
        if (props.balances)
        {   
            chart();
        }
    }, [props.balances]);

    useEffect(() =>
    {
        if (props.balances)
        {
            chart();
        }
    }, [activeFilter]);


    const generateDates = () =>
    {   
        let startDate = new Date();
        let endDate = new Date();

        if (activeFilter === '1d')
        {
            startDate.setDate(startDate.getDate() - daysInterval);
        }

        if (activeFilter === '1w')
        {
            startDate.setDate(startDate.getDate() - (7 * weeksInterval));
        }

        if (activeFilter === '1m')
        {
            startDate.setMonth(startDate.getMonth() - monthsInterval);
        }

        const dateArray = [];
        let currentDate = moment(startDate);
        const stopDate = moment(endDate);

        while (currentDate <= stopDate) 
        {
            if (activeFilter === '1d' || activeFilter === '1w')
            {
                dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
                currentDate = moment(currentDate).add(
                    activeFilter === '1d' ? 1 : 7, 'days');
            }
            else
            {
                dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
                currentDate = moment(currentDate).add(1, 'months');
            }
        }
        return dateArray;
    }

    const setupValues = dates =>
    {
        let values = [];

        dates.forEach((date) =>
        {
            let balancesDateArr;
            let balanceDate;

            balancesDateArr = props.balances.filter(balance => moment(new Date(balance.time)).format('YYYY-MM-DD') === date);

            if (activeFilter === '1w' && balancesDateArr.length <= 0)
            {
                const daysInAWeek = 7;

                let dateInUse = new Date(date);
                
                for (let i = 1; i <= daysInAWeek; i++)
                {
                    dateInUse.setDate(dateInUse.getDate() - i);
                    
                    balancesDateArr = allBalances.filter(balance => 
                        moment(new Date(balance.time)).format('YYYY-MM-DD') === moment(dateInUse).format('YYYY-MM-DD'));

                    if (balancesDateArr.length > 0)
                    {
                        break;
                    }
                }
            }

            if (activeFilter === '1m' && balancesDateArr.length <= 0)
            {
                let dateInUse = new Date(date);
                
                for (let i = 0; i < monthsInterval; i++)
                {
                    dateInUse.setDate(dateInUse.getMonth() - i);
                    
                    balancesDateArr = allBalances.filter(balance => 
                        moment(new Date(balance.time)).format('YYYY-MM') === moment(dateInUse).format('YYYY-MM'));

                    if (balancesDateArr.length > 0)
                    {
                        break;
                    }
                }
            }
            
            if (balancesDateArr?.length <= 0 && values.length === 0)
            {
                balanceDate = 0;
            }
            
            if (balancesDateArr?.length <= 0  && values.length > 0)
            {
                balanceDate = values[values.length - 1];
            }

            if (balancesDateArr?.length > 0 )
            {
                balanceDate = balancesDateArr[balancesDateArr.length - 1]?.balance.split(' ')[0];
            }

            values.push(Number(balanceDate));
        });
        
        return values;
    }

    const chart = () => 
    {
		const categories = [];

        const dateGenerated = generateDates();

		dateGenerated.forEach((i) => 
        {
            if (activeFilter === '1m')
            {
                categories.push(moment(i).format('MMM YY'));  
            }
            else
            {
                categories.push(moment(i).format('DD MMM'));
            }
		});

        const values = setupValues(dateGenerated);

		return Highcharts.chart('chart', {
			chart: {
				type: "area",
				backgroundColor: "transparent",
				height: "300rem",
			},
			title: {
				text: "",
			},
			subtitle: {
				align: "right",
				verticalAlign: "bottom",
			},
			colors: [
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
			],
			legend: {
				enabled: false,
			},
			xAxis: {
				categories: [...categories],
			},
			yAxis: {
				title: {
					text: "",
				},
				gridLineColor: "transparent",
			},
			plotOptions: {
				area: {
					fillOpacity: 0.5,
					fillColor: {
						linearGradient: [0, 0, 0, 300],
						stops: [
							[0, "#dedede"],
							[1, "#ffffff"],
						],
					},
				},
			},
			credits: {
				enabled: false,
			},
			series: [
				{
					name: "balance",
					data: [...values],
					marker: {
						fillColor: "#fbd731",
					},
				},
			],
		});
	};

    return (
        <>
            <div className="borders">
                <div className="mb-3">
                    <div className="col-12 edit-screen-stakes-sm header pt-4">
                        <strong>Overall Game Balance</strong>
                    </div>
                </div>
                <div className="col-12 edit-screen-stakes-sm">
                    <span className={`submenus  mr-2 cursor-pointer ${activeFilter === '1m' ? '' : 'not-active'}`}
                        onClick={() => setActiveFilter('1m')}>
                        1m |
                    </span>
                    <span className={`submenus mr-2 cursor-pointer ${activeFilter === '1w' ? '' : 'not-active'}`}
                        onClick={() => setActiveFilter('1w')}>
                        1w |
                    </span>
                    <span className={`submenus cursor-pointer ${activeFilter === '1d' ? '' : 'not-active'}`}
                        onClick={() => setActiveFilter('1d')}>
                        1d
                    </span>
                </div>
                <div className="col-12 text-right not-active">
                    QUDO
                </div>
                <div id="chart"></div>
            </div>
        </>
    )
}