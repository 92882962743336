import { SET_UAL } from "../actions/types";

export default function(state = "", action) {
	switch (action.type) {
		case SET_UAL:
			return action.payload || false;
		default:
			return state;
	}
}

// const signedData = await ual.activeUser.signTransaction({...});
/* signedData: {
	wasBroadcast: false,
	transaction: {
		chain: {
			abiCache: [{ "qudotoken111" => { version: 'eosio::abi/1.1', types: Array(0), structs: Array(22), actions: Array(14), tables: Array(8), types: [], variants: [], version: "eosio::abi/1.1" }} ],
			chainId: { array: Uint8Array(32), chainName: 0, chainVariant: same as array, hexString: "1eaa0824707c8c16bd25145493bf062aecddfeb56c736f6ba6397f3195f33c9f"}
			client: { provider: {url: 'https://...telosmadrid.io:443', fetch: f() } },
			pendingAbis: Map(0) {},
		},
		payload: {
			cid: "1eaa0824707c8c16bd25145493bf062aecddfeb56c736f6ba6397f3195f33c9f",
			ex: "2024-07-17T11:07:04",
			rbn: "62559",
			req: "esr://gmOUW8WhUlDTI7ZXVSRk8n42rTd3_23NKc7PXmZZbzj1s818BgGnGVyXfCZvY2BgCNe1OXuW0WHyNgWNF2dqgAIMK94aGWnCBBLWBd92nbUxjZEBAlgCQ138gTRHSFFiXnFaahGTdUZJSUGxlb5-cpJeYl5yRn6RXk5mXra-SWqysaWZpaGumWFqiq6JoaGZroWxeapukrm5ZVKyoYGlqZkZM2defnxufkpmWiUjI3dRaklpUV58QWJJhgzM0PTE3NRivcLSlHy9zHz98sScnNQSFpD5LBeXTk8DAA",
			rid: "1286785432",
			sa: "jnaike1.qudo",
			sig: "SIG_K1_K8LSeWECawnYTGzvaEVBVPNHpmhyQprXx4Pgsenhu4EBipa2xvYAPxoBggch1dyoomDn3ienbqu91184Jgrr7NntU2QPwK",
			sp: "active",
			tx: "f92df9f227d97737f888013cefc7a81e2f617164a46bd96f882812a42c40e85f"
		},
		resolved: {
			chainId: {array: Uint8Array(32) same as in chain.chainId},
			request: {
				abiProvider: same as chain,
				data: {callback:"https://cb.anchor.link/4ec39691-61ed-4116-837e-b779bc109566", chain_id: same as chain.chainId, flags: {value:{negative:0, words:[2,0], length:1, red:null}, background:true, broadcast:false,byteArray:[2]}, info:[{"no_modify":1}, {"return_path":Uint8Array(28)}, {"link"}:[209,165,151,102] ],
					req: { variantIdx: 0, variantName: "action", value: {
						account:[43532816, 13841538, 2921], 
						authorization:[{actor:[43532816, 13841538, 2921], permission:[0, 12282368, 803]}], 
						data: [0, 12282368, 803] (uft8String "@�� (��|`�S�E��f\u0001\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0004QUDO\u0000\u0000\u0000\bTransfer"),
						name: [50331648, 55511893, 3292],
						decode: f()
				} } }, signature: undefined, version: 2, zlib: Deflate: ƒ ce(t), Inflate: ƒ Ye(t), constants: {Z_BEST_COMPRESSION: 9, Z_BEST_SPEED: 1, Z_BINARY: 0, Z_BLOCK: 5, Z_BUF_ERROR: -5, Z_DATA_ERROR: -3, Z_DEFAULT_COMPRESSION: -1, Z_DEFAULT_STRATEGY: 0, Z_DEFLATED: 8, Z_ERRNO: -1, Z_FILTERED: 1, Z_FINISH: 4, Z_FIXED: 4, Z_FULL_FLUSH: 3, Z_HUFFMAN_ONLY: 2, Z_MEM_ERROR: -4, Z_NEED_DICT: 2, Z_NO_COMPRESSION: 0, Z_NO_FLUSH: 0, Z_OK: 0, Z_PARTIAL_FLUSH: 1, Z_RLE: 3, Z_STREAM_END: 1, Z_STREAM_ERROR: -2, Z_SYNC_FLUSH: 2, Z_TEXT: 1, Z_TREES: 6, Z_UNKNOWN: 2}, deflate: ƒ se(t,e), deflateRaw: ƒ (t,e), gzip: ƒ (t,e), inflate: ƒ $e(t,e), inflateRaw: ƒ (t,e), ungzip: ƒ $e(t,e)
			},
		}
		resolvedTransaction: {
			actions: [{
				account: [43532816, 13841538, 2921],
				authorization:[{actor:[11965248, 54135304, 1996], permission:[0, 12282368, 803]}],
				data: {
					from: [11965248, 54135304, 1996],
					memo: "Transfer",
					quantity: {symbol: {name:"QUDO", precision: 4}, units: 1},
					to: [55815776, 6721910, 1643],
				}, name: [50331648, 55511893, 3292]
			}],
			context_free_actions: [],
			... delay_sec, expiration, max_cpu_usage_ms, max_net_usage_words, ref_block_num, ref_block_prefix, transaction_extensions
		},
		serializedTransaction: Uint8Array(91),
		signatures: ['SIG_K1_K8LSeWECawnYTGzvaEVBVPNHpmhyQprXx4Pgsenhu4E…pa2xvYAPxoBggch1dyoomDn3ienbqu91184Jgrr7NntU2QPwK'],
		signer: {actor, permision},
		transaction: {...resolvedTransaction, id, transaction_id },
		}
	}
*/