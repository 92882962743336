import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

import QUDOServer from "../../../helpers/QUDOServerConnection";

const medal = require("../../../images/definitive/icn-medal-updated.png");

export default function AchievementsList({ game, highlight, editAchievement, refreshAchievementsList }) {
    const [state, setState] = useState({ searchTerm: "", filter: "All", achievements: [] });
    const [highlightAlias, sethighlightAlias] = useState('');

    useEffect(() => {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/achievement/list`, {
            appID: game.appID
        })
            .then(result => {
                setState(oldState => {
                    return {
                        ...oldState,
                        achievements: result.data
                    }
                });
            });
    }, [game, refreshAchievementsList]);

    useEffect(() => {
        sethighlightAlias(highlight?.alias);
    }, [highlight]);

    function search(event) {
        event.preventDefault();
        setState(oldState => {
            return {
                ...oldState,
                searchTerm: event.target.value
            }
        })
    }

    function filter(event) {
        setState(oldState => {
            return {
                ...oldState,
                filter: event.target.value
            }
        })
    }

    function filterAndSearch(i) {
        return (
            i.name.toLowerCase().includes(state.searchTerm.toLowerCase())
            && (state.filter !== "All" ? i[state.filter] === true : true)
            &&
            // <div className="">
            <div className="col-12 user-select-none" id="" key={i.alias}>
                <div className="align-items-center w-100 achievementcard-border-faded" id=""
                    style={highlightAlias === i.alias ? { opacity: "0.7" } : { opacity: "1" }}
                    onClick={() => editAchievement(i)}>
                    <div className="col-1 mr-3">
                        <img src={medal} alt="medal" className="icon-32px" />
                    </div>
                    <div className="col-4 row">
                        <div className="amountgreen col-12" style={{ textAlign: "left", lineHeight: 'initial' }}>
                            {i.isDynamic && false ? (
                                <div>
                                    {(i.coefficient * 100).toFixed(0)}%
                                </div>
                            ) : (
                                <div>
                                    {parseFloat(i.reward).toFixed(2)} QUDO
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-7 row">
                        <div className="col-12 achievementtitle" id="">
                            {i.name}
                        </div>
                        <div className="col-12 achievementsubtitle" id="">
                            {i.description}
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    return (
        <div className="col-12 mx-auto">
            <Form className="row">
                <InputGroup className="col-12">
                    <Form.Control
                        id="achievementsFilterField"
                        value={state.searchTerm}
                        placeholder="search"
                        onChange={search}
                    />
                </InputGroup>

            </Form>
            <div className="row mt-3 scrollable">
                {Array.isArray(state.achievements) && state.achievements.length > 0 ? (
                    state.achievements.map(i => filterAndSearch(i))
                ) : (
                    <div className='col-12 mt-2 achievementtitle'>
                        This game doesn't have any achievements yet!
                    </div>
                )}
            </div>
        </div>
    )

}
