import React from "react";
import {Form, InputGroup} from "react-bootstrap";

export function NumberInput(props) {
    const title = props.title;
    const unit = props.unit;
    const max = !isNaN(props.max) ? Number(props.max) : null;
    const min = !isNaN(props.min) ? Number(props.min) : null;

    return(
        <InputGroup
            id="numberinput"
            className="mx-auto"
            style={props.disabled ? {backgroundColor: 'transparent'} : {}}
        >
            <Form.Label className="edit-input-title">
                {title}
            </Form.Label>
            <div className="d-flex justify-content-between align-items-end mt-3">
                <div className="col-10">
                    <input
                        type="number"
                        className="h1 numbered-range-input"
                        disabled={props.disabled}
                        //max={max}
                        min={min}
                        step={props.step ? props.step : 0.01}
                        value={props.value}
                        onChange={(e) => {
                            if(max && e.target.value > max) e.target.value = max;

                            props.setValue(e.target.value);
                        }}
                        onBlur={() => {
                            var value = props.value;

                            //Replace commas with dots
                            if(!value || isNaN(value)) {
                                value = parseFloat(String(value).replace(',', '.'));
                            }

                            //Replace dots with commas
                            if(!value || isNaN(value)) {
                                value = parseFloat(String(value).replace('.', ','));
                            }

                            if(props.integer) {
                                value = parseInt(value);
                            } else {
                                value = parseFloat(value);
                            }

                            if(!value || isNaN(value)) {
                                value = min;
                            } else {
                                //value = !isNaN(max) && value > max ? max : value;
                                value = !isNaN(min) && value < min ? min : value;
                            }

                            value = Number(parseFloat(value).toFixed(4));
                            props.setValue(value);
                        }}
                    />
                </div>
                <div className={`col-2 text-right ${props.classes ? props.classes : ''}`}>
                    {unit}
                </div>
            </div>
        </InputGroup>
    );
}
