import React, {useState, useEffect} from "react";
import moment from "moment";
import {Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import {arrayToHex} from "eosjs/dist/eosjs-serialize";

import ServiceCard from "./provider/serviceCard";
import {ButtonInput} from "./components/forms/buttons";
import {MessageModal} from "./components/modals/modals.js";
//import ConfirmModal from "./components/modals/confirmModal/confirmModal";
import ServiceDetailsModal from "./components/modals/ServiceDetailsModal";
import ChatMessagesModal from "./components/modals/chatMessagesModal/chatMessagesModal";

import {ServiceTypes} from "../helpers/serviceTypes";
import useWindowSize from "../helpers/useWindowSize";
import QUDOServer from "../helpers/QUDOServerConnection";
import NavigationProvider from "./provider/navigation/navigationProvider";
import ProviderTitleContainer from "./provider/providerTitleContainer/providerTitleContainer";

export default function ProviderMarketplace(props) {
    const windowSize = useWindowSize();

	const ual = useSelector(state => state.ual);
	const currentUser = useSelector(state => state.info);

    const [currentServices, setCurrentServices] = useState([]);
    const [closedServices, setClosedServices] = useState([]);

    const [editService, setEditService] = useState(null);
    const [showService, setShowService] = useState(false);
	const [modalMessage, setModalMessage] = useState('');
    const [chatServiceID, setChatServiceID] = useState('');

    useEffect(() => {
        getHiredServices();
    }, []);

    function getHiredServices() {
        setEditService(null);
        setShowService(false);
        setModalMessage('');

        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/provider/services/hired`, {
            withCredentials: true
        })
        .then(result => {
            const activeServices = result.data.filter(e => {
                return !e.closed;
            });

            const finishedServices = result.data.filter(e => {
                return e.closed;
            });

            setCurrentServices(activeServices);
            setClosedServices(finishedServices);
        });
    }

    function startService(serviceID) {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/service/start`, {
            serviceContractID: serviceID
        }, {
            withCredentials: true
        })
        .then(result => {
            setModalMessage('Success');
        })
        .catch(error => {
            if(error.errorMessage) {
                setModalMessage(error.errorMessage);
            } else {
                setModalMessage('');
            }
        });
    }

    function closeService(serviceID) {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/service/close`, {
            serviceContractID: serviceID
        }, {
            withCredentials: true
        })
        .then(result => {
            setModalMessage('Success');
        })
        .catch(error => {
            if(error.errorMessage) {
                setModalMessage(error.errorMessage);
            } else {
                setModalMessage('');
            }
        });
    }

    async function cancelService(serviceContract) {
		let signatureData = null;

		if(!currentUser.qudoManaged) {
			try {
				if(!ual.activeUser) {
					ual.logout();
					ual.restart();
					ual.showModal();
					return;
				} else {
					const signedData = await ual.activeUser.signTransaction({
						actions: [{
							account: process.env.REACT_APP_TOKENCONTRACT,
							name: "transfer",
							authorization: [{
								actor: currentUser.account_name,
								permission: "active"
							}],
							data: {
                                from: currentUser.account_name,
                                to: serviceContract.account.account_name,
                                quantity: `${Number(serviceContract.price - (serviceContract.price * (!isNaN(serviceContract.fee) ? serviceContract.fee : 0) / 100)).toFixed(4)} ${process.env.REACT_APP_TOKENNAME}`,
                                memo: `Refunded service: ${serviceContract.service.account_name}`
							}
						}]
					}, {
						blocksBehind: 3,
						expireSeconds: 30,
						broadcast: false
					});

					signatureData = {
						signatures: signedData.transaction.signatures,
						packed_trx: arrayToHex(signedData.transaction.serializedTransaction)
					}
				}
			} catch(error) {
				//console.log(error);
				setModalMessage(error.toString());
				return;
			}
		}

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/service/cancel`, {
            serviceContractID: serviceContract._id,
			signature: signatureData
        }, {
            withCredentials: true
        })
        .then(result => {
            setModalMessage('Success');
        })
        .catch(error => {
            if(error.errorMessage) {
                setModalMessage(error.errorMessage);
            } else {
                setModalMessage('');
            }
        });
    }

    return(
        <div className="gamedevact-edit-screen-container">
            <div className="row col-12 mb-3">
                <ProviderTitleContainer username={currentUser.username} displayName={currentUser.display_name ? currentUser.display_name : currentUser.username}/>
            </div>
            <div className="row col-12 mb-3">
                <NavigationProvider/>
            </div>            
            <div className="text-left">
                <div id="title" className="mt-4 ml-2">
                    Your Projects
                </div>
            </div>

            <div className="col-12 mx-auto mt-4">
                <div className="row">
                    <div className={`col-12 ${editService || (Array.isArray(closedServices) && closedServices.length > 0) ? 'col-xl-8' : ''} mb-3`}>
                        <div className="servicecard-title mb-2">
                            Active
                        </div>
                        {Array.isArray(currentServices) && currentServices.length > 0 ? (
                            <div>
                                {currentServices.map(service => {
                                    return(
                                        <ServiceRow
                                            service={service}
                                            selectService={(service) => {
                                                if(editService && editService._id === service._id) {
                                                    setEditService(null);
                                                } else {
                                                    setEditService(service);
                                                }
                                            }}
                                            setChatServiceID={setChatServiceID}
                                        />
                                    );
                                })}
                            </div>
                        ) : (
                            <div>
                                You currently don't have any active services.
                            </div>
                        )}
                    </div>

                    {(windowSize && windowSize.width < 1200 ? true : !editService) && Array.isArray(closedServices) && closedServices.length > 0 && (
                    <div className="col-12 col-xl-4 mb-3">
                        <div className="servicecard-title mb-2">
                            Archived
                        </div>
                        {/* {Array.isArray(closedServices) && closedServices.length > 0 && ( */}
                            <div className="row w-100">
                                {closedServices.map(service => {
                                    return(
                                        <ServiceCard
                                            key={service.service.name}
                                            service={service.service}
                                            onClick={() => setEditService(service)}
                                            className="col-12 col-md-6 col-xl-12"
                                            dark={true}
                                            backgroundColor={service.canceled ? "#f2544c" : ""}
                                            footer={`Service ${service.canceled ? "canceled and refunded" : "finished"} on: ${moment(service.finishTimestamp).format("DD MMM YYYY @ HH:mm")}`}
                                        />
                                    );
                                })}
                            </div>
                        {/* )} */}
                    </div>
                    )}

                    {editService && (
                        <div className="col-12 col-xl-4 mb-3">
                            {windowSize && windowSize.width < 1200 ? (
                                <Modal show={editService} onHide={() => setEditService(null)}>
                                    <Modal.Header closeButton className='modal-header-position'/>
                                    <Modal.Body>
                                        <div className="mx-2 mt-2">
                                            <ServiceDetails
                                                service={editService}
                                                showService={setShowService}
                                                editService={setEditService}
                                                startService={startService}
                                                closeService={closeService}
                                                cancelService={cancelService}
                                                setChatServiceID={setChatServiceID}
                                            />
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            ) : (
                                <ServiceDetails
                                    service={editService}
                                    showService={setShowService}
                                    editService={setEditService}
                                    startService={startService}
                                    closeService={closeService}
                                    cancelService={cancelService}
                                    setChatServiceID={setChatServiceID}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>

            {showService && (
                <ServiceDetailsModal
                    service={showService}
                    show={showService}
                    setShow={() => setShowService(null)}
                />
            )}

            {modalMessage && (
				<MessageModal
					show={modalMessage} 
					message={modalMessage}
					hide={() => getHiredServices()}
				/>
            )}

            {chatServiceID && (
                <ChatMessagesModal
                    show={chatServiceID}
                    setShow={() => setChatServiceID('')}
                    serviceContractID={chatServiceID}
                />
            )}
        </div>
    );
}

function ServiceRow(props) {
    const serviceContract = props.service;

    const windowSize = useWindowSize();

    const mobile = windowSize && windowSize.width < 576;
    const ipad = windowSize && windowSize.width < 992;

    return(
        <>
        <div
            key={serviceContract.service.name}
            className="row w-100 servicecard enable-click"
        >
            <div
                onClick={() => props.selectService(serviceContract)}
                className={`${ipad ? 'col-6' : 'col-2'} py-2 my-auto servicecard-vertical-separator`}
            >
                <div className="servicecard-title">
                    Service
                </div>
                <div className="servicecard-description" style={{fontSize: '1rem'}}>
                    {/* {ServiceTypes[serviceContract.service.type]} */}
                    {/*
                        serviceContract.service.provider.display_name
                        ? serviceContract.service.provider.display_name
                        : serviceContract.service.provider.username
                    */}
                    {serviceContract.service.display_name ? serviceContract.service.display_name : serviceContract.service.name}
                </div>
            </div>

            <div
                onClick={() => props.selectService(serviceContract)}
                className={`${ipad ? 'col-6' : 'col-2 servicecard-vertical-separator'} py-2 my-auto`}
            >
                <div className="servicecard-title">
                    Cost
                </div>
                <div className="servicecard-description" style={{color: '#fbd731', fontSize: '1rem', fontWeight: 'bold'}}>
                    {serviceContract.price} QUDO
                </div>
                {!isNaN(serviceContract.fee) && Number(serviceContract.fee) > 0 && (
                    <div className="servicecard-description" style={{fontSize: '0.9rem'}}>
                        {`(${serviceContract.fee}% purchase fee)`}
                    </div>
                )}
            </div>

            <div
                onClick={() => props.selectService(serviceContract)}
                className={`${mobile ? 'col-12' : ipad ? 'col-6 servicecard-vertical-separator' : 'col-2 servicecard-vertical-separator'} py-2 my-auto`}
            >
                <div className="servicecard-title">
                    Customer
                </div>
                <div className="servicecard-description" style={{fontSize: '1rem'}}>
                    {serviceContract.account.display_name ? serviceContract.account.display_name : serviceContract.account.username}
                </div>
            </div>

            <div
                onClick={() => props.selectService(serviceContract)}
                className={`${mobile ? 'col-12' : ipad ? 'col-6' : 'col-4 servicecard-vertical-separator'} py-2 my-auto`}
            >
                <div className="servicecard-title">
                    State
                </div>
                <div className="servicecard-description" style={{fontSize: '1rem'}}>
                    Obtained:{" "}
                    {moment(serviceContract.purchaseTimestamp).format("DD MMM YYYY @ HH:mm")}
                </div>
                <div className="servicecard-description" style={{fontSize: '1rem'}}>
                    {serviceContract.closed ? (
                        <>
                        <div className="d-inline-block">Service {serviceContract.canceled ? "canceled and refunded" : "finished"} on:{" "}</div>
                        <div className="d-inline-block">{moment(serviceContract.finishTimestamp).format("DD MMM YYYY @ HH:mm")}</div>
                        </>
                    ) : (
                        serviceContract.active ? (
                            <>
                            <div className="d-inline-block">Service started on:{" "}</div>
                            <div className="d-inline-block">{moment(serviceContract.startedTimestamp).format("DD MMM YYYY @ HH:mm")}</div>
                            </>
                        ) : (
                            <div className="d-inline-block">Service not yet initiated</div>
                        )
                    )}
                </div>
            </div>

            <div className="col-12 col-lg-2 py-2 my-auto">
                <ButtonInput
                    trigger
                    label={"Contact Customer"}
                    set={() => props.setChatServiceID(serviceContract._id)}
                    style={{backgroundColor: "#fbd731", marginTop: '0px'}}
                />
            </div>
        </div>
        </>
    );
}

function ServiceDetails(props) {
    const serviceContract = props.service;

	const ual = useSelector(state => state.ual);
	const currentUser = useSelector(state => state.info);

    return(
        <>
        <div className="row">
            <div className="col-12 mb-3">
                <div className="servicecard-title">
                    {serviceContract.service.display_name ? serviceContract.service.display_name : serviceContract.service.name}
                </div>
                <div className="servicecard-description" style={{fontSize: '1rem'}}>
                    {ServiceTypes[serviceContract.service.type]}
                </div>
            </div>

            <div className="col-12 mb-2" style={{whiteSpace: 'pre-line'}}>
                <div className="servicecard-description" style={{fontSize: '1rem'}}>
                    {serviceContract.service.description}
                </div>
            </div>

            <div className="col-12 mb-4">
                <div className="row">
                    <div className="col-6 col-sm-3 mt-3 servicecard-vertical-separator">
                        <div className="servicecard-title">
                            Cost
                        </div>
                        <div className="servicecard-description" style={{color: '#fbd731', fontSize: '1rem', fontWeight: 'bold'}}>
                            {serviceContract.price} QUDO
                        </div>
                        {!isNaN(serviceContract.fee) && Number(serviceContract.fee) > 0 && (
                            <div className="servicecard-description" style={{fontSize: '0.9rem'}}>
                                {`(${serviceContract.fee}% purchase fee)`}
                            </div>
                        )}
                    </div>

                    <div className="col-6 col-sm-4 mt-3 servicecard-vertical-separator">
                        <div className="servicecard-title">
                            Customer
                        </div>
                        <div className="servicecard-description" style={{fontSize: '1rem'}}>
                            {serviceContract.account.display_name ? serviceContract.account.display_name : serviceContract.account.username}
                        </div>
                    </div>

                    <div className="col-12 col-sm-5 mt-3">
                        <div className="servicecard-title">
                            State
                        </div>
                        <div className="servicecard-description" style={{fontSize: '1rem'}}>
                            {serviceContract.closed ? (
                                <>
                                <div className="d-inline-block">Service {serviceContract.canceled ? "canceled and refunded" : "finished"} on:{" "}</div>
                                <div className="d-inline-block">{moment(serviceContract.finishTimestamp).format("DD MMM YYYY @ HH:mm")}</div>
                                </>
                            ) : (
                                serviceContract.active ? (
                                    <>
                                    <div className="d-inline-block">Service started on:{" "}</div>
                                    <div className="d-inline-block">{moment(serviceContract.startedTimestamp).format("DD MMM YYYY @ HH:mm")}</div>
                                    </>
                                ) : (
                                    <div className="d-inline-block">Service not yet initiated</div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 mb-2">
                <div className="row">
                    {serviceContract.closed ? (
                        serviceContract.canceled ? (
                            <>
                            <div className="col-12 col-sm-12 mb-1">
                                <ButtonInput
                                    trigger
                                    label={"Messages"}
                                    set={() => props.setChatServiceID(serviceContract._id)}
                                    style={{backgroundColor: "#fbd731", marginTop: '0px'}}
                                />
                            </div>
                            <div className="col-12 col-sm-12 mb-1">
                                <ButtonInput
                                    trigger
                                    label={"Close"}
                                    set={() => props.editService(null)}
                                    style={{backgroundColor: "#ededed", marginTop: '0px'}}
                                />
                            </div>
                            </>
                        ) : (
                            <>
                            <div className="col-12 col-sm-6 mb-1">
                                <ButtonInput
                                    trigger
                                    label={"Re-open Service"}
                                    set={() => props.startService(serviceContract._id)}
                                    style={{backgroundColor: "#fbd731", marginTop: '0px'}}
                                />
                            </div>
                            <div className="col-12 col-sm-6 mb-1">
                                <ButtonInput
                                    trigger
                                    label={"Refund Service"}
                                    style={{
                                        backgroundColor: !currentUser.qudoManaged && !ual.activeUser ? "#b2b2b2" : "#f2544c",
                                        cursor: !currentUser.qudoManaged && !ual.activeUser ? "initial" : "pointer",
                                        marginTop: '0px'
                                    }}
                                    set={() => {
                                        if(currentUser.qudoManaged || ual.activeUser) {
                                            props.cancelService(serviceContract);
                                        }
                                    }}
                                />
                                {!currentUser.qudoManaged && !ual.activeUser && (
                                    <div
                                        className='my-1'
                                        style={{fontSize: '0.9rem'}}
                                        onClick={() => {
                                            ual.logout();
                                            ual.restart();
                                            ual.showModal();
                                        }}
                                    >
                                        Please
                                        <b
                                            className='mx-1'
                                            style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}}
                                        >
                                            sign in
                                        </b>
                                        with one of the available wallets to refund a service
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-sm-12 mb-1">
                                <ButtonInput
                                    trigger
                                    label={"Messages"}
                                    set={() => props.setChatServiceID(serviceContract._id)}
                                    style={{backgroundColor: "#fbd731", marginTop: '0px'}}
                                />
                            </div>
                            <div className="col-12 col-sm-12 mb-1">
                                <ButtonInput
                                    trigger
                                    label={"Close"}
                                    set={() => props.editService(null)}
                                    style={{backgroundColor: "#ededed", marginTop: '0px'}}
                                />
                            </div>
                            </>
                        )
                    ) : (
                        <>
                        {serviceContract.active ? (
                            <div className="col-12 col-sm-6 mb-1">
                                <ButtonInput
                                    trigger
                                    label={"End Service"}
                                    set={() => props.closeService(serviceContract._id)}
                                    style={{backgroundColor: "#fbd731", marginTop: '0px'}}
                                />
                            </div>
                        ) : (
                            <div className="col-12 col-sm-6 mb-1">
                                <ButtonInput
                                    trigger
                                    label={"Start Service"}
                                    set={() => props.startService(serviceContract._id)}
                                    style={{backgroundColor: "#fbd731", marginTop: '0px'}}
                                />
                            </div>
                        )}
                        <div className="col-12 col-sm-6 mb-1">
                            <ButtonInput
                                trigger
                                label={"Refund Service"}
                                style={{
                                    backgroundColor: !currentUser.qudoManaged && !ual.activeUser ? "#b2b2b2" : "#f2544c",
                                    cursor: !currentUser.qudoManaged && !ual.activeUser ? "initial" : "pointer",
                                    marginTop: '0px'
                                }}
                                set={() => {
                                    if(currentUser.qudoManaged || ual.activeUser) {
                                        props.cancelService(serviceContract);
                                    }
                                }}
                            />
                            {!currentUser.qudoManaged && !ual.activeUser && (
                                <div
                                    className='my-1'
                                    style={{fontSize: '0.9rem'}}
                                    onClick={() => {
                                        ual.logout();
                                        ual.restart();
                                        ual.showModal();
                                    }}
                                >
                                    Please
                                    <b
                                        className='mx-1'
                                        style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}}
                                    >
                                        sign in
                                    </b>
                                    with one of the available wallets to refund a service
                                </div>
                            )}
                        </div>
                        <div className="col-12 col-sm-12 mb-1">
                            <ButtonInput
                                trigger
                                label={"Messages"}
                                set={() => props.setChatServiceID(serviceContract._id)}
                                style={{backgroundColor: "#fbd731", marginTop: '0px'}}
                            />
                        </div>
                        <div className="col-12 col-sm-12 mb-1">
                            <ButtonInput
                                trigger
                                label={"Close"}
                                set={() => props.editService(null)}
                                style={{backgroundColor: "#ededed", marginTop: '0px'}}
                            />
                        </div>
                        </>
                    )}
                </div>
            </div>
        </div>
        </>
    );
}
