import React from "react";
import {ClipLoader} from "react-spinners";
import {Modal, Form} from "react-bootstrap";
import {TextAreaInput} from "../../forms/text";

export default function ConfirmModal(props) {
    return(
        <>
            <Modal show={props.show} onHide={props.hide}>
                <Modal.Body className="confirm-modal">
                    <Form style={{padding: "2rem 4rem 0rem"}}>
                        <div className="row confirm-modal-text">
                            <div className="col-12 text-center">
                                {props.message}
                            </div>
                        </div>
                        {props.loading ? (
                            <div className="row my-4">
                                <div className="col-12">
                                    <div className="text-center">
                                        <ClipLoader color="#282725"/>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                            {props.feedbackPlaceholder && (
                                <div className="row my-4">
                                    <div className="col-12">
                                        <TextAreaInput
                                            value={props.feedback}
                                            set={props.setFeedback}
                                            placeholder={props.feedbackPlaceholder}
                                            className={"input-field-custom"}
                                            style={{minHeight: '7.5rem'}}
                                            required={false}
                                            maxLength={1000}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="row d-flex position-relative align-middle mt-4" style={{margin: "1rem -4rem 0rem"}}>
                                <div className={`text-center col-6 confirm-modal-button pointer pt-3`} onClick={props.hide}>
                                    Cancel
                                </div>
                                <div className={`col-6 text-center confirm-modal-button pointer pt-3`} onClick={props.confirm}>
                                    Confirm
                                </div>
                            </div>
                            </>
                        )}
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
