import { ButtonInput } from "components/components/forms/buttons";
import { DropdownInput } from "components/components/forms/dropdowns";
import { MessageModal } from "components/components/modals/modals";
import QUDOServer from "helpers/QUDOServerConnection";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import SteamWishlistForm from "./challengesForms/SteamWishlistForm";
import SteamReviewForm from "./challengesForms/SteamReviewForm";

const challengesNames = {
    steamWishlist: 'Steam Wishlist',
    steamReview: 'Steam Review'
}

export default function Challenge({
    game, challenge, refresh
}) {

    const [challenges, setChallenges] = useState();
    const [defaultReward, setDefaultReward] = useState(0);
    const [gameChallenges, setGameChallenges] = useState({});

    const [valid, setValid] = useState(false);
    const [form, setForm] = useState(null);
    const [formData, setFormData] = useState(null);
    const [enabled, setEnabled] = useState(false);
    const [dropdownChallenge, setDropdownChallenge] = useState([{ key: '', label: '' }]);
    const [selectedChallenge, setSelectedChallenge] = useState();
    const [modalMessage, setModalMessage] = useState('');


    function getChallenges() {
        const config = {
            withCredentials: true
        }

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/challenge/getavailablechallenges`, { game: game.name }, config)
            .then(response => {
                setDefaultReward(response.data.reward);
                setChallenges(response.data.challenges);

                const dropdown = response.data.challenges.map(c => {
                    return ({
                        key: c.name,
                        label: c.name
                    });
                });
                setDropdownChallenge(dropdown);
            });
    }

    useEffect(() => {
        getChallenges();
    }, []);

    useEffect(() => {
        if (challenge) {
            setEnabled(challenge.active);
        }
    }, [challenge]);

    
    useEffect(() => {
        if (!challenge) {
            if (selectedChallenge) {
                switch (selectedChallenge.name) {
                    case challengesNames.steamWishlist:
                        if (!isNaN(formData.reward)
                            && formData.steamId !== '') {
                            setValid(true);
                        } else {
                            setValid(false);
                        }
                        break;
                    case challengesNames.steamReview:
                        if (!isNaN(formData.reward)
                            && !isNaN(formData.minMinutes)
                            && formData.steamId !== '') {
                            setValid(true);
                        } else {
                            setValid(false);
                        }
                        break;
                    default:
                        setValid(false)
                        break;
                }
            }
        } else if ((challenge?.name === challengesNames.steamWishlist)
            || selectedChallenge?.name === challengesNames.steamWishlist) {

            if (!isNaN(formData.reward)
                && formData.steamId !== '') {

                setValid(true);
            } else {
                setValid(false);
            }
        } else if ((challenge?.name === challengesNames.steamReview)
            || selectedChallenge?.name === challengesNames.steamReview) {

            if (!isNaN(formData.reward)
                && !isNaN(formData.minMinutes)
                && formData.steamId !== '') {

                setValid(true);
            } else {
                setValid(false);
            }
        } else {
            setValid(false);
        }

    }, [formData]);

    const handleChallengeSelection = (name) => {
        setSelectedChallenge(challenges.find(c => c.name === name));
    }

    const submit = (value) => {
        let body = {};

        if ((challenge && challenge.name === challengesNames.steamWishlist)
            || selectedChallenge?.name === challengesNames.steamWishlist) {

            body = {
                name: challenge ? challenge.name : selectedChallenge.name,
                game: game.name,
                reward: formData.reward,
                conditions: {
                    "steamwishlist": {
                        data: {
                            steamId: formData.steamId
                        }
                    }
                },
                active: challenge ? enabled : false
            }
        } else if ((challenge && challenge.name === challengesNames.steamReview)
            || selectedChallenge.name === challengesNames.steamReview) {

            body = {
                name: challenge ? challenge.name : selectedChallenge.name,
                game: game.name,
                reward: formData.reward,
                conditions: {
                    "gameplaytime": {
                        data: {
                            minMinutes: formData.minMinutes
                        }
                    },
                    "steamreview": {
                        data: {
                            steamId: formData.steamId
                        }
                    }
                },
                active: challenge ? enabled : false
            }
        }

        var url = '';

        if (challenge) {
            url = `${process.env.REACT_APP_QUDO_SERVER}/game/challenge/update`;
        } else {
            url = `${process.env.REACT_APP_QUDO_SERVER}/game/challenge/setgamedevchallenge`;
        }

        console.log("submitting challenge: ", body, " to ", url, " with credentials")

        QUDOServer.post(url, body, { withCredentials: true })
            .then(result => {
                setModalMessage('Success');
                setSelectedChallenge(undefined);
                setValid(false);

                //reset();
                refresh();
            })
            .catch(error => {
                setModalMessage(error.errorMessage);
                //setFailureModal(true);

                //reset();
                //props.refresh();
            });
    }

    return (
        <div>
            {challenge ?
                <>
                    <div
                        className={`text-break col-12 mb-4 edit-screen-menu`}>
                        Edit Challenges
                    </div>
                    <div className="col-12">
                        <Form ref={(el) => setForm(el)}>
                            <div className={`text-break col-12 mb-4 edit-screen-menu`}>
                                {challenge.name}
                            </div>

                            {(challenge.name === challengesNames.steamWishlist) &&
                                <SteamWishlistForm setData={setFormData}
                                    defaultReward={defaultReward} challenge={challenge} />
                            }
                            {(challenge.name === challengesNames.steamReview) &&
                                <SteamReviewForm setData={setFormData}
                                    defaultReward={defaultReward} challenge={challenge} />
                            }

                            <div className="row mt-1 mb-3 mx-2 user-select-none">
                                <div className="col" onClick={() => setEnabled(!enabled)}>
                                    <span>Enabled?</span>
                                    <input
                                        className="ml-2 align-middle"
                                        type="checkbox"
                                        checked={enabled}
                                        onChange={() => setEnabled(!enabled)}
                                    />
                                </div>
                            </div>

                            <ButtonInput
                                trigger
                                label="Save Challenge"
                                set={submit}
                                disabled={!valid}
                                style={
                                    !valid
                                        ? { cursor: 'initial' }
                                        : { backgroundColor: "#fbd731" }
                                }
                            />
                        </Form>
                    </div>
                </>
                :
                <>
                    <div
                        className={`text-break col-12 mb-4 edit-screen-menu`}>
                        Add Challenges
                    </div>
                    <div className="col-12">
                        <Form ref={(el) => setForm(el)}>
                            <DropdownInput
                                label='Select Available Challenges'
                                initial='Please select a challenge'
                                set={handleChallengeSelection}
                                choices={dropdownChallenge}
                            />

                            {(selectedChallenge && selectedChallenge.name === challengesNames.steamWishlist) &&
                                <SteamWishlistForm setData={setFormData}
                                    defaultReward={defaultReward} challenge={undefined} />
                            }

                            {(selectedChallenge && selectedChallenge.name === challengesNames.steamReview) &&
                                <SteamReviewForm setData={setFormData}
                                    defaultReward={defaultReward} challenge={undefined} />
                            }

                            <ButtonInput
                                trigger
                                label="Create Challenge"
                                set={submit}
                                disabled={!valid}
                                style={
                                    !valid
                                        ? { cursor: 'initial' }
                                        : { backgroundColor: "#fbd731" }
                                }
                            />
                        </Form>
                    </div>
                </>
            }
            <MessageModal
                show={!!modalMessage}
                message={modalMessage}
                hide={() => {
                    setModalMessage('');
                }}
            />
        </div>
    );
}