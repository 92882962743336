import React, {useState, useEffect} from 'react';
import ReactPlayer from 'react-player';
//import ReactTooltip from 'react-tooltip';

const fallbackAvatar = require("../../../images/fallbackAvatar.png");

function getVideoThumb(videoID) {
    return `https://img.youtube.com/vi/${videoID}/default.jpg`;
}

function getvideoID(URL) {
    const url = String(URL);

    const linkFormats = [
        'http://www.youtube.com/embed/',
        'https://www.youtube.com/embed/',
        'http://www.youtube.com/watch?v=',
        'https://www.youtube.com/watch?v=',
        'http://www.youtube.com/v/',
        'https://www.youtube.com/v/',
        'https://youtu.be/'
    ];

    var response = '';

    //Get the video ID
    for(var i = 0; i < linkFormats.length; i++) {
        const linkFormat = linkFormats[i];

        if(url.includes(linkFormat)) {
            response = url.substr(linkFormat.length, url.length);
        }
    }

    //Remove any extra info the URL might still have
    if(response.includes('&')) {
        response = response.substr(0, response.indexOf('&'));
    }
    if(response.includes('?')) {
        response = response.substr(0, response.indexOf('?'));
    }

    //console.log(response);
    return response;
}

export function customRenderThumb(children) {
    //console.log(children);

    return children.map((item) => {
        if(item.type === 'img') {
            const imageItem = (
                <img
                    alt='Thumbnail'
                    src={item.props.src}
                    onError={(i) => (i.target.src = fallbackAvatar)}
                    delete={item.props.delete}
                    className='img-fluid'
                    style={{maxHeight: '75px'}}
                />
            );

            return customRenderItem(imageItem, imageItem.props, true);

            // return customRenderItem(item, item.props, true);
        } else {
            const videoID = getvideoID(item.props.url);

            const imageItem = (
                <img
                    alt='Thumbnail'
                    src={getVideoThumb(videoID)}
                    onError={(i) => (i.target.src = fallbackAvatar)}
                    delete={item.props.delete}
                    className='img-fluid'
                    //style={{maxHeight: '75px'}}
                />
            );

            return customRenderItem(imageItem, imageItem.props, true);

            // return (
            //     <img alt="Thumbnail" src={getVideoThumb(videoID)}/>
            // );
        }
    });
}

export function customRenderItem(item, props, thumbnail) {
    //console.log(item, props);

    //If it's a thumbnail and there's a delete prop, it means that we should render the delete icon
    if(thumbnail && item.props.delete) {
        return (
            <div key={Math.random()}>
                <item.type {...item.props} {...props}/>

                <div
					//data-tip
					//data-for='delete'
                    onClick={item.props.delete}
                    className='enable-click'
                    style={{
                        display: 'inline-block',
                        position: 'relative',
                        top: '-10px',
                        right: '15px',
                        color: 'red',
                        fontWeight: 'bold',
                        fontSize: '1.5rem'
                    }}
                >
                    x
                </div>

                {/*}
                <ReactTooltip id='receiver' type='light'>
				    <span>Click to delete this image</span>
				</ReactTooltip>
                {*/}
            </div>
        );
    } else {
        return (
            <item.type key={Math.random()} {...item.props} {...props}/>
        );
    }
}

export function YoutubeSlide({url, isSelected}) {
    const [playing, setPlaying] = useState(false);

    useEffect(() => {
        if(playing && !isSelected) {
            setPlaying(false);
        }
    }, [playing, isSelected]);

    return (
        <ReactPlayer
            url={url}
            playing={false}
            controls={true}
            light={true}
            width="100%"
            config={{
                youtube: {
                    playerVars: { fs: 0 }
                }
            }}
            height="100%"
            onPlay={() => {
                setPlaying(true);
            }}
        />
    );
}
