import React, {useState, useEffect} from "react";

import useWindowSize from "../../helpers/useWindowSize";
import QUDOServer from "../../helpers/QUDOServerConnection";

import {ButtonInput} from '../components/forms/buttons';

const fallBackAvatar = require("../../images/definitive/icon-avatar.png");

export default function TopGuilds(props) {
    const windowSize = useWindowSize();
    const isMobile = (windowSize && windowSize.width < 768);

    const guildsMinLoadNr = 5;
    const guildsMaxLoadNr = 10;

    const [guilds, setGuilds] = useState([]);
    const [showAllGuilds, setShowAllGuilds] = useState(false);

    useEffect(() => {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/top/guilds`)
        .then((response) => {
            setGuilds(response.data);
        });
    }, []);

    /*
    return(
        Array.isArray(guilds) && guilds.length > 0 ? (
            <div id="sh-container">
                <div className="h4" id="sh-list-title">
                    Top Gaming Guilds
                </div>
                <div className="m-auto pt-2">
                    <div className="row pt-1">
                        <>
                        {guilds.slice(0, (!isMobile || showAllGuilds ? guildsMaxLoadNr : guildsMinLoadNr)).map((i, index) => {
                            return(
                                <div
                                    id="guildRow"
                                    key={i.account_name}
                                    className="col-12 col-md-6"
                                >
                                    <div
                                        className="row d-flex align-top top-stakeholders-card"
                                        style={((!isMobile && index > 1) || (isMobile && index > 0)) ? {borderTop: '1px solid #282725'}: {}}
                                    >
                                        <div className="col-2 col-xl-1 sh-avatar-outer">
                                            {i.imageID ? (
                                                <img
                                                    alt="User avatar"
                                                    src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${i.imageID}&type=${i.type}`}
                                                    className="sh-avatar rounded-circle"
                                                    onError={i=> i.target.src = fallBackAvatar}
                                                />
                                            ) : (
                                                <img
                                                    alt="User avatar"
                                                    //src={fallBackAvatar}
                                                    src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=0&type=user`}
                                                    className="sh-avatar rounded-circle"
                                                    onError={i=> i.target.src = fallBackAvatar}
                                                />
                                            )}
                                        </div>
                                        <div className="col-10 flex-column justify-content-center text-wrap">
                                            <div className="text-wrap" style={{display: 'inline-block'}}>
                                                <div id="top-players-info-bottom" className="text-wrap">
                                                    {i.username ? (i.display_name ? i.display_name : i.username) : i.account_name}
                                                </div>
                                            </div>
                                            <div
                                                id="top-players-score"
                                                className="text-wrap"
                                                style={
                                                    document.getElementById('guildRow')
                                                    && document.getElementById('guildRow').clientWidth < 450
                                                    ? {} : {float: 'right'}
                                                }
                                            >
                                                #{index + 1}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {(isMobile && guilds.length > guildsMinLoadNr) && (
                            <div className="col-12">
                                <ButtonInput
                                    trigger
                                    label={showAllGuilds ? "Show Less" : "Show More"}
                                    set={() => setShowAllGuilds(!showAllGuilds)}
                                    style={{
                                        color: "#fbd731",
                                        backgroundColor: "#282725",
                                        border: "0px",
                                        marginTop: "10px"
                                    }}
                                />
                            </div>
                        )}
                        </>
                    </div>
                </div>
            </div>
        ) : (
            <></>
        )
    );
    */

    if(Array.isArray(guilds) && guilds.length > 0) {
        return(
            <div
                className="main-inner-container py-5"
                style={{
                    backgroundColor: "#282725"
                }}
            >
                <div
                    className="h4 main-inner-title"
                    style={{color: "#fbd731"}}
                >
                    Top Gaming Guilds
                </div>
                <div>
                    <div className="d-flex flex-column mt-4 flex-wrap"
                        style={isMobile ? {} : {height: 264}}>
                        {guilds.slice(0, (!isMobile || showAllGuilds ? guildsMaxLoadNr : guildsMinLoadNr)).map((i, index) => {
                            return(
                                <div className="col-12 col-md-6" key={index}
                                    style={{flexBasis: '20%'}}>
                                    <div
                                        className='px-4 py-3 mb-2'
                                        style={{
                                            backgroundColor: index < 2 ? '#fcd902' : (index < 5 ? '#ffffff' : '#b2b2b2'),
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <div className='row'>
                                            <div className='col-2'>
                                                {i.imageID ? (
                                                    <img
                                                        alt="User avatar"
                                                        src={
                                                            i.imageURL ? i.imageURL :
                                                                `${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${i.imageID}&type=${i.type}&size=xS`
                                                        }
                                                        className="sh-avatar rounded-circle"
                                                        onError={i=> i.target.src = fallBackAvatar}
                                                    />
                                                ) : (
                                                    <img
                                                        alt="User avatar"
                                                        //src={fallBackAvatar}
                                                        src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=0&type=user&size=xS`}
                                                        className="sh-avatar rounded-circle"
                                                        onError={i=> i.target.src = fallBackAvatar}
                                                    />
                                                )}
                                            </div>
                                            <div className='col-10 my-auto'>
                                                <div className='row'>
                                                    <div
                                                        className='col-2 align-self-center text-left'
                                                        style={{fontSize: '1.25rem', fontWeight: 'bold'}}
                                                    >
                                                        #{index+1}
                                                    </div>
                                                    <div
                                                        className='col-10 align-self-center text-right'
                                                        style={{fontWeight: 'bold'}}
                                                    >
                                                        {i.username ? (i.display_name ? i.display_name : i.username) : i.account_name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {(isMobile && guilds.length > guildsMinLoadNr) && (
                            <div className="col-12">
                                <ButtonInput
                                    trigger
                                    label={showAllGuilds ? "Show Less" : "Show More"}
                                    set={() => setShowAllGuilds(!showAllGuilds)}
                                    style={{
                                        color: "#282725",
                                        backgroundColor: "#fbd731",
                                        border: "0px",
                                        marginTop: "10px"
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}
