import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ClipLoader} from 'react-spinners';

import classes from './balance.scss';

//import ConfirmFundingModal from '../../../components/modals/confirmFundingModal/confirmFundingModal';
//import AfterActionModal from '../../../components/modals/successModal/afterActionModal';
import {MessageModal} from '../../../components/modals/modals.js';
import WalletImportTutorial from '../../../common/walletMigration/walletImportTutorial';

import {fetchUser} from '../../../../actions/index';

import QUDOServer from "../../../../helpers/QUDOServerConnection";

export default function GameBalance(props)
{
    const shouldRender = props.gameAccount && props.gameAccount.accepted && props.gameAccount.account_name;

    const dispatch = useDispatch();

    const ual = useSelector(state => state.ual);
    const info = useSelector(state => state.info);
    const balanceState = useSelector(state => state.balance);

    const [gameBalance, setGameBalance] = useState(0);
    //const [gameBalance, setGameBalance] = useState(props.gameBalance);
    const [maxBalance, setMaxBalance] = useState(0);
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [balance, setBalance] = useState(0);

    const [newGamedevBalance, setNewGamedevBalance] = useState(0);
    const [newGameBalance, setNewGameBalance] = useState(0);
    //const [fundingValid, setFundingValid] = useState(0);

    //const [successModal, setSuccessModal] = useState();
    //const [failureModal, setFailureModal] = useState();
	const [modalMessage, setModalMessage] = useState('');
	const [sendLoading, setSendLoading] = useState(false);
    //const [confirmFundingModal, setConfirmFundingModal] = useState();

    const inputEl = useRef(null);
    const [funding, setFunding] = useState(false);

    const [gameBalanceHistory, setGameBalanceHistory] = useState(0);
    const [gamedevBalanceHistory, setGamedevBalanceHistory] = useState(0);
    const [balanceHistoryReady, setBalanceHistoryReady] = useState(false);

    const [buttonSelected, setButtonSelected] = useState(null);
    const [maxValueInput, setMaxValueInput] = useState();

	const [tutorialModal, setTutorialModal] = useState(false);

    //const [gameDifference, setGameDifference] = useState(0)

    const cancelChanges = () =>
    {
        resetFunding();

        setQuantity(0);

        if (balance && gameBalance)
        {
            setNewGamedevBalance(Number(balance.split(' ')[0]).toFixed(4));
            setNewGameBalance(Number(gameBalance.split(' ')[0]).toFixed(4));
        }
    }

    useEffect(() =>
    {
        getGameBalance();
        getGameBalanceHistory();
        getBalanceHistory();
    }, [])

    useEffect(() =>
    {
        getGameBalance();
        getGameBalanceHistory();
        getBalanceHistory();

    }, [balanceState])

    const getGameBalance = () =>
    {
        if(shouldRender) {
            QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/game/balance?game=${props.gameAccount.name}`, {
                withCredentials: true
            })
            .then((response) => {
                setGameBalance(response.data.balance);
                setNewGameBalance(Number(response.data.balance.split(' ')[0]).toFixed(4));
            })
        } else {
            setGameBalance(0);
        }
    }

    const getGameBalanceHistory = () =>
    {
        // const date = new Date();
        //         const weekBefore = date.getDate() - 7;
        //         date.setDate(weekBefore);
    
        //         let weekAgoBalance = props.balances?.find(gameBalance => 
        //             new Date(gameBalance.time).getTime() < date.getTime());
                
        //         if (!weekAgoBalance && props.balances)
        //         {
        //             weekAgoBalance = props.balances[0];
        //         }

        //         setGameBalanceHistory(weekAgoBalance);

        if(shouldRender) {
            QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/game/history?game=${props.gameAccount.name}`, 
            {
                withCredentials: true
            })
            .then(resp => 
            {
                if (!resp.errorMessage)
                {                
                    const date = new Date();
                    const weekBefore = date.getDate() - 7;
                    date.setDate(weekBefore);
        
                    let weekAgoBalance = resp.data.balances.find(gameBalance => 
                        new Date(gameBalance.time).getTime() === date.getTime());
                    
                    if (weekAgoBalance)
                    {
                        weekAgoBalance = weekAgoBalance.balance;
                    }
    
                    setGameBalanceHistory(weekAgoBalance);
                }
            })
            .catch(err =>
            {
                setGameBalanceHistory(0);
            });
        }
    }

    const getBalanceHistory = () =>
    {
        if(shouldRender) {
            QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/api/history/balance`, {
                username: info.username
            })
            .then(resp =>
            {
                const date = new Date();
                const weekBefore = date.getDate() - 7;
                date.setDate(weekBefore);
    
                let weekAgoBalance = resp.data?.balances.find(devBalance => 
                    new Date(devBalance.time).getTime() === date.getTime());
                    
                if (weekAgoBalance)
                {
                    weekAgoBalance = weekAgoBalance.balance;
                }
                
                setGamedevBalanceHistory(weekAgoBalance);
                setBalanceHistoryReady(true);
            });
        }
    }

    useEffect(() =>
    {
        setInitialMaxBalance();
    }, [gameBalance]);

    useEffect(() =>
    {
        if (balanceState?.balance)
        {
            const calculatedBalance = Number(balanceState.balance.split(' ')[0]) - Number(balanceState.stake.split(' ')[0]);
            setBalance(calculatedBalance.toFixed(4) + " QUDO");
            setNewGamedevBalance(calculatedBalance.toFixed(4));
        }
    }, [balanceState]);

    useEffect(() =>
    {
        resetFunding();
    }, [props.activePage]);

    useEffect(() =>
    {
        if (gameBalance && balance)
        {
            setMaxValueInput(getMaxValue());
        }
    }, [buttonSelected]);

    useEffect(() =>
    {
        if (!gamedevBalanceHistory)
        {
            setGamedevBalanceHistory(balance)
        }
    }, [balance]);

    const resetFunding = () =>
    {
        setFunding(false);
    }

    const toggle = (type) => 
    {
        if(!balanceState) {
            return;
        }

        setButtonSelected(type)
        setFunding(true);
    }

    const setInitialMaxBalance = () =>
    {
        if (gameBalance && balance)
        {
            const total = Number(gameBalance.split(' ')[0]) + Number(balance.split(' ')[0]);
            setMaxBalance(total);
        }
    }

    const transfer = (event) => {
        //event.preventDefault();

        setSendLoading(true);

        if(buttonSelected === 'devToGame') {
            if(!info.qudoManaged) {
                if(!ual.activeUser) {
                    ual.logout();
                    ual.restart();
                    ual.showModal();
					setSendLoading(false);
                    return;
                } else {
                    ual.activeUser.signTransaction({
						actions: [{
							account: process.env.REACT_APP_TOKENCONTRACT,
							name: "transfer",
							authorization: [{
								actor: info.account_name,
								permission: "active"
							}],
							data: {
								from: info.account_name,
								to: process.env.REACT_APP_APPCONTRACT,
								quantity: `${Number(quantity).toFixed(4)} ${process.env.REACT_APP_TOKENNAME}`,
								memo: "Game balance deposit: " + props.gameAccount.account_name
							}
						}]
					}, {
						blocksBehind: 3,
						expireSeconds: 30,
						broadcast: true
					})
                    .then((result) => {
                        //setSuccessModal(true);
                        setModalMessage('Success');

                        setTimeout(() => {
                            triggerUpdate();
                        }, 2500);
                    })
                    .catch((error) => {
                        //console.log(error);
                        //setFailureModal(true);
                        setModalMessage(error.toString());
                    })
                    .finally(() => {
                        //setConfirmFundingModal(false);
                        setSendLoading(false);
                    });
                }
            } else {
                QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/balance/deposit`, {
                    game: props.gameAccount.name,
                    quantity: quantity
                }, {
                    withCredentials: true
                })
                .then((result) => {
                    //setSuccessModal(true);
                    setModalMessage('Success');

                    setTimeout(() => {
                        triggerUpdate();
                    }, 2500);
                })
                .catch((error) => {
                    //setFailureModal(true);
                    setModalMessage(error.errorMessage);
                })
                .finally(() => {
                    //setConfirmFundingModal(false);
                    setSendLoading(false);
                });
            }
        }

        if(buttonSelected === 'gameToDev') {
            if(!info.qudoManaged) {
                if(!ual.activeUser) {
                    ual.logout();
                    ual.restart();
                    ual.showModal();
					setSendLoading(false);
                    return;
                } else {
                    ual.activeUser.signTransaction({
						actions: [{
							account: process.env.REACT_APP_APPCONTRACT,
							name: "remgamebal",
							authorization: [{
								actor: info.account_name,
								permission: "active"
							}],
							data: {
                                game_id: props.gameAccount.account_name,
								quantity: `${Number(quantity).toFixed(4)} ${process.env.REACT_APP_TOKENNAME}`
							}
						}]
					}, {
						blocksBehind: 3,
						expireSeconds: 30,
						broadcast: true
					})
                    .then((result) => {
                        //setSuccessModal(true);
                        setModalMessage('Success');

                        setTimeout(() => {
                            triggerUpdate();
                        }, 2500);
                    })
                    .catch((error) => {
                        //console.log(error);
                        //setFailureModal(true);
                        setModalMessage(error.toString());
                    })
                    .finally(() => {
                        //setConfirmFundingModal(false);
                        setSendLoading(false);
                    });
                }
            } else {
                QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/balance/withdraw`, {
                    game: props.gameAccount.name,
                    quantity: quantity
                }, {
                    withCredentials: true
                })
                .then((result) => {
                    //setSuccessModal(true);
                    setModalMessage('Success');

                    setTimeout(() => {
                        triggerUpdate();
                    }, 2500);
                })
                .catch((error) => {
                    //setFailureModal(true);
                    setModalMessage(error.errorMessage);
                })
                .finally(() => {
                    //setConfirmFundingModal(false);
                    setSendLoading(false);
                });
            }
        }
    }

    const changeGameBalance = (value) => 
    {
        //Replace commas with dots
        if(!value || isNaN(value)) {
            value = parseFloat(String(value).replace(',', '.'));
        }

        //Replace dots with commas
        if(!value || isNaN(value)) {
            value = parseFloat(String(value).replace('.', ','));
        }

        value = parseFloat(value);

        if(isNaN(value)) {
            value = Number(gameBalance);
        }

        //setFundingValid(true);

        let from, quantity, to = "";

        let gameDevBalance = 0;
        let gameBalanceChange = 0;

        if (buttonSelected === 'gameToDev') 
        {
            gameDevBalance = Number(balance.split(' ')[0]) + value;
            gameBalanceChange = Number(gameBalance.split(' ')[0]) - value;

            //from = props.gameAccount.account_name;
            from = process.env.REACT_APP_APPCONTRACT;
            to = info.account_name;
            quantity = value.toFixed(4);
        } 

        if (buttonSelected === 'devToGame') 
        {
            gameDevBalance = Number(balance.split(' ')[0]) - value;
            gameBalanceChange = Number(gameBalance.split(' ')[0]) + value;

            from = info.account_name;
            //to = props.gameAccount.account_name;
            to = process.env.REACT_APP_APPCONTRACT;
            quantity = value.toFixed(4);        
        }

        setNewGameBalance(gameBalanceChange.toFixed(4));
        setNewGamedevBalance(gameDevBalance.toFixed(4));
        setFrom(from);
        setTo(to);
        setQuantity(Number(quantity));

        /*
        if(value === 0) 
        {
            setFundingValid(false)
        }
        */
    }

    const getBalanceDifference = (type) =>
    {
        if (!balanceHistoryReady || !balance || !gameBalance)
        {
            return;
        }

        let balanceWeekAgo = gamedevBalanceHistory;

        if (!gamedevBalanceHistory)
        {
            balanceWeekAgo = balance;
        }

        let checkDifference = 0;
        let stringToReturn = '0';

        let actualBalance = balance ? Number(balance.split(' ')[0]) : 0;
        let weekAgoBalance = balanceWeekAgo ? Number(balanceWeekAgo.split(' ')[0]) : 0;

        if (type === 'game')
        {
            if (!gameBalanceHistory)
            {
                balanceWeekAgo = gameBalance;
            }

            actualBalance = gameBalance ? Number(gameBalance.split(' ')[0]) : 0;
            weekAgoBalance = balanceWeekAgo ? Number(balanceWeekAgo.split(' ')[0]) : 0;
        }


        if (actualBalance === 0 || weekAgoBalance === 0)
        {
            return;
        }

        checkDifference = (actualBalance - weekAgoBalance) / weekAgoBalance * 100;

        stringToReturn = checkDifference > 0 ? 
            `+${checkDifference.toFixed(4)}%` : `${checkDifference.toFixed(4)}%`;

        return (
            <div className={checkDifference > 0 ? 'deltas green' : checkDifference === 0 ? 'deltas grey' : 'deltas red'}>
                {stringToReturn}
            </div>
        );
    }

    const getMaxValue = () =>
    {
        if (buttonSelected === 'gameToDev') {
            return Number(gameBalance.split(' ')[0]);
        } else {
            return Number(balance.split(' ')[0]);
        }
        // const balanceNoQudo = Number(balance.split(' ')[0]);
        // const gameBalanceNoQudo = Number(gameBalance.split(' ')[0]);
        // return buttonSelected === 'gameToDev' ? gameBalanceNoQudo : balanceNoQudo;
    }

    const triggerUpdate = () =>
    {
        dispatch(fetchUser());
        getGameBalanceHistory();
        getBalanceHistory();
        getGameBalance();
    }

    return (
        shouldRender &&
        <>
        <div className="borders mb-3">
            <div className="row mx-1 mb-3">
                <div className="col-12 edit-screen-stakes-sm header mt-0">
                    <strong>Available Balance</strong>
                </div>
                {/* <div className="col-12 updated">value to be shown</div> */}

                    <div className="col-6 mt-2">
                        <input
                            type="number"
                            className="edit-screen-stakes-big-input"
                            step={1}
                            disabled={true}
                            //disabled={!funding}
                            value={(!isNaN(newGamedevBalance)) ? newGamedevBalance : parseFloat(balance).toFixed(4)}
                            onChange={(e) => setNewGamedevBalance(e.target.value)}
                            onBlur={() => {
                                const value = maxBalance - parseFloat(newGamedevBalance ? newGamedevBalance : balance.toFixed(4));
                                changeGameBalance(value);
                            }}
                        />

                    <div className="edit-screen-stakes-sm submenus mb-3">Dev Balance</div>

                    <div>
                        {getBalanceDifference('gamedev')}
                    </div>

                    <div className="updated">
                        Since Last Week
                    </div>
                </div>

                <div className="col-6 mt-2">
                    <input
                        type="number"
                        className="edit-screen-stakes-big-input"
                        step={1}
                        disabled={true}
                        //disabled={!funding}
                        onChange={(e) => setNewGameBalance(e.target.value)}
                        onBlur={() => changeGameBalance(!isNaN(newGameBalance) ? newGameBalance : parseFloat(gameBalance).toFixed(4))}
                        value={(!isNaN(newGameBalance)) ? newGameBalance : parseFloat(gameBalance).toFixed(4)}
                    />

                    <div className="edit-screen-stakes-sm submenus mb-3">Game Balance</div>

                    <div>
                        {getBalanceDifference('game')}
                    </div>

                    <div className="updated">
                        Since Last Week
                    </div>
                </div>

                {/* If the user is funding the game (clicked FUND THIS GAME) then present the slider  */}
                {funding &&
                    <>
                        <div className="col-12">
                            <input
                                ref ={inputEl}
                                style={{ clear: "both" }}
                                type="range"
                                className="custom-range col-12"
                                required
                                onChange={(e) => changeGameBalance(e.target.value)}
                                value={quantity}
                                max={maxValueInput}
                                min={0}
                                step={0.0001}
                            />
                        </div>
                        {/*}
                        <div className="col-12 text-center deltas value">
                            {`${inputEl.current?.value ? inputEl.current.value : 0} QUDO`}
                        </div>
                        {*/}
                        {/*}
                        <input
                            type="number"
                            className="edit-screen-stakes-big-input"
                            step={1}
                            value={quantity}
                            onChange={(e) => changeGameBalance(e.target.value)}
                        />
                        {*/}
                        <div className="col-12 text-center deltas value">
                            <input
                                type="number"
                                className="edit-screen-stakes-big-input text-center deltas value"
                                step={1}
                                value={quantity}
                                onChange={(e) => changeGameBalance(e.target.value)}
                            />
                            <div style={{fontSize: '1rem'}}>
                                QUDO
                            </div>
                        </div>
                    </>
                }
            </div>

            {!info.qudoManaged && !ual.activeUser ? (
                <>
                    <div className="row col-12 mx-0 mt-3 pb-3">
                        <div className="text-center">
                            Please sign in with one of the available wallets to change your game's balance
                        </div>
                        <div
                            onClick={() => {
                                ual.logout();
                                ual.restart();
                                ual.showModal();
                            }}
                            className="editgamebtn col-12 button-text mt-2"
                        >
                            SIGN IN
                        </div>
                        <div
                            onClick={() => setTutorialModal(!tutorialModal)}
                            className="editgamebtn col-12 button-text mt-2"
                            style={{backgroundColor: '#00cc69'}}
                        >
                            HOW TO SIGN IN
                        </div>

                        {tutorialModal && (
                            <WalletImportTutorial
                                show={tutorialModal}
                                hide={() => setTutorialModal(!tutorialModal)}
                            />
                        )}
                    </div>
                </>
            ) : (
                <>
                {sendLoading ? (
                    <div className="row">
                        <div className="col-12 mt-2 mb-3">
                            <div className="text-center">
                                <ClipLoader color="#282725"/>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                    {/* If the user is funding the game, present "SAVE CHANGES", and call the confirm modal if he clicks
                            else just present "FUND THIS GAME" that will trigger the "funding" variable in state  */}
                    {funding ?
                        <div className="row col-12 pl-0 pr-0 mr-0 ml-0">
                            <div className="col-6 button text-center red pb-1 pt-1 cursor-pointer"
                                onClick={() => cancelChanges()}>
                                CANCEL
                            </div>
                            <div className={`col-6 button text-center green pb-1 pt-1 cursor-pointer ${inputEl.current?.value === 0 ? 'disabled' : ''}`}
                                onClick={(e) => {
                                    //setConfirmFundingModal(true);
                                    //toggle(e);
                                    transfer();
                                }}
                            >
                                SAVE
                            </div>
                        </div>
                        :
                        <>
                            {balanceState && (
                                <>
                                <div className="row col-12 mx-0">
                                    <div className="editgamebtn col-5 button-text"
                                        onClick={() => {
                                            cancelChanges();
                                            toggle('devToGame');
                                        }}>
                                        TOP-UP GAME
                                    </div>
                                    <div className="editgamebtn col-5 ml-4 button-text" 
                                        onClick={() => {
                                            cancelChanges();
                                            toggle('gameToDev');
                                        }}>
                                        WITHDRAW
                                    </div>
                                </div>
                                </>
                            )}
                            <div className="white-space"></div>
                        </>
                    }
                    </>
                )}
                </>
            )}
        </div>

        {/*
        confirmFundingModal &&
            <div className="col-12 mx-auto">
                <ConfirmFundingModal
                    show={confirmFundingModal} 
                    hide={() => setConfirmFundingModal(false)}
                    transfer={(event) => transfer(event)}
                    quantity={quantity}
                    from={from}
                    to={to}
                />
            </div>
        */}

        {/*
        successModal &&
            <div className="col-12 mx-auto">
                <AfterActionModal
                    show={true}
                    successModal={successModal} 
                    hide={() => setSuccessModal(false)}
                    update={() => triggerUpdate()}
                />
            </div>
        */}

        {/*
        failureModal &&
            <div className="col-12 mx-auto">
                <AfterActionModal
                    show={true}
                    failure={failureModal} 
                    hide={setFailureModal(false)}
                    update={triggerUpdate}
                />
            </div>
        */}

        {modalMessage && (
		    <MessageModal
			    show={modalMessage} 
				message={modalMessage}
				hide={() => {
                    //triggerUpdate();
                    setModalMessage('');
                }}
			/>
        )}
        </>
    );
}
