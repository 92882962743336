import React, { useState, useEffect } from "react";

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import useWindowSize from '../../helpers/useWindowSize';

const fallBack = require("../../images/fallbackAvatar.png");

export default function GamedevsList(props) {
	//Although not being used, this next statement forces the component to rerender whenever the window size changes (so don't delete)
	const windowSize = useWindowSize();

	const [gds, setGds] = useState();
	const [gdsKeys, setGdsKeys] = useState([]);

    const [swiper, setSwiper] = useState(null);

    const sliderParams = {
        slidesPerView: 1,
        spaceBetween: 30,
        //loop: true,
        breakpoints: {
            550: {
              slidesPerView: 2,
              spaceBetween: 40
			},
            850: {
              slidesPerView: 3,
              spaceBetween: 40
			},
			992: {
				slidesPerView: 2,
				spaceBetween: 40
			},
			1600: {
				slidesPerView: 4,
				spaceBetween: 40
			}
        }
	}

	useEffect(() => {
		let gamedevsList = {}
		let gamedevsKeys = []

		for(let game of props.games) {
			//const gamedevName = game.gamedev.display_name ? game.gamedev.display_name : game.gamedev.username;
			const gamedevName = game.gamedev.username;

			if(!gamedevsList[gamedevName]) {
				gamedevsList[gamedevName] = [];
			}

			gamedevsList[gamedevName].push(game.name);

			const exists = gamedevsKeys.find(e => e === gamedevName);

			if(!exists) {
				gamedevsKeys.push(gamedevName);
			}
		}

		gamedevsKeys = gamedevsKeys.sort((a, b) => Math.random() - 0.5);

		setGds(gamedevsList);
		setGdsKeys(gamedevsKeys);
	}, [props.games]);

    useEffect(() => {
		var mySwiper = document.querySelector(".gd-swiper-container");

		if(mySwiper) {
			setSwiper(mySwiper.swiper);
		}
	}, [gds]);

	function getDisplayName(username) {
		const game = props.games.find(e => e.gamedev.username === username);
		
		if(game && game.gamedev && game.gamedev.username) {
			return (game.gamedev.display_name ? game.gamedev.display_name : game.gamedev.username);
		} else {
			return username;
		}
	}

	function getImageURLGameDev(username) {
		const game = props.games.find(e => e.gamedev.username === username);
											
		if(game && game.gamedev && game.gamedev.username) {
			return game.gamedev.imageURL ? game.gamedev.imageURL :
				`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygamedev?gamedev=${username}&size=S`;
		} else {
			return `${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygamedev?gamedev=${username}&size=S`;
		}
	}

	function getImageURLGame(name) {
		const game = props.games.find(e => e.name === name);
											
		if(game) {
			return game.imageURL ? game.imageURL :
			`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${name}&size=S`;
		} else {
			return `${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${name}&size=S`;
		}
	}

    return (
        <div className="main-inner-container py-5" style={{position: "relative"}}>
            <div className="row mb-3" style={{margin: "auto 1rem auto auto"}}>
                <div className="main-inner-title">
                    Game Developers

					{gds && (
						<span style={{fontSize: '1.25rem', marginLeft: '3px'}}>
							({Object.keys(gds).length})
						</span>
					)}
                </div>
            </div>
            <div className="swiper-button-prev-custom" onClick={() => swiper.slidePrev()}/>
            {gds &&
			<Swiper {...sliderParams} containerClass="gd-swiper-container swiper-overflow-hidden">
 				{gdsKeys.map(
					(i, index, gdList) => {
						return (
							<div key={i} className="d-inline-block enable-click">
								<div
									className="card gd-card mx-auto"
									style={{border: '0px'}}
									onClick={() =>
										props.showDetails(i)
									}
								>
									<div
										//id={i + 'Dev'} 
										id="DevCard"
										className="card-body row"
										style={{
											display: "flex",
											alignItems: "center",
											backgroundColor: 'rgb(237, 237, 237)',
											border: '1px solid rgba(0, 0, 0, 0.125)'
										}}
									>
										<div className="col-12 text-right">
											<div className="gd-list-gamedev-name">
												{getDisplayName(i)}
											</div>
											<div className="gd-list-games-count">
												{
													gds[i]
														.length
												}{" "}
												{gds[i]
													.length === 1
													? "game"
													: "games"}
											</div>
										</div>
										<div className="col-6 d-inline-block flex-column">
											<img
												src={getImageURLGameDev(i)}
												className="gd-list-thumbnail"
												alt="gamedevs avatar"
												onError={(i) => {
													i.target.src = fallBack;
												}}
											/>
										</div>
										<div className="col-6 text-right">
											{/*}
											<div className="gd-list-gamedev-name">
												{getDisplayName(i)}
											</div>
											<div className="gd-list-games-count">
												{
													gds[i]
														.length
												}{" "}
												{gds[i]
													.length === 1
													? "game"
													: "games"}
											</div>
											{*/}
											<div className="gd-images-box">
												{gds[i].map((game, index) => {
													var maxIndex = 2;

													if(
														document.getElementById('DevCard')
														&& document.getElementById('DevCard').clientWidth < 325
													) {
														maxIndex = 1;
													} else if(
														document.getElementById('DevCard')
														&& document.getElementById('DevCard').clientWidth > 425
													) {
														maxIndex = 3;
													}

													if(index < maxIndex) {
														return (
															<img
																src={getImageURLGame(game)}
																id="gd-list-games-image"
																alt="game avatar"
																key={game}
																onError={(
																	e
																) =>
																	(e.target.style.display =
																		"none")
																}
															/>
														);
													} else {
														return null;
													}
												})}
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					}
				)}
            </Swiper>}
            <div className="swiper-button-next-custom" onClick={() => swiper.slideNext()}/>
        </div>
    );
}
