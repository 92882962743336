import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts';
import './inAppPurchases.scss';

export default function InAppPurchasesChart(props)
{
    const allPurchases = props.allPurchases;
    const monthInterval = 30;
    const weekInterval = 7;

    const [dayChart, setDayChart] = useState(true);
    const [weekChart, setWeekChart] = useState(true);
    const [monthChart, setMonthChart] = useState(true);
    const [topChart, setTopChart] = useState(true);

    useEffect(() =>
    {
        if (allPurchases)
        {
            chart();
        }
    }, [allPurchases]);

    const setupValues = type =>
    {
        let values = {};
        let totalValue = 0;
        let titles;
        let colors;

        if (allPurchases)
        {
            let [purchases, title, color] = dividePurchases(type);
            titles = title;
            colors = color;

            purchases.forEach(purchase =>
                {
                    const checkIfExists = Object.keys(values).find(key => key === purchase.alias);
                    
                    checkIfExists ? values[purchase.alias].price += purchase.price :
                        values[purchase.alias] = {
                            alias: purchase.alias,
                            price: purchase.price
                        };

                    totalValue += purchase.price;
                });

                Object.keys(values).forEach(key => values[key].percentage = Number(((values[key].price / totalValue) * 100).toFixed(2)));   
        }

        let items = [];

        Object.keys(values).forEach(key => 
        {
            items.push(values[key])
        });

        if (items.length < 3)
        {
            while (items.length < 3)
            {
                const emptyItem = {
                    alias: '',
                    price: 0,
                    percentage: 0
                };

                items.push(emptyItem);
            }
        }

        items.sort((current, next) => next.percentage - current.percentage);
        items = items.slice(0, 3);
        const categories = [];
        const percentage = [];

        items.forEach(item =>
        {
            categories.push(item.alias);
            percentage.push(item.percentage);
        });

        return [categories, percentage, titles, colors];
    }

    const chart = () => 
    {
		getChartOptions('topPurchases');
        getChartOptions('monthlyPurchases');
        getChartOptions('weeklyPurchases');
        getChartOptions('dailyPurchases');
	};

    const dividePurchases = type =>
    {
        let purchases;
        let color;

        if (type === 'topPurchases')
        {
            purchases = allPurchases;
            color = '#fcd902';

            if (purchases.length === 0)
            {
                setTopChart(false);
            }
        }

        if (type === 'monthlyPurchases')
        {
            const date = new Date();
            date.setDate(date.getDate() - monthInterval)

            purchases = allPurchases.filter(purchase => new Date(purchase.timestamp).getTime() >= date.getTime());

            if (purchases.length === 0)
            {
                setMonthChart(false);
            }

            color = '#b2b2b2';
        }

        if (type === 'weeklyPurchases')
        {
            const date = new Date();
            date.setDate(date.getDate() - weekInterval)
            
            purchases = allPurchases.filter(purchase => new Date(purchase.timestamp).getTime() >= date.getTime());

            if (purchases.length === 0)
            {
                setWeekChart(false);
            }

            color = '#656565';
        }

        if (type === 'dailyPurchases')
        {
            const date = new Date();
            date.setHours(0, 0, 0, 0);
            purchases = allPurchases.filter(purchase => new Date(purchase.timestamp).getTime() >= new Date().getTime());

            if (purchases.length === 0)
            {
                setDayChart(false);
            }

            color = '#282725';
        }

        return [purchases, color];
    };

    const noData = (
        <div className="text-align-center border-bottom custom-height">
            No values to be shown
        </div>
    );

    const getChartOptions = name =>
    {
        const [categories, percentage, color] = setupValues(name);

        return Highcharts.chart(name, {
			chart: {
				type: "bar",
				backgroundColor: "transparent",
				height: "150rem",
			},
			legend: {
				enabled: false,
			},
            title: {
                text: ''
            },
            xAxis : {
                gridLineWidth: 0,
                categories: [...categories]
            },
            yAxis: {
                gridLineWidth: 0,
                lineWidth: 0,
                visible: false
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                line: {
                    visible: false
                },
                column: {
                    pointWidth: '30px'
                }
            },
			series: [
				{
					data: [...percentage],
                    color: color
				}
			]
		});
    }

    return (
        <>
            <div className="borders">
                <div className="mb-3">
                    <div className="col-12 edit-screen-stakes-sm header remove-margin">
                        <strong>Incomes from IAP's</strong>
                    </div>
                </div>
                <div className="col-12 border-bottom">
                    <div className="custom-header pt-2">Top 3 IAP's</div> 
                    {topChart ?
                        <div id="topPurchases"></div>
                        :
                        noData
                    }
                </div>
                <div className="col-12 border-bottom">
                    <div className="custom-header pt-2">Monthly Top 3 IAP's</div>
                    {monthChart ?
                        <div id="monthlyPurchases"></div>
                        :
                        noData
                    }
                </div>
                <div className="col-12 border-bottom">
                    <div className="custom-header pt-2">Weekly Top 3 IAP's</div> 
                    {weekChart ?
                        <div className="border-bottom" id="weeklyPurchases"></div>
                        :
                        noData
                    }
                </div>
                <div className="col-12 border-bottom">
                    <div className="custom-header pt-2">Daily Top 3 IAP's</div>
                    {dayChart ?
                    <div id="dailyPurchases"></div>
                    :
                    noData
                }
                </div>
            </div>
        </>
    )
}