import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts';

export default function TopIncomesServices(props)
{
    const allServices = props.hiredServices;
    const monthInterval = 30;
    const weekInterval = 7;

    const [dayChart, setDayChart] = useState(true);
    const [weekChart, setWeekChart] = useState(true);
    const [monthChart, setMonthChart] = useState(true);
    const [topChart, setTopChart] = useState(true);

    useEffect(() =>
    {
        if (allServices)
        {
            chart();
        }
    }, [allServices]);

    const setupValues = type =>
    {
        let values = {};
        let totalValue = 0;
        let titles;
        let colors;

        if (allServices)
        {
            let [services, title, color] = divideServices(type);
            titles = title;
            colors = color;

            services.forEach(service =>
                {
                    const checkIfExists = Object.keys(values).find(key => key === service.service.name);
                    
                    checkIfExists ? values[service.service.name].price += service.service.price :
                        values[service.service.name] = {
                            name: service.service.name,
                            price: service.service.price
                        };

                    totalValue += service.service.price;
                });

                Object.keys(values).forEach(key => values[key].percentage = Number(((values[key].price / totalValue) * 100).toFixed(2)));   
        }

        let items = [];

        Object.keys(values).forEach(key => 
        {
            items.push(values[key])
        });

        if (items.length < 3)
        {
            while (items.length < 3)
            {
                const emptyItem = {
                    name: '',
                    price: 0,
                    percentage: 0
                };

                items.push(emptyItem);
            }
        }

        items.sort((current, next) => next.percentage - current.percentage);
        items = items.slice(0, 3);
        const categories = [];
        const percentage = [];

        items.forEach(item =>
        {
            categories.push(item.name);
            percentage.push(item.percentage);
        });

        return [categories, percentage, titles, colors];
    }

    const chart = () => 
    {
		getChartOptions('topServices');
        getChartOptions('monthlyServices');
        getChartOptions('weeklyServices');
        getChartOptions('dailyServices');
	};

    const divideServices = type =>
    {
        let services;
        let color;
        
        if (type === 'topServices')
        {
            services = allServices;
            color = '#fcd902';

            if (services.length === 0)
            {
                setTopChart(false);
            }
        }

        if (type === 'monthlyServices')
        {
            const date = new Date();
            date.setDate(date.getDate() - monthInterval)

            services = allServices.filter(service => new Date(service.purchaseTimestamp).getTime() >= date.getTime());

            if (services.length === 0)
            {
                setMonthChart(false);
            }

            color = '#b2b2b2';
        }

        if (type === 'weeklyServices')
        {
            const date = new Date();
            date.setDate(date.getDate() - weekInterval)
            
            services = allServices.filter(service => new Date(service.purchaseTimestamp).getTime() >= date.getTime());

            if (services.length === 0)
            {
                setWeekChart(false);
            }

            color = '#656565';
        }

        if (type === 'dailyServices')
        {
            const date = new Date();
            date.setHours(0, 0, 0, 0);
            services = allServices.filter(service => new Date(service.purchaseTimestamp).getTime() >= new Date().getTime());

            if (services.length === 0)
            {
                setDayChart(false);
            }

            color = '#282725';
        }

        return [services, color];
    };

    const noData = (
        <div className="text-align-center border-bottom custom-height">
            No values to be shown
        </div>
    );

    const getChartOptions = name =>
    {
        const [categories, percentage, color] = setupValues(name);

        return Highcharts.chart(name, {
			chart: {
				type: "bar",
				backgroundColor: "transparent",
				height: "150rem",
			},
            title: {
                text: ''
            },
            xAxis : {
                categories: [...categories],
                lineColor: 'white',
                height: 70
            },
            yAxis: {
                lineColor: 'white',
                visible: false,
                max: 100
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            tooltip: {
                valueSuffix: '%',
                enabled: true
            },
			series: [
				{
					data: [...percentage],
                    color: color,
                    dataLabels: {
                        enabled: true,
                        color: '#333',
                        format: '{y}%'
                    },
                    pointPadding: 0.1,
                    groupPadding: 0
				}
			]
		});
    }

    return (
        <>
            <div className="borders">
                <div className="mb-3">
                    <div className="col-12 edit-screen-stakes-sm header pt-4">
                        <strong>Top Incomes from Services</strong>
                    </div>
                </div>
                <div className="col-12 border-bottom">
                    <div className="custom-header pt-2">Top 3 Services</div> 
                    {topChart ?
                        <div id="topServices"></div>
                        :
                        noData
                    }
                </div>
                <div className="col-12 border-bottom">
                    <div className="custom-header pt-2">Monthly Top 3 Services</div>
                    {monthChart ?
                        <div id="monthlyServices"></div>
                        :
                        noData
                    }
                </div>
                <div className="col-12 border-bottom">
                    <div className="custom-header pt-2">Weekly Top 3 Services</div> 
                    {weekChart ?
                        <div className="border-bottom" id="weeklyServices"></div>
                        :
                        noData
                    }
                </div>
                <div className="col-12 border-bottom">
                    <div className="custom-header pt-2">Daily Top 3 Services</div>
                    {dayChart ?
                    <div id="dailyServices"></div>
                    :
                    noData
                }
                </div>
            </div>
        </>
    )
}