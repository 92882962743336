import React from "react";
import {Form, InputGroup} from "react-bootstrap";

import QUDOServer from "../../../helpers/QUDOServerConnection";

const medal = require("../../../images/definitive/icn-trophy-gold.png");

export default class HighscoresList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {searchTerm: "", achievements: []}
    }

    componentDidMount() {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/highscore/list`, {
            appID: this.props.game.appID
        })
        .then(result => {
            this.setState({
                achievements: result.data
            });
        });
    }

    search = async (event) => {
        event.preventDefault();
        await this.setState({
            searchTerm: event.target.value
        });
    }

    filterAndSearch = (i) => {
        return (
            i.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())
            &&
            // <div className="">
                <div className="col-12" id="" key={i.alias}>
                    <div className="align-items-center w-100 achievementcard-border-faded" id=""  
                        style={this.props.highlight ? (this.props.highlight.alias === i.alias ? {opacity: "1"} : {opacity: "0.5"}) : {opacity: "1"}} 
                        onClick={() => this.props.editHighscore(i)}>
                        {/* highscores list  */}
                        <div className="col-1 mr-3">
                            <img src={medal} alt="medal" className="icon-32px"/> 
                        </div>
                        <div className="col-4 row">
                            <div className="amountgreen col-12" style={{textAlign: "left"}}>{(i.coefficient * 100).toFixed(0)}%</div> 
                            <div className="col-12">
                                {/*}
                                <div className="amountyellow d-inline-block">{i.pot}</div> 
                                <div className="amountgrey d-inline-block">Pot</div>
                                {*/}

                                <div className="amountgrey d-inline-block" style={{textAlign: "left"}}>
                                    {i.pot} QUDO
                                </div>
                            </div>
                        </div>
                        <div className="col-7 row">
                            <div className="col-12 achievementtitle" id="">
                                {i.name}
                            </div>
                            <div className="col-12 achievementsubtitle" id="">
                                {i.description}
                            </div>
                        </div>
                    </div>
                </div>
        )
        
    }

    render() {
        return (
            <div className="col-12 mx-auto">
                <Form className="row">
                    <InputGroup className="col-12">
                        <Form.Control 
                            id="achievementsFilterField"
                            value={this.state.searchTerm}
                            placeholder="search" 
                            onChange={this.search}
                            />
                    </InputGroup>

                </Form>
                <div className="row mt-3 scrollable">
                    {Array.isArray(this.state.achievements) && this.state.achievements.length > 0 ? (
                        this.state.achievements.map(i => this.filterAndSearch(i))
                    ) : (
                        <div className='col-12 mt-2 achievementtitle'>
                            This game doesn't have any highscores yet!
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
