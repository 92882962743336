import QUDOServer from "helpers/QUDOServerConnection";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../actions";

import challengeIcon from '../../../../images/definitive/icn-challenges.svg';
import iconUp from "../../../../images/definitive/icn-up.png";
import iconDown from "../../../../images/definitive/icn-down.png";
import { MessageModal } from "../modals";
import useWindowSize from "helpers/useWindowSize";

export function GameDetailsChallenges({
    game,
    challenges,
    setFailureModal,
    setModalMessage
}) {
    const dispatch = useDispatch();
    const windowSize = useWindowSize();
    const isMobile = (windowSize && windowSize.width < 767);

    const info = useSelector(state => state.info);

    const [firstLoading, setFirstLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [userChallenges, setUserChallenges] = useState([]);

    const challengesNames = {
        steamWishlist: 'Steam Wishlist',
        steamReview: 'Steam Review'
    }

    useEffect(() => {
        if (info && info.type !== process.env.REACT_APP_GUILD_TYPE) {
            checkUserChallenges();
        }
    }, [modal]);

    const addLinkedAccount = (steamId) => {
        setLoading(true);
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/profile/addlinkedaccount`, {
            steamid: steamId
        }, {
            withCredentials: true
        })
            .then(response => {
                dispatch(actions.fetchUser());
            })
            .catch(err => {
                //console.log(err)
                setFailureModal(true);
                setModalMessage('Something went wrong')
                setLoading(false);
            });
    }

    const checkSteamWishlist = (challengeName) => {
        setLoading(true);
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/challenge/steamwishlist`, {
            game: game,
            challengeName: challengeName
        }, {
            withCredentials: true
        })
            .then(response => {
                setModal(true);
            })
            .catch(err => {
                setFailureModal(true);
                setModalMessage(err.errorMessage)
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const checkSteamReview = (challengeName) => {
        setLoading(true);
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/challenge/steamreview`, {
            game: game,
            challengeName: challengeName
        }, {
            withCredentials: true
        })
            .then(response => {
                setModal(true);
                console.log("data", response.data)
            })
            .catch(err => {
                setFailureModal(true);
                setModalMessage(err.errorMessage)
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const checkUserChallenges = () => {
        setFirstLoading(true);
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/challenge/getUserGameChallenges`, {}, {
            withCredentials: true
        })
            .then(response => {
                if (response.data.challenges) {
                    setUserChallenges(response.data.challenges);
                }
            })
            .catch(err => {
                //console.log(err)
                setFailureModal(true);
                setModalMessage('Something went wrong')
            })
            .finally(() => {
                setFirstLoading(false)
            });
    }

    function ChallengeContainer({ children }) {
        const [expanded, setExpanded] = useState(false);

        return (
            <div className="game-challenge" onClick={() => setExpanded(e => !e)}>
                <div className={`${isMobile ? '' : 'text-center'} d-flex flex-row`}>
                    {!isMobile &&
                        <img className="p-0 icon-32px-force"
                            style={{ objectFit: "scale-down", marginRight: "16px" }}
                            src={expanded ? iconUp : iconDown}
                            alt={`${expanded ? 'close' : 'open'}`} />
                    }
                    <img
                        alt='Challenges Icon'
                        src={challengeIcon}
                        className=''
                        style={{ maxWidth: '34px' }}
                    />
                </div>
                <>
                    {React.cloneElement(children, { expanded })}
                </>
            </div>
        );
    }

    function Completed({ date }) {
        return (
            <div className="challenge-completed d-flex flex-column">
                <span className={isMobile ? 'completed-txt' : ''}>Completed</span>
                {date &&
                    <span className="completed-date">
                        {new Date(date).toLocaleDateString("en-US", {
                            year: 'numeric', month: 'numeric', day: 'numeric'
                        })}
                    </span>
                }
            </div>
        );
    }

    function Disabled() {
        return (
            <div className="challenge-completed challenge-disabled d-flex flex-column">
                <span className={isMobile ? 'completed-txt' : ''}>Ended</span>
            </div>
        );
    }

    function SteamWishlist({
        challenge, expanded
    }) {
        const [steamId, setSteamId] = useState('');

        const userChallenge = userChallenges.find(userChallenge => userChallenge.challenge.name === challenge.name);

        return (
            <div className={`d-flex ${isMobile ? 'flex-column w-100' : 'flex-row flex-grow-1'}`} style={{ minWidth: 0 }}>
                <div className="d-flex flex-column flex-grow-1 mr-3"
                    style={{ minWidth: 0 }}>
                    <span><strong>{challenge.name}</strong></span>
                    <div className={`challenge-description ${expanded ? 'expanded' : ''}`}>
                        Be rewarded by wishlisting this game on Steam!
                        {((challenge.active && !userChallenge) && info && info.type !== "guild") &&
                            <>
                                {!(info.linkedAccounts && info.linkedAccounts.steam && info.linkedAccounts.steam !== '') ?
                                    ' Please provide your Steam ID and make sure that you have your Steam wishlist public. '
                                    :
                                    ' Please make sure that you have your Steam wishlist public. '
                                }
                            </>
                        }
                    </div>
                </div>

                <div className="d-flex flex-column mr-3">
                    <div className="achievement-reward-value">
                        {challenge.reward}
                    </div>
                    <div className="achievement-reward-unit">
                        QUDO
                    </div>
                </div>

                {(info && info.type !== "guild") &&
                    <>
                        {userChallenge ?
                            <>
                                <Completed date={(isMobile || expanded) ? userChallenge.timestamp : null} />
                            </>
                            :
                            <>
                                {challenge.active ?
                                    <>
                                        {(info.linkedAccounts && info.linkedAccounts.steam && info.linkedAccounts.steam !== '') ?
                                            <div className='m-0 d-flex align-items-center'>
                                                <button className={`editgamebtn my-0 ${!loading ? "" : "disabled"} ${isMobile ? '' : 'mr-0'}`}
                                                    style={{ height: '2.5rem', width: 'fit-content', padding: '0.5rem 1.5rem' }}
                                                    onClick={() => checkSteamWishlist(challenge.name)}>
                                                    {!loading ? 'Confirm' : 'Validating'}
                                                </button>
                                            </div>
                                            :
                                            <div className={`d-flex flex-column ${!isMobile ? 'justify-content-center' : ''}`}
                                                style={{ flexBasis: `${isMobile ? 'auto' : '548px'}`, marginLeft: '16px' }}>
                                                <div className='my-2 d-flex justify-content-between'>
                                                    <input
                                                        className="w-50 send-to-input-box py-2"
                                                        placeholder='SteamID'
                                                        value={steamId}
                                                        onChange={(e) => {
                                                            setSteamId(e.target.value.toLowerCase());
                                                        }}
                                                        style={{ height: '2.5rem' }}
                                                    />
                                                    <button className={`editgamebtn w-50 mx-2 my-0 ${!loading ? "" : "disabled"}`}
                                                        style={{ height: '2.5rem', padding: '0.5rem 0.5rem', fontSize: '1rem' }}
                                                        onClick={() => addLinkedAccount(steamId)}>
                                                        {!loading ? 'Link Account' : 'Saving'}
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </>
                                    :
                                    <Disabled />
                                }
                            </>
                        }
                    </>
                }
            </div>
        )
    }

    function SteamReview({
        challenge, expanded
    }) {
        const [steamId, setSteamId] = useState('');

        const userChallenge = userChallenges.find(userChallenge => userChallenge.challenge.name === challenge.name);

        return (
            <div className={`d-flex ${isMobile ? 'flex-column' : 'flex-row flex-grow-1'}`} style={{ minWidth: 0 }}>
                <div className="d-flex flex-column flex-grow-1 mr-3"
                    style={{ minWidth: 0 }}>
                    <span><strong>{challenge.name}</strong></span>
                    <div className={`challenge-description ${expanded ? 'expanded' : ''}`}>
                        Be rewarded by sharing your feedback and reviewing this game on Steam!
                        {((challenge.active && !userChallenge) && info && info.type !== "guild") &&
                            <>
                                {!(info.linkedAccounts && info.linkedAccounts.steam && info.linkedAccounts.steam !== '') ?
                                    <>
                                        &nbsp;Please provide your Steam ID. Your Steam ID should look like this 76561197960287930.
                                        You can find it from <a target='_blank'
                                            rel='noopener noreferrer'
                                            href="https://www.steamidfinder.com/">
                                            SteamIDFinder
                                        </a> and seach for steamID64 (Dec).
                                    </>
                                    :
                                    ' After reviewing a game, please wait a few hours to have your review public. '
                                }
                            </>
                        }
                    </div>
                </div>

                <div className="d-flex flex-column mr-3">
                    <div className="achievement-reward-value">
                        {challenge.reward}
                    </div>
                    <div className="achievement-reward-unit">
                        QUDO
                    </div>
                </div>

                {(info && info.type !== "guild") &&
                    <>
                        {userChallenge ?
                            <>
                                <Completed date={(isMobile || expanded) ? userChallenge.timestamp : null} />
                            </>
                            :
                            <>
                                {challenge.active ?
                                    <>
                                        {(info.linkedAccounts && info.linkedAccounts.steam && info.linkedAccounts.steam !== '') ?
                                            <div className='m-0 d-flex align-items-center'>
                                                <button className={`editgamebtn my-0 ${!loading ? "" : "disabled"} ${isMobile ? '' : 'mr-0'}`}
                                                    style={{ height: '2.5rem', width: 'fit-content', padding: '0.5rem 1.5rem' }}
                                                    onClick={() => checkSteamReview(challenge.name)}>
                                                    {!loading ? 'Confirm' : 'Validating'}
                                                </button>
                                            </div>
                                            :
                                            <div className={`d-flex flex-column ${!isMobile ? 'justify-content-center' : ''}`}
                                                style={{ flexBasis: `${isMobile ? 'auto' : 'auto'}`, marginLeft: '16px' }}>
                                                <div className='my-2 d-flex justify-content-between'
                                                    style={{ minWidth: '256px' }}>
                                                    <input
                                                        className="w-50 send-to-input-box py-2"
                                                        placeholder='SteamID'
                                                        value={steamId}
                                                        onChange={(e) => {
                                                            setSteamId(e.target.value.toLowerCase());
                                                        }}
                                                        style={{ height: '2.5rem' }}
                                                    />
                                                    <button className={`editgamebtn w-50 mx-2 my-0 ${!loading ? "" : "disabled"}`}
                                                        style={{ height: '2.5rem', padding: '0.5rem 0.5rem', fontSize: '1rem' }}
                                                        onClick={() => addLinkedAccount(steamId)}>
                                                        {!loading ? 'Link Account' : 'Saving'}
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </>
                                    :
                                    <Disabled />
                                }
                            </>
                        }
                    </>
                }
            </div>
        )
    }

    return (
        <div className="mx-2">
            {info.type === "provider" &&
                <div>QUDO Challenges are only available for Gamers</div>
            }

            {firstLoading ?
                (<>
                    <div style={{
                        fontSize: '20px', marginTop: '36px', display: "flex", justifyContent: "center",
                        backgroundColor: "#fbd731", borderRadius: ".5rem", padding: '8px'
                    }}>
                        <div className="loading-spinning-image" >
                            Loading...
                        </div>
                    </div>
                </>
                )
                :
                <>
                    {(!challenges || challenges.length <= 0) ?
                        <>
                            <div className="edit-screen-text-area mt-2 text-center">
                                No game challenges rewards offered at the moment by this game.
                            </div>
                        </>
                        :
                        <>
                            {challenges.map(challenge => {
                                if (challenge.name === challengesNames.steamWishlist) {
                                    return (<ChallengeContainer key={challenge.name}>
                                        <SteamWishlist challenge={challenge} />
                                    </ChallengeContainer>);
                                } else if (challenge.name === challengesNames.steamReview) {
                                    return (<ChallengeContainer key={challenge.name}>
                                        <SteamReview challenge={challenge} />
                                    </ChallengeContainer>);
                                }
                                return (<></>);
                            })}
                        </>
                    }
                </>
            }

            <MessageModal
                show={modal}
                message={'Success'}
                hide={() => {
                    setModal(false);
                }}
            />
        </div>
    );
}
