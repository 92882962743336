import { NameInput } from "components/components/forms/text";
import { MessageModal } from "components/components/modals/modals";
import QUDOServer from "helpers/QUDOServerConnection";
import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";

export default function LinkedAccountsForm({ info, fetchNewInfo }) {

    const [steamId, setSteamId] = useState('');
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);

    const form = useRef(null);

    useEffect(() => {
        if (info.linkedAccounts?.steam) {
            setSteamId(info.linkedAccounts.steam);
        }
    }, []);

    function updateLinkedAccounts() {
        setLoading(true);
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/profile/addlinkedaccount`,
            { steamid: steamId },
            { withCredentials: true })
            .then(data => {
                setModal(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (<>
        <Form ref={form} onSubmit={e => e.preventDefault()}>
            <div style={{ fontWeight: "bold" }}>
                Linked Accounts
            </div>

            <div>
                Steam ID
                <div className="small">
                    Please provide steamID64 (Dec) (i.e. 76561197960287930)
                    <br />
                    You can find your SteamID on <a target='_blank'
                        rel='noopener noreferrer'
                        href="https://www.steamidfinder.com/">
                        SteamIDFinder
                    </a>
                </div>
            </div>
            <NameInput
                key={1}
                placeholder="Steam ID"
                style={{ height: "4.5rem" }}
                className={`edit-field col-12`}
                ignoreValidation={true}
                initial={steamId}
                set={(value) => {
                    setSteamId(value);
                }}
            />

            <div
                className={`col-12 edit-profile-save-btn ${!loading ? "" : "disabled"}`}
                onClick={updateLinkedAccounts}
            >
                Link Accounts
            </div>

        </Form>

        <MessageModal
            show={modal}
            message={'Success'}
            hide={() => {
                fetchNewInfo();
                setModal(false);
            }}
        />
    </>);
}