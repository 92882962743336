import React from 'react';

import { Modal } from 'react-bootstrap';

export default function Unavailable() {

    return (
        <Modal show={true}
            dialogClassName="meta-box">
            <Modal.Body className="scroll-95 pt-0">
                <div className="row meta-body">
                    <div className="col-12 login-main-text t_registerTitle">
                        Welcome to the
                        <br />
                        QUDO World!
                    </div>
                    <div className="col-12">
                        We've temporarily disabled new registrations to ensure the platform's usual performance.
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
