import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import * as actions from '../../actions';

import QUDOServer from '../../helpers/QUDOServerConnection';

import {MessageModal} from '../components/modals/modals';
import GuildDetailsModal from '../components/modals/GuildDetailsModal';
import ConfirmModal from '../components/modals/confirmModal/confirmModal';

export default function GuildCard({guild, guildName, memberCount, showJoinButton, showLeaveButton, pendingApproval}) {
    const dispatch = useDispatch();

    const [modalMessage, setModalMessage] = useState('');
    const [requestLoading, setRequestLoading] = useState(false);
    const [showGuildModal, setShowGuildModal] = useState(false);
    const [confirmJoinGuild, setConfirmJoinGuild] = useState(false);
    const [confirmLeaveGuild, setConfirmLeaveGuild] = useState(false);
    const [confirmCancelGuild, setConfirmCancelGuild] = useState(false);

    function joinGuild() {
        setRequestLoading(true);

		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/guild/join`, {
            guild: guild
		}, {
			withCredentials: true
		})
        .then(response => {
            setModalMessage('Success');
            dispatch(actions.fetchUser());
        })
        .catch(error => {
            setModalMessage(error.errorMessage);
        })
        .finally(() => {
            setRequestLoading(false);
            setConfirmJoinGuild(false);
        });
    }

    function leaveGuild() {
        setRequestLoading(true);

		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/guild/leave`, {
            //
		}, {
			withCredentials: true
		})
        .then(response => {
            setModalMessage('Success');
            dispatch(actions.fetchUser());
        })
        .catch(error => {
            setModalMessage(error.errorMessage);
        })
        .finally(() => {
            setRequestLoading(false);
            setConfirmLeaveGuild(false);
        });
    }

    function cancelGuild() {
        setRequestLoading(true);

		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/guild/cancel`, {
            //
		}, {
			withCredentials: true
		})
        .then(response => {
            setModalMessage('Success');
            dispatch(actions.fetchUser());
        })
        .catch(error => {
            setModalMessage(error.errorMessage);
        })
        .finally(() => {
            setRequestLoading(false);
            setConfirmCancelGuild(false);
        });
    }

    return(
        <>
        <div
            className='row enable-click'
            style={{
                backgroundColor: 'white'
            }}
        >
            <div
                className='col-2 py-3 text-center'
                onClick={() => {
                    setShowGuildModal(true);
                }}
            >
                <img
                    alt='Guild avatar'
                    src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbyguild?guild=${guild}`}
                    style={{height: "3rem", width: '3rem'}}
                />
            </div>
            <div
                className='col-6 py-3 align-self-center text-left'
                onClick={() => {
                    setShowGuildModal(true);
                }}
            >
                <div>
                    {guildName}
                </div>
                {/* {(showJoinButton || showLeaveButton) && ( */}
                    <div style={{fontSize: '0.9', color: 'gray'}}>
                        {memberCount} {memberCount === 1 ? 'member' : 'members'}
                    </div>
                {/* )} */}
                {/*}
                {pendingApproval && (
                    <div style={{fontSize: '0.9', color: 'gray'}}>
                        Pending approval...
                    </div>
                )}
                {*/}
            </div>
            <div
                className='col-4 py-3 align-self-center text-center'
                style={showJoinButton || showLeaveButton || pendingApproval ? {color: '#fcd902', backgroundColor: '#282725'} : {}}
            >
                <>
                {showJoinButton ? (
                    <div
                        className='enable-click'
                        onClick={() => {
                            setConfirmJoinGuild(true);
                        }}
                    >
                        <div>REQUEST</div>
                        <div>TO JOIN</div>
                    </div>
                ) : (
                    <>
                    {showLeaveButton ? (
                        <div
                            className='enable-click'
                            onClick={() => {
                                setConfirmLeaveGuild(true);
                            }}
                        >
                            <div>LEAVE</div>
                            <div>GUILD</div>
                        </div>
                    ) : (
                        <>
                        {pendingApproval ? (
                            <div
                                className='enable-click'
                                onClick={() => {
                                    setConfirmCancelGuild(true);
                                }}
                            >
                                <div>CANCEL</div>
                                <div>REQUEST</div>
                            </div>
                        ) : (
                            <div
                                style={{fontSize: '0.9', color: 'gray'}}
                                onClick={() => {
                                    setShowGuildModal(true);
                                }}
                            >
                                {memberCount} {memberCount === 1 ? 'member' : 'members'}
                            </div>
                        )}
                        </>
                    )}
                    </>
                )}
                </>
            </div>
        </div>

        {showGuildModal && (
            <GuildDetailsModal
                show={showGuildModal}
                hide={() => setShowGuildModal(false)}
                guild={guild}
            />
        )}

        <ConfirmModal
            show={confirmJoinGuild}
            loading={requestLoading}
            hide={() => setConfirmJoinGuild(false)}
            confirm={() => joinGuild()}
            message={`Are you sure you want to join this guild?`}
        />

        <ConfirmModal
            show={confirmLeaveGuild}
            loading={requestLoading}
            hide={() => setConfirmLeaveGuild(false)}
            confirm={() => leaveGuild()}
            message={`Are you sure you want to leave your guild?`}
        />

        <ConfirmModal
            show={confirmCancelGuild}
            loading={requestLoading}
            hide={() => setConfirmCancelGuild(false)}
            confirm={() => cancelGuild()}
            message={`Are you sure you want to cancel your request to join this guild?`}
        />

        <MessageModal
            show={modalMessage} 
            message={modalMessage}
            hide={() => setModalMessage('')}
        />
        </>
    );
}
