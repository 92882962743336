import React, {useState, useEffect} from "react";
//import {useSelector} from "react-redux";
import {Form} from "react-bootstrap";

import QUDOServer from "../../helpers/QUDOServerConnection";

import {MessageModal} from "../components/modals/modals.js";

export default function EmailSettings(props) {
    const currentUser = props.info;
	//const currentUser = useSelector(state => state.info);

    const [sendTransaction, setSendTransaction] = useState(true);
    const [receiveTransaction, setReceiveTransaction] = useState(true);
    const [newBugSpottingMessage, setNewBugSpottingMessage] = useState(true);
    const [newHiredServiceMessage, setNewHiredServiceMessage] = useState(true);

    const [update, setUpdate] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const disabled = (
        currentUser && currentUser.emailSettings
        && sendTransaction === currentUser.emailSettings.send_transaction
        && receiveTransaction === currentUser.emailSettings.receive_transaction
        && newBugSpottingMessage === currentUser.emailSettings.new_bug_spotting_message
        && newHiredServiceMessage === currentUser.emailSettings.new_hired_service_message
    );

    useEffect(() => {
        if(currentUser && currentUser.emailSettings) {
            if(typeof currentUser.emailSettings.send_transaction === 'boolean') {
                setSendTransaction(currentUser.emailSettings.send_transaction);
            }

            if(typeof currentUser.emailSettings.receive_transaction === 'boolean') {
                setReceiveTransaction(currentUser.emailSettings.receive_transaction);
            }

            if(typeof currentUser.emailSettings.new_bug_spotting_message === 'boolean') {
                setNewBugSpottingMessage(currentUser.emailSettings.new_bug_spotting_message);
            }

            if(typeof currentUser.emailSettings.new_hired_service_message === 'boolean') {
                setNewHiredServiceMessage(currentUser.emailSettings.new_hired_service_message);
            }
        }
    }, [currentUser]);

    function saveChanges() {
        if(disabled) {
            return;
        }

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/profile/change`, {
            emailSettings: {
                send_transaction: sendTransaction,
                receive_transaction: receiveTransaction,
                new_bug_spotting_message: newBugSpottingMessage,
                new_hired_service_message: newHiredServiceMessage
            }
        }, {
            withCredentials: true
        })
        .then((data) => {
            setUpdate(true);
            setModalMessage("Success");
        })
        .catch((error) => {
            setUpdate(false);
            setModalMessage(error.errorMessage);
        });
    }

    return(
        <>
        <Form>
            <div className="mb-2" style={{fontWeight: "bold"}}>
                Email Settings
            </div>

            <div
                className="row mt-3 mx-0 enable-click"
                onClick={() => setReceiveTransaction(!receiveTransaction)}
            >
                <div className="col-10">
                    Send me an email when another user transfers QUDO to my account
                </div>
                <div className="col-2">
                    <div style={{display: 'inline-block', float: 'right'}}>
                        <input
                            type="checkbox"
                            checked={receiveTransaction}
                        />
                    </div>
                </div>
            </div>

            <div
                className="row mt-3 mx-0 enable-click"
                onClick={() => setSendTransaction(!sendTransaction)}
            >
                <div className="col-10">
                    Send me a confirmation email when I successfully send QUDO to another user
                </div>
                <div className="col-2">
                    <div style={{display: 'inline-block', float: 'right'}}>
                        <input
                            type="checkbox"
                            checked={sendTransaction}
                        />
                    </div>
                </div>
            </div>

            {(
                currentUser.type === process.env.REACT_APP_USER_TYPE
                || currentUser.type === process.env.REACT_APP_GAMEDEV_TYPE
                || currentUser.type === process.env.REACT_APP_PROVIDER_TYPE
            ) && (
                <div
                    className="row mt-3 mx-0 enable-click"
                    onClick={() => setNewBugSpottingMessage(!newBugSpottingMessage)}
                >
                    <div className="col-10">
                        Send me an email when I receive a new chat message from an open bug report
                    </div>
                    <div className="col-2">
                        <div style={{display: 'inline-block', float: 'right'}}>
                            <input
                                type="checkbox"
                                checked={newBugSpottingMessage}
                            />
                        </div>
                    </div>
                </div>
            )}

            {(
                currentUser.type === process.env.REACT_APP_GAMEDEV_TYPE
                || currentUser.type === process.env.REACT_APP_PROVIDER_TYPE
            ) && (
                <div
                    className="row mt-3 mx-0 enable-click"
                    onClick={() => setNewHiredServiceMessage(!newHiredServiceMessage)}
                >
                    <div className="col-10">
                        Send me an email when I receive a new chat message from a hired service
                    </div>
                    <div className="col-2">
                        <div style={{display: 'inline-block', float: 'right'}}>
                            <input
                                type="checkbox"
                                checked={newHiredServiceMessage}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className="mt-3">
                <div
                    onClick={saveChanges}
                    className={`col-12 edit-profile-save-btn ${disabled ? "disabled" : ""}`}
                >
                    Save
                </div>
            </div>
        </Form>

		<MessageModal
		    show={modalMessage} 
			message={modalMessage}
			hide={() => {
                if(update) {
                    props.fetchNewInfo();
                }

                setUpdate(false);
                setModalMessage("");
            }}
		/>
        </>
    );
}
