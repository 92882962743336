import QUDOServer from "helpers/QUDOServerConnection";
import React, { useEffect, useState } from "react";
const cup = require("../../../images/definitive/icn-cup.png");

export default function ChallengesList({
    game, selected, editChallenge, refresh, refreshKeys
}) {

    const [gameChallenges, setGameChallenges] = useState([]);
    const [highlight, setHighlight] = useState('');

    useEffect(() => {
        const config = {
            withCredentials: true
        }

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/challenge/getgamedevchallenges`, { game: game.name }, config)
            .then(response => {
                setGameChallenges(oldChallenges => response.data.challenges)
            })
            .catch(error => {
                //console.log(error)
            });
    }, [refresh]);

    useEffect(() => {
        setHighlight(selected);
    }, [selected, refreshKeys]);

    return (
        <div key={"cl"}>
            <div className="row mt-3 scrollable">
                {Array.isArray(gameChallenges) && gameChallenges.length > 0 ? (
                    gameChallenges.map(challenge =>
                        <>
                            <div className="col-12 user-select-none" id="" key={challenge.name}>
                                <div className="align-items-center w-100 achievementcard-border-faded" id=""
                                    style={highlight === challenge.name ? { opacity: "0.7" } : { opacity: "1" }}
                                    onClick={() => editChallenge(challenge)}>
                                    <div className="col-1 mr-3">
                                        <img src={cup} alt="challenge cup" className="icon-32px" />
                                    </div>
                                    <div className="col-4 row">
                                        <div className="amountgreen col-12" style={{ textAlign: "left", lineHeight: 'initial' }}>
                                            <div>
                                                {parseFloat(challenge.reward).toFixed(2)} QUDO
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-7 row">
                                        <div className="col-12 achievementtitle" id="">
                                            {challenge.name}
                                        </div>
                                        <div className="col-12 achievementsubtitle" id="">
                                            {challenge.active ? 'Active ' : 'Disabled '}
                                            | Completed: {challenge.totalCompleted}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                ) : (
                    <div className='col-12 mt-2 achievementtitle'>
                        This game doesn't have any challenges yet!
                    </div>
                )}
            </div>
        </div>
    );
}