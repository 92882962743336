export function sendFacebookPixelEvent(eventType, eventName, data) {
    if(window && window.fbq) {
        try {
            if(data) {
                window.fbq(eventType, eventName, data);
            } else {
                window.fbq(eventType, eventName);
            }
        } catch(error) {
            //console.log(error);
        }
    } else {
        //console.log(`sendFacebookPixelEvent() -> 'window' or 'window.fbq()' not found!`);
    }
}
