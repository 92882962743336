import React from "react";

//import Slider from 'react-slick';
import SwipeableViews from 'react-swipeable-views';
import Pagination from '../components/components/react-swipeable-views-pagination/Pagination';

import LastPlayed from "./user/lastPlayed";
import Achievements from "./user/achievements";
import Highscores from "./user/highscores";
import Products from "./user/products";
import NetworkRewards from "./user/networkRewards";
import BugSpotting from "./user/bugSpotting/bugSpotting";

class UserActivity extends React.Component {
	constructor(props) {
		super(props);

        this.state = {
			index: 0,
			tab: "achievements"
		}

		window.addEventListener("resize", this.update);
	}

	componentDidMount() {
    	this.update();
	}

	update = () => {
		this.setState({
		  	height: window.innerHeight,
		  	width: window.innerWidth
		});
	}

	tabChange = (event) => {
		this.setState({
			tab: event
		})
	}

	render() {
		const desktop = this.state.width > 991;

		if(desktop) {
			return (
				<div className="position-relative">
					{/* <div className="row" id="title-box">
					</div> */}
					<div className="mt-4"/>
					<div id="title" className="col-12">Games</div>
					<div className="row px-4">
						<div className="col-12 col-lg-4 mt-5 activity-statistics-container">
							{this.props.info && <LastPlayed /*info={this.props.info}*//>}
						</div>

						<div className="col-12 col-sm-8 col-lg-4 mx-auto mt-5 activity-statistics-container">
							<div className="mb-3" id="title">Statistics</div>
							<div className="row m-auto justify-content-between">
								<div 
									className={`text-center edit-screen-menu${(this.state.tab === "achievements" || this.state.tab === 'highscores') ? "": "-disabled"}`}
									style={(this.state.tab === "achievements" || this.state.tab === 'highscores') ? {cursor: "default"} : {cursor: "pointer"}}
									onClick={() => this.tabChange('achievements')}
								>
										Performance
								</div>

								<div 
									className={`text-center edit-screen-menu${this.state.tab === "products" ? "": "-disabled"}`}
									style={this.state.tab === "products" ? {cursor: "default"} : {cursor: "pointer"}}
									onClick={() => this.tabChange("products")}
								>
										Products
								</div>
								<div 
									className={`text-center edit-screen-menu${this.state.tab === 'bugSpotting' ? "": "-disabled"}`}
									style={this.state.tab === 'bugSpotting' ? {cursor: "default"} : {cursor: "pointer"}}
									onClick={() => this.tabChange('bugSpotting')}
								>
										Bug Spotting
								</div>
							</div>

							{
								(this.state.tab === 'achievements' || this.state.tab === 'highscores') && (
									<div className="d-flex justify-content-left">
										<div 
											className={`text-center edit-screen-menu${this.state.tab === "achievements" ? "": "-disabled"}`}
											style={this.state.tab === "achievements" ? {cursor: "default"} : {cursor: "pointer"}}
											onClick={() => this.tabChange("achievements")}
										>
												Achievements
										</div>
										<div 
											className={`pl-3 text-center edit-screen-menu${this.state.tab === "highscores" ? "": "-disabled"}`}
											style={this.state.tab === "highscores" ? {cursor: "default"} : {cursor: "pointer"}}
											onClick={() => this.tabChange("highscores")}
										>
												Highscores
										</div>
									</div>
								)
							}

							{this.state.tab === "achievements" ?
								<Achievements/>
							: this.state.tab === "highscores" ?
								<Highscores/>
							: this.state.tab === 'products' ?
								<Products/>
							:
								<BugSpotting/>
							}
						</div>

						<div className="col-12 col-lg-4 mt-5 activity-statistics-container">
							{this.props.info && <NetworkRewards/>}
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div style={{width: '100%'}}>
					<div className="col-12 games">Games</div>
					<SwipeableViews
						enableMouseEvents={true}
						index={this.state.index}
						onSwitching={index => {
							if(index === 0 || index === 1 || index === 2) {
								this.setState({index: index});
							}
						}}
					>
						<div className="mx-5 mt-5 activity-statistics-container">
							{this.props.info && <LastPlayed /*info={this.props.info}*//>}
						</div>

						<div className="mx-5 mt-5 activity-statistics-container">
							<div className="mb-3" id="title">Statistics</div>
							<div className="row m-auto justify-content-between">
								<div 
									className={`text-center edit-screen-menu${(this.state.tab === "achievements" || this.state.tab === 'highscores') ? "": "-disabled"}`}
									style={(this.state.tab === "achievements" || this.state.tab === 'highscores') ? {cursor: "default"} : {cursor: "pointer"}}
									onClick={() => this.tabChange('achievements')}
								>
										Performance
								</div>

								<div 
									className={`text-center edit-screen-menu${this.state.tab === "products" ? "": "-disabled"}`}
									style={this.state.tab === "products" ? {cursor: "default"} : {cursor: "pointer"}}
									onClick={() => this.tabChange("products")}
								>
										Products
								</div>
								<div 
									className={`text-center edit-screen-menu${this.state.tab === 'bugSpotting' ? "": "-disabled"}`}
									style={this.state.tab === 'bugSpotting' ? {cursor: "default"} : {cursor: "pointer"}}
									onClick={() => this.tabChange('bugSpotting')}
								>
										Bug Spotting
								</div>
							</div>

							{
								(this.state.tab === 'achievements' || this.state.tab === 'highscores') && (
									<div className="d-flex justify-content-left mt-2">
										<div 
											className={`text-center edit-screen-menu${this.state.tab === "achievements" ? "": "-disabled"}`}
											style={this.state.tab === "achievements" ? {cursor: "default"} : {cursor: "pointer"}}
											onClick={() => this.tabChange("achievements")}
										>
												Achievements
										</div>
										<div 
											className={`pl-3 text-center edit-screen-menu${this.state.tab === "highscores" ? "": "-disabled"}`}
											style={this.state.tab === "highscores" ? {cursor: "default"} : {cursor: "pointer"}}
											onClick={() => this.tabChange("highscores")}
										>
												Highscores
										</div>
									</div>
								)
							}

							{this.state.tab === "achievements" ?
								<Achievements/>
							: this.state.tab === "highscores" ?
								<Highscores/>
							: this.state.tab === 'products' ?
								<Products/>
							:
								<BugSpotting/>
							}
						</div>

						<div className="mx-5 mt-5 activity-statistics-container">
							{this.props.info && <NetworkRewards/>}
						</div>
					</SwipeableViews>

					<Pagination
						dots={3}
						index={this.state.index}
						onChangeIndex={index => this.setState({index: index})}
					/>
				</div>
			);
		}
	}
}

export default UserActivity;
