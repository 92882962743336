import React, {useState, useEffect} from "react";
import { Form, Modal } from "react-bootstrap";

import QUDOServer from "../../../helpers/QUDOServerConnection";

import { topMetaModal } from "./MetaModals";
import { ButtonInput } from "../forms/buttons";

export default function ForgotUsername(props) {
    const [email, setEmail] = useState('');

    function submit() {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/auth/recover/forgotusername`, {
            email: email
        })
        .then(result => {
            props.success('We sent you an e-mail with your username');
        })
        .catch(error => {
            props.failure(error.errorMessage);
        })
        .finally(() => {
            setEmail('');
        });
    }

    return (
        <Modal
            dialogClassName="meta-box"
            show={props.show}
            onHide={() => {
                setEmail('');
                props.setShow(null);
            }}
        >
            <Modal.Body className="scroll-100 pt-0">
                {topMetaModal(props.setShow)}

                <div className="row meta-body">
                    <div className="col-12">
                        <div className="col-12 login-main-text">
                            Oh! That's bad!
                            <br/>
                            But don't worry, we've got it covered!
                        </div>
                        <Form onSubmit={e => e.preventDefault()}>
                            <div style={{marginBottom: "10px"}}>
                                Enter your email
                            </div>
                            <input
                                type="text"
                                className={`input-field-custom col-12`}
                                //style={{marginBottom: "35px"}}
                                name="Email"
                                placeHolder="Email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <ButtonInput
                                trigger
                                type="submit"
                                className="form-submit-btn text-center"
                                label="Email me my username"
                                set={() => submit()}
                                //disabled={!this.state.valid}
                            />
                        </Form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
