import React, {useState, useEffect} from 'react';
import { useSelector } from "react-redux";

import ProviderTitleContainer from './provider/providerTitleContainer/providerTitleContainer';
import NavigationProvider from './provider/navigation/navigationProvider';
import OverallBalanceProvider from './provider/overallBalance/overallBalanceProvider';
import QUDOServer from "../helpers/QUDOServerConnection";
import TotalEarningsProvider from './provider/earnings/totalEarnings/totalEarnings';
import IncomeServicesProvider from './provider/earnings/incomeService/IncomeServiceProvider';
import PurchasesServiceProvider from './provider/earnings/purchaseService/purchasesServiceProvider';
import TopIncomesServices from './provider/earnings/topIncomesServices/topIncomesServices';

export default function ProviderEarnings(props)
{
    const info = useSelector(state => state.info);
    const [balances, setBalances] = useState();
    const balance = useSelector(state => state.balance);
    const [hiredServices, setHiredServices] = useState();

    useEffect(() =>
    {
        getBalanceHistory();
        getHiredServices();
    }, []);

    const getBalanceHistory = async () =>
    {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/api/history/balance`, {
            username: info.username
        })
        .then(resp =>
        {
            if (!resp.errorMessage)
            {
                setBalances(resp.data.balances);
            }
        })
        .catch(err =>
        {
            setBalances([]);
        }); 
    }

    const getHiredServices = async () =>
    {

        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/provider/services/hired`, {
            withCredentials: true
        })
        .then(result => 
        {
            if (!result.errorMessage)
            {
                setHiredServices(result.data);
            }
        });
    }

    return (
        <div className="gamedevact-edit-screen-container row">
            <div className="row col-12 mb-3">
                <ProviderTitleContainer username={info.username} displayName={info.display_name ? info.display_name : info.username}/>
            </div>
            <div className="row col-12 mb-3">
                <NavigationProvider/>
            </div>
            <div className="col-12 col-md-12 col-lg-4">
                <OverallBalanceProvider balances={balances} balance={balance}/>
                <TotalEarningsProvider hiredServices={hiredServices}/>
            </div>
            <div className="col-12 col-md-12 col-lg-4">
                <IncomeServicesProvider hiredServices={hiredServices} />
                <PurchasesServiceProvider hiredServices={hiredServices} />
            </div>
            <div className="col-12 col-md-12 col-lg-4">
                <TopIncomesServices hiredServices={hiredServices} />
            </div>
           
        </div>
    )
}