import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts';

export default function TotalEarningsProvider(props)
{
    const [chartReady, setChartReady] = useState(false);

    useEffect(() =>
    {
        if (props.hiredServices)
        {
            setChartReady(true);
        }
    }, [props.hiredServices])

    useEffect(() =>
    {
        if (props.hiredServices)
        {   
            chart();
        }
    }, [chartReady]);

    const setupValues = () =>
    {       
        let totalFees = 0;
        let totalEarnings = 0;

        if(Array.isArray(props.hiredServices)) {
            for(let i = 0; i < props.hiredServices.length; i++) {
                const service = props.hiredServices[i];

                let fee = 0;

                if(!isNaN(service.fee) && Number(service.fee) > 0) {
                    fee = Number(service.price) * Number(service.fee) / 100;
                }

                totalFees += fee;
                totalEarnings += Number(service.price) - fee;
            }
        }

        return [
            {
                y: Number(totalFees.toFixed(4)),
                color: '#b2b2b2',
                name: 'Total Purchase Fees'
            },
            {
                y: Number(totalEarnings.toFixed(4)),
                color: '#fcd902',
                name: 'Total Earnings'
            }
        ];
    }

    const chart = () => 
    {
        const values = setupValues();
        let title = `<strong>${values[1]['y'].toFixed(4)}</strong><br>QUDO`;
    
        return Highcharts.chart('totalEarnings', {
            chart: {
                type: 'pie',
                backgroundColor: null
            },
            title: {
                text: title,
                align: 'center',
                verticalAlign: 'middle',
                y: 20,
                style: {
                    color: '#fcd902',
                    fontSize: '25px'
                }
            },
            subtitle: {
                text: 'Total Earnings',
                verticalAlign: 'middle',
                y: 70,
                style: {
                    color: '#000000',
                    fontSize: '17px'
                }
            },
            credits: {
                enabled: false,
            },
            tooltip: {
                valueSuffix: ' QUDO',
                enabled: true
            },
            plotOptions: {
                pie: {
                    innerSize: '75%'
                }
            },
            series: [{
                data: values,
                dataLabels: {
                    enabled: true
                }
            }]
        });
	};

    const noData = (
        <div className="text-align-center border-bottom custom-height">
            No values to be shown
        </div>
    );

    return (
        <>
            <div className="borders mt-5">
                <div className="mb-3">
                    <div className="col-12 edit-screen-stakes-sm header pt-4">
                        <strong>Service Earnings</strong>
                    </div>
                </div>
                {chartReady ?
                    <div id="totalEarnings"></div>
                :
                    noData
                }
            </div>
        </>
    )
}