import React, { useState, useEffect } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

import { NumberedRange } from "../../components/forms/range";
import { NameInput, TextAreaInput } from "../../components/forms/text";
import { ButtonInput } from "../../components/forms/buttons";

import { /* SuccessModal, FailureModal, */ MessageModal } from "../../components/modals/modals.js";

import regexes from "../../../helpers/validationRegexes";
import QUDOServer from "../../../helpers/QUDOServerConnection";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export default function Highscore(props) {
    const [valid, setValid] = useState(false);
    const [name, setName] = useState("");
    const [alias, setAlias] = useState("");
    const [aliasValid, setAliasValid] = useState(true);
    const [description, setDescription] = useState("");
    const [pot, setPot] = useState(props.highscore ? props.highscore.pot : 0);
    const [coefficient, setCoefficient] = useState(props.highscore ? props.highscore.coefficient * 100 : 0);
    const [incremental, setIncremental] = useState(props.highscore ? props.highscore.incremental ? true : false : false);

    const [form, setForm] = useState(null);

    //const [successModal, setSuccessModal] = useState(false);
    //const [failureModal, setFailureModal] = useState(false);
	//const [update, setUpdate] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        setPot(props.highscore ? props.highscore.pot : 0);
        setCoefficient(props.highscore ? Number((props.highscore.coefficient * 100).toFixed(2)) : 0);
    }, [props.highscore]);

    useEffect(() => {
        if ((!props.highscore && !alias) || !name || !description || !coefficient || !pot) {        
            setValid(false)
        } else {
            setValid(true)
        }
    }, [props.highscore, alias, name, description, coefficient, pot]);

    useEffect(() => {
        const valid = props.verifyAlias('highscore', alias);
        setAliasValid(valid);
    }, [alias]);

    function deleteItem() {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/highscore/delete`,
            {
                gamename: props.game.name,
                alias: props.highscore.alias
            },
            {
                withCredentials: true
            }
        )
        .then((result) => {
            props.refresh();
        })
        .catch(error => {
            setModalMessage(error.errorMessage);
        });
    }

    const cancelChanges = () =>
    {
        setName(props.highscore ? props.highscore.name : '');
        setDescription(props.highscore ? props.highscore.description : '');
        setAlias(props.highscore ? props.highscore.alias : '');
        setPot(props.highscore ? props.highscore.pot : 0);
        setCoefficient(props.highscore ? props.highscore.coefficient * 100 : 0)
        setEditing(false);
    }

    const submit = (value) => {
        const body = {
            alias: props.highscore ? props.highscore.alias : alias,
            gamename: props.game.name,
            name,
            description,
            coefficient: coefficient / 100,
            pot,
            incremental
        }

        var url = '';

        if(props.highscore) {
            url = `${process.env.REACT_APP_QUDO_SERVER}/game/highscore/update`;
        } else {
            url = `${process.env.REACT_APP_QUDO_SERVER}/game/highscore/create`;
        }

        console.log("submitting highscore: ", body, " to ", url, " with credentials")

        QUDOServer.post(url, body, { withCredentials: true })
        .then(result => {
            setModalMessage('Success');
            //setSuccessModal(true);

            reset();
            //props.refresh();
        })
        .catch(error => {
            setModalMessage(error.errorMessage);
            //setFailureModal(true);

            //reset();
            //props.refresh();
        });
    }

    const reset = () => {
        form.reset();
    }

    const incrementalTooltip = (
        <Tooltip placement="right" className="">
            <span className="text-light"><small>
            Enabling incremental rewards adds all new scores to a user's previous high score, not just the highest single score.<br/><br/>
            For example, if a user's previous high score is 5 and they get a new score of 3, their new high score will be 8 (5 + 3).<br/><br/>
            With incremental rewards disabled, their high score would only increase if the new score is higher than the previous one (it would remain 5 in this case).
            </small></span>
        </Tooltip>
    );


    const coefficientTooltip = (
        <Tooltip placement="right" className="">
            <span className="text-light"><small>
            <u>Reward = Score * Coefficient</u><br/>
            eg.: score of 250 with 22.3% coefficient rewards <b>55,75</b> QUDO.
            </small></span>
        </Tooltip>
    );

    const potTooltip = (
        <Tooltip placement="right" className="">
            <span className="text-light"><small>
            <b>Pot</b> is the maximum total amount of QUDO tokens that can be <b>awarded to each gamer</b> for this highscore.
            </small></span>
        </Tooltip>
    );


    const addHighscore = !props.highscore && (
        <>
            <div className="col-12">
                <Form ref={(el) => setForm(el)}>
                    <NameInput 
                        set={setAlias}
                        title="Alias"
                        pattern={regexes.highscoreAlias}
                        disabled={props.highscore}
                        placeholder={props.highscore ? props.highscore.alias : "high1"}
                        invalid={!aliasValid}
                        errorMessage={'Alias is already being used!'}
                    />
                    <NameInput 
                        set={setName} 
                        title="Highscore Name"
                        pattern={regexes.highscoreName}
                        initial={props.highscore ? props.highscore.name : ""}
                        placeholder={props.highscore ? props.highscore.name : "Highscore level 1"}
                    />
                    <TextAreaInput 
                        set={setDescription}
                        title="Description"
                        initial={props.highscore ? props.highscore.description : ""}
                        placeholder={props.highscore ? props.highscore.description : "A nice highscore description"}
                    />
                    <NumberedRange 
                        max={100}
                        min={0}
                        initial={props.highscore ? props.highscore.coefficient : coefficient}
                        title="Coefficient"
                        unit="%"
                        setValue={setCoefficient}
                        value={coefficient}
                        step={0.1}
                        tooltip={<OverlayTrigger placement="right" overlay={coefficientTooltip}>
                            <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                        </OverlayTrigger>}
                    />
                    
                    <NumberedRange 
                        max={props.balance ? Number(props.balance.split(' ')[0]) : 0} 
                        min={0}
                        initial={props.highscore ? props.highscore.pot : pot}
                        title="Pot"
                        unit="QUDO"
                        setValue={setPot}
                        value={pot}
                        tooltip={<OverlayTrigger placement="right" overlay={potTooltip}>
                            <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                        </OverlayTrigger>}
                        classes="yellow-qudo"
                    />
                    {/*}
                    <ButtonInput 
                        label="Reset" 
                        set={reset} 
                        trigger
                    />
                    {*/}

                    <div className="row mt-1 mb-3 mx-2 user-select-none">
                        <div className="col text-right" onClick={() => setIncremental(!incremental)}>
                            <small>
                                <span>Incremental</span>
                                <OverlayTrigger placement="right" overlay={incrementalTooltip}>
                                    <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                                </OverlayTrigger>
                            </small>
                            <input
                                className="ml-2 align-middle"
                                type="checkbox"
                                checked={incremental}
                                onChange={() => setIncremental(!incremental)}
                            />
                        </div>
                    </div>

                    <ButtonInput
                        trigger
                        label="Create Highscore" 
                        set={submit} 
                        disabled={!valid}
                        style={
                            !valid
                            ? {cursor: 'initial'}
                            : {backgroundColor: "#fbd731"}
                        }
                    />
                </Form>
            </div>
        </>
    );

    const editHighscore = (props.highscore && editing) && (
        <>
            <div className="col-12">
                <Form ref={(el) => setForm(el)}>
                    <NameInput 
                        set={setAlias}
                        title="Alias"
                        pattern={regexes.highscoreAlias}
                        disabled={props.highscore}
                        placeholder={props.highscore ? props.highscore.alias : "high1"}
                        invalid={!aliasValid}
                        errorMessage={'Alias is already being used!'}
                    />
                    <NameInput 
                        set={setName} 
                        title="Highscore Name"
                        pattern={regexes.highscoreName}
                        initial={props.highscore ? props.highscore.name : ""}
                        placeholder={props.highscore ? props.highscore.name : "Highscore level 1"}
                    />
                    <TextAreaInput 
                        set={setDescription}
                        title="Description"
                        initial={props.highscore ? props.highscore.description : ""}
                        placeholder={props.highscore ? props.highscore.description : "A nice highscore description"}
                    />
                    <NumberedRange 
                        max={100}
                        min={0}
                        initial={props.highscore ? props.highscore.coefficient * 100 : coefficient}
                        title="Coefficient"
                        unit="%"
                        setValue={setCoefficient}
                        value={coefficient}
                        step={0.1}
                        tooltip={<OverlayTrigger placement="right" overlay={coefficientTooltip}>
                            <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                        </OverlayTrigger>}
                    />
                    
                    <NumberedRange 
                        max={props.balance ? Number(props.balance.split(' ')[0]) : 0} 
                        min={0}
                        initial={props.highscore ? props.highscore.pot : pot}
                        title="Pot"
                        unit="QUDO"
                        setValue={setPot}
                        value={pot}
                        tooltip={<OverlayTrigger placement="right" overlay={potTooltip}>
                            <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                        </OverlayTrigger>}
                        classes="yellow-qudo"
                    />
                    <div className="row mt-1 mb-3 mx-2 user-select-none">
                        <div className="col text-right" onClick={() => setIncremental(!incremental)}>
                            <small>
                                <span>Incremental</span>
                                <OverlayTrigger placement="right" overlay={incrementalTooltip}>
                                    <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                                </OverlayTrigger>
                            </small>
                            <input
                                className="ml-2 align-middle"
                                type="checkbox"
                                checked={incremental}
                                onChange={() => setIncremental(!incremental)}
                            />
                        </div>
                    </div>
                </Form>
            </div>
            <div className="row col-12 pl-0 pr-0 mr-0 ml-0">
                <div className="col-6 button text-center red pb-1 pt-1 cursor-pointer"
                    onClick={() => cancelChanges()}>
                    CANCEL
                </div>
                <div className={`col-6 button text-center green pb-1 pt-1 cursor-pointer`}
                    onClick={(e) => {
                        submit(e);
                    }}
                >
                    SAVE
                </div>
            </div>
        </>
    );

    const viewHighscore = (props.highscore && !editing) && (
        <>
            <div className="mb-3 mt-4 col-12 border-bottom cursor-pointer"
                onClick={() => setEditing(true)}>
                <h1 className="custom-header mb-4">Highscore Name</h1>
                <p>{props.highscore.name}</p>
            </div>
            <div className="mb-3 mt-4 col-12 border-bottom cursor-pointer"
                onClick={() => setEditing(true)}>
                <h1 className="custom-header mb-4">Alias</h1>
                <p>{props.highscore.alias ? props.highscore.alias : ''}</p>
            </div>
            <div className="mb-3 mt-4 col-12 border-bottom cursor-pointer"
                onClick={() => setEditing(true)}>
                <h1 className="custom-header mb-4">Description</h1>
                <p>{props.highscore.description ? props.highscore.description : ''}</p>
            </div>
            <div className="mt-4 mb-3 col-12 border-bottom cursor-pointer"
                onClick={() => setEditing(true)}>
                <h1 className="custom-header mb-4">Coefficient</h1>
                <p>{(Number(props.highscore.coefficient) * 100).toFixed(2)}%</p>
            </div>
            <div className={`mt-4 col-12 ${props.highscore.incremental && "border-bottom"} cursor-pointer`}
                onClick={() => setEditing(true)}>
                <h1 className="custom-header mb-4">Pot</h1>
                <p>{props.highscore.pot}</p>
            </div>
            {props.highscore.incremental && (
                <div className="mt-4 col-12 cursor-pointer"
                onClick={() => setEditing(true)}>
                <h1 className="custom-header mb-2">Incremental</h1>
                </div>
            )}
        </>
    );

    return (
        <div className="col-12 mx-auto">
            <div>
                {/* TITLE */}
                <div
                    className={`text-break col-12 mb-4 edit-screen-menu`}>
                    {props.highscore ? "Edit" : "Add"} Highscores
                </div>

                <div className="mx-auto border custom-rounded mb-4 pt-2">
                    {!props.highscore && 
                        addHighscore
                    }
                    {(props.highscore && editing) &&
                        editHighscore
                    }   
                    {(props.highscore && !editing) &&
                        viewHighscore
                    }
                </div>

                {/* MODALS */}
                <>
                    {/*}
                    <SuccessModal 
                        show={successModal} 
                        hide={() => {
                            setSuccessModal(!successModal);
                            props.refresh();
                        }}
                    />
                    <FailureModal
                        show={failureModal}
                        hide={() => {
                            setFailureModal(!failureModal);
                            //props.refresh();
                        }}
                    />
                    {*/}

                    <MessageModal
                        show={modalMessage}
                        message={modalMessage}
                        hide={() => {
                            setModalMessage('');
                        }}
                    />
                </>

                {props.highscore &&
                    <ButtonInput
                        trigger
                        label="Delete Highscore"
                        set={deleteItem} 
                        style={{backgroundColor: "#f44336", color:'#ffffff'}}
                    />
                }
            </div>
        </div>
    );
}
