import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import EosEcc from 'eosjs-ecc';
import {Modal} from 'react-bootstrap';
import {ClipLoader} from 'react-spinners';

import QUDOServer from '../../../helpers/QUDOServerConnection';
import {AccountNameFields} from '../../components/modals/RegisterUserModal';

import qudoIcon from '../../../images/QUDO_Logo.svg';

export default function MainnetMigration(props) {
	const currentUser = useSelector(state => state.info);

    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState('introduction');

    const [accountReward, setAccountReward] = useState(0);
    const [usePercentage, setUsePercentage] = useState(0);

    const [newPublicKey, setNewPublicKey] = useState('');
    const [newPrivateKey, setNewPrivateKey] = useState('');

    const [password, setPassword] = useState('');
    const [publicKey, setPublicKey] = useState('');
    const [accountName, setAccountName] = useState('');
    const [newAccountName, setNewAccountName] = useState(true);

	const [validAccountName, setValidAccountName] = useState(false);
	const [randomizeAccountName, setRandomizeAccountName] = useState(false);

    const [response, setResponse] = useState('');
    const [success, setSuccess] = useState(false);

    const shouldMigrateKeys = newAccountName && usePercentage >= 100;

    useEffect(() => {
        EosEcc.initialize();
    }, []);

    useEffect(() => {
        getAccountReward();
    }, [currentUser]);

    async function generateNewKeys() {
        const privateKey = await EosEcc.randomKey();
        const publicKey = await EosEcc.privateToPublic(privateKey);

        setNewPublicKey(publicKey);
        setNewPrivateKey(privateKey);
    }

	function CopyToClipboard(value) {
		var tempInput = document.createElement("input");
		tempInput.value = value;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand("copy");
		document.body.removeChild(tempInput);

		//setCopied(true);
		//setTimeout(() => setCopied(false), 3000);
	}

    function getAccountReward() {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/auth/mainnet/reward`, {
            withCredentials: true
        })
        .then(result => {
            setAccountReward(Number(result.data.reward));
            setUsePercentage(Number(result.data.usePercentage));
        })
        .catch(error => {
            //console.log(error);
        });
    }

    function sendRequest() {
        setLoading(true);

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/auth/mainnet/migrate`, {
            password: password,
            publicKey: publicKey,
            accountName: accountName,
            newAccountName: newAccountName
        }, {
            withCredentials: true
        })
        .then(result => {
            setSuccess(true);
            //setResponse(result.data);
            setResponse('Success! Your QUDO account has been registered on the TELOS main net and you can now earn real QUDO tokens by playing!');
        })
        .catch(error => {
            setSuccess(false);
            setResponse(error.errorMessage);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    if(currentUser && !currentUser.migratedMainnet) {
        return(
            <Modal
                show={props.show}
                onHide={props.setShow}
            >
                <Modal.Header id='migration-header'>
                    <div className='w-100 text-center'>
                        QUDO is now available on the TELOS Mainnet!
                    </div>
                </Modal.Header>

                <Modal.Body id='migration-body'>
                    <div className='mt-1 mb-3 text-center'>
                        <img
                            alt=''
                            src={qudoIcon}
                            className='img-fluid'
                            style={{maxWidth: '100px'}}
                        />
                    </div>

                    {step === 'introduction' && (
                        <>
                            <div className='mt-3 mb-2 text-center'>
                                READ CAREFULLY
                            </div>

                            <div style={{color: '#b2b2b2'}}>
                                <div className='mb-2'>
                                    The following instructions will guide you through the steps of migrating your 
                                    QUDO account from the TELOS test net to the TELOS main net.
                                </div>
                                <div>
                                    To begin receiving actual QUDO tokens rather than QUDO test tokens, you must 
                                    first associate a TELOS main net account name to your QUDO account.
                                </div>
                            </div>

                            <div className='mt-4'>
                                <Buttons
                                    back={() => props.setShow()}
                                    next={() => setStep('newAccountName')}
                                    allowNext={true}
                                />
                            </div>
                        </>
                    )}

                    {step === 'newAccountName' && (
                        <>
                            <div className='mt-3 mb-4'>
                                <div style={{color: '#b2b2b2'}}>
                                    As a result, we urge you to <b>create a new TELOS main net account</b> to associate with your QUDO 
                                    account, or <b>if you want to use an existing one, please choose the option "I 
                                    already have a TELOS account".</b> Keep in mind that if you associate an already 
                                    existing TELOS main net account, <b>you must have ownership over that account.</b>
                                </div>
                            </div>

                            <div className='mt-2'>
                                <AccountNameFields 
                                    set={setAccountName}
                                    username={currentUser ? currentUser.username : 'user'}
                                    newAccount={newAccountName}
                                    setNewAccount={setNewAccountName}
                                    setValid={setValidAccountName}
                                    randomizeAccountName={randomizeAccountName}
                                    setRandomizeAccountName={setRandomizeAccountName}
                                />
                            </div>

                            {shouldMigrateKeys && (
                                <>
                                    <div
                                        className='mt-2'
                                        style={{color: '#b2b2b2'}}
                                    >
                                        <div className='mb-1'>
                                            - Please insert the Public Key that should represent you as the owner of this TELOS account.
                                        </div>
                                        <div className='mb-1'>
                                            - Make sure that you have the Private Key that corresponds to this Public Key
                                            stored in a secure location and never share it with anyone!
                                        </div>
                                        <div className='mb-1'>
                                            - If you don't have a valid key pair, or don't know how to generate one, we've
                                            provided a key pair generator. This key pair generator works entirely on the
                                            frontend side of the application, so the generated private keys will never
                                            be sent over the internet. Make sure you store the keys in a secure location
                                            and never share the Private Key with anyone!
                                        </div>
                                    </div>
        
                                    <div className='mt-4'>
                                        <button
                                            type="button"
                                            className="std-button"
                                            style={{backgroundColor: '#fcd902'}}
                                            onClick={() => generateNewKeys()}
                                        >
                                            <div
                                                className="text-center"
                                                style={{color: '#282725'}}
                                            >
                                                Generate new KEY pair
                                            </div>
                                        </button>
        
                                        {newPrivateKey && (
                                            <div className='mt-2'>
                                                <div
                                                    className="text-center mb-2"
                                                    style={{fontSize: '0.9rem', color: '#b2b2b2'}}
                                                >
                                                    Private Key
                                                </div>
                                                <div
                                                    className='keygen-area'
                                                    onClick={() => CopyToClipboard(newPrivateKey)}
                                                >
                                                    {newPrivateKey}
                                                </div>
                                            </div>
                                        )}
        
                                        {newPublicKey && (
                                            <div className='mt-3 mb-3'>
                                                <div
                                                    className="text-center mb-2"
                                                    style={{fontSize: '0.9rem', color: '#b2b2b2'}}
                                                >
                                                    Public Key
                                                </div>
                                                <div
                                                    className='keygen-area'
                                                    onClick={() => CopyToClipboard(newPublicKey)}
                                                >
                                                    {newPublicKey}
                                                </div>
                                            </div>
                                        )}
        
                                        {(newPrivateKey || newPublicKey) && (
                                            <div
                                                className='mb-2 text-center'
                                                style={{fontSize: '0.9rem', color: '#f44336'}}
                                            >
                                                Make sure you store the keys in a secure location and never share
                                                the Private Key with anyone!
                                            </div>
                                        )}
        
                                        <input
                                            type='text'
                                            className='mt-2 migration-key-input'
                                            placeholder='Insert your new PUBLIC KEY'
                                            value={publicKey}
                                            onChange={(e) => setPublicKey(e.target.value)}
                                        />
                                    </div>
                                </>
                            )}

                            <div className='mt-4'>
                                <Buttons
                                    back={() => setStep('introduction')}
                                    next={() => setStep('confirmation')}
                                    allowNext={accountName && (shouldMigrateKeys ? publicKey : true)}
                                />
                            </div>
                        </>
                    )}

                    {step === 'confirmation' && (
                        <>
                            {accountReward > 0 && (
                                <>
                                <div
                                    className='mt-3 mb-3'
                                    style={{color: '#b2b2b2'}}
                                >
                                    <div>
                                        Your contributions were noticed throughout the alpha version of QUDO. As a result 
                                        of being an early adopter of QUDO, you will be awarded with
                                    </div>
                                    <div className='my-3 text-center'>
                                        <h3 style={{color: '#fcd902'}}>
                                            {accountReward} QUDO
                                        </h3>
                                    </div>
                                    <div>
                                        These rewards are determined by your account age and activity during the 
                                        pre-launch phase of QUDO.
                                    </div>
                                </div>
                                </>
                            )}

                            <div
                                className='mt-3 mb-3'
                                style={{color: '#b2b2b2'}}
                            >
                                The TELOS main net account <b>{accountName}</b> will be 
                                {newAccountName ? ' created and' : ''} associated to your QUDO account.
                            </div>

                            {shouldMigrateKeys && (
                                <div
                                    className='mt-3 mb-4'
                                    style={{color: '#b2b2b2'}}
                                >
                                    <div className='mb-1'>
                                        - Make sure that you have the Private Key that corresponds to the Public Key you inserted
                                    </div>
                                    <div className='mb-1'>
                                        - Keep your keys stored in a secure location
                                    </div>
                                    <div className='mb-1'>
                                        - Never share your Private Key with anyone
                                    </div>
                                </div>
                            )}

                            <input
                                type='password'
                                className='migration-password-input'
                                placeholder='Insert your QUDO PASSWORD to confirm'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />

                            <div className='mt-4'>
                                <Buttons
                                    back={() => setStep('newAccountName')}
                                    next={() => {
                                        sendRequest();
                                        setStep('result');
                                    }}
                                    allowNext={password}
                                />
                            </div>
                        </>
                    )}

                    {step === 'result' && (
                        <>
                            {loading ? (
                                <div className="migration-main-loading mt-3">
                                    <div className="migration-fof-loading">
                                        <h1 className="m-0 ml-3">
                                            <ClipLoader color="#fcd902"/>
                                        </h1>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div
                                        className='mt-4 text-center'
                                        style={success ? {color: '#00cc69'} : {color: '#f44336'}}
                                    >
                                        {response}
                                    </div>

                                    <div className='row mt-4'>
                                        <div className='col-12'>
                                            <button
                                                type="button"
                                                className="std-button"
                                                style={success ? {backgroundColor: '#00cc69'} : {backgroundColor: '#f44336'}}
                                                onClick={() => {
                                                    props.setShow();

                                                    if(success) {
                                                        window.location.reload();
                                                    }
                                                }}
                                            >
                                                <div className="text-center">
                                                    CLOSE
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>
        );
    } else {
        return null;
    }
}

function Buttons(props) {
    return(
        <div className='row'>
            <div className='col-12 col-sm-6'>
                <button
                    type="button"
                    className="std-button"
                    style={{backgroundColor: '#656565'}}
                    onClick={() => props.back()}
                >
                    <div
                        className="text-center"
                        style={{color: '#ededed'}}
                    >
                        BACK
                    </div>
                </button>
            </div>
            <div className='col-12 col-sm-6'>
                <button
                    type="button"
                    className="std-button"
                    style={
                        props.allowNext
                        ? {backgroundColor: '#fcd902'}
                        : {cursor: 'initial', backgroundColor: '#656565', opacity: '0.5'}
                    }
                    onClick={() => {
                        if(props.allowNext) {
                            props.next();
                        }
                    }}
                >
                    <div
                        className="text-center"
                        style={props.allowNext ? {color: '#282725'} : {color: '#ededed'}}
                    >
                        NEXT
                    </div>
                </button>
            </div>
        </div>
    );
}
