import React, {useEffect, useState} from 'react';
import OverallGameBalanceChart from '../gameBalance/overallGameBalanceChart/overallGameBalanceChart';
import QUDOServer from "../../../../helpers/QUDOServerConnection";
import GameBalance from '../gameBalance/balances';
import EarningsExpensesChart from './earningsExpensesChart/earningsExpensesChart';
import ExpensesBalancesChart from './expensesBalance/expensesBalanceChart';
import ExpensesChart from './expensesChart/expensesChart';
import InAppPurchasesChart from './inAppPurchases/inAppPurchasesChart';

export default function Earnings(props)
{
    const shouldRender = props.gameAccount && props.gameAccount.accepted && props.gameAccount.account_name;

    const [balances, setBalances] = useState();
    const [purchases, setPurchases] = useState();
    const [rewards, setRewards] = useState();

    useEffect(() => 
    {
        getGameEarnings();
    }, []);

    const getGameEarnings = async () =>
    {
        if(shouldRender) {
            QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/game/history?game=${props.gameAccount.name}`, 
            {
                withCredentials: true
            })
            .then(resp => 
            {
                if (!resp.errorMessage)
                {
                    setPurchases(resp.data.purchaseHistory);              
                    setBalances(resp.data.balances);
                    setRewards(resp.data.rewardHistory);
                }
            })
            .catch(err =>
            {
                setPurchases([]);              
                setBalances([]);
                setRewards([]);
            });
        }
    }

    if(!shouldRender) {
        return null;
    }

    if (props.type === 'left')
    {
        return (
            <>
                <div>
                    <GameBalance 
                        gameAccount={props.gameAccount}
                        //gameBalance={props.gameBalance}
                        activePage={props.activePage}
                        //balances={balances}
                    />
                </div>
                <div className="mb-4">
                    <OverallGameBalanceChart 
                        balances={balances}
                    />
                </div>
                <div className="mb-4">
                    <EarningsExpensesChart
                        allBalances={balances}
                        allRewards={rewards}
                        allPurchases={purchases}
                    />
                </div>
            </>
        )
    }

    if (props.type === 'center')
    {
        return (
            <>
                <div className="mb-4 mt-0 pt-0">
                    <ExpensesBalancesChart 
                        allBalances={balances}
                        allRewards={rewards}
                    />
                </div>
                <div className="mb-4 mt-0 pt-0">
                    <ExpensesChart
                        allRewards={rewards}
                    />
                </div>
            </>
        )
    }

    if (props.type === 'right')
    {
        return (
            <>
                <div className="mb-4 mt-0 pt-0">
                    <InAppPurchasesChart 
                        allPurchases={purchases}
                    />
                </div>
            </>
        )
    }

    if (props.type === 'overall')
    {
        return (
            <div className="mb-4">
                <OverallGameBalanceChart 
                    balances={balances}
                />
            </div>
        )
    }
}
