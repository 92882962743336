import React from "react";
import { Form, Modal } from "react-bootstrap";

import QUDOServer from "../../../helpers/QUDOServerConnection";

import { topMetaModal } from "./MetaModals";
import { ButtonInput } from "../forms/buttons";

class ForgotPassword extends React.Component {
	constructor(props) {
		super(props);
        this.submit = this.submit.bind(this);
		this.state = {
            valid: false
        }
	}

	changeUsername = async event => {
        this.setState({
            username: event.target.value
        });
    }

    submit = () => {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/auth/recover/forgotpassword`, {
            username: this.state.username,
			deviceID: process.env.REACT_APP_WEBAPPUUID
        })
        .then(result => {
            this.props.success(`Check your email to reset your password if your account exists.`);
        })
        .catch(error => {
            this.props.failure(error.errorMessage);
        })
        .finally(() => {
            this.setState({
                username: ''
            });
        });

        /*
        this.setState({
            submitted: true
        });
        */
    }

	render() {
		//if (this.state.submitted) {
        //    return (
        //        <div className="col-12 login-main-text">
        //            We sent you an e-mail with your new password!
        //        </div>
        //    )
        //} else {
            return (
                <Modal
                    dialogClassName="meta-box"
                    show={this.props.show}
                    onHide={() => {
                        this.setState({username: ''});
                        this.props.setShow(null);
                    }}
                >
                    <Modal.Body className="scroll-100 pt-0">
                        {topMetaModal(this.props.setShow)}
                        <div className="row meta-body">
                            <div className="col-12">
                                <div className="col-12 login-main-text">
                                    Oh! That's bad!
                                    <br/>
                                    But don't worry, we've got it covered!
                                </div>
                                <Form ref={(el) => this.clearPass = el} onSubmit={e => { e.preventDefault(); }}>     
                                    <div style={{marginBottom: "10px"}}>
                                        Enter your username/email
                                    </div>
                                    <input
                                        type="text"
                                        className={`input-field-custom col-12`}
                                        //style={{marginBottom: "35px"}}
                                        name="Username"
                                        value={this.state.username}
                                        onChange={this.changeUsername}
                                        placeHolder="Username or Email"
                                    />
                                    {/*}
                                    <div 
                                        //className={`col-12 edit-profile-save-btn ${this.state.valid ? "" : "disabled"}`}
                                        className='col-12 edit-profile-save-btn'
                                        onClick={this.submit}
                                    >
                                        Get me a new password
                                    </div>
                                    {*/}
                                    <ButtonInput
                                        type="submit"
                                        trigger
                                        className="form-submit-btn text-center"
                                        label="Get me a new password"
                                        set={() => this.submit()}
                                        //disabled={!this.state.valid}
                                    />
                                </Form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            );
        //}
	}
}

export default ForgotPassword;
