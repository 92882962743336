import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

import QUDOServer from "../../../helpers/QUDOServerConnection";
import { PasswordInput} from "../forms/text";

export default function ResetPassword(props) {

	const [disabled, setDisabled] = useState(true);

	const [password, setPassword] = useState("");
	const [passwordConf, setPasswordConf] = useState("");
	const [passwordsMismatchMsg, setPasswordsMismatchMsg] = useState(null);

	const [token, setToken] = useState(null);

	useEffect(() => {
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);

		const resetpass = params.get('resetpass');
		const token = params.get('token');

		if(global.isDev) console.log("reset password: ", token);

		setToken(token);

	}, []);

	useEffect(() => {
		if(passwordConf && (password !== passwordConf)) {
			setPasswordsMismatchMsg("Passwords don't match");
		} else {
			setPasswordsMismatchMsg("");
		}

		if(password === passwordConf && password) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [password, passwordConf]);

	function close() {
		setPassword("");
		setPasswordConf("");
		props.setShow(null);
	}

	async function submit() {

		if(disabled) {
			console.log("Registering disabled")
			return;
		}

		let body = {
			token: token,
			password: password
		}
		
		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/auth/recover/resetpass`, body, {
			headers: {"Content-Type": "application/json"},
			json: true,
			withCredentials: true
		})
		.then((result) => {
			props.success("Password changed successfuly! You just need to login now.");
		})
		.catch((error) => {
			props.failure(error.errorMessage);
			console.log("Reset password:", error);
		});
	}

	return(
		<Modal 
			show={props.show}
			onHide={close}
			dialogClassName="meta-box"
		>
			<Modal.Body className="scroll-95 pt-0">
				<div className="row meta-body">
					
					<div className="col-12 login-main-text">
						<div style={{}}>Lost your</div>
						<div style={{}}>Password?</div>
					</div>

					<div className="col-12">
						
						<div style={{marginBottom: "10px"}}>
							Enter your new password
						</div>

						<PasswordInput 
							key={1} 
							placeholder="Password"
							set={setPassword}
							validate={true}
						/>

						<PasswordInput 
							key={2} 
							placeholder="Confirm password"
							set={setPasswordConf} 
							//validate={true}
							forceInvalid={passwordsMismatchMsg}
						/>

						<div className="mt-3">
							<button
								type={"button"}
								name={"Submit Changes"}
								className={'col-12 add-achievement-toggle-btn'}
								style={disabled ? {backgroundColor: "rgb(249 218 80)"} : {backgroundColor: "#fbd731"}}
								onClick={(e) => {e.preventDefault(); submit()}}
							>
							Submit Changes
							</button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}
