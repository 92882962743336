import React from 'react';
import {useSelector} from 'react-redux';

export default function BalanceLimitCard(props) {
	const info = useSelector(state => state.info);
	const balance = useSelector(state => state.balance);

    if(
        info && info.qudoManaged
        && balance && !isNaN(balance.usePercentage) && Number(balance.usePercentage) >= 30
    ) {
        var message = '';
        var subtitle = '';

        if(Number(balance.usePercentage) >= 100) {
            subtitle = 'ACCOUNT LOCKED';
            message = 'NETWORK REWARDS are temporally DISABLED. You need to take control of your TELOS account to re-enable NETWORK REWARDS.';
        } else {
            message = 'QUDO Managed accounts have a maximum balance limit.';
        }

        var style = {}

        if(Number(balance.usePercentage) >= 100) {
            style.color = '#fbfafb';
            style.backgroundColor = '#f54337';
            //style.border = '3px solid #fbfafb';
        } else if(Number(balance.usePercentage) >= 90) {
            style.backgroundColor = '#f54337';
            //style.border = '3px solid #f54337';
        } else if(Number(balance.usePercentage) >= 60) {
            style.backgroundColor = '#fe9a06';
            //style.border = '3px solid #fe9a06';
        } else if(Number(balance.usePercentage) >= 30) {
            style.backgroundColor = '#fdd903';
            //style.border = '3px solid #fdd903';
        }

        return(
            <div
                style={style}
                className={`card roundcard shadow ${props.onClick ? 'enable-click' : ''}`}
                onClick={() => {
                    if(props.onClick) {
                        props.onClick();
                    }
                }}
            >
                <div className="card-body p-4">
                    <div>
                        <h1 className="d-inline-block">
                            {Number(balance.usePercentage).toFixed(0)}%
                        </h1>
                        <div className="d-inline-block ml-2">
                            Balance Limit
                        </div>
                    </div>

                    {subtitle && (
                        <h3>
                            {subtitle}
                        </h3>
                    )}

                    <div>
                        {message}
                    </div>
                    <div style={{fontWeight: 'bold', textDecoration: 'underline'}}>
                        Click here to take control of your account.
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}
