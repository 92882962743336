import React, { useEffect, useState } from 'react';
import {Modal} from 'react-bootstrap';
import {ClipLoader} from 'react-spinners';
import {ButtonInput} from '../../forms/buttons';
import {TextAreaInput} from '../../forms/text';

export default function CloseBugModal(props)
{
    //const [valid, setValid] = useState(false);

    /*
    useEffect(() =>
    {
        props.gamedevFeedback && props.gamedevFeedback.length > 5 ? setValid(true) : setValid(false);
    }, [props.gamedevFeedback]);
    */

    return (
        <>
            <Modal show={props.show} onHide={props.hide}>
                <Modal.Body style={{padding: '0'}}>
                    <Modal.Header
                        closeButton
                        className='modal-header-position'
                    />

                    <div className="card-body mt-2">
                        <div
                            className="title"
                            style={{
                                color: `${props.accept ? '#00cc69' : '#f44336'}`
                            }}
                        >
                            {props.accept ? "Your Feedback (optional)" : "Reason for Rejection"}
                        </div>
                    </div>
                    <div className="mt-2 col-12">
                        <TextAreaInput 
                            set={props.setGamedevFeedback} 
                            //minLength="5"
                            title="Description"
                            initial=""
                            placeholder={props.accept ? "Game Developer Feedback" : "Reason for Rejection"}
                            required={true}
                        />
                    </div>
                    {props.loading ? (
                        <div className="row my-4">
                            <div className="col-12">
                                <div className="text-center">
                                    <ClipLoader color="#282725"/>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row mt-4">
                            <div className="col-6 pr-0 mr-0">
                                <ButtonInput
                                    label="Cancel"
                                    trigger
                                    set={props.hide}
                                    className="editgamebtn w-100"
                                    style={{border: '1px solid #eee', backgroundColor: 'white', color: '#f44336',
                                    borderRadius: '0.5rem 0 0 0.5rem'}}
                                />
                            </div>
                            <div className="col-6 ml-0 pl-0">
                                <ButtonInput
                                    label="Submit"
                                    set={props.confirm}
                                    trigger
                                    className="editgamebtn w-100"
                                    style={{border: '1px solid #eee', backgroundColor: 'white', color: '#00cc69', borderRadius: '0 0.5rem 0.5rem 0'}}
                                    //disabled={!valid}
                                />
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )   
}