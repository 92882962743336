import { GET_BLOCK } from "../actions/types";

export default function(state = "", action) {
	switch (action.type) {
		case GET_BLOCK:
			return action.payload || false;
		default:
			return state;
	}
}
