import React, {useState, useEffect} from 'react';
import QUDOServer from '../../../../helpers/QUDOServerConnection';
import { DropdownInput } from '../../../components/forms/dropdowns';

export default function BugsNavBar(props)
{
    const [choices, setChoices] = useState();

    useEffect(() =>
    {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/bugReports`, {
            game: props.game.name
        }, {
            withCredentials: true
        })
        .then(resp =>
        {
            prepareChoicesDropdown(resp.data)
        })
        .catch(err =>
        {
            prepareChoicesDropdown()
        })
    }, []);

    const prepareChoicesDropdown = (bugs = null) =>
    {
        const tempChoices = [{
            key: 'all',
            label: 'All Versions'
        }];

        if (bugs && bugs.length > 0)
        {
            bugs.forEach(bug =>
            {
                const objectToPush = {
                    key: bug.gameVersion,
                    label: bug.gameVersion
                }

                if (!tempChoices.some(choice => choice.key === bug.gameVersion))
                {
                    tempChoices.push(objectToPush);
                }
            })
        }

        setChoices(tempChoices);
    }

    return (
        <div>
            <div className="col-12 row mobile-navbar">
                <div className={`edit-screen-menu${props.submenuBugs === 'setup' ? "" : "-disabled"} pl-3`}
                    style={props.submenuBugs === 'setup' ? { cursor: "default" } : { cursor: "pointer" }}
                    onClick={() => {props.setSubmenuBugs('setup'); props.setReportChosen(null)}}>
                    Setup
                </div>
                <div className={`edit-screen-menu${props.submenuBugs === 'manage' ? "" : "-disabled"} pl-3`}
                    style={props.submenuBugs === 'manage' ? { cursor: "default" } : { cursor: "pointer" }}
                    onClick={() => {props.setSubmenuBugs('manage'); props.setReportChosen(null)}}>
                    Manage
                </div>
            </div>
            <div className="col-4 pl-2 mt-2">
                {(props.submenuBugs === 'manage' && choices) &&
                (
                    <DropdownInput
                        label="Game version"
                        initial="All Versions"
                        set={value => props.setActiveFilterBugs(value)}
                        choices={choices}
                    />
                )}
            </div>
        </div>
    );
};