import React, {useState} from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import './navigationProvider.scss';

export default function NavigationProvider(props)
{

    const location = window.location.pathname;

    return (
        <div className="row ml-4 pl-2 responsive w-100">
            <span
                className={`edit-screen-menu sub-menu`}>
                <Link
                    className={`custom-link ${location === process.env.REACT_APP_PROVIDER_EARNINGS ? 'chosen' : ''}`} 
                    style={{textDecoration: 'none'}} 
                    to={process.env.REACT_APP_PROVIDER_EARNINGS}
                >
                    Earnings
                </Link>
            </span>
            <span
                className={`edit-screen-menu ml-3 sub-menu`}>
                <Link
                    className={`custom-link ${location === process.env.REACT_APP_PROVIDER_SERVICES ? 'chosen' : ''}`}  
                    style={{textDecoration: 'none'}} 
                    to={process.env.REACT_APP_PROVIDER_SERVICES}
                >
                    Setup
                </Link>
            </span>
            <span
                className={`edit-screen-menu ml-3 sub-menu`}>
                <Link
                    className={`custom-link ${location === process.env.REACT_APP_PROVIDER_MANAGEMENT ? 'chosen' : ''}`} 
                    style={{textDecoration: 'none'}} 
                    to={process.env.REACT_APP_PROVIDER_MANAGEMENT}
                >
                    Management
                </Link>
            </span>
        </div>
    )
}
