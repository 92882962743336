import React, {useState, useEffect} from "react";

import useWindowSize from "../../helpers/useWindowSize";
import QUDOServer from "../../helpers/QUDOServerConnection";

import {ButtonInput} from '../components/forms/buttons';

const fallBackAvatar = require("../../images/definitive/icon-avatar.png");

export default function TopReferrals(props) {
    /*
        Although not being used, this next statement forces the component to rerender whenever the window size
        changes (so don't delete it or the floats won't update when the screen size changes)
    */
    const windowSize = useWindowSize();
    const isMobile = (windowSize && windowSize.width < 1360);

    const referrersMinLoadNr = 5;
    const referrersMaxLoadNr = 10;

    const [referrers, setReferrers] = useState([]);
    const [showAllReferrers, setShowAllReferrers] = useState(false);

    useEffect(() => {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/top/referrals`)
        .then((response) => {
            setReferrers(response.data);
        });
    }, []);

    return(
        Array.isArray(referrers) && referrers.length > 0 ? (
            <div className="main-inner-container ref-container">
                <div className="h4" id="sh-list-title">
                    Top Referrers
                </div>
                <div className="m-auto pt-2">
                    <div className={isMobile ? "row pt-1" : "d-flex flex-column flex-wrap pt-1"}
                        style={isMobile ? {} : {height: 418}}>
                        <>
                        {referrers.slice(0, (!isMobile || showAllReferrers ? referrersMaxLoadNr : referrersMinLoadNr)).map((i, index) => {
                            return(
                                <div
                                    id="referrerRow"
                                    key={i.username}
                                    className={isMobile ? "col-12" : "col-12 col-md-6"}
                                    style={isMobile ? {} : {flexBasis: '20%'}}
                                >
                                    <div
                                        className="row d-flex align-top mx-0 top-stakeholders-card"
                                        style={((index > 0) && (!isMobile && index !== (referrersMaxLoadNr / 2))) ? {borderTop: '1px solid #282725'}: {}}
                                    >
                                        <div className="col-2 col-xl-1 sh-avatar-outer">
                                            {i.imageID ? (
                                                <img
                                                    alt="User avatar"
                                                    src={
                                                        i.imageURL ? i.imageURL :
                                                        `${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${i.imageID}&type=user&size=xS`
                                                    }
                                                    className="sh-avatar rounded-circle"
                                                    onError={i=> i.target.src = fallBackAvatar}
                                                />
                                            ) : (
                                                <img
                                                    alt="User avatar"
                                                    //src={fallBackAvatar}
                                                    src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=0&type=user&size=xS`}
                                                    className="sh-avatar rounded-circle"
                                                    onError={i=> i.target.src = fallBackAvatar}
                                                />
                                            )}
                                        </div>
                                        <div className="col-10 flex-column justify-content-center text-wrap">
                                            <div className="text-wrap" style={{display: 'inline-block'}}>
                                                <div id="top-players-info-top" className="text-wrap">
                                                    #{index + 1}
                                                </div>
                                                <div id="top-players-info-bottom" className="text-wrap">
                                                    {i.username}
                                                </div>
                                            </div>
                                            <div
                                                id="top-players-score"
                                                className="text-wrap"
                                                style={
                                                    document.getElementById('referrerRow')
                                                    && document.getElementById('referrerRow').clientWidth < 450
                                                    ? {} : {float: 'right'}
                                                }
                                            >
                                                {i.referralCount} Referrals
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {(isMobile && referrers.length > referrersMinLoadNr) && (
                            <div className="col-12">
                                <ButtonInput
                                    trigger
                                    label={showAllReferrers ? "Show Less" : "Show More"}
                                    set={() => setShowAllReferrers(!showAllReferrers)}
                                    style={{
                                        color: "#fbd731",
                                        backgroundColor: "#282725",
                                        border: "0px",
                                        marginTop: "10px"
                                    }}
                                />
                            </div>
                        )}
                        </>
                    </div>
                </div>
            </div>
        ) : (
            <></>
        )
    );
}
