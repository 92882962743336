import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

import QUDOServer from "../../../helpers/QUDOServerConnection";

const medal = require("../../../images/definitive/icn-product.png");

export default function ProductsList({ refreshProductList, editProduct, highlight, game }) {
    const [state, setState] = useState({ searchTerm: "", filter: "All", products: [] });
    const [highlightAlias, sethighlightAlias] = useState('');

    async function getProducts() {
        await QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/product/list`, {
            appID: game.appID
        })
            .then(result => {
                setState(oldState => {
                    return {...oldState, products: result.data}
                });
            });
    }

    useEffect(() => {
        getProducts();
    }, [game, refreshProductList]);

    useEffect(() => {
        sethighlightAlias(highlight?.alias);
    }, [highlight]);

    function search(event) {
        setState(oldState => {
            return {...oldState, searchTerm: event.target.value}
        });
    }

    function filter(event) {
        setState(oldState => {
            return {...oldState, filter: event}
        });
    }

    function filterAndSearch(i) {
        return (
            i.name.toLowerCase().includes(state.searchTerm.toLowerCase())
            //&& (state.filter !== "All" ? i[state.filter] === true : true)
            &&
            // <div className="">
            <div className="col-12 user-select-none" id="" key={i.alias}>
                <div className="align-items-center w-100 achievementcard-border-faded" id=""
                    style={highlightAlias === i.alias ? { opacity: "0.7" } : { opacity: "1" }}
                    onClick={() => editProduct(i)}>
                    <div className="col-1 mr-3">
                        <img src={medal} alt="medal" className="icon-32px" />
                    </div>
                    <div className="col-4 row">
                        <div className="amountgreen col-12" style={{ textAlign: "left", lineHeight: 'initial' }}>
                            {(i.price).toFixed(2)} QUDO
                        </div>
                    </div>
                    <div className="col-7 row">
                        <div className="col-12 achievementtitle" id="">
                            {i.name}
                        </div>
                        <div className="col-12 achievementsubtitle" id="">
                            {i.description}
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    return (
        <div className="col-12 mx-auto">
            <Form className="row">
                <InputGroup className="col-12">
                    <Form.Control
                        id="achievementsFilterField"
                        value={state.searchTerm}
                        placeholder="search"
                        onChange={search}
                    />
                </InputGroup>

                {/*}
                    <div className="col-6" >
                        <Dropdown id="achievementsFilterField">
                            <Dropdown.Toggle as="div" className="my-auto" style={{paddingLeft: "1rem"}}>
                                <div className="d-inline-block">{this.state.filter}</div>
                                <img src={arrowDown} className="d-inline-block edit-profile-dropdown-arrow" alt="arrow down"/>
                                    
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{width: "100%"}}>
                                <Dropdown.Item onSelect={this.filter} eventKey="All" id="edit-profile-filter-items">{this.state.productFilter ? this.state.productFilter : "Select your filter"}</Dropdown.Item>
                                <Dropdown.Item onSelect={this.filter} eventKey="isDynamic" id="edit-profile-filter-items">Dynamic</Dropdown.Item>
                                <Dropdown.Item onSelect={this.filter} eventKey="recurring" id="edit-profile-filter-items">Recurring</Dropdown.Item>
                            </Dropdown.Menu >
                        </Dropdown>
                    </div>
                    {*/}
            </Form>
            <div className="row mt-3">
                {Array.isArray(state.products) && state.products.length > 0 ? (
                    state.products.map(i => filterAndSearch(i))
                ) : (
                    <div className='col-12 mt-2 achievementtitle'>
                        This game doesn't have any products yet!
                    </div>
                )}
            </div>
        </div>
    )

}
