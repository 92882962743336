import moment from 'moment';
import React, { useEffect, useState } from 'react';
import QUDOServer from '../../../helpers/QUDOServerConnection';
import BugReportModal from '../../components/modals/bugReportModal/bugReportModal';
import './bugSpotting.scss'

export default function BugSpotting(props)
{
    const [bugsReported, setBugsReported] = useState([]);
    const [showReportModal, setShowReportModal] = useState(false);
    const [bugSelected, setBugSelected] = useState();

    useEffect(() =>
    {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/profile/bugreports`,
        {
            withCredentials: true
        })
        .then(resp => 
        {
            setBugsReported(resp.data);
        });
    }, []);

    const getBugReportsList = () =>
    {
        let dataToReturn = (
            <div>
                You have no bug reports
            </div>
        );

        if (bugsReported.length > 0)
        {
            dataToReturn = bugsReported.sort((a, b) => {
                return new Date(b.reportTimestamp).getTime() - new Date(a.reportTimestamp).getTime();
            }).map(bug =>
            {
                return (
                    <div className={`bordered-custom pb-2 pt-2 row cursor-pointer mb-2
                        ${ !bug.closed ? 'neutral' : bug.accepted ? 'accepted' : 'rejected'}`}
                        onClick={() => {
                            setShowReportModal(true);
                            setBugSelected(bug)
                        }}
                        key={bug._id}>
                        <div className="col-2 align-middle">
                            <img
                                // className="bug-report-game-image"
                                className="img-fluid rounded-circle mt-2"
                                style={{maxWidth: "45px", maxHeight: "45px"}}
                                src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${bug.game.name}`}
                                alt="game"
                            />
                        </div>
                        <div className="col-7">
                            <div>{bug.game.display_name ? bug.game.display_name : bug.game.name}</div>
                            <div>{bug.title}</div>
                            <div className="date">{moment(bug.reportTimestamp).format('YYYY/MM/DD')}</div>
                        </div>
                        <div className="col-3 text-right">
                            <div className={`bug-status ${ !bug.closed ? 'neutral' : bug.accepted ? 'accepted' : 'rejected'}`}>
                                    {!bug.closed ? 'Awaiting' : bug.accepted ? 'Accepted' : 'Rejected'}
                            </div>
                            {(bug.closed && bug.accepted) && (
                                <>
                                    <div className="reward-amount">
                                        {bug.reward.toFixed(4)}
                                    </div>
                                    <div className="reward-amount qudo">
                                        QUDO
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )
            })
        }

        return dataToReturn;
    }

    return (
        <div className="bugSpotting col-12 mt-2">
            {getBugReportsList()}
            {bugSelected && (
                <BugReportModal 
                    show={showReportModal} 
                    hide={() =>
                    {
                        setShowReportModal(false);
                        setBugSelected(null)
                    }}
                    reportID={bugSelected._id}
                    gameName={bugSelected.game.name}
                    gameDisplayName={bugSelected.game.display_name}
                    reportTimestamp={bugSelected.reportTimestamp}
                    title={bugSelected.title}
                    category={bugSelected.category}
                    closed={bugSelected.closed}
                    accepted={bugSelected.accepted}
                    reward={bugSelected.reward}
                    description={bugSelected.description}
                    gamedevFeedback={bugSelected.gamedevFeedback}
                />
            )}
        </div>
    )
}