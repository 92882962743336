import {useState, useEffect} from 'react';

/*
    Since we're only using this component to track the screen width, by default it only returns a new window
    size object when the width of the screen changes, so if the height changes it will not return a new object.
    This was done in order to fix the issue in mobile where the components would re-render when the browser bar
    would appear or disappear.
    This behavior can be overwritten by passing the function parameter checkHeight as 'true'.
*/
export default function useWindowSize(checkHeight) {
    const initialObject = {
        width: undefined,
        height: undefined
    }

    const [windowSize, setWindowSize] = useState(initialObject);
    const [newWindowSize, setNewWindowSize] = useState(initialObject);

    useEffect(() => {
        function handleResize() {
            setNewWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    useEffect(() => {
        if(checkHeight) {
            //If the checkHeight flag is true, always update the windowSize object
            setWindowSize(newWindowSize);
        } else {
            //If the current window size is invalid
            if(!windowSize || !windowSize.width || !windowSize.height) {
                setWindowSize(newWindowSize);
            } else {
                //If the width of the new window size is different from the current window size
                if(newWindowSize.width !== windowSize.width) {
                    setWindowSize(newWindowSize);
                }
            }
        }
    }, [newWindowSize]);

    return windowSize;
}
