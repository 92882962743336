import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

import { ClaimButton } from '../../../common/transactionHistory';

import QUDOServer from '../../../../helpers/QUDOServerConnection';

export default function ReferralModal(props) {
    const balance = useSelector(state => state.balance);
    const currentUser = useSelector(state => state.info);
    const pendingReward = useSelector(state => state.pendingReward);

    const [referrals, setReferrals] = useState([]);
    const [referralswoActivity, setReferralswoActivity] = useState([]);
    const [maxReferrals, setMaxReferrals] = useState('?');
    const [freeReferrals, setFreeReferrals] = useState('?');
    const [stakePerReferral, setStakePerReferral] = useState('?');
    const [minimumStakeReferrals, setMinimumStakeReferrals] = useState('?');

    const [copied, setCopied] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [referralLink, setReferralLink] = useState('');

    const maxReferralsToShow = 5;

    useEffect(() => {
        if (currentUser) {
            getReferrals();
            setReferralLink(`https://games.qudo.io?register=true&referral=${currentUser.username}`);
        } else {
            props.setShow(false);
        }
    }, []);

    useEffect(() => {
        if (
            balance
            && Array.isArray(referrals)
            && !isNaN(freeReferrals)
            && !isNaN(stakePerReferral)
            && !isNaN(minimumStakeReferrals)
        ) {
            const currentStake = Number(String(balance.stake).split(' ')[0]);
            const minimumStake = Number(String(balance.minimumStake).split(' ')[0]);

            const minimumStakeNotReferrals = minimumStake - minimumStakeReferrals;
            const availableStakeForReferrals = currentStake - minimumStakeNotReferrals;

            setMaxReferrals(
                freeReferrals + Math.trunc((availableStakeForReferrals > 0 ? availableStakeForReferrals : 0) / stakePerReferral)
            );
        }
    }, [balance, referrals, freeReferrals, stakePerReferral, minimumStakeReferrals]);

    function CopyToClipboard(value) {
        var tempInput = document.createElement("input");
        tempInput.value = value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);

        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
    }

    function getReferrals() {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/profile/referrals`, {
            withCredentials: true
        })
            .then(response => {
                if (response.data) {
                    if (Array.isArray(response.data.referrals)) {
                        let filterRefs = [];
                        let filterRefsNoAct = [];
                        response.data.referrals.forEach(ref => {
                            if (ref.hasActivity) {
                                filterRefs.push(ref);
                            } else {
                                filterRefsNoAct.push(ref);
                            }
                        })
                        setReferrals(filterRefs);
                        setReferralswoActivity(filterRefsNoAct);
                    }

                    if (!isNaN(response.data.free_referrals)) {
                        setFreeReferrals(Number(response.data.free_referrals));
                    }

                    if (!isNaN(response.data.stake_per_referral)) {
                        setStakePerReferral(Number(response.data.stake_per_referral));
                    }

                    if (!isNaN(response.data.minimum_stake_referrals)) {
                        setMinimumStakeReferrals(Number(response.data.minimum_stake_referrals));
                    }
                }
            })
            .catch(error => {
                //console.log(error);
            });
    }

    let filteredReferrals = referrals.filter(ref => ref.username.includes(searchTerm));
    let filteredReferralswoActivity = referralswoActivity.filter(ref => ref.username.includes(searchTerm));

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.setShow}
            //centered={true}
            >
                <Modal.Body
                    //id="gamedetails-modal"
                    style={{
                        opacity: 1,
                        justifyContent: 'initial'
                    }}
                >
                    <Modal.Header
                        closeButton
                        className='modal-header-position'
                    />
                    <div>
                        <div className='row mx-auto mt-3'>
                            <div className='col-12'>
                                <h4>Referrals</h4>
                            </div>
                        </div>

                        <div className='row mx-auto my-3'>
                            <div className='col-12'>
                                You can increase your network rewards as well as those of your friends by referring
                                them to join QUDO using your referral link. You'll both be eligible for a percentage of
                                the network rewards reserved just for referrals and guilds.
                            </div>
                        </div>

                        <div className='row mx-auto'>
                            <div
                                data-tip
                                data-for='referralLink'
                                className="col-12 edit-field-yellow"
                                onClick={() => {
                                    CopyToClipboard(referralLink);
                                }}
                            >
                                <div className="edit-field-title">
                                    Referral Link
                                </div>
                                <div style={{ color: "#656565", whiteSpace: "nowrap", overflow: "hidden" }}>
                                    {referralLink}
                                </div>
                                <ReactTooltip id='referralLink' type='light'>
                                    <span>
                                        {copied ? 'Copied' : 'Click to copy to clipboard'}
                                    </span>
                                </ReactTooltip>
                            </div>
                        </div>

                        <div className='row mx-auto'>
                            <div className='col-12 col-md-6 mt-3 mb-2'>
                                <div className='mb-2' style={{ fontWeight: 'bold' }}>
                                    Pending Rewards
                                </div>
                                <div className='d-inline-block'>
                                    <div
                                        className='network-rew-bg text-left'
                                        style={{ fontSize: '2rem' }}
                                    >
                                        {String(pendingReward).split(' ')[0]}
                                    </div>
                                    <div
                                        className='text-right'
                                        style={{ fontSize: '0.75rem' }}
                                    >
                                        QUDO
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 mt-3 mb-2'>
                                <div className='mb-2' style={{ fontWeight: 'bold' }}>
                                    Available Referrals
                                </div>
                                <div
                                    className='network-rew-bg text-left'
                                    style={{ lineHeight: '0.25' }}
                                >
                                    <div
                                        className='d-inline-block'
                                        style={{ fontSize: '2rem', color: '#00cc69' }}
                                    >
                                        {Array.isArray(referrals) ?
                                            Array.isArray(referralswoActivity) ?
                                                referrals.length + referralswoActivity.length :
                                                referrals.length : '?'}
                                    </div>
                                    <div
                                        className='d-inline-block px-1'
                                        style={{ fontSize: '2rem', color: '#676766' }}
                                    >
                                        /
                                    </div>
                                    <div
                                        className='d-inline-block'
                                        style={{ fontSize: '2rem', color: '#676766' }}
                                    >
                                        {maxReferrals}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mx-auto my-3'>
                            <div className='col-12'>
                                <div style={{ fontWeight: 'bold' }}>
                                    {`Active Referrals (${referrals.length})`}
                                </div>

                                <div>
                                    <p style={{ fontSize: '0.75rem' }}>
                                        Only accounts that are verified and played at least once will be considered Active Referrals.
                                    </p>
                                </div>

                                <div className='row my-2'>
                                    <input
                                        className="col-12 send-to-input-box py-2"
                                        placeholder='Search...'
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value.toLowerCase());
                                        }}
                                    />
                                </div>

                                <div
                                    className='row with-scroll'
                                    style={{ maxHeight: '450px', overflowX: 'hidden' }}
                                >
                                    {referrals.length > 0 ? (
                                        <>
                                            {filteredReferrals.slice(0, maxReferralsToShow).map(e => {
                                                return (
                                                    <div
                                                        key={e.username}
                                                        className='col-12 p-2 mt-1'
                                                        style={{
                                                            border: '1px solid #e3e3e3',
                                                            borderRadius: '5px'
                                                        }}
                                                    >
                                                        <div className='row'>
                                                            <div className='col-2 text-center'>
                                                                <img
                                                                    alt='Referral avatar'
                                                                    src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${e.imageID}&type=${e.type}`}
                                                                    style={{ height: "2.5rem", width: '2.5rem' }}
                                                                />
                                                            </div>
                                                            <div className='col-6 align-self-center text-left'>
                                                                {e.username}
                                                            </div>
                                                            <div className='col-4 align-self-center text-right'>
                                                                {moment(e.dateJoined).format("DD-MM-YYYY")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {filteredReferrals.length > maxReferralsToShow &&
                                                <div className='col-12 p-2 mt-1'>
                                                    <p className='m-0'>
                                                        ... and {filteredReferrals.length - 5} more
                                                    </p>
                                                </div>
                                            }
                                        </>
                                    ) : (
                                        <div className='col-12'>
                                            You don't have any active referrals
                                        </div>
                                    )}
                                    {referralswoActivity.length > 0 && (
                                        <>
                                            <p className='mt-2 mb-0 px-2' style={{ fontWeight: 'bold' }}>⚠️ Pending Referrals (without activity)</p>
                                            <p className='px-2 m-0' style={{ fontSize: '0.75rem' }}>
                                                The following accounts are verified but haven't played any game yet.
                                            </p>
                                            <div
                                                className='col-12 p-2' style={{ color: '#eb4034' }}
                                            >
                                                {filteredReferralswoActivity.slice(0, maxReferralsToShow).map(e => {
                                                    return (
                                                        <p className='m-1' key={e.username}>
                                                            {e.username}
                                                        </p>
                                                    )
                                                    //+ '(' + moment(e.dateJoined).format("DD-MM-YYYY") + ')';
                                                })}
                                                {filteredReferralswoActivity.length > maxReferralsToShow &&
                                                    <p className='m-1'>
                                                        ... and {filteredReferralswoActivity.length - 5} more
                                                    </p>
                                                }
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='row my-3'>
                            <div className='col-12 mt-2'>
                                <ClaimButton />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
