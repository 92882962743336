import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';

export default function BugsPerVersion(props)
{
    const allBugs = props.bugReports;
    const [chartReady, setChartReady] = useState(false);

    useEffect(() =>
    {
        if (allBugs)
        {
            setChartReady(true);
        }
    }, [props.bugReports])

    useEffect(() =>
    {
        if (props.bugReports?.length > 0)
        {   
            chart();
        }
    }, [chartReady]);

    const setupValues = availableVersions =>
    {
        const acceptedBugsPerVersion = [];
        const rejectedBugsPerVersion = [];
        const totalBugsFoundPerVersion = [];

        availableVersions.forEach(version =>
        {
            let bugsAccepted = 0;
            let bugsRejected = 0;
            let totalBugs = 0;

            allBugs.filter(bugs => bugs.gameVersion === version).forEach(bug => 
            {
                totalBugs++;
                
                if (bug.closed)
                {
                    bug.accepted ? bugsAccepted++ : bugsRejected++;
                }
            });

            acceptedBugsPerVersion.push(bugsAccepted);
            rejectedBugsPerVersion.push(bugsRejected);
            totalBugsFoundPerVersion.push(totalBugs);
        });

        return [acceptedBugsPerVersion, rejectedBugsPerVersion, totalBugsFoundPerVersion];
    }

    const availableVersions = () =>
    {
        const tempVersions = [];

        if (allBugs && allBugs.length > 0)
        {
            allBugs.forEach(bug =>
            {
                if (!tempVersions.some(version => version === bug.gameVersion))
                {
                    tempVersions.push(bug.gameVersion);
                }
            });
        }

        return tempVersions;
    }

    const chart = () => 
    {
        const versionsFound = availableVersions();
        const [acceptedValues, rejectedValues, totalValues] = setupValues(versionsFound);

		return Highcharts.chart('bugsPerVersion', {
			chart: {
				type: "Combination chart",
				backgroundColor: "transparent",
				height: "300rem",
			},
			title: {
				text: "",
			},
			subtitle: {
				align: "right",
				verticalAlign: "bottom",
			},
			xAxis: {
                categories: [...versionsFound],
			},
            credits: {
                enabled: false
            },
			plotOptions: {
				area: {
					fillOpacity: 0.5,
					fillColor: {
						linearGradient: [0, 0, 0, 300],
						stops: [
							[0, "#dedede"],
							[1, "#ffffff"],
						],
					},
				}
			},
			series: [
				{
					name: 'Total Bugs',
					data: [...totalValues],
                    type: 'line',
                    marker: {
						fillColor: '#707070',
                        lineWidth: 2
					},
                    color: '#707070'
				},
                {
                    type: 'column',
					name: 'Accepted',
					data: [...acceptedValues],
					marker: {
						fillColor: "#fcd902",
					},
                    color: '#fcd902'
				},
                {
                    type: 'column',
					name: 'Rejected Bugs',
					data: [...rejectedValues],
					marker: {
						fillColor: '#b2b2b2',
					},
                    color: '#b2b2b2'
				}
			]
		});
    }

    const noData = (
        <div className="text-align-center border-bottom custom-height">
            No values to be shown
        </div>
    );

    return (
        <>
            <div className="borders mt-4">
                <div className="mb-3">
                    <div className="col-12 edit-screen-stakes-sm header pt-4">
                        <strong>Bugs Per Version</strong>
                    </div>
                </div>
                {chartReady ?
                    <div className="col-12" id="bugsPerVersion"></div>
                :
                    noData
                }
            </div>
        </>
    )
}