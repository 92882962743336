import React, { useState, useEffect } from "react";
//import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import classes from './arrow.scss';

//import * as actions from "../../actions";
const arrowBack = require("../../images/definitive/icn-arrow-back.png");

export function GoBackArrow(props) {
    //const dispatch = useDispatch();
    const history = useHistory();
    const [goBack, setGoBack] = useState(false);

    useEffect(() => {
        if(goBack === true) {
            //dispatch(actions.fetchUser());
            //dispatch(actions.getGames());
            history.goBack();
        }
    }, [goBack]);

    return (
        <div className={props.customClass ? props.customClass : 'col-2 align-self-center'}>
            <img
                src={arrowBack}
                className=""
                style={{ cursor: "pointer" }}
                onClick={() => setGoBack(true)}
                alt="arrow"
            />
        </div>
    );
}
