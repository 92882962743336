import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {Modal} from 'react-bootstrap';

import * as actions from '../../../actions';

import useWindowSize from '../../../helpers/useWindowSize';
import QUDOServer from '../../../helpers/QUDOServerConnection';

import {MessageModal} from './modals';
import ConfirmModal from './confirmModal/confirmModal';

const GuildRoles = {
	"owner": "Owner",
	"admin": "Admin",
	"officer": "Officer",
	"recruiter": "Recruiter",
	"member": "Member"
}

const GuildRoleWeight = {
	"owner": 1,
	"admin": 2,
	"officer": 3,
	"recruiter": 4,
	"member": 5
}

export default function GuildDetailsModal({show, hide, guild}) {
    const dispatch = useDispatch();

	const windowSize = useWindowSize();
    const isMobile = windowSize.width <= 768;

    const currentUser = useSelector(state => state.info);

    const [copied, setCopied] = useState(false);
    const [guildInfo, setGuildInfo] = useState(null);
    const [referralLink, setReferralLink] = useState('');
    const [searchTermMembers, setSearchTermMembers] = useState('');

    const [modalMessage, setModalMessage] = useState('');
    const [requestLoading, setRequestLoading] = useState(false);
    const [confirmJoinGuild, setConfirmJoinGuild] = useState(false);

    useEffect(() => {
        getGuildInfo();
    }, []);

    function getGuildInfo() {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/guild?guild=${guild}`)
        .then(response => {
            setGuildInfo(response.data);
            setReferralLink(`https://games.qudo.io?register=true&referral=${response.data.username}`);
        })
        .catch(error => {
            //console.log(error);
            hide();
        });
    }

    function joinGuild() {
        setRequestLoading(true);

		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/guild/join`, {
            username: currentUser.username,
            guild: guild
		}, {
			withCredentials: true
		})
        .then(response => {
            setModalMessage('Success');
            dispatch(actions.fetchUser());
        })
        .catch(error => {
            setModalMessage(error.errorMessage);
        })
        .finally(() => {
            setRequestLoading(false);
            setConfirmJoinGuild(false);
        });
    }

	function CopyToClipboard(value) {
		var tempInput = document.createElement("input");
		tempInput.value = value;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand("copy");
		document.body.removeChild(tempInput);

		setCopied(true);
		setTimeout(() => setCopied(false), 3000);
	}

    return(
        guildInfo ? (
            <Modal show={show} onHide={hide}>
                <Modal.Body
                    id='gamedetails-modal'
                    style={{
                        opacity: 1,
                        justifyContent: 'initial'
                    }}
                >
                    <Modal.Header
                        closeButton
                        className='modal-header-position'
                    />
                    <div className='row with-scroll'>
                        <div className='col-12 mt-2 mb-4'>
                            <h2>{guildInfo.display_name ? guildInfo.display_name : guildInfo.username}</h2>
                        </div>
                        <div className='col-12 col-md-5 my-2'>
                            <div className='row'>
                                <div
                                    className='col-12 mb-2'
                                    style={isMobile ? {textAlign: 'center'} : {}}
                                >
                                    <img
                                        alt='Guild avatar'
                                        src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${guildInfo.imageID}&type=${guildInfo.type}`}
                                        //src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${guildInfo.imageID}&type=${guildInfo.type}&rand=${Date.now()}`}
                                        className='img-fluid'
                                        style={isMobile ? {maxWidth: '300px'} : {maxWidth: '425px'}}
                                    />
                                    {
                                        !isMobile
                                        && Array.isArray(guildInfo.members)
                                        && guildInfo.members.find(e => e.role === 'owner')
                                        && (
                                            <div className='row mt-5'>
                                                <div className='col-12 mb-2 custom-header'>
                                                    <h6>Owners</h6>
                                                </div>
                                                {guildInfo.members.sort((a, b) => {
                                                    let a_weight = GuildRoleWeight[a.role];
                                                    let b_weight = GuildRoleWeight[b.role];

                                                    if(isNaN(a_weight)) {
                                                        a_weight = 1000;
                                                    }

                                                    if(isNaN(b_weight)) {
                                                        b_weight = 1000;
                                                    }

                                                    return a_weight - b_weight;
                                                }).map(e => {
                                                    if(e.role === 'owner') {
                                                        return(
                                                            <div className='col-6 text-center'>
                                                                <div>
                                                                    <img
                                                                        alt='Member avatar'
                                                                        src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${e.user.imageID}&type=${e.user.type}`}
                                                                        style={{height: "3.5rem", width: '3.5rem'}}
                                                                    />
                                                                </div>
                                                                <div className='mt-1'>
                                                                    {e.user.display_name ? e.user.display_name : e.user.username}
                                                                </div>
                                                            </div>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-md-7 my-2'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='custom-header mb-1'>
                                        <strong>Website</strong>
                                    </div>
                                    <a
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href={guildInfo.website}
                                        style={{color: 'black'}}
                                    >
                                        {guildInfo.website}
                                    </a>
                                </div>
                                <div className='col-12 mt-4'>
                                    <div className='custom-header mb-1'>
                                        <strong>Guild Description</strong>
                                    </div>
                                    {guildInfo.description}
                                </div>
                                <div className='row col-12 mx-auto mt-4'>
                                    <div
                                        data-tip
                                        data-for='referralLink'
                                        className="col-12 edit-field-yellow"
                                        onClick={() => {
                                            CopyToClipboard(referralLink);
                                        }}
                                    >
                                        <div className="edit-field-title">
                                            Referral Link
                                        </div>
                                        <div style={{color: "#656565", whiteSpace: "nowrap", overflow: "hidden"}}>
                                            {referralLink}
                                        </div>
                                        <ReactTooltip id='referralLink' type='light'>
                                            <span>
                                                {copied ? 'Copied' : 'Click to copy to clipboard'}
                                            </span>
                                        </ReactTooltip>
                                    </div>
                                </div>
                                <div className={`col-12 ${isMobile ? 'mt-4' : 'mt-5'}`}>
                                    <div className='custom-header mb-1'>
                                        <strong>
                                            Guild Members
                                            {Array.isArray(guildInfo.members) && guildInfo.members.length > 0 && (
                                                ` (${guildInfo.members.length})`
                                            )}
                                        </strong>
                                    </div>
                                    <div className='my-2'>
                                        <input
                                            className="w-100 p-2 send-to-input-box"
                                            placeholder='Search...'
                                            value={searchTermMembers}
                                            onChange={(e) => {
                                                setSearchTermMembers(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div
                                        className='with-scroll'
                                        style={{maxHeight: '450px', overflowX: 'hidden'}}
                                    >
                                        {Array.isArray(guildInfo.members) && guildInfo.members.length > 0 ? guildInfo.members.map((e, index) => {
                                            const search = String(searchTermMembers ? searchTermMembers : '').toLowerCase();

                                            if(
                                                !String(e.user.username).toLowerCase().includes(search)
                                                && !String(e.user.display_name).toLowerCase().includes(search)
                                                // && !String(e.user.account_name).toLowerCase().includes(search)
                                            ) {
                                                return null;
                                            }

                                            return(
                                                <div
                                                    className='row mb-1 p-3'
                                                    style={{
                                                        backgroundColor: '#ecedec'
                                                        //backgroundColor: index % 2 ? '#fafbfb' : '#ecedec'
                                                    }}
                                                >
                                                    <div className='col-2 col-xl-1 align-self-center text-center'>
                                                        <img
                                                            alt='Member avatar'
                                                            src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${e.user.imageID}&type=${e.user.type}`}
                                                            style={{height: "2.5rem", width: '2.5rem'}}
                                                        />
                                                    </div>
                                                    <div className={`col-6 align-self-center`}>
                                                        {e.user.display_name ? e.user.display_name : e.user.username}
                                                    </div>
                                                    <div className={`col-4 align-self-center`}>
                                                        {GuildRoles[e.role]}
                                                    </div>
                                                </div>
                                            )
                                        }) : (
                                            <div className='my-3'>
                                                This Gaming Guild doesn't have any members
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {currentUser
                                && !currentUser.guild
                                && !currentUser.pendingGuildRequest
                                && currentUser.type !== process.env.REACT_APP_GUILD_TYPE
                                && (
                                    <div className='col-12 my-3'>
                                        <div
                                            className='enable-click align-self-center text-center py-3'
                                            style={{
                                                color: '#fff',
                                                backgroundColor: '#00ce6b',
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                setConfirmJoinGuild(true);
                                            }}
                                        >
                                            REQUEST TO JOIN
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <ConfirmModal
                            show={confirmJoinGuild}
                            loading={requestLoading}
                            hide={() => setConfirmJoinGuild(false)}
                            confirm={() => joinGuild()}
                            message={`Are you sure you want to join this guild?`}
                        />

                        <MessageModal
                            show={modalMessage} 
                            message={modalMessage}
                            hide={() => setModalMessage('')}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        ) : (
            <></>
        )
    );
}
