import React, {useState, useEffect} from 'react';
import {ToggleButtonInput} from '../../../../../components/forms/buttons';
import QUDOServer from "../../../../../../helpers/QUDOServerConnection";
import {MessageModal} from "../../../../../components/modals/modals";
import {useSelector, useDispatch} from "react-redux";
import * as actions from "../../../../../../actions";

export default function BugSpottingSwitch(props)
{
    const [activeBugSpotting, setActiveBugSpotting] = useState(props.game ?
        props.game.bugSpotting.active : false);
    const [successModal, setSuccessModal] = useState(false);
    const [failureModal, setFailureModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const dispatch = useDispatch();

    useEffect(() =>
    {
        if (props.game)
        {
            setActiveBugSpotting(props.game.bugSpotting.active);
        }
    }, [props.game]);

    const editBugSpotting = value =>
    {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/bugspotting/edit/state`, {
            game: props.game.name
        }, {
            withCredentials: true
        })
        .then(resp =>
        {
            setActiveBugSpotting(value);
            setSuccessModal(true);
            setModalMessage(`Bug Spotting Rewards is now ${value ? 'active' : 'inactive'}`)
        })
        .catch(err =>
        {
            setFailureModal(true);
            setModalMessage('Something went wrong')
        }); 
    }

    const updateGameInfo = () =>
    {
        dispatch(actions.fetchUser());
    }

    return (
        <div className="row">
            <div className="col-9 col-md-8 col-lg-7">
                Bug Spotting Rewards
            </div>
            <div className="col-2 col-lg-2">
                <ToggleButtonInput
                    initial={activeBugSpotting}
                    set={value => editBugSpotting(value)}
                    onColor="#fcd902"
                    offColor="#b2b2b2"
                />
            </div>
            <>
				<MessageModal
					show={successModal} 
					message={modalMessage}
					hide={() => {setSuccessModal(!successModal); updateGameInfo()}}
				/>
				<MessageModal
					show={failureModal} 
					message={modalMessage}
					hide={() => setFailureModal(!failureModal)}
				/>
			</>
        </div>
    );
};