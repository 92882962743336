import React, {useState, useEffect} from 'react';

import {Loading} from './App';
import {Header} from './Community';
import QUDOServer from '../helpers/QUDOServerConnection';

export default function AirdropRankings() {
    const [accounts, setAccounts] = useState(null);
    const [highlight, setHighlight] = useState(null);

    useEffect(() => {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/airdrop/rankings/users`)
        .then((response) => {
            let rows = response.data;

            for(let i = 0; i < rows.length; i++) {
                rows[i].totalActivity = Number(rows[i].epoch1Activity) + Number(rows[i].epoch2Activity) + Number(rows[i].epoch3Activity);
            }

            rows = rows.sort((a, b) => {
                return b.totalActivity - a.totalActivity;
            });

            for(let i = 0; i < rows.length; i++) {
                if(i < 10) {
                    rows[i].tier = 'Tier 1';
                } else if(i < 50) {
                    rows[i].tier = 'Tier 2';
                } else if(i < 250) {
                    rows[i].tier = 'Tier 3';
                } else if(i < 1000) {
                    rows[i].tier = 'Tier 4';
                } else if(i < 2500) {
                    rows[i].tier = 'Tier 5';
                } else if(i < 10000) {
                    rows[i].tier = 'Tier 6';
                }
            }

            setAccounts(rows);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
			const url = new URL(window.location.href);
			const params = new URLSearchParams(url.search);

			const accountName = params.get('account');

            if(accountName) {
                setHighlight(accountName);

                setTimeout(() => {
                    if(document.getElementById(accountName)) {
                        const section = document.querySelector(`#${accountName}`);
                        section.scrollIntoView({behavior: 'smooth', block: 'center'});
                    }
                }, 500);
            }
        });
    }, []);

    return(
        Array.isArray(accounts) ? (
            <>
                <div>
                    <Header hideAirdrop={true}/>
                </div>
                <div style={{backgroundColor: 'rgb(225, 225, 225)'}}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='py-4 text-center'>
                                    This list is updated once every day
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div
                        className='row px-2 py-3'
                        style={{
                            fontWeight: 'bold',
                            borderBottom: '1px solid black'
                        }}
                    >
                        <div className='col-12 col-md-1 my-1 text-center'>
                            Rank
                        </div>
                        <div className='col-12 col-md-3 my-1 text-center'>
                            TELOS Account
                        </div>
                        <div className='col-12 col-md-2 my-1 text-center'>
                            Activity Tier
                        </div>
                        <div className='col-12 col-md-2 my-1 text-center'>
                            Total Activity
                        </div>
                        <div className='col-12 col-md-4 my-1 text-center'>
                            Account Epoch (First Token Grab)
                        </div>
                    </div>

                    {accounts
                    // .sort((a, b) => {
                    //     return b.totalActivity - a.totalActivity;
                    // })
                    .map((account, index) => {
                        return AccountRow(account, index + 1, account.account === highlight);
                    })}
                </div>
            </>
        ) : (
            <Loading/>
        )
    );
}

function AccountRow(account, index, highlight) {
    return(
        <div
            id={account.account}
            className='row px-2 py-3'
            style={{
                borderBottom: '1px solid black',
                fontWeight: highlight ? 'bold' : 'initial',
                backgroundColor: highlight ? '#fcd902' : 'initial'
            }}
        >
            <div className='col-12 col-md-1 my-1 text-center'>
                #{index}
            </div>
            <div className='col-12 col-md-3 my-1 text-center'>
                {account.account}
            </div>
            <div className='col-12 col-md-2 my-1 text-center'>
                {account.tier}
            </div>
            <div className='col-12 col-md-2 my-1 text-center'>
                {account.totalActivity}
            </div>
            <div className='col-12 col-md-4 my-1 text-center'>
                {account.firstTokenGrab !== '?' ? (
                    <>
                        #{account.firstTokenGrabEpoch} ({account.firstTokenGrab})
                    </>
                ) : (
                    <>
                        ?
                    </>
                )}
            </div>
        </div>
    );
}
