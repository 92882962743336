import React, { useEffect, useState } from 'react';
import QUDOServer from '../../../../../../helpers/QUDOServerConnection';
import moment from 'moment'
import '../bugsLeftColumn.scss';

export default function BugsList(props)
{
    const [bugReports, setBugReports] = useState();
    const [activeSubmenu, setActiveSubmenu] = useState('active');
    const [filteredBugReports, setFilteredBugReports] = useState();
    const [reportChosen, setReportChosen] = useState();

    useEffect(() =>
    {
        request();
    }, []);

    useEffect(() =>
    {
        filterBugs();
    }, [bugReports, activeSubmenu, props.activeFilter]);

    useEffect(() =>
    {
        if (props.update)
        {
            request();
            filterBugs();
        }
    }, [props.update]);

    const request = () =>
    {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/bugReports`, {
            game: props.game.name
        }, {
            withCredentials: true
        })
        .then(resp =>
        {
            setBugReports(resp.data);
            setFilteredBugReports(resp.data);
        })
        .catch(error =>
        {
            setBugReports([]);
        });
    }

    const filterBugs = () =>
    {
        if (bugReports && bugReports.length > 0)
        {
            let tempBugsList = activeSubmenu === 'active' ?
                bugReports.filter(bug => !bug.closed) :
                bugReports.filter(bug => bug.closed);

            //if (tempBugsList !== 'all' && !tempBugsList)
            //{
            //    tempBugsList = tempBugsList.filter(bug => bug.gameVersion === props.activeFilter);
            //}

            if (props.activeFilter !== 'all')
            {
                tempBugsList = tempBugsList.filter(bug => bug.gameVersion === props.activeFilter);
            }

            setFilteredBugReports(tempBugsList);
        }

        props.setUpdate(false);
    }

    const onClickBug = bug =>
    {
        if (reportChosen?._id === bug._id)
        {
            props.setReportChosen(null); 
            setReportChosen(null);
        }
        else
        {
            props.setReportChosen(bug); 
            setReportChosen(bug)
        }
    }

    const getList = () =>
    {
        let listToReturn = (
            <div>
                This game doesn't have any reported issues
            </div>
        )

        if (bugReports && bugReports.length > 0)
        {
            if (filteredBugReports && filteredBugReports.length > 0)
            {
                listToReturn = filteredBugReports.sort((a, b) => {
                    return new Date(b.reportTimestamp).getTime() - new Date(a.reportTimestamp).getTime();
                }).map((bug) =>
                {
                    return (
                        <div className={`border-bottom pb-2 pt-3 row cursor-pointer 
                            ${reportChosen?._id === bug._id ? 'selected' : ''}`}
                            onClick={() => onClickBug(bug)}
                            key={bug._id}>
                            <div className="col-6">
                                <div>{bug.title}</div>
                                <div className="name">{bug.player ? (bug.player.display_name ? bug.player.display_name : bug.player.username) : 'Unknown User'}</div>
                                <div>{bug.category}</div>
                            </div>
                            <div className="col-6 text-right">
                                <div className="date">{moment(bug.reportTimestamp).format('YYYY/MM/DD')}</div>
                                <div className="mt-3">
                                    <span className={`status ${!bug.closed ? 'await' : bug.accepted ? 'accept' : 'reject'}`}>
                                        {!bug.closed ? 'Awaiting' : bug.accepted ? 'Accepted' : 'Rejected'}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                });
            }
            else
            {
                listToReturn = (
                    <div>
                        There are no {activeSubmenu} bug reports!
                    </div>
                )
            }
        }

        return listToReturn;
    }

    return (
        <>
            <div className="filters">
                <div
                    className={`edit-screen-menu${activeSubmenu === 'active' ? "" : "-disabled"}` }
                    style={activeSubmenu === 'active' ? {cursor: 'default'} : {cursor: 'pointer'}} 
                    onClick={() => {
                        setActiveSubmenu('active'); 
                        props.setReportChosen(null); 
                        setReportChosen(null)
                    }}>
                    Active
                </div>
                <div
                    className={`edit-screen-menu${activeSubmenu === 'archived' ? "" : "-disabled"}` }
                    style={activeSubmenu === 'archived' ? {cursor: 'default'} : {cursor: 'pointer'}}
                    onClick={() => {
                        setActiveSubmenu('archived');
                        props.setReportChosen(null); 
                        setReportChosen(null);
                    }}>
                    Archived
                </div>
            </div>
            <div className="col-12 scroll-70-no-scrollbar">
                {getList()}
            </div>
        </>
    )
}