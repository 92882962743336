import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import GuildSettings from './guild/guildSettings';
import GuildStatistics from './guild/guildStatistics';

export default function GuildActivity() {
	const currentUser = useSelector(state => state.info);

    const [selectedPage, setSelectedPage] = useState('management');

    return(
        <div className='row ml-1 responsive w-100'>
            <div className='col-12'>
                <h1 className='custom-h1 mt-3 align-middle ml-2'>
                    <img
                        alt='Guild icon'
                        className='game-icon'
                        src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbyguild?guild=${currentUser.username}&rand=${Date.now()}`}
                    />
                    <span className='align-middle'>{currentUser.display_name ? currentUser.display_name : currentUser.username}</span>         
                </h1>
            </div>
            <div className='col-12 mt-2'>
                <div
                    className={`d-inline-block enable-click mr-2 custom-link ${selectedPage === 'management' ? 'chosen' : ''}`}
                    onClick={() => setSelectedPage('management')}
                >
                    Management
                </div>
                <div
                    className={`d-inline-block enable-click mr-2 custom-link ${selectedPage === 'statistics' ? 'chosen' : ''}`}
                    onClick={() => setSelectedPage('statistics')}
                >
                    Statistics
                </div>
            </div>
            <div className='col-12'>
                {selectedPage === 'statistics' && (
                    <GuildStatistics/>
                )}
                {selectedPage === 'management' && (
                    <GuildSettings/>
                )}
            </div>
        </div>
    );
}
