import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faTrash} from "@fortawesome/free-solid-svg-icons";

import {ClaimButton} from '../common/transactionHistory';
import {MessageModal} from '../components/modals/modals';
import {DropdownInput} from '../components/forms/dropdowns';
import ConfirmModal from '../components/modals/confirmModal/confirmModal';

import useWindowSize from '../../helpers/useWindowSize';
import QUDOServer from '../../helpers/QUDOServerConnection';

const fallBackAvatar = require("../../images/definitive/icon-avatar.png");

const GuildRoles = {
	"owner": "Owner",
	"admin": "Admin",
	"officer": "Officer",
	"recruiter": "Recruiter",
	"member": "Member"
}

const GuildRoleWeight = {
	"owner": 1,
	"admin": 2,
	"officer": 3,
	"recruiter": 4,
	"member": 5
}

const GuildRoleChoices = Object.keys(GuildRoles).map(e => {
    return {
        key: e,
        label: GuildRoles[e]
    }
});

const GuildRoleFilters = Object.keys(GuildRoles).map(e => {
    return {
        key: e,
        label: GuildRoles[e]
    }
});

GuildRoleFilters.unshift({
    key: '',
    label: 'Any'
});

const GuildSortByOptions = [
    {key: 'name', label: 'Name'},
    {key: 'role', label: 'Role'},
    {key: 'date', label: 'Join Date'}
];

export default function GuildSettings() {
	const windowSize = useWindowSize();

    const renderMembersJoinedDate = windowSize.width > 991;

    const balance = useSelector(state => state.balance);
    const currentUser = useSelector(state => state.info);
	//const pendingReward = useSelector(state => state.pendingReward);

    const [guildInfo, setGuildInfo] = useState(null);
    const [maxMembers, setMaxMembers] = useState('?');
    const [referralLink, setReferralLink] = useState('');
    const [guildMembers, setGuildMembers] = useState([]);

    const [topGuilds, setTopGuilds] = useState([]);
    const [activeChallenges, setActiveChallenges] = useState([]);

    const [copied, setCopied] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [renderMembers, setRenderMembers] = useState(false);
    const [requestLoading, setRequestLoading] = useState(false);

    const [kickUsername, setKickUsername] = useState('');

    const [pendingUsername, setPendingUsername] = useState('');
    const [acceptPendingRequest, setAcceptPendingRequest] = useState(false);
    const [guildFeedback, setGuildFeedback] = useState('');

    const [searchTermMembers, setSearchTermMembers] = useState('');
    const [filterMembers, setFilterMembers] = useState(GuildRoleFilters[0].key);
    const [sortByMembers, setSortByMembers] = useState(GuildSortByOptions[1].key);
    const [searchTermPendingRequests, setSearchTermPendingRequests] = useState('');

    useEffect(() => {
        if(currentUser) {
            getGuildInfo();
            getTopGuilds();
            getActiveChallenges();
        }
    }, []);

    useEffect(() => {
        if(
            balance
            && balance.stake
            && guildInfo
            && !isNaN(guildInfo.free_members)
            && !isNaN(guildInfo.stake_per_member)
            && !isNaN(guildInfo.initial_minimum_stake)
        ) {
            const currentStake = Number(String(balance.stake).split(' ')[0]);
            //const minimumStake = Number(String(balance.minimumStake).split(' ')[0]);

            let maxMemberNr = 0;

            if(currentStake >= Number(guildInfo.initial_minimum_stake)) {
                maxMemberNr += Number(guildInfo.free_members);
            }

            const availableStakeForExtraMembers = currentStake - Number(guildInfo.initial_minimum_stake);

            if(availableStakeForExtraMembers > 0) {
                maxMemberNr += Math.trunc(availableStakeForExtraMembers / Number(guildInfo.stake_per_member));
            }

            setMaxMembers(maxMemberNr);
        }
    }, [balance, guildInfo]);

    useEffect(() => {
        orderGuildMembers(guildMembers);
    }, [sortByMembers]);

	function CopyToClipboard(value) {
		var tempInput = document.createElement("input");
		tempInput.value = value;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand("copy");
		document.body.removeChild(tempInput);

		setCopied(true);
		setTimeout(() => setCopied(false), 3000);
	}

    function getActiveChallenges() {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/guilds/challenges`)
        .then(response => {
            if(Array.isArray(response.data)) {
                setActiveChallenges(response.data);
            }
        })
        .catch(error => {
            //console.log(error);
        });
    }

    function getTopGuilds() {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/top/guilds`)
        .then(response => {
            if(Array.isArray(response.data)) {
                setTopGuilds(response.data);
            }
        })
        .catch(error => {
            //console.log(error);
        });
    }

    function getGuildInfo() {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/guild?guild=${currentUser.username}`, {withCredentials: true})
        .then(response => {
            setGuildInfo(response.data);
            setReferralLink(`https://games.qudo.io?register=true&referral=${response.data.username}`);

            orderGuildMembers(response.data.members);
        })
        .catch(error => {
            //console.log(error);
        });
    }

    function kickMember(username) {
        setRequestLoading(true);

		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/guild/manage/user/kick`, {
            username: username
		}, {
			withCredentials: true
		})
        .then(response => {
            getGuildInfo();
            setModalMessage('Success');
        })
        .catch(error => {
            setModalMessage(error.errorMessage);
        })
        .finally(() => {
            setKickUsername('');
            setRequestLoading(false);
        });
    }

    function handlePendingRequest(username, accept, feedback) {
        setRequestLoading(true);

		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/guild/manage/user/request`, {
            accept: accept,
            username: username,
            feedback: feedback ? feedback : ''
		}, {
			withCredentials: true
		})
        .then(response => {
            getGuildInfo();
            setModalMessage('Success');
        })
        .catch(error => {
            setModalMessage(error.errorMessage);
        })
        .finally(() => {
            setPendingUsername('');
            setRequestLoading(false);
        });
    }

    function changeUserRole(username, role) {
		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/guild/manage/user/role`, {
            username: username,
            role: role
		}, {
			withCredentials: true
		})
        .then(response => {
            //getGuildInfo();

            let guildMembersCopy = JSON.parse(JSON.stringify(guildMembers));

            for(let i = 0; i < guildMembersCopy.length; i++) {
                const member = guildMembersCopy[i];

                if(member.user && member.user.username === username) {
                    member.role = role;
                }
            }

            orderGuildMembers(guildMembersCopy);
        })
        .catch(error => {
            //console.log(error);
        });
    }

    function orderGuildMembers(members) {
        if(!Array.isArray(members)) {
            return;
        }

        if(sortByMembers !== 'name' && sortByMembers !== 'role' && sortByMembers !== 'date') {
            return;
        }

        setRenderMembers(false);

        let orderedGuildMembers = [];

        if(sortByMembers === 'name') {
            orderedGuildMembers = members.sort((a, b) => {
                const a_name = a.user.display_name ? a.user.display_name : a.user.username;
                const b_name = b.user.display_name ? b.user.display_name : b.user.username;

                return String(a_name).localeCompare(String(b_name));
            });
        } else if(sortByMembers === 'role') {
            orderedGuildMembers = members.sort((a, b) => {
                let a_weight = GuildRoleWeight[a.role];
                let b_weight = GuildRoleWeight[b.role];

                if(isNaN(a_weight)) {
                    a_weight = 1000;
                }

                if(isNaN(b_weight)) {
                    b_weight = 1000;
                }

                return a_weight - b_weight;
            });
        } else if(sortByMembers === 'date') {
            orderedGuildMembers = members.sort((a, b) => {
                return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
            });
        }

        setGuildMembers(orderedGuildMembers);

        setTimeout(() => {
            setRenderMembers(true);
        }, 250);
    }

    return(
        guildInfo ? (
            <div>
                {/*}
                <div id='title-box' className='pl-3 text-left'>
                    <div id='title'>
                        Guild Management
                    </div>
                </div>
                {*/}
                <div className='mx-3 mt-3 mb-5'>
                    <div className='row'>
                        <div className='col-12 col-md-5 my-2'>
                            <div className='row'>
                                <div className='col-12'>
                                    <img
                                        alt='Guild avatar'
                                        src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${guildInfo.imageID}&type=${guildInfo.type}`}
                                        //src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${guildInfo.imageID}&type=${guildInfo.type}&rand=${Date.now()}`}
                                        className='img-fluid'
                                        style={{maxWidth: '300px'}}
                                    />
                                </div>
                                <div className='col-12 mt-4'>
                                    <h5>{guildInfo.display_name ? guildInfo.display_name : guildInfo.username}</h5>
                                </div>
                                <div className='col-12'>
                                    <a
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href={guildInfo.website}
                                        style={{color: 'black'}}
                                    >
                                        {guildInfo.website}
                                    </a>
                                </div>
                                <div className='col-12 mt-2'>
                                    {guildInfo.description}
                                </div>
                                <div className='row col-12 mx-auto mt-3'>
                                    <div
                                        data-tip
                                        data-for='referralLink'
                                        className="col-12 edit-field-yellow"
                                        onClick={() => {
                                            CopyToClipboard(referralLink);
                                        }}
                                    >
                                        <div className="edit-field-title">
                                            Referral Link
                                        </div>
                                        <div style={{color: "#656565", whiteSpace: "nowrap", overflow: "hidden"}}>
                                            {referralLink}
                                        </div>
                                        <ReactTooltip id='referralLink' type='light'>
                                            <span>
                                                {copied ? 'Copied' : 'Click to copy to clipboard'}
                                            </span>
                                        </ReactTooltip>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className='col-12 col-md-7 p-4 my-2'
                            style={{backgroundColor: Array.isArray(topGuilds) && topGuilds.length ? '#282725' : ''}}
                        >
                            {Array.isArray(topGuilds) && topGuilds.length > 0 && (
                                <>
                                <div
                                    className='mb-4'
                                    style={{color: '#fcd902'}}
                                >
                                    <h4>Top Gaming Guilds</h4>
                                </div>
                                <div>
                                    {topGuilds.slice(0, 5).map((i, index) => {
                                        return(
                                            <div
                                                key={index}
                                                className='px-4 py-2 mb-2'
                                                style={{
                                                    backgroundColor: index < 2 ? '#fcd902' : '#ffffff',
                                                    borderRadius: '10px'
                                                }}
                                            >
                                                <div className='row'>
                                                    <div className='col-2'>
                                                        {i.imageID ? (
                                                            <img
                                                                alt="User avatar"
                                                                src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${i.imageID}&type=${i.type}`}
                                                                className="sh-avatar rounded-circle"
                                                                onError={i=> i.target.src = fallBackAvatar}
                                                            />
                                                        ) : (
                                                            <img
                                                                alt="User avatar"
                                                                //src={fallBackAvatar}
                                                                src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=0&type=user`}
                                                                className="sh-avatar rounded-circle"
                                                                onError={i=> i.target.src = fallBackAvatar}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className='col-10 my-auto'>
                                                        <div className='row'>
                                                            <div
                                                                className='col-2 align-self-center text-left'
                                                                style={{fontSize: '1.25rem', fontWeight: 'bold'}}
                                                            >
                                                                #{index+1}
                                                            </div>
                                                            <div
                                                                className='col-10 align-self-center text-right'
                                                                style={{fontWeight: 'bold'}}
                                                            >
                                                                {i.username ? (i.display_name ? i.display_name : i.username) : i.account_name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                </>
                            )}
                        </div>
                    </div>

                    {Array.isArray(activeChallenges) && activeChallenges.length > 0 && (
                        <div className='row my-4'>
                            <div className='col-12'>
                                <h5
                                    className='py-3 text-center'
                                    style={{
                                        color: '#fcd902',
                                        backgroundColor: '#282725'
                                    }}
                                >
                                    Active Challenges
                                </h5>
                            </div>
                            {activeChallenges.map(e => {
                                return(
                                    <div className='col-12 col-md-6 col-xl-4 my-2'>
                                        <div className='card roundcard shadow'>
                                            <div className='card-body enable-click'>
                                                <div>
                                                    <h5>{e.title}</h5>
                                                </div>
                                                <div>
                                                    {e.description}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    <div className='row'>
                        <div className='col-12 col-xl-5 my-2'>
                            <div className='mb-4'>
                                <h5 className='mb-2'>
                                    Guild Perks
                                </h5>
                                <div className='my-2'>
                                    <div>
                                        <div
                                            className='d-inline-block'
                                            style={{fontSize: '2rem', color: '#00cc69'}}
                                        >
                                            {Array.isArray(guildMembers) ? guildMembers.length : '?'}
                                        </div>
                                        <div
                                            className='d-inline-block px-1'
                                            style={{fontSize: '2rem', color: '#676766'}}
                                        >
                                            /
                                        </div>
                                        <div
                                            className='d-inline-block'
                                            style={{fontSize: '2rem', color: '#676766'}}
                                        >
                                            {maxMembers}
                                        </div>
                                    </div>
                                    <div style={{color: '#676766'}}>
                                        Total Members
                                    </div>
                                </div>
                                {/*
                                pendingReward && (
                                    <div className='my-1'>
                                        <div
                                            className='d-inline-block'
                                            style={{fontSize: '2rem', color: '#fcd902'}}
                                        >
                                            {String(pendingReward).toLowerCase()}
                                        </div>
                                        <div style={{color: '#676766'}}>
                                            Total Rewards
                                        </div>
                                    </div>
                                )
                                */}
                                <div
                                    className='mt-3'
                                    style={{maxWidth: '500px'}}
                                >
                                    <ClaimButton/>
                                </div>
                            </div>

                            <div>
                                <h5>
                                    Pending Requests
                                    {Array.isArray(guildInfo.pendingRequests) && guildInfo.pendingRequests.length > 0 && (
                                        ` (${guildInfo.pendingRequests.length})`
                                    )}
                                </h5>
                                <div className='my-2'>
                                    <input
                                        className="w-100 px-2 py-3 send-to-input-box"
                                        placeholder='Search...'
                                        value={searchTermPendingRequests}
                                        onChange={(e) => {
                                            setSearchTermPendingRequests(e.target.value);
                                        }}
                                    />
                                </div>
                                <div
                                    className='with-scroll'
                                    style={{maxHeight: '450px', overflowX: 'hidden'}}
                                >
                                    {Array.isArray(guildInfo.pendingRequests) && guildInfo.pendingRequests.length > 0 ? guildInfo.pendingRequests.map((e, index) => {
                                        const search = String(searchTermPendingRequests ? searchTermPendingRequests : '').toLowerCase();

                                        if (!e.user) {
                                            return null;
                                        }

                                        if(
                                            !String(e.user.username).toLowerCase().includes(search)
                                            && !String(e.user.display_name).toLowerCase().includes(search)
                                            // && !String(e.user.account_name).toLowerCase().includes(search)
                                        ) {
                                            return null;
                                        }

                                        return(
                                            <div
                                                key={index}
                                                className='row p-3'
                                                style={{
                                                    backgroundColor: index % 2 ? '#fafbfb' : '#ecedec'
                                                }}
                                            >
                                                <div className='col-2 text-center'>
                                                    <img
                                                        alt='Pending request member avatar'
                                                        src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${e.user.imageID}&type=${e.user.type}`}
                                                        style={{height: "2.5rem", width: '2.5rem'}}
                                                    />
                                                </div>
                                                <div className='col-7 align-self-center'>
                                                    <div>
                                                        {e.user.display_name ? e.user.display_name : e.user.username}
                                                    </div>
                                                    {!isNaN(e.hoursPlayed) && (
                                                        <div
                                                            style={{
                                                                color: '#676766',
                                                                fontSize: '0.8rem'
                                                            }}
                                                        >
                                                            {Number(e.hoursPlayed).toFixed(1)} hours played last week
                                                        </div>
                                                    )}
                                                </div>
                                                <div
                                                    className='col-1 align-self-center enable-click text-center'
                                                    onClick={() => {
                                                        setAcceptPendingRequest(true);
                                                        setPendingUsername(e.user.username);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faCheck}/>
                                                </div>
                                                <div
                                                    className='col-1 align-self-center enable-click text-center'
                                                    onClick={() => {
                                                        setAcceptPendingRequest(false);
                                                        setPendingUsername(e.user.username);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </div>
                                            </div>
                                        )
                                    }) : (
                                        <div className='my-3'>
                                            This Gaming Guild doesn't have any pending requests
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-xl-7 my-2'>
                            <h5>
                                Members
                                {Array.isArray(guildMembers) && guildMembers.length > 0 && (
                                    ` (${guildMembers.length})`
                                )}
                            </h5>
                            <div className='my-2'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <input
                                            className="w-100 px-2 py-3 send-to-input-box"
                                            placeholder='Search...'
                                            value={searchTermMembers}
                                            onChange={(e) => {
                                                setSearchTermMembers(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 mt-2'>
                                        <DropdownInput
                                            label='Role'
                                            choices={GuildRoleFilters}
                                            initial={GuildRoleFilters[0].label}
                                            set={(value) => setFilterMembers(value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 mt-2'>
                                        <DropdownInput
                                            label='Sort by'
                                            choices={GuildSortByOptions}
                                            initial={GuildSortByOptions[1].label}
                                            set={(value) => setSortByMembers(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className='with-scroll'
                                style={{maxHeight: '450px', overflowX: 'hidden'}}
                            >
                                {renderMembers && Array.isArray(guildMembers) && guildMembers.length > 0 ? guildMembers.map((e, index) => {
                                    if (!e.user) {
                                        return null;
                                    }
                                    
                                    const search = String(searchTermMembers ? searchTermMembers : '').toLowerCase();

                                    if(
                                        search
                                        && !String(e.user.username).toLowerCase().includes(search)
                                        && !String(e.user.display_name).toLowerCase().includes(search)
                                        // && !String(e.user.account_name).toLowerCase().includes(search)
                                    ) {
                                        return null;
                                    }

                                    if(
                                        filterMembers
                                        && filterMembers !== e.role
                                    ) {
                                        return null;
                                    }

                                    return(
                                        <div
                                            key={index}
                                            className='row px-3'
                                            style={{
                                                backgroundColor: index % 2 ? '#fafbfb' : '#ecedec'
                                            }}
                                        >
                                            <div className='col-2 col-xl-1 align-self-center text-center'>
                                                <img
                                                    alt='Member avatar'
                                                    src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${e.user.imageID}&type=${e.user.type}`}
                                                    style={{height: "2.5rem", width: '2.5rem'}}
                                                />
                                            </div>
                                            <div className={`col-4 align-self-center`}>
                                                {e.user.display_name ? e.user.display_name : e.user.username}
                                            </div>
                                            <div className={`${renderMembersJoinedDate ? 'col-2 col-xl-3' : 'col-4'} align-self-center mt-3`}>
                                                <DropdownInput
                                                    label='Role'
                                                    initial={GuildRoles[e.role]}
                                                    choices={GuildRoleChoices}
                                                    set={(value) => changeUserRole(e.user.username, value)}
                                                />
                                            </div>
                                            {renderMembersJoinedDate && (
                                                <div className='col-3 align-self-center text-center'>
                                                    {moment(e.timestamp).format("DD-MM-YYYY")}
                                                </div>
                                            )}
                                            <div
                                                className='col-1 align-self-center enable-click text-center'
                                                onClick={() => setKickUsername(e.user.username)}
                                            >
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </div>
                                        </div>
                                    )
                                }) : (
                                    <div className='my-3'>
                                        This Gaming Guild doesn't have any members
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <ConfirmModal
                    show={!!kickUsername}
                    loading={requestLoading}
                    hide={() => setKickUsername('')}
                    confirm={() => kickMember(kickUsername)}
                    message={`Are you sure you want to kick this user from the guild?`}
                />

                <ConfirmModal
                    show={!!pendingUsername}
                    loading={requestLoading}
                    hide={() => setPendingUsername('')}
                    confirm={() => handlePendingRequest(pendingUsername, acceptPendingRequest, guildFeedback)}
                    message={`Are you sure you want to ${acceptPendingRequest ? 'accept' : 'reject'} this user's request to join the guild?`}
                    feedback={guildFeedback}
                    setFeedback={setGuildFeedback}
                    feedbackPlaceholder={acceptPendingRequest ? 'Welcome message (optional)' : 'Reason for rejection (optional)'}
                />

                <MessageModal
                    show={!!modalMessage} 
                    message={modalMessage}
                    hide={() => setModalMessage('')}
                />
            </div>
        ) : (
            <></>
        )
    );
}
