import React, {useState, useEffect} from 'react';
import QUDOServer from '../../../../../helpers/QUDOServerConnection';
import BugsPerVersion from './bugsPerVersion/bugsPerVersion';
import BugsReported from './bugsReported/bugsReported';
import SpendingsPerVersion from './spendingsPerVersion/spendingsPerVersion';
import ValidInvalidBugs from './validInvalidBugs/validInvalidBugs';

export default function BugsRightColumn(props)
{
    const [bugReports, setBugReports] = useState();

    useEffect(() =>
    {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/bugReports`, {
            game: props.game.name
        }, {
            withCredentials: true
        })
        .then(resp =>
        {
            setBugReports(resp.data)
        })
        .catch(error =>
        {
            setBugReports([]);
        })
    }, []);

    return (
        <div>
            <ValidInvalidBugs
                bugReports={bugReports}
            />
            <BugsPerVersion
                bugReports={bugReports}
            />
            <BugsReported
                bugReports={bugReports}
            />
            <SpendingsPerVersion
                bugReports={bugReports}
            />
        </div>
    )
};