import React, {useState, useEffect} from 'react';
import QUDOServer from "../../../../helpers/QUDOServerConnection";
import classes from './gameGalleryImage.scss';

const fallBackAvatar = require("../../../../images/definitive/icon-avatar.png");

export default function GameGalleryImages(props)
{
    const [images, setImages] = useState();
    const [items, setItems] = useState([]);
    const [indicators, setIndicators] = useState();
    const [activeImages, setActiveImages] = useState(0);
    const [imagesToShow, setImagesToShow] = useState([])
    
    useEffect(() => 
    {
        getImages();
    }, []);

    useEffect(() =>
    {
        availableImages();
    }, [images]);

    useEffect(() =>
    {
        getIndicators();
        getImagesToShow();
    }, [items]);

    useEffect(() =>
    {
        getIndicators();
        getImagesToShow();
    }, [activeImages]);

    function getImages() 
    {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/gameinfo?gamename=${props.game}`)
        .then(async (response) => 
        {
            setImages(response.data.media_images);
        });
    }

    const availableImages = () => 
    {
        const tempItems = [];
    
        if(images && Object.keys(images).length > 0) 
        {
            for(let image in images) 
            {
                tempItems.push(
                    <div
                        key={images[image].imageID}
                        className="mb-3 col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center"
                    >
                        <img
                            key={image.imageID}
                            alt="Game artwork/screenshot"
                            src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?type=game&imageID=${images[image].imageID}`}
                            onError={(i) => (i.target.src = fallBackAvatar)}
                            className="img-fluid"
                            style={{maxHeight: "75px"}}
                        />
                    </div>
                );
            }
    
          setItems(tempItems);
        } else {
          setItems(
            <div>
              There are no Game images available for this game!
            </div>
          );
        }
    }

    const getIndicators = () =>
    {
        if (items.length <= 6)
        {
            setIndicators(null);
        }

        if (items.length >= 7 && items.length <= 12)
        {
            const tempIndicators = (
                <div className="col-12 row indicator d-flex justify-content-center">         
                    <div className={activeImages === 0 ? 'active' : ''}
                        id="circle" 
                        onClick={() => setActiveImages(0)}>   
                    </div>   
                    <div className={activeImages === 1 ? 'active' : ''}
                        id="circle" 
                        onClick={() => setActiveImages(1)}>   
                    </div>  
                </div>
            );

            setIndicators(tempIndicators);
        }

        if (items.length > 12)
        {
            const tempIndicators = (
                <div className="col-12 row d-flex justify-content-center">
                    <div className="indicator" onClick={() => setActiveImages(0)}>   
                        <figure className={activeImages === 0 ? 'circle active' : 'circle'}></figure>
                    </div>         
                    <div className="indicator" onClick={setActiveImages(1)}>   
                        <figure className={activeImages === 1 ? 'circle active' : 'circle'}></figure>
                    </div>   
                    <div className="indicator" onClick={setActiveImages(2)}>   
                        <figure className={activeImages === 2 ? 'circle active' : 'circle'}></figure>
                    </div>   
                </div>
            );

            setIndicators(tempIndicators);
        }
    }

    const getImagesToShow = () =>
    {
        if (items.length > 0)
        {
            if (activeImages === 0)
            {
                setImagesToShow(items.slice(0, 6));
            }
            else if (activeImages === 1)
            {
                setImagesToShow(items.slice(6, 12));
            }
            else
            {
                setImagesToShow(items.slice(12));
            }
        }
    }

    return (
        <div>
            <div className="row mt-2 w-100 pl-0 ml-0 pr-0 mr-0">
                {imagesToShow}
            </div>
            <div >
                    {indicators}
            </div> 
        </div>
    );
}