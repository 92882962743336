import React, {useEffect, useState} from 'react';
import Highcharts, { format } from 'highcharts';
import moment from 'moment';

export default function ExpensesChart(props)
{
    const [activeFilter, setActiveFilter] = useState('1d');
    const allRewards = props.allRewards;
    const HIGHSCORE = 'highscore';
    const ACHIEVEMENT = 'achievement';
    const CROWD_TESTING = 'crowd testing';

    const daysInterval = 15;
    const weeksInterval = 15;
    const monthsInterval = 12;

    useEffect(() =>
    {
        if (allRewards)
        {
            chart();
        }
    }, [allRewards]);

    useEffect(() =>
    {
        if (allRewards)
        {
            chart();
        }
    }, [activeFilter]);


    const generateDates = () =>
    {   
        let startDate = new Date();
        let endDate = new Date();

        if (activeFilter === '1d')
        {
            startDate.setDate(startDate.getDate() - daysInterval);
        }

        if (activeFilter === '1w')
        {
            startDate.setDate(startDate.getDate() - (7 * weeksInterval));
        }

        if (activeFilter === '1m')
        {
            startDate.setMonth(startDate.getMonth() - monthsInterval);
        }

        const dateArray = [];
        let currentDate = moment(startDate);
        const stopDate = moment(endDate);

        while (currentDate <= stopDate) 
        {
            if (activeFilter === '1d' || activeFilter === '1w')
            {
                dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
                currentDate = moment(currentDate).add(
                    activeFilter === '1d' ? 1 : 7, 'days');
            }
            else
            {
                dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
                currentDate = moment(currentDate).add(1, 'months');
            }
        }
        return dateArray;
    }

    const setupValues = dates =>
    {
        let values = [[],[],[]];

        if (allRewards)
        {
            const allHighscores = allRewards.filter(reward => reward.type === HIGHSCORE);
            const allAchievements = allRewards.filter(reward => reward.type === ACHIEVEMENT);
            const allCrowdTesting = allRewards.filter(reward => reward.type === CROWD_TESTING);

            dates.forEach((date) =>
            {
                let achievementsDateArr = [];
                let highscoresDateArr = [];
                let crowdTestingDateArr = [];
                let achievementToAdd = 0;
                let highscoreToAdd = 0;
                let crowdTestingToAdd = 0;
    
                achievementsDateArr = allAchievements.filter(achievement => moment(new Date(achievement.timestamp)).format('YYYY-MM-DD') === date);
                highscoresDateArr = allHighscores.filter(highscore => moment(new Date(highscore.time)).format('YYYY-MM-DD') === date);

                if (activeFilter === '1w')
                {
                    const daysInAWeek = 7;
    
                    let dateInUse = new Date(date);
                    
                    for (let i = 0; i < daysInAWeek; i++)
                    {
                        dateInUse.setDate(dateInUse.getDate() - 1);
                        
                        const tempAchievementsDateArr = allAchievements.filter(reward =>
                            moment(new Date(reward.timestamp)).format('YYYY-MM-DD') === moment(dateInUse).format('YYYY-MM-DD'));
                    
                        if (tempAchievementsDateArr.length > 0)
                        {
                            tempAchievementsDateArr.forEach(achievement => achievementsDateArr.push(achievement));
                        }
        
                        const tempHighscoresDateArr = allHighscores.filter(highscore =>
                            moment(new Date(highscore.timestamp)).format('YYYY-MM-DD') === moment(dateInUse).format('YYYY-MM-DD'));
                    
                        if (tempHighscoresDateArr.length > 0)
                        {
                            tempHighscoresDateArr.forEach(highscore => highscoresDateArr.push(highscore));
                        }
                        
                        const tempCrowdTestingDateArr = allCrowdTesting.filter(crowdTesting =>
                            moment(new Date(crowdTesting.timestamp)).format('YYYY-MM-DD') === moment(dateInUse).format('YYYY-MM-DD'));
                    
                        if (tempCrowdTestingDateArr.length > 0)
                        {
                            tempCrowdTestingDateArr.forEach(crowdTesting => crowdTestingDateArr.push(crowdTesting));
                        }
                    }
                }
    
                if (activeFilter === '1m')
                {
                    let dateInUse = new Date(date);

                    const tempAchievementsDateArr = allAchievements.filter(achievement =>
                        moment(new Date(achievement.timestamp)).format('YYYY-MM') === moment(dateInUse).format('YYYY-MM'));

                    if (tempAchievementsDateArr.length > 0)
                    {
                        tempAchievementsDateArr.forEach(achievement => achievementsDateArr.push(achievement));
                    }

                    const tempHighscoresDateArr = allHighscores.filter(highscore =>
                        moment(new Date(highscore.timestamp)).format('YYYY-MM') === moment(dateInUse).format('YYYY-MM'));

                    if (tempHighscoresDateArr.length > 0)
                    {
                        tempHighscoresDateArr.forEach(highscore => highscoresDateArr.push(highscore));
                    }

                    const tempCrowdTestingDateArr = allCrowdTesting.filter(crowdTest =>
                        moment(new Date(crowdTest.timestamp)).format('YYYY-MM') === moment(dateInUse).format('YYYY-MM'));

                    if (tempCrowdTestingDateArr.length > 0)
                    {
                        tempCrowdTestingDateArr.forEach(crowdTest => crowdTestingDateArr.push(crowdTest));
                    }
                }
    
                if (achievementsDateArr?.length >= 1)
                {
                    achievementsDateArr.forEach(reward =>
                    {
                        achievementToAdd += reward.amount;
                    });
                }

                if (highscoresDateArr?.length >= 1)
                {
                    highscoresDateArr.forEach(highscore =>
                    {
                        highscoreToAdd += highscore.amount;
                    });
                }

                if (crowdTestingDateArr?.length >= 1)
                {
                    crowdTestingDateArr.forEach(crowdTesting =>
                    {
                        crowdTestingToAdd += crowdTesting.amount;
                    });
                }
    
                values[0].push(Number(highscoreToAdd.toFixed(2)));
                values[1].push(Number(achievementToAdd.toFixed(2)));
            });
        }

        return values;
    }

    const chart = () => 
    {
		const categories = [];

        const dateGenerated = generateDates();
        const [highscoresValues, achievementsValues, crowdTestingValues] = setupValues(dateGenerated);

		dateGenerated.forEach((i) => 
        {
            if (activeFilter === '1m')
            {
                categories.push(moment(i).format('MMM YY'));  
            }
            else
            {
                categories.push(moment(i).format('DD MMM'));
            }
		});

		return Highcharts.chart('expenses', {
			chart: {
				type: "column",
				backgroundColor: "transparent",
				height: "300rem",
			},
			title: {
				text: "",
			},
			subtitle: {
				align: "right",
				verticalAlign: "bottom",
			},
			legend: {
				enabled: false,
			},
			xAxis: {
                categories: [...categories],
			},
            credits: {
                enabled: false
            },
			plotOptions: {
				area: {
					fillOpacity: 0.5,
					fillColor: {
						linearGradient: [0, 0, 0, 300],
						stops: [
							[0, "#dedede"],
							[1, "#ffffff"],
						],
					},
				}
			},
            legend: {
                align: 'center',
                enabled: true
            },
			series: [
				{
					name: 'Highscores',
					data: [...highscoresValues],
                    color: '#c2a90c'
				},
                {
					name: 'Achievements',
					data: [...achievementsValues],
                    color: '#fcd902'
				},
                {
					name: 'Crowd Testing',
					data: [...crowdTestingValues],
                    color: '#7a6d1c'
				},
			]
		});
	};

    return (
        <>
            <div className="borders">
                <div className="mb-3">
                    <div className="col-12 edit-screen-stakes-sm header remove-margin">
                        <strong>Expenses</strong>
                    </div>
                </div>
                <div className="col-12 edit-screen-stakes-sm">
                    <span className={`submenus  mr-2 cursor-pointer ${activeFilter === '1m' ? '' : 'not-active'}`}
                        onClick={() => setActiveFilter('1m')}>
                        1m |
                    </span>
                    <span className={`submenus mr-2 cursor-pointer ${activeFilter === '1w' ? '' : 'not-active'}`}
                        onClick={() => setActiveFilter('1w')}>
                        1w |
                    </span>
                    <span className={`submenus cursor-pointer ${activeFilter === '1d' ? '' : 'not-active'}`}
                        onClick={() => setActiveFilter('1d')}>
                        1d
                    </span>
                </div>
                <div className="col-12 text-right not-active">
                    QUDO
                </div>
                <div id="expenses"></div>
            </div>
        </>
    )
}