import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import moment from 'moment';

import QUDOServer from '../../../../../../helpers/QUDOServerConnection';

import {NumberedRange} from '../../../../../components/forms/range';
import {ButtonInput} from '../../../../../components/forms/buttons';
import ConfirmModal from '../../../../../components/modals/confirmModal/confirmModal';
import {MessageModal} from '../../../../../components/modals/modals';
import CloseBugModal from '../../../../../components/modals/closeBugModal/closeBugModal.js.js';
import ChatMessagesModal from '../../../../../components/modals/chatMessagesModal/chatMessagesModal';

const fallBack = require('../../../../../../images/fallbackAvatar.png');

export default function BugReport(props)
{
    const [reward, setReward] = useState();
    const [accepted, setAccepted] = useState();
    const [gamedevFeedback, setGamedevFeedback] = useState("");
    //const [confirmModal, setConfirmModal] = useState(false);
    const [closeBugModal, setCloseBugModal] = useState(false);
    //const [successModal, setSuccessModal] = useState(false);
    //const [failureModal, setFailureModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [showMessages, setShowMessages] = useState(false);
    const [selectedImageID, setSelectedImageID] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() =>
    {
        getColumn();
        setReward(props.reportChosen ? props.reportChosen.minReward : 0);
    }, [props.reportChosen]);

    const submitReport = event =>
    {
        setLoading(true);

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/bugreports/close`, {
            reportID: props.reportChosen._id,
            accept: accepted,
            reward: accepted ? reward : 0,
            feedback: gamedevFeedback ? gamedevFeedback : ''
        }, {
            withCredentials: true
        })
        .then(result =>
        {
            //setSuccessModal(true);
            setModalMessage(`Bug status changed to ${accepted ? 'Accepted' : 'Rejected'} with success`);
            props.setReportChosen(null);
        })
        .catch(err =>
        {
            //setFailureModal(true);
            setModalMessage(err.errorMessage);
        })
        .finally(() => {
            setLoading(false);
            //resetProperties();
            props.setUpdate(true);
            setCloseBugModal(false);
        });
    }

    /*
    const resetProperties = () =>
    {
        setReward(0);
        setAccepted(false);
        setGamedevFeedback('');
    }
    */

    const getColumn = () =>
    {
        return props.reportChosen ?
        (
            <>
            <div className="borders">
                <div className="col-12 pt-4">
                    <div className="d-flex justify-content-between">
                        <div>{props.reportChosen.title}</div>
                        <div className="date pt-1">{moment(props.reportChosen.reportTimestamp).format('YYYY/MM/DD')}</div>
                    </div>
                </div>
                <div className="mt-4 col-12">
                    <div>Player Name</div>
                    <div className="report-data">
                        {
                            props.reportChosen.player ? (
                                props.reportChosen.player.display_name
                                ? props.reportChosen.player.display_name
                                : props.reportChosen.player.username
                            ) : (
                                'Unknown User'
                            )
                        }
                    </div>
                </div>
                <div className="mt-3 col-12">
                    <div>Bug Category</div>
                    <div className="report-data">{props.reportChosen.category}</div>
                </div>
                <div className="mt-3 col-12">
                    <div>Description</div>
                    <div className="report-data">{props.reportChosen.description}</div>
                </div>
                {props.reportChosen.platform && (
                    <div className="mt-3 col-12">
                        <div>Platform</div>
                        <div className="report-data">{props.reportChosen.platform}</div>
                    </div>
                )}
                {props.reportChosen.gameVersion && (
                    <div className="mt-3 col-12">
                        <div>Game Version</div>
                        <div className="report-data">{props.reportChosen.gameVersion}</div>
                    </div>
                )}
                {props.reportChosen.sdkVersion && (
                    <div className="mt-3 col-12">
                        <div>QUDO SDK Version</div>
                        <div className="report-data">{props.reportChosen.sdkVersion}</div>
                    </div>
                )}
                <div className="mt-3 col-12">
                    <div>Screenshot</div>
                    {props.reportChosen.imageID ? (
                        <div className="w-100 d-flex justify-content-center mt-2">
                            <img
                                alt="bug screenshot"
                                src={`${process.env.REACT_APP_QUDO_SERVER}/api/screenshots/getbyid?imageID=${props.reportChosen.imageID}`}
                                onError={(i) => (i.target.src = fallBack)}
                                className="enable-click"
                                style={{minWidth: '80%', maxWidth: '90%'}}
                                onClick={() => {
                                    setSelectedImageID(props.reportChosen.imageID);
                                }}
                            />
                        </div>
                    ) : (
                        <div className="report-data">No screenshot available</div>
                    )}
                </div>
                <div className="col-12 mt-3">
                    <ButtonInput
                        trigger
                        label={"Messages"}
                        set={setShowMessages}
                        style={{margin: 'auto', maxWidth: '175px', backgroundColor: '#fbd731'}}
                    />
                </div>
                {!props.reportChosen.closed ? (
                    <>
                        <div className="mt-4 col-12">
                        <div className="mb-2">Reward</div>
                        <div className="d-flex justify-content-between">
                            <div>
                                <div className="rewards numbers">{props.reportChosen.minReward}</div>
                                <div className="rewards type">Min Reward</div>
                            </div>
                            <div>
                                <div className="rewards numbers">{props.reportChosen.maxReward}</div>
                                <div className="rewards type">Max Reward</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <NumberedRange 
                            min={props.reportChosen.minReward}
                            max={props.reportChosen.maxReward}
                            unit="QUDO"
                            classes="yellow-qudo"
                            numberClass="big-number"
                            step={0.01}
                            initial={reward}
                            value={reward}
                            setValue={setReward}
                        />
                    </div>
                    <div className="row">
                        <div className="col-6 pr-0 mr-0">
                            <ButtonInput 
                                label="Reject"
                                trigger
                                set={() => {
                                    setAccepted(false);
                                    //resetProperties();
                                    setCloseBugModal(true);
                                }}
                                className="editgamebtn w-100"
                                style={{border: '1px solid #eee', backgroundColor: 'white', color: '#f44336',
                                borderRadius: '0.5rem 0 0 0.5rem'}}
                            />
                        </div>
                        <div className="col-6 ml-0 pl-0">
                            <ButtonInput
                                label="Accept"
                                set={() => {
                                    setAccepted(true);
                                    //resetProperties();
                                    setCloseBugModal(true);
                                }}
                                trigger
                                className="editgamebtn w-100"
                                style={{border: '1px solid #eee', backgroundColor: 'white', color: '#00cc69', borderRadius: '0 0.5rem 0.5rem 0'}}
                            />
                        </div>
                    </div>
                  </>
                ) : (
                    <>
                        {!props.reportChosen.accepted && (
                            <div className="mt-3 col-12">
                                <div>Feedback</div>
                                <div className="report-data">{props.reportChosen.gamedevFeedback}</div>
                            </div>
                        )}
                        <div className="mt-3 col-12 d-flex justify-content-center pt-2">
                            <div className={`final-status ${props.reportChosen.accepted ? 'accepted' : 'rejected'}`}>{props.reportChosen.accepted ? 'Accepted' : 'Rejected'}</div>
                        </div>
                    </>
                )}
            </div>

            {selectedImageID && (
                <Modal
                    show={selectedImageID}
                    onHide={() => {
                        setSelectedImageID("");
                    }}
                >
                    <Modal.Body id="bugspotting-screenshot-modal">
                        <Modal.Header
                            closeButton
                            className="modal-header-position"
                        />
                        <div className="row">
                            <div className="col-12 text-center">
                                <img
                                    alt="bug screenshot"
                                    src={`${process.env.REACT_APP_QUDO_SERVER}/api/screenshots/getbyid?imageID=${selectedImageID}`}
                                    onError={(i) => (i.target.src = fallBack)}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%'
                                    }}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}

            {showMessages && (
                <ChatMessagesModal
                    show={showMessages}
                    setShow={setShowMessages}
                    reportID={props.reportChosen._id}
                />
            )}
            </>
        )
        :
        (
            <>
            </>
        )
    }

    return (
        <>
            {getColumn()}

            {/*}
            <ConfirmModal
                show={confirmModal} 
                hide={() => setConfirmModal(false)}
                confirm={(event) => {
                    submitReport(event);
                    //setConfirmModal(false);
                }}
                message={`Are you sure want to accept this bug report?`}
                loading={loading}
            />
            {*/}
            <CloseBugModal
                show={closeBugModal}
                hide={() => setCloseBugModal(false)}
                confirm={(event) => {
                    submitReport(event);
                    //setCloseBugModal(false);
                }}
                accept={accepted}
                gamedevFeedback={gamedevFeedback}
                setGamedevFeedback={setGamedevFeedback}
                loading={loading}
            />
            {/*}
            <MessageModal
                show={successModal} 
                message={modalMessage}
                hide={() => {setSuccessModal(!successModal)}}
            />
            {*/}
            {/*}
            <MessageModal
                show={failureModal} 
                message={modalMessage}
                hide={() => setFailureModal(!failureModal)}
            />
            {*/}
            <MessageModal
                show={modalMessage} 
                message={modalMessage}
                hide={() => {
                    setModalMessage("");
                }}
            />
        </>
    )
}