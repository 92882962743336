import React, {useState, useEffect} from "react";
import moment from "moment";

import ServiceCard from "../provider/serviceCard";
import ServiceDetailsModal from '../components/modals/ServiceDetailsModal';
import ChatMessagesModal from '../components/modals/chatMessagesModal/chatMessagesModal';
import {ButtonInput} from "../components/forms/buttons";

import QUDOServer from "../../helpers/QUDOServerConnection";
import useWindowSize from "../../helpers/useWindowSize";
//import {ServiceTypes} from "../../helpers/serviceTypes";

export default function GamedevServices(props) {
    const [currentServices, setCurrentServices] = useState([]);
    const [closedServices, setClosedServices] = useState([]);

    const [showService, setShowService] = useState(false);
    const [chatServiceID, setChatServiceID] = useState('');

    useEffect(() => {
        getHiredServices();
    }, []);

    function getHiredServices() {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/profile/services/hired`, {
            withCredentials: true
        })
        .then(result => {
            const activeServices = result.data.filter(e => {
                return !e.closed;
            });

            const finishedServices = result.data.filter(e => {
                return e.closed;
            });

            setCurrentServices(activeServices);
            setClosedServices(finishedServices);
        });
    }

    return(
        <>
        <div className="row">
            <div className={`col-12 ${Array.isArray(closedServices) && closedServices.length > 0 ? 'col-xl-8' : ''} mb-3`}>
                <div className="servicecard-title mb-2">
                    Current Services
                </div>
                {Array.isArray(currentServices) && currentServices.length > 0 ? (
                    <div className="col-12">
                        {currentServices.map(service => {
                            return(
                                <ServiceRow
                                    service={service}
                                    showService={setShowService}
                                    setChatServiceID={setChatServiceID}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <div>
                        You currently don't have any active services.
                    </div>
                )}
            </div>

            {Array.isArray(closedServices) && closedServices.length > 0 && (
            <div className="col-12 col-xl-4 mb-3">
                <div className="servicecard-title mb-2">
                    Closed Services
                </div>
                {/* {Array.isArray(closedServices) && closedServices.length > 0 && ( */}
                    <div className="row w-100">
                        {closedServices.map(service => {
                            return(
                                <ServiceCard
                                    key={service.service.name}
                                    service={service.service}
                                    //onClick={() => setShowService(service.service.name)}
                                    onClick={() => setChatServiceID(service._id)}
                                    className="col-12 col-md-6 col-xl-12"
                                    dark={true}
                                    backgroundColor={service.canceled ? "#f2544c" : ""}
                                    footer={`Service ${service.canceled ? "canceled and refunded" : "finished"} on: ${moment(service.finishTimestamp).format("DD MMM YYYY @ HH:mm")}`}
                                />
                            );
                        })}
                    </div>
                {/* )} */}
            </div>
            )}
        </div>

        {showService && (
            <ServiceDetailsModal
                service={showService}
                show={showService}
                setShow={() => setShowService(null)}
            />
        )}

        {chatServiceID && (
            <ChatMessagesModal
                show={chatServiceID}
                setShow={() => setChatServiceID('')}
                serviceContractID={chatServiceID}
            />
        )}
        </>
    );
}

function ServiceRow(props) {
    const serviceContract = props.service;

    const windowSize = useWindowSize();

    const mobile = windowSize && windowSize.width < 576;
    const ipad = windowSize && windowSize.width < 992;

    return(
        <>
        <div
            key={serviceContract.service.name}
            className="row w-100 servicecard enable-click"
        >
            <div
                onClick={() => props.showService(serviceContract.service.name)}
                className={`${ipad ? 'col-6' : 'col-2'} py-2 my-auto servicecard-vertical-separator`}
            >
                <div className="servicecard-title">
                    Service
                </div>
                <div className="servicecard-description" style={{fontSize: '1rem'}}>
                    {/* {ServiceTypes[serviceContract.service.type]} */}
                    {/*
                        serviceContract.service.provider.display_name
                        ? serviceContract.service.provider.display_name
                        : serviceContract.service.provider.username
                    */}
                    {serviceContract.service.display_name ? serviceContract.service.display_name : serviceContract.service.name}
                </div>
            </div>

            <div
                onClick={() => props.showService(serviceContract.service.name)}
                className={`${ipad ? 'col-6' : 'col-2 servicecard-vertical-separator'} py-2 my-auto`}
            >
                <div className="servicecard-title">
                    Cost
                </div>
                <div className="servicecard-description" style={{color: '#fbd731', fontSize: '1rem', fontWeight: 'bold'}}>
                    {serviceContract.price} QUDO
                </div>
                {!isNaN(serviceContract.fee) && Number(serviceContract.fee) > 0 && (
                    <div className="servicecard-description" style={{fontSize: '0.9rem'}}>
                        {`(${serviceContract.fee}% purchase fee)`}
                    </div>
                )}
            </div>

            <div
                onClick={() => props.showService(serviceContract.service.name)}
                className={`${mobile ? 'col-12' : ipad ? 'col-6 servicecard-vertical-separator' : 'col-3 servicecard-vertical-separator'} py-2 my-auto`}
            >
                <div className="servicecard-title">
                    Obtained
                </div>
                <div className="servicecard-description" style={{fontSize: '1rem'}}>
                    {moment(serviceContract.purchaseTimestamp).format("DD MMM YYYY @ HH:mm")}
                </div>
            </div>

            <div
                onClick={() => props.showService(serviceContract.service.name)}
                className={`${mobile ? 'col-12' : ipad ? 'col-6' : 'col-3 servicecard-vertical-separator'} py-2 my-auto`}
            >
                <div className="servicecard-title">
                    State
                </div>
                <div className="servicecard-description" style={{fontSize: '1rem'}}>
                    {serviceContract.closed ? (
                        <>
                        <div className="d-inline-block">Service {serviceContract.canceled ? "canceled and refunded" : "finished"} on:{" "}</div>
                        <div className="d-inline-block">{moment(serviceContract.finishTimestamp).format("DD MMM YYYY @ HH:mm")}</div>
                        </>
                    ) : (
                        serviceContract.active ? (
                            <>
                            <div className="d-inline-block">Service started on:</div>
                            <div className="d-inline-block">{moment(serviceContract.startedTimestamp).format("DD MMM YYYY @ HH:mm")}</div>
                            </>
                        ) : (
                            <div className="d-inline-block">Service not yet initiated</div>
                        )
                    )}
                </div>
            </div>

            <div className="col-12 col-lg-2 py-2 my-auto">
                <ButtonInput
                    trigger
                    label={"Contact Provider"}
                    set={() => props.setChatServiceID(serviceContract._id)}
                    style={{backgroundColor: "#fbd731", marginTop: '0px'}}
                />
            </div>
        </div>
        </>
    );
}
