import React, { useState, useEffect } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

import { NumberInput } from "../../components/forms/number";
import { NumberedRange } from "../../components/forms/range";
import { NameInput, TextAreaInput } from "../../components/forms/text";
import { ButtonInput } from "../../components/forms/buttons";

import { /* SuccessModal, FailureModal, */ MessageModal } from "../../components/modals/modals.js";

import regexes from "../../../helpers/validationRegexes";
import QUDOServer from "../../../helpers/QUDOServerConnection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export default function Product({ product, verifyAlias, deleteAlias, game, refresh, productAdded }) {
    const [valid, setValid] = useState(false);
    const [name, setName] = useState("");
    const [alias, setAlias] = useState("");
    const [aliasValid, setAliasValid] = useState(true);
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState(product ? product.price : 0);
    const [maxQuantity, setMaxQuantity] = useState(product ? product.maxQuantity : 1);
    const [infiniteQuantity, setInfiniteQuantity] = useState(product ? product.infiniteQuantity : false);
    const [indexedPrice, setIndexedPrice] = useState(product ? product.fiatPrice !== 0 : false);

    const [form, setForm] = useState(null)

    //const [successModal, setSuccessModal] = useState(false);
    //const [failureModal, setFailureModal] = useState(false);
	//const [update, setUpdate] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    useEffect(() => {
        setPrice(product ? product.price : 0);
    }, [product]);

    useEffect(() => {
        if((!product && !alias) || !name || !description || !price || (!infiniteQuantity && isNaN(maxQuantity))) {        
            setValid(false);
        } else {
            setValid(true);
        }
    }, [product, alias, name, description, price, maxQuantity, infiniteQuantity])

    useEffect(() => {
        const valid = verifyAlias('product', alias);
        setAliasValid(valid);
    }, [alias]);

    function deleteItem() {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/product/delete`,
            {
                gamename: game.name,
                alias: product.alias
            },
            {
                withCredentials: true
            }
        )
        .then((result) => {
            deleteAlias('product', alias);
            refresh();
        })
        .catch(error => {
            setModalMessage(error.errorMessage);
        });
    }

    const submit = (value) => {
        const body = {
            gamename: game.name,
            alias: product ? product.alias : alias,
            name,
            description,
            price,
            maxQuantity,
            infiniteQuantity,
            indexedPrice
        }

        var url = '';

        if(product) {
            url = `${process.env.REACT_APP_QUDO_SERVER}/game/product/update`;
        } else {
            url = `${process.env.REACT_APP_QUDO_SERVER}/game/product/create`;
        }

        QUDOServer.post(url, body, { withCredentials: true })
        .then(result => {
            setModalMessage('Success');
            //setSuccessModal(true);

            reset();
            // This is to force refresh on product list
            productAdded(value => !value);
            refresh();
        })
        .catch(error => {
            setModalMessage(error.errorMessage);
            //setFailureModal(true);

            //reset();
            refresh();
        });
    }

    const reset = () => {
        form.reset();
    }

    const tooltip = (
        <Tooltip placement="right" className="">
            <span className="text-light"><small>
                Link the value of this product to USD. The qudo value of the product will be adjusted
                periodically to match the USD value set during creation/modification.
            </small></span>
        </Tooltip>
    );

    return (
        <div className="col-12 mx-auto">
            <div>

                {/* TITLE */}
                <div
                    className={`text-break col-12 mb-4 edit-screen-menu`}>
                    {product ? "Edit" : "Add"} Products
                </div>

                {/* FORM */}
                <Form ref={(el) => setForm(el)}>
                    <NameInput 
                        set={setAlias}
                        title="Alias"
                        pattern={regexes.productAlias}
                        disabled={product}
                        placeholder={product ? product.alias : "prod1"}
                        invalid={!aliasValid}
                        errorMessage={'Alias is already being used!'}
                    />
                    <NameInput 
                        set={setName} 
                        title="Product Name"
                        pattern={regexes.productName}
                        initial={product ? product.name : ""}
                        placeholder={product ? product.name : "Product 1"}
                    />
                    <TextAreaInput 
                        set={setDescription} 
                        title="Description"
                        initial={product ? product.description : ""}
                        placeholder={product ? product.description : "A nice product description"}
                    />
                    <NumberInput
                        title="Maximum Quantity per User"
                        unit="units"
                        min={0}
                        step={1}
                        disabled={infiniteQuantity}
                        initial={maxQuantity}
                        value={maxQuantity}
                        setValue={setMaxQuantity}
                    />
                    <div className="row mt-1 mb-3 mx-2">
                        <div className="col-10">
                            Ignore maximum quantity
                        </div>

                        <div className="col-2 text-right">
                            <input
                                type="checkbox"
                                checked={infiniteQuantity}
                                onChange={() => setInfiniteQuantity(!infiniteQuantity)}
                            />
                        </div>
                    </div>
                    <NumberedRange 
                        max={100} 
                        min={0}
                        title="Price"
                        unit="qudo"
                        initial={product ? product.price : price}
                        setValue={setPrice}
                        value={price}
                    />
                    <div className="row mt-1 mb-3 mx-2 user-select-none">
                        <div className="col text-right" onClick={() => setIndexedPrice(!indexedPrice)}>
                            <small>
                                <span>Index value to USD</span>
                                <OverlayTrigger placement="right" overlay={tooltip}>
                                    <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                                </OverlayTrigger>
                            </small>
                            <input
                                className="ml-2 align-middle"
                                type="checkbox"
                                checked={indexedPrice}
                                onChange={() => setIndexedPrice(!indexedPrice)}
                            />
                        </div>
                    </div>
                    <ButtonInput
                        trigger
                        label="Submit" 
                        set={submit} 
                        disabled={!valid}
                        style={
                            !valid
                            ? {cursor: 'initial'}
                            : {backgroundColor: "#fbd731"}
                        }
                    />
                    {
                        product &&
                        <ButtonInput
                            trigger
                            label="Delete product"
                            set={deleteItem} 
                            style={{backgroundColor: "#f44336"}}
                        />
                    }
                </Form>

                {/* MODALS */}
                <>
                    {/*}
                    <SuccessModal 
                        show={successModal} 
                        hide={() => {
                            setSuccessModal(!successModal);
                            props.refresh();
                        }}
                    />
                    <FailureModal
                        show={failureModal}
                        hide={() => {
                            setFailureModal(!failureModal);
                            //props.refresh();
                        }}
                    />
                    {*/}

                    <MessageModal
                        show={!!modalMessage}
                        message={modalMessage}
                        hide={() => {
                            setModalMessage('');
                        }}
                    />
                </>
            </div>
        </div>
    );
}
