import React from 'react';
import BugSpottingSwitch from './bugSpottingSwitch/bugSpottingSwitch';
import BugsList from './bugsList/bugsList';

export default function BugsLeftColumn(props)
{
    if (props.submenu === 'setup')
    {
        return (
            <BugSpottingSwitch
                game={props.game}
                reportChosen={props.reportChosen}
            />
        )
    }

    return (
        <BugsList
            game={props.game}
            activeFilter={props.activeFilterBugs}
            reportChosen={props.reportChosen}
            setReportChosen={props.setReportChosen}
            update={props.update}
            setUpdate={props.setUpdate}
        />
    )
};