import React, {useEffect, useState} from "react";

import QUDOServer from "../../../../helpers/QUDOServerConnection";

import {ButtonInput} from "../../../components/forms/buttons";
import {ImageUploadModal} from "../../../components/modals/ImageUploadModal";

const fallBackAvatar = require("../../../../images/definitive/icon-avatar.png");

export default function UserAvatarsUpload(props) {
  const [upload, setUpload] = useState(false);
  const [gameAvatars, setGameAvatars] = useState([]);
  const [isDeleting, setDeleting] = useState(false);

  useEffect(() => {
    updateAvatars();
  }, []);

  function updateAvatars() {
    QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getavailable`, {withCredentials: true})
    .then((result) => {
      setGameAvatars(result.data[props.displayName ? props.displayName : props.name]);
    });
  }

  function availableAvatars() {
    const items = [];

    if(gameAvatars && Object.keys(gameAvatars).length > 0) {
      for(let avatar in gameAvatars) {
        items.push(
          <div
            key={gameAvatars[avatar]}
            className="mb-3 col-6 col-sm-3 col-md-6 col-lg-3 col-xl-3"
          >
            {isDeleting && (
              <div>
                <div
                  onClick={() => deleteAvatar(gameAvatars[avatar])}
                  className='enable-click'
                  style={{
                    display: 'inline-block',
                    position: 'absolute',
                    top: '-15px',
                    right: '10px',
                    color: 'red',
                    fontWeight: 'bold',
                    fontSize: '1.5rem'
                  }}
                >
                  x
                </div>
              </div>
            )}

            <img
              alt="User avatar"
              className="rounded-circle img-fluid"
              style={{maxHeight: '125px'}}
              src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${gameAvatars[avatar]}`}
              onError={(i) => (i.target.src = fallBackAvatar)}
            />
          </div>
        );
      }

      return items;
    } else {
      return (
        <div>
          There are no Player Avatars available for this game!
        </div>
      );
    }
  }

  async function saveAvatar(image) {
    //console.log(image);

    const body = new FormData();

    body.append("avatar", image);
    body.append("gamename", props.name);

    const config = {
        withCredentials: true
    }

    await QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/avatar/replace`, body, config);

    //setTimeout(() => {
      updateAvatars();
    //}, 1000);
  }

  function deleteAvatar(imageID) {

    if (window.confirm('Do you really want to delete this avatar?'))
    {
      const body = {
        gamename: props.name,
        imageID: imageID
      }
  
      const config = {
          withCredentials: true
      }
  
      QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/avatar/delete`, body, config)
      .then(data => {
        //setTimeout(() => {
          updateAvatars();
        //}, 1000);
      });
    }
  }

  const changeEditing = () =>
  {
    setDeleting(!isDeleting)
  }

  return (
    <>
    <div className="col-12 mt-5">
      <div className="section-title mb-3">
        Available Player Avatars
      </div>

      <div className="align-items-center col-12 mt-4 pl-0 pr-0">
        <div className="row ml-0 pl-0 mr-0 pr-0">
          {availableAvatars()}
        </div>

        <>
          {!isDeleting ?
            <ButtonInput
              trigger 
              label={isDeleting ? 'Stop Editing' : 'Edit Avatars'}
              set={changeEditing}
              className="editgamebtn w-100 my-2"
              style={{marginBottom: '1rem', padding: '0px'}}
            />
            :
            <ButtonInput
              trigger 
              label="Stop Editing"
              set={changeEditing}
              className="editgamebtn w-100 my-2"
              style={{marginBottom: '1rem', padding: '0px'}}
            />
          }
          <ButtonInput
            trigger
            label="Upload Avatars"
            set={setUpload}
            className="editgamebtn w-100 my-2"
            style={{/* backgroundColor: "#ededed", */ marginBottom: '1rem', padding: '0px'}}
          />

          <div className="section-subtitle m-0">
            * Only 4 avatars per game. The older avatars will be replaced by the new ones you upload.
          </div>
        </>
      </div>
    </div>

    {upload && (
      <ImageUploadModal
        title='Avatars'
        show={upload}
        hide={() => setUpload(false)}
        saveImage={saveAvatar}
        saveFormat={'image/png'}
        //maxSizeMB={2}
        maxWidth={1024}
        maxHeight={1024}
        aspectRatios={[
          {
            name: '1:1',
            ratio: Number(1/1)
          }
        ]}
      />
    )}
    </>
  );
}
