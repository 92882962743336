export const ServiceTypes = {
    "UI": "UX/UI Design",
    "ads": "Advertising",
    "tool": "Tools",
    "code": "Programming",
    "network": "Networking",
    "art": "2D Art",
    "modeling": "3D Modeling",
    "music_sfx": "Music & SFX",
    "voiceover": "Voice Over",
    "copyright": "Copywriting",
    "other": "Other"
}

export function getDefaultServiceImage(serviceType) {
    var image;

    switch(serviceType) {
        case ServiceTypes.UI:
            image = require("../images/ServiceIcons/UI.png");
            break;
        case ServiceTypes.ads:
            image = require("../images/ServiceIcons/Ads.png");
            break;
        case ServiceTypes.tool:
            image = require("../images/ServiceIcons/Tool.png");
            break;
        case ServiceTypes.code:
            image = require("../images/ServiceIcons/Code.png");
            break;
        case ServiceTypes.network:
            image = require("../images/ServiceIcons/Network.png");
            break;
        case ServiceTypes.art:
            image = require("../images/ServiceIcons/2D_Art.png");
            break;
        case ServiceTypes.modeling:
            image = require("../images/ServiceIcons/3D_Modeling.png");
            break;
        case ServiceTypes.music_sfx:
            image = require("../images/ServiceIcons/Music_SFX.png");
            break;
        case ServiceTypes.voiceover:
            image = require("../images/ServiceIcons/VoiceOver.png");
            break;
        case ServiceTypes.copyright:
            image = require("../images/ServiceIcons/Copyright.png");
            break;
        default:
            image = require("../images/ServiceIcons/Default.png");
            break;
    }

    return image;
}
