import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts';

export default function PurchasesServiceProvider(props)
{
    const allServices = props.hiredServices;
    const [chartReady, setChartReady] = useState(false);
    const [hasData, setHasData] = useState(false);

    useEffect(() =>
    {
        if (props.hiredServices)
        {
            if (props.hiredServices.length > 0)
            {
                setHasData(true);
                setChartReady(true);
            }
        }
    }, [props.hiredServices])

    useEffect(() =>
    {
        if (props.hiredServices?.length > 0)
        {   
            chart();
        }
    }, [chartReady]);

    const setupValues = type =>
    {
        let values = {};
        let totalValue = 0;

        if (allServices)
        {
            let services = allServices;

            services.forEach(service =>
                {
                    const checkIfExists = Object.keys(values).find(key => key === service.service.name);
                    
                    checkIfExists ? values[service.service.name].amount++ :
                        values[service.service.name] = {
                            name: service.service.name,
                            amount: 1
                        };

                    totalValue++;
                });

                Object.keys(values).forEach(key => values[key].percentage = Number(((values[key].amount / totalValue) * 100).toFixed(2)));   
        }

        let items = [];

        Object.keys(values).forEach(key => 
        {
            items.push(values[key])
        });

        items.sort((current, next) => next.percentage - current.percentage);
        const categories = [];
        const percentage = [];

        items.forEach(item =>
        {
            categories.push(item.name);
            percentage.push(item.percentage);
        });

        return [categories, percentage];
    }

    const chart = () => 
    {
        const [categories, percentage] = setupValues();
    
        return Highcharts.chart('purchasesService', {
			chart: {
				type: "bar",
				backgroundColor: "transparent",
				height: "150rem"
			},
			legend: {
				enabled: false,
			},
            title: {
                text: ''
            },
            xAxis : {
                categories: [...categories],
                lineColor: 'white',
                height: 50
            },
            yAxis: {
                lineColor: 'white',
                visible: false,
                max: 100
            },
            credits: {
                enabled: false
            },
            tooltip: {
                valueSuffix: '%',
                enabled: true
            },
			series: [
				{
					data: [...percentage],
                    color: '#00cc69',
                    dataLabels: {
                        enabled: true,
                        color: '#333',
                        format: '{y}%'
                    },
                    pointPadding: 0.1,
                    groupPadding: 0
				}
			],
		});
	};

    const noData = (
        <div className="text-align-center border-bottom custom-height">
            No values to be shown
        </div>
    );

    return (
        <>
            <div className="borders mt-5">
                <div className="mb-3">
                    <div className="col-12 edit-screen-stakes-sm header pt-4">
                        <strong>Purchases Per Services</strong>
                    </div>
                </div>
                <div className="col-12">
                    {hasData ?
                        <div id="purchasesService"></div>
                    :
                        noData
                    }
                </div>
            </div>
        </>
    )
}