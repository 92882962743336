import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import moment from 'moment';

export default function BugsReported(props)
{
    const allBugs = props.bugReports;
    const [chartReady, setChartReady] = useState(false);
    const [activeFilter, setActiveFilter] = useState('1d');

    const daysInterval = 15;
    const weeksInterval = 15;
    const monthsInterval = 12;

    useEffect(() =>
    {
        if (allBugs)
        {
            setChartReady(true);
        }
    }, [props.bugReports])

    useEffect(() =>
    {
        if (props.bugReports)
        {   
            chart();
        }
    }, [chartReady]);

    useEffect(() =>
    {
        if (allBugs)
        {
            chart();
        }
    }, [activeFilter]);

    const setupValues = dates =>
    {
        let values = [[],[],[]];

        if (allBugs)
        {
            dates.forEach((date) =>
            {
                let bugsDateArr = [];
                let acceptedValue = 0;
                let rejectedValue = 0;
                let totalValue = 0;
    
                bugsDateArr = allBugs.filter(bug => moment(new Date(bug.reportTimestamp)).format('YYYY-MM-DD') === date && bug.closed);

                if (activeFilter === '1w')
                {
                    const daysInAWeek = 7;
    
                    let dateInUse = new Date(date);
                    
                    for (let i = 0; i < daysInAWeek; i++)
                    {
                        dateInUse.setDate(dateInUse.getDate() - 1);
                        
                        const tempBugsDateArr = allBugs.filter(bug =>
                            moment(new Date(bug.reportTimestamp)).format('YYYY-MM-DD') === moment(dateInUse).format('YYYY-MM-DD') && 
                            bug.closed);
                    
                        if (tempBugsDateArr.length > 0)
                        {
                            tempBugsDateArr.forEach(bug => bugsDateArr.push(bug));
                        }
                    }
                }
    
                if (activeFilter === '1m')
                {
                    let dateInUse = new Date(date);

                    const tempBugsDateArr = allBugs.filter(bugs =>
                        moment(new Date(bugs.reportTimestamp)).format('YYYY-MM') === moment(dateInUse).format('YYYY-MM'));

                    if (tempBugsDateArr.length > 0)
                    {
                        tempBugsDateArr.forEach(bug => bugsDateArr.push(bug));
                    }
                }

                totalValue = bugsDateArr.length;
    
                if (bugsDateArr?.length >= 1)
                {
                    bugsDateArr.forEach(bug =>
                    {
                        bug.accepted ? acceptedValue++ : rejectedValue++;
                    });
                }
    
                values[0].push(acceptedValue);
                values[1].push(rejectedValue);
                values[2].push(totalValue);
            });
        }

        return values;
    }

    const generateDates = () =>
    {   
        let startDate = new Date();
        let endDate = new Date();

        if (activeFilter === '1d')
        {
            startDate.setDate(startDate.getDate() - daysInterval);
        }

        if (activeFilter === '1w')
        {
            startDate.setDate(startDate.getDate() - (7 * weeksInterval));
        }

        if (activeFilter === '1m')
        {
            startDate.setMonth(startDate.getMonth() - monthsInterval);
        }

        const dateArray = [];
        let currentDate = moment(startDate);
        const stopDate = moment(endDate);

        while (currentDate <= stopDate) 
        {
            if (activeFilter === '1d' || activeFilter === '1w')
            {
                dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
                currentDate = moment(currentDate).add(
                    activeFilter === '1d' ? 1 : 7, 'days');
            }
            else
            {
                dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
                currentDate = moment(currentDate).add(1, 'months');
            }
        }
        return dateArray;
    }

    const chart = () => 
    {
		const categories = [];

        const dateGenerated = generateDates();
        const [acceptedBugs, rejectedBugs, totalBugs] = setupValues(dateGenerated);

		dateGenerated.forEach((i) => 
        {
            if (activeFilter === '1m')
            {
                categories.push(moment(i).format('MMM YY'));  
            }
            else
            {
                categories.push(moment(i).format('DD MMM'));
            }
		});

		return Highcharts.chart('bugsReported', {
			chart: {
				type: "line",
				backgroundColor: "transparent",
				height: "300rem",
			},
			title: {
				text: "",
			},
			subtitle: {
				align: "right",
				verticalAlign: "bottom",
			},
			xAxis: {
                categories: [...categories],
			},
            credits: {
                enabled: false
            },
			plotOptions: {
				area: {
					fillOpacity: 0.5,
					fillColor: {
						linearGradient: [0, 0, 0, 300],
						stops: [
							[0, "#dedede"],
							[1, "#ffffff"],
						],
					},
				}
			},
            legend: {
                align: 'center',
                enabled: true
            },
			series: [
				{
					name: 'Total Bugs',
					data: [...totalBugs],
                    color: '#c2a90c'
				},
                {
					name: 'Accepted Bugs',
					data: [...acceptedBugs],
                    color: '#fcd902'
				},
                {
					name: 'Rejected Bugs',
					data: [...rejectedBugs],
                    color: '#7a6d1c'
				},
			]
		});
	};

    const noData = (
        <div className="text-align-center border-bottom custom-height">
            No values to be shown
        </div>
    );

    return (
        <>
            <div className="borders mt-4">
                <div className="mb-3">
                    <div className="col-12 header pt-4">
                        <strong>Bugs Reported</strong>
                    </div>
                </div>
                {chartReady ?
                    <>
                        <div className="col-12 edit-screen-stakes-sm mb-2">
                            <span className={`submenus  mr-2 cursor-pointer ${activeFilter === '1m' ? '' : 'not-active'}`}
                                onClick={() => setActiveFilter('1m')}>
                                1m |
                            </span>
                            <span className={`submenus mr-2 cursor-pointer ${activeFilter === '1w' ? '' : 'not-active'}`}
                                onClick={() => setActiveFilter('1w')}>
                                1w |
                            </span>
                            <span className={`submenus cursor-pointer ${activeFilter === '1d' ? '' : 'not-active'}`}
                                onClick={() => setActiveFilter('1d')}>
                                1d
                            </span>
                        </div>
                        <div id="bugsReported"></div>
                    </>
                :
                    noData
                }
            </div>
        </>
    )
}