import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {Modal} from "react-bootstrap";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import QUDOServer from "../../../helpers/QUDOServerConnection";
import useWindowSize from "../../../helpers/useWindowSize";
import {ServiceTypes} from "../../../helpers/serviceTypes";

import StarRating from 'react-star-ratings';
import {ButtonInput} from "../forms/buttons";
import HireServiceModal from "./HireServiceModal";
import ProviderDetailsModal from "./ProviderDetailsModal";
import {MessageModal} from "../../components/modals/modals.js";
import {YoutubeSlide, customRenderItem, customRenderThumb} from "../react-responsive-carousel/YoutubeSlide";

import './styles/generic.scss';

const fallBack = require("../../../images/fallbackAvatar.png");
const avatar = require("../../../images/definitive/icon-avatar.png");

export default function ServiceDetailsModal(props) {
	const windowSize = useWindowSize();

	const userInfo = useSelector(state => state.info);

    const [service, setService] = useState(null);
	const [provider, setProvider] = useState(null);

	const [showHireModal, setShowHireModal] = useState(false);
	//const [showFullDescription, setShowFullDescription] = useState(true);

	const [rating, setRating] = useState();
	const [numberOfReviews, setNumberOfReviews] = useState();

	const [successModal, setSuccessModal] = useState(false);
	const [failureModal, setFailureModal] = useState(false);
	const [modalMessage, setModalMessage] = useState('');

	const [isServiceContracted, setIsServiceContracted] = useState(false);

	const [gamedevReview, setGamedevReview] = useState(0);

	const [showProvider, setShowProvider] = useState('');

	/*
	useEffect(() => {
		if(document.getElementById('service_description')) {
			const descriptionHeigth = document.getElementById('service_description').clientHeight;

			if(descriptionHeigth > 350) {
				setShowFullDescription(false);
			} else {
				setShowFullDescription(true);
			}
		}
	}, [service]);
	*/

	useEffect(() => {
		if(props.service) {
			QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/serviceinfo?service=${props.service}`)
			.then(response => {
                setService(response.data);
                setProvider(response.data.provider);
				setNumberOfReviews(response.data.reviewNr);
				setRating(response.data.rating);
			})
			.catch(error => {
				cleanOnHide();
			});
		}
	}, [props.service, gamedevReview]);

	/*
	useEffect(() => {
		if(props.service) {
			QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/serviceinfo?service=${props.service}`)
			.then(response => {
				setNumberOfReviews(response.data.reviewNr);
				setRating(response.data.rating);
			})
			.catch(error => {
				cleanOnHide();
			});
		}
	}, [gamedevReview]);
	*/

	useEffect(() => {
		if(props.service && userInfo) {
			QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/profile/services/reviews?service=${props.service}`,
			{
				withCredentials: true
			})
			.then(response => 
			{
                response.data.length > 0 ? setGamedevReview(response.data[0].rating) : setGamedevReview(0);
			})
			.catch(error => 
			{
				//console.log(error);
				//cleanOnHide();
			});
		}
	}, [props.service]);

	useEffect(() => 
	{
        getHiredServices();
    }, [props.service]);

    function getHiredServices() 
	{
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/profile/services/hired`, {
            withCredentials: true
        })
        .then(async result => {
			const isServiceHired = result.data.some(service => service.service.name === props.service);
            setIsServiceContracted(isServiceHired);
        });
    }

	function cleanOnHide() {
        props.setShow(null);

        setService(null);
        setProvider(null);
        //setShowFullDescription(true);
		setRating(0);
		setGamedevReview(0);
	}

	function renderGalleryItems(videos, images) {
		const response = [];

		if(Array.isArray(videos)) {
			videos.map(video => {
				response.push(
					<YoutubeSlide url={video.videoLink}/>
				);
			});
		}

		if(Array.isArray(images)) {
			images.map(image => {
				response.push(
					<img
						alt="Service artwork/screenshot"
						src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?type=service&imageID=${image.imageID}`}
						onError={(i) => (i.target.src = fallBack)}
						className="img-fluid"
						style={{width: "initial", maxHeight: "450px"}}
					/>
				);
			});
		}

		//console.log(response);
		return response;
	}

	const getRating = () =>
	{
		return (
			<>
				<div className="custom-header mb-1 mt-2">
					Service Review
				</div>
				{rating ?
					<div className="row rating w-100 col-12">
						<div className="rating-number">
							{rating}
						</div>
						<div className="stars">
							<StarRating 
								rating={rating}
								starRatedColor="#fcd902"
								name="rating"
								numberOfStars={5}
								starDimension="30px"
								starSpacing="0px"
							/>
						</div>
						<div className="col-lg-3 row people mb-2">
							<div className="col-md-2 col-lg-1 col-1 img">
								<img
									src={avatar}
									className="icon-32px"
									alt="player-icon"
								/>
							</div>
							<div className="col-md-8 col-lg-3 col-3 ml-1">
								{numberOfReviews} total
							</div>
						</div>
					</div>
					:
					<div>
						This service doesn't have a rating yet
					</div>
				}	
			</>
		)
	}

	const getGamedevReview = () =>
	{
		if(
			userInfo && userInfo.username !== service.provider.username && (
				userInfo.type === process.env.REACT_APP_GAMEDEV_TYPE
				|| userInfo.type === process.env.REACT_APP_PROVIDER_TYPE
			)
		) {
			return (
				<div className="mb-2">
					<div className="custom-header mt-2">
						My Review
					</div>
					{isServiceContracted ?
						<div className="row rating w-100 col-12">
							<div className="rating-number">
								{gamedevReview}
							</div>
							<div className="stars">
								<StarRating 
									rating={gamedevReview}
									starRatedColor="#fcd902"
									name="gamedevReview"
									changeRating={(value) => rateProvider(value)}
									numberOfStars={5}
									starDimension="30px"
									starSpacing="0px"
									starHoverColor="blue"
								/>
							</div>
							<div className="w-100">
								<button className="editgamebtn custom-height"
									onClick={() => deleteReview()}>
									Delete Review
								</button>
							</div>
						</div>
						:
						<div className="mb-2">
							You need to get this service before you rate it.
						</div>
					}
				</div>
			);
		} else {
			return null;
		}
	}

	const rateProvider = value =>
	{
		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/service/review`, {
            service: service.name,
			rating: value
        }, {
			withCredentials: true
		})
		.then(response =>
		{
			console.log(response)
			setGamedevReview(value);
			setSuccessModal(true);
			setModalMessage('Review added with success')
		})
		.catch(err =>
		{
			console.log(err)
			setFailureModal(true);
			setModalMessage('Something went wrong')
		})
	}

	const deleteReview = () =>
	{
		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/service/review/delete`, {
            service: service.name
        }, {
			withCredentials: true
		})
		.then(response =>
		{
			setGamedevReview(0);
			setSuccessModal(true);
			setModalMessage('Review removed with success')
		})
		.catch(err =>
		{
			setFailureModal(true);
			setModalMessage('Something went wrong')
		})
	}

	return(
		service &&
		(
		<>
		<Modal show={props.show} onHide={cleanOnHide}>
			<Modal.Body
				id="gamedetails-modal"
				style={{
                    opacity: 1,
                    justifyContent: 'initial',
					paddingTop: '3rem',
					paddingBottom: '1rem',
					paddingLeft: '2.5%',
					paddingRight: '2.5%'
				}}
			>
				<Modal.Header
					closeButton
					className='modal-header-position'
				/>

				<div className="row with-scroll">
					{windowSize && windowSize.width < 768 ? (
						<div className="col-12 mb-4">
							<div className="modal-title-big m-0">
								{service.display_name ? service.display_name : props.service}
							</div>

							<div
								className="section-title enable-click"
								style={{lineHeight: 'normal'}}
								onClick={() => setShowProvider(provider.username)}
							>
								{provider && (provider.display_name ? provider.display_name : provider.username)}
							</div>
						</div>
					) : null}

					<div className={`col-12 col-md-2 text-center`}>
						<div style={{position: 'relative', display: 'inline-block'}}>
							<img
								src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbyservice?service=${props.service}&rand=${Date.now()}`}
								onError={(i) => {
									(i.target.src = fallBack);
								}}
								className="details-modal-image img-fluid custom-image-height"
								style={{maxHeight:'250px'}}
								alt="Service banner"
							/>
						</div>
					</div>

					<div className="col-12 col-md-8 mx-auto">
						{windowSize && windowSize.width >= 768 ? (
							<>
							<div className="modal-title-big m-0">
								{service.display_name ? service.display_name : props.service}
							</div>

							<div
								className="section-title enable-click"
								style={{lineHeight: 'normal'}}
								onClick={() => setShowProvider(provider.username)}
							>
								{provider && (provider.display_name ? provider.display_name : provider.username)}
							</div>
							</>
						) : null}

						<>
							<div className="mt-3 mb-1 custom-header">
								Service Type
							</div>
							<div
								className={`edit-screen-text-area`}
								style={{whiteSpace: 'initial', fontSize: '1.25rem'}}
							>
								{ServiceTypes[service.type]}
							</div>
						</>

						<>
							<div className="mt-3 mb-1 custom-header">
								Price
							</div>
							<div
								className={`edit-screen-text-area`}
								style={{whiteSpace: 'initial', fontSize: '1.25rem'}}
							>
								{service.price} QUDO
							</div>
						</>

						<>
							<div className="mt-3 mb-1 custom-header">
								Average Estimated Completion Time
							</div>
							<div
								className={`edit-screen-text-area`}
								style={{whiteSpace: 'initial', fontSize: '1.25rem'}}
							>
								{service.estimatedTime} {Number(service.estimatedTime) !== 1 ? 'hours' : 'hour'}
							</div>
						</>
						{service.website && (
							<div>
								<div className={`mt-4 mb-1 custom-header`}>
									Official Website
								</div>
								<a
									style={{ color: "black" }}
									target="_blank"
									rel="noopener noreferrer"
									href={service.website}
								>
									{service.website}
								</a>
							</div>
						)}
						<div className="mt-4">
							<div className="mb-1 custom-header">
								Service description
							</div>
							<div id="service_description" className={`edit-screen-text-area`}>
								{service.description}
							</div>
							{/*}
							<div id="service_description" className={`edit-screen-text-area`}>
								{showFullDescription ? service.description : String(service.description).slice(0, 250) + '...'}
							</div>
							{*/}

							{/*
							!showFullDescription && (
								<div className="text-center">
									<div
										className="mb-2 enable-click section-title"
										style={{display: 'inline-block'}}
										onClick={() => setShowFullDescription(true)}
									>
										Read more...
									</div>
								</div>
							)
							*/}
						</div>

						{
							(Array.isArray(service.media_images) && service.media_images.length > 0)
							|| (Array.isArray(service.media_videos) && service.media_videos.length > 0)
							?
							(
								<div className="mt-4">
									<div className="my-2 custom-header">
										Gallery
									</div>
									<div
										className="mt-2"
										style={
											windowSize.width < 768
											? {maxWidth: '95%', margin: 'auto'}
											: {maxWidth: '75%', margin: 'auto'}
										}
									>
										<Carousel
											swipeable={true}
											useKeyboardArrows={true}
											showArrows={true}
											showThumbs={true}
											showIndicators={false}
											showStatus={false}
											dynamicHeight={true}
											infiniteLoop={true}
											renderItem={customRenderItem}
											renderThumbs={customRenderThumb}
										>
											{renderGalleryItems(service.media_videos, service.media_images)}
										</Carousel>
									</div>
								</div>
							) : (
								<div className="col-12" style={{height: '1.5rem'}}></div>
							)
						}

						{getRating()}
						{getGamedevReview()}

						{windowSize && windowSize.width >= 768
							&& userInfo && userInfo.username !== service.provider.username
							&& (userInfo.type === process.env.REACT_APP_GAMEDEV_TYPE || userInfo.type === process.env.REACT_APP_PROVIDER_TYPE)
							&& (
								<>
								<div className="mt-3 mb-1 row col-12">
									<div className="button-hire min-w-60">
										<span className="price">PRICE</span> <span className="numbers">{service.price}</span> QUDO
									</div>
									<div className="button-hire min-w-40">
										<ButtonInput
											trigger
											//label="Hire"
											label="Get Service"
											set={setShowHireModal}
											className="editgamebtn w-100 m-0 button-hire min-w-40"
											style={{backgroundColor: "#fbd731"}}
										/>
									</div>
								</div>
								</>
							)}
					</div>

					{windowSize && windowSize.width < 768
					&& userInfo && userInfo.username !== service.provider.username
					&& (userInfo.type === process.env.REACT_APP_GAMEDEV_TYPE || userInfo.type === process.env.REACT_APP_PROVIDER_TYPE)
					&& (
						<>
						<div className="col-12 mb-3">
							<ButtonInput
								trigger
								//label="Hire"
								label="Get Service"
								set={setShowHireModal}
								className="editgamebtn w-100 m-0"
								style={{backgroundColor: "#fbd731"}}
							/>
						</div>
						</>
					)}
				</div>
			</Modal.Body>
			<MessageModal
				show={successModal} 
				message={modalMessage}
				hide={() => setSuccessModal(!successModal)}
			/>
			<MessageModal
				show={failureModal} 
				message={modalMessage}
				hide={() => setFailureModal(!failureModal)}
			/>
		</Modal>

		{showProvider && (
			<ProviderDetailsModal
				show={showProvider}
				setShow={setShowProvider}
				provider={showProvider}
			/>
		)}

		{/* This forces the component to re-render every time it's opened/closed */}
		{showHireModal &&
			<HireServiceModal
				service={service}
				show={showHireModal}
				setShow={setShowHireModal}
			/>
		}
		</>
		)
	);
}
