import React, { useState, useEffect } from "react";

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import useWindowSize from '../../helpers/useWindowSize';
import QUDOServer from '../../helpers/QUDOServerConnection';

const fallBack = require("../../images/fallbackAvatar.png");

export default function ProvidersList(props) {
	//Although not being used, this next statement forces the component to rerender whenever the window size changes (so don't delete)
	const windowSize = useWindowSize();

	const [gds, setGds] = useState();
	const [gdsKeys, setGdsKeys] = useState([]);

    const [swiper, setSwiper] = useState(null);

    const sliderParams = {
        slidesPerView: 1,
        spaceBetween: 30,
        //loop: true,
        breakpoints: {
            550: {
              slidesPerView: 2,
              spaceBetween: 40
			},
            850: {
              slidesPerView: 3,
              spaceBetween: 40
			},
			992: {
				slidesPerView: 2,
				spaceBetween: 40
			},
			1600: {
				slidesPerView: 4,
				spaceBetween: 40
			}
        }
	}

    useEffect(() => {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/services/getexistent`)
        .then(result => {
            //console.log(result.data);

            var providersKeys = Object.keys(result.data);
            providersKeys = providersKeys.sort((a, b) => Math.random() - 0.5);

            setGds(result.data);
            setGdsKeys(providersKeys);
        });
    }, []);

    useEffect(() => {
		var mySwiper = document.querySelector(".sp-swiper-container");

		if(mySwiper) {
			setSwiper(mySwiper.swiper);
		}
	}, [gds]);

    return (
        <div className="main-inner-container py-5" style={{position: "relative"}}>
            <div className="row mb-3" style={{margin: "auto 1rem auto auto"}}>
                <div className="main-inner-title">
                    Service Providers

					{gds && (
						<span style={{fontSize: '1.25rem', marginLeft: '3px'}}>
							({Object.keys(gds).length})
						</span>
					)}
                </div>
            </div>
            <div className="swiper-button-prev-custom" onClick={() => swiper.slidePrev()}/>
            {gds &&
			<Swiper {...sliderParams} containerClass="sp-swiper-container swiper-overflow-hidden">
 				{gdsKeys.map(
					(i, index, gdList) => {
						//This is used to avoid rendering the same service type/icon multiple times in a row
						var LastRenderedType = '';

						return (
							<div key={i} className="d-inline-block enable-click">
								<div
									className="card gd-card mx-auto"
									style={{border: '0px'}}
									onClick={() => {
										props.showDetails(gds[i][0].provider.username);
									}}
								>
									<div
										//id={i + 'Dev'} 
										id="DevCard"
										className="card-body row"
										style={{
											display: "flex",
											alignItems: "center",
											backgroundColor: '#fff',
											border: '1px solid rgba(0, 0, 0, 0.125)'
										}}
									>
										<div className="col-12 text-right">
											<div className="gd-list-gamedev-name">
												{i}
											</div>
											<div className="gd-list-games-count">
												{
													gds[i]
														.length
												}{" "}
												{gds[i]
													.length === 1
													? "service"
													: "services"}
											</div>
										</div>
										<div className="col-6 d-inline-block flex-column">
											<img
												src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbyprovider?provider=${gds[i][0].provider.username}&size=S`}
												className="gd-list-thumbnail"
												alt="service provider avatar"
												onError={(i) => {
													i.target.src = fallBack;
												}}
											/>
										</div>
										<div className="col-6 text-right">
											<div className="gd-images-box">
												{gds[i].map((service, index) => {
													var maxIndex = 2;

													if(
														document.getElementById('DevCard')
														&& document.getElementById('DevCard').clientWidth < 375
													) {
														maxIndex = 1;
													} else if(
														document.getElementById('DevCard')
														&& document.getElementById('DevCard').clientWidth > 475
													) {
														maxIndex = 3;
													}

													if(index < maxIndex && LastRenderedType !== service.type) {
														LastRenderedType = service.type;

														return (
															<img
																src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbyservice?service=${service.name}&size=S`}
																id="gd-list-games-image"
                                                                style={{width: 'initial'}}
																alt="service avatar"
																key={service.name}
																onError={(
																	e
																) =>
																	(e.target.style.display =
																		"none")
																}
															/>
														);
													} else {
														return null;
													}
												})}
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					}
				)}
            </Swiper>}
            <div className="swiper-button-next-custom" onClick={() => swiper.slideNext()}/>
        </div>
    );
}
