import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';

export default function ValidInvalidBugs(props)
{
    const allBugs = props.bugReports;
    const [chartReady, setChartReady] = useState(false);

    useEffect(() =>
    {
        if (allBugs)
        {
            setChartReady(true);
        }
    }, [props.bugReports])

    useEffect(() =>
    {
        if (props.bugReports)
        {   
            chart();
        }
    }, [chartReady]);

    const setupValues = () =>
    {
        let validBugCounter = 0;
        let invalidBugCounter = 0;

        allBugs.filter(bug => bug.closed).forEach(bug =>
        {
            bug.accepted ? validBugCounter++ : invalidBugCounter++;
        })

        const values = [{
            y: validBugCounter,
            color: '#fcd902',
            name: 'Valid Bugs'
        }, {
            y: invalidBugCounter,
            color: '#707070',
            name: 'Invalid Bugs'
        }];

        return values;
    }

    const chart = () => 
    {
        const values = setupValues();
        let title = `<strong>${values[0]['y'] + values[1]['y']}</strong><br>BUGS`;
    
        return Highcharts.chart('validInvalidBugs', {
            chart: {
                type: 'pie',
                backgroundColor: null
            },
            title: {
                text: title,
                align: 'center',
                verticalAlign: 'middle',
                y: 10,
                style: {
                    color: '#fcd902',
                    fontSize: '25px'
                }
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    innerSize: '75%',
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [{
                name: 'Bugs',
                data: values,
                dataLabels: {
                    enabled: false
                }
            }]
        });
	};

    const noData = (
        <div className="text-align-center border-bottom custom-height">
            No values to be shown
        </div>
    );

    return (
        <>
            <div className="borders">
                <div className="mb-3">
                    <div className="col-12 header pt-4">
                        <strong>Valid/Invalid Bugs</strong>
                    </div>
                </div>
                {chartReady ?
                    <div id="validInvalidBugs"></div>
                :
                    noData
                }
            </div>
        </>
    )
}