import React, { useState, useEffect } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

import { NumberedRange } from "../../components/forms/range";
import { NameInput, TextAreaInput } from "../../components/forms/text";
import { ButtonInput } from "../../components/forms/buttons";

import { /* SuccessModal, FailureModal, */ MessageModal } from "../../components/modals/modals.js";

import regexes from "../../../helpers/validationRegexes";
import QUDOServer from "../../../helpers/QUDOServerConnection";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export default function Achievement(props) {
    const [valid, setValid] = useState(false);

    const [name, setName] = useState(props.achievement ? props.achievement.name : '');
    const [description, setDescription] = useState(props.achievement ? props.achievement.description : '');

    const [alias, setAlias] = useState(props.achievement ? props.achievement.alias : '');
    const [aliasValid, setAliasValid] = useState(true);

    const [reward, setReward] = useState(props.achievement ? props.achievement.reward : 0);
    const [repeatable, setRepeatable] = useState(props.achievement ? props.achievement.repeatable : false);

    const [form, setForm] = useState(null);

    //const [successModal, setSuccessModal] = useState(false);
    //const [failureModal, setFailureModal] = useState(false);
	//const [update, setUpdate] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        setReward(props.achievement ? props.achievement.reward : 0);
    }, [props.achievement]);

    useEffect(() => {
        if((!props.achievement && !aliasValid) || !name || !description || isNaN(reward)) {        
            setValid(false);
        } else {
            setValid(true);
        }
    }, [props.achievement, alias, aliasValid, name, description, reward]);

    useEffect(() => {
        const valid = props.verifyAlias('achievement', alias);
        setAliasValid(valid);
    }, [alias]);

    const cancelChanges = () =>
    {
        setName(props.achievement ? props.achievement.name : '');
        setDescription(props.achievement ? props.achievement.description : '');
        setAlias(props.achievement ? props.achievement.alias : '');
        setEditing(false);
    }

    function deleteItem() {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/achievement/delete`,
            {
                gamename: props.game.name,
                alias: props.achievement.alias
            }, {
                withCredentials: true
            }
        )
        .then((result) => {
            props.refresh();
        })
        .catch(error => {
            setModalMessage(error.errorMessage);
        });
    }

    const submit = (value) => 
    {
        const body = {
            gamename: props.game.name,
            alias: props.achievement ? props.achievement.alias : alias,
            achievement: name,
            description: description,
            reward: reward,
            repeatable: repeatable
        }

        var url = '';

        if(props.achievement) {
            url = `${process.env.REACT_APP_QUDO_SERVER}/game/achievement/update`;
        } else {
            url = `${process.env.REACT_APP_QUDO_SERVER}/game/achievement/create`;
        }

        QUDOServer.post(url, body, { withCredentials: true })
        .then(result => {
            setModalMessage('Success');
            //setSuccessModal(true);

            reset();
            props.achievementAdded(value => !value);
            //props.refresh();
        })
        .catch(error => {
            setModalMessage(error.errorMessage);
            //setFailureModal(true);

            //reset();
            //props.refresh();
        });
    }

    const reset = () => {
        form.reset();
    }

    const repeatableTooltip = (
        <Tooltip placement="right" className="">
            <span className="text-light"><small>
                Repeatable achievements can be completed multiple times.<br/>---<br/>If you have any suggestion for a new feature, please let us know!
            </small></span>
        </Tooltip>
    );

    const addAchievement = !props.achievement ?
    (
        <div className="col-12">
            <Form ref={(el) => setForm(el)}>
                <NameInput 
                    set={setAlias}
                    title="Alias"
                    disabled={props.achievement}
                    pattern={regexes.achievementAlias}
                    placeholder={props.achievement ? props.achievement.alias : "ach1"}
                    invalid={!aliasValid}
                    errorMessage={'Alias is already being used!'}
                />
                <NameInput 
                    set={setName} 
                    title="Achievement Name"
                    pattern={regexes.achievementName}
                    initial={props.achievement ? props.achievement.name : ""}
                    placeholder={props.achievement ? props.achievement.name : "Ended level 1"}
                />
                <TextAreaInput 
                    set={setDescription} 
                    title="Description"
                    initial={props.achievement ? props.achievement.description : ""}
                    placeholder={props.achievement ? props.achievement.description : "A nice achievement description"}
                />
                <NumberedRange 
                    max={props.balance ? Number(props.balance.split(' ')[0]) : 0} 
                    min={0}
                    initial={props.achievement ? props.achievement.reward : reward}
                    title="Reward"
                    unit="QUDO"
                    classes="yellow-qudo"
                    setValue={setReward}
                    value={reward}
                />

                <div className="row mt-1 mb-3 mx-2 user-select-none">
                    <div className="col text-right" onClick={() => setRepeatable(!repeatable)}>
                        <small>
                            <span>Repeatable</span>
                            <OverlayTrigger placement="right" overlay={repeatableTooltip}>
                                <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                            </OverlayTrigger>
                        </small>
                        <input
                            className="ml-2 align-middle"
                            type="checkbox"
                            checked={repeatable}
                            onChange={() => setRepeatable(!repeatable)}
                        />
                    </div>
                </div>

                <div className="mt-4"></div>

                <ButtonInput
                    trigger
                    label="Create Achievement" 
                    set={submit} 
                    disabled={!valid}
                    style={
                        !valid
                        ? {cursor: 'initial'}
                        : {backgroundColor: "#fbd731"}
                    }
                />
                {
                    props.achievement &&
                    <ButtonInput
                        trigger
                        label="Delete Achievement"
                        set={deleteItem} 
                        style={{backgroundColor: "#f44336"}}
                    />
                }
            </Form>
        </div>
    )
    :
    null;

    const editAchievement = props.achievement && editing ?
    (
        <>
            <div className="col-12">
                <Form ref={(el) => setForm(el)}>
                    <NameInput 
                        set={setAlias}
                        title="Alias"
                        disabled={props.achievement}
                        pattern={regexes.achievementAlias}
                        placeholder={props.achievement ? props.achievement.alias : "ach1"}
                        invalid={props.achievement ? false : !aliasValid}
                        errorMessage={'Alias is already being used!'}
                    />
                    <NameInput 
                        set={setName} 
                        title="Achievement Name"
                        pattern={regexes.achievementName}
                        initial={props.achievement ? props.achievement.name : ""}
                        placeholder={props.achievement ? props.achievement.name : "Ended level 1"}
                    />
                    <TextAreaInput 
                        set={setDescription} 
                        title="Description"
                        initial={props.achievement ? props.achievement.description : ""}
                        placeholder={props.achievement ? props.achievement.description : "A nice achievement description"}
                    />
                    <NumberedRange 
                        max={props.balance ? Number(props.balance.split(' ')[0]) : 0} 
                        min={0}
                        initial={props.achievement ? props.achievement.reward : reward}
                        title="Reward"
                        unit="QUDO"
                        classes="yellow-qudo"
                        setValue={setReward}
                        value={reward}
                    />
                    <div className="row mt-1 mb-3 mx-2 user-select-none">
                        <div className="col text-right" onClick={() => setRepeatable(!repeatable)}>
                            <small>
                                <span>Repeatable</span>
                                <OverlayTrigger placement="right" overlay={repeatableTooltip}>
                                    <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                                </OverlayTrigger>
                            </small>
                            <input
                                className="ml-2 align-middle"
                                type="checkbox"
                                checked={repeatable}
                                onChange={() => setRepeatable(!repeatable)}
                            />
                        </div>
                    </div>

                    <div className="mt-4"></div>
                </Form>
            </div>
            <div className="row col-12 pl-0 pr-0 mr-0 ml-0">
                <div className="col-6 button text-center red pb-1 pt-1 cursor-pointer"
                    onClick={() => cancelChanges()}>
                    CANCEL
                </div>
                <div className={`col-6 button text-center green pb-1 pt-1 cursor-pointer`}
                    onClick={(e) => {
                        submit(e);
                    }}
                >
                    SAVE
                </div>
            </div>
        </>
    )
    :
    null;

    const viewAchievement = props.achievement && !editing ?
    (
        <>
            <div className="mb-3 mt-4 col-12 border-bottom cursor-pointer"
                onClick={() => setEditing(true)}>
				<h1 className="custom-header mb-4">Achievement Name</h1>
				<p>{props.achievement.name}</p>
			</div>
            <div className="mb-3 mt-4 col-12 border-bottom cursor-pointer"
                onClick={() => setEditing(true)}>
				<h1 className="custom-header mb-4">Alias</h1>
				<p>{props.achievement.alias ? props.achievement.alias : ''}</p>
			</div>
            <div className="mb-3 mt-4 col-12 border-bottom cursor-pointer"
                onClick={() => setEditing(true)}>
				<h1 className="custom-header mb-4">Description</h1>
				<p>{props.achievement.description ? props.achievement.description : ''}</p>
			</div>
            <div className={`mt-4 col-12 ${props.achievement.repeatable && "border-bottom"} cursor-pointer`}
                onClick={() => setEditing(true)}>
				<h1 className="custom-header mb-4">Reward</h1>
				<p>{props.achievement.reward}</p>
			</div>
            {props.achievement.repeatable && (
                <div className="mt-4 col-12 cursor-pointer"
                onClick={() => setEditing(true)}>
                <h1 className="custom-header mb-2">Repeatable</h1>
                </div>
            )}
        </>
    )
    :
    null;

    return (
        <>
                <div>
                    {/* TITLE */}
                    <div
                        className={`text-break col-12 mb-3 edit-screen-menu mt-0`}>
                        {props.achievement ? "Edit" : "Add"} Achievements
                    </div>

                    <div className="mx-auto border custom-rounded mb-4 pt-2">
                        {!props.achievement && 
                            addAchievement
                        }
                        {(props.achievement && editing) &&
                            editAchievement
                        }   
                        {(props.achievement && !editing) &&
                            viewAchievement
                        }
                    </div>

                    {/* MODALS */}
                    <>
                        {/*}
                        <SuccessModal 
                            show={successModal} 
                            hide={() => {
                                setSuccessModal(!successModal);
                                props.refresh();
                            }}
                        />
                        <FailureModal
                            show={failureModal}
                            hide={() => {
                                setFailureModal(!failureModal);
                                //props.refresh();
                            }}
                        />
                        {*/}

                        <MessageModal
                            show={!!modalMessage}
                            message={modalMessage}
                            hide={() => {
                                setModalMessage('');
                            }}
                        />
                    </>

                    {props.achievement &&
                        <ButtonInput
                            trigger
                            label="Delete Achievement"
                            set={deleteItem} 
                            style={{backgroundColor: "#f44336", color:'#ffffff'}}
                        />
                    }
                </div>
        </>
    );
}
