import React, {useState, useEffect} from "react";

import GamedevServices from "./gamedev/GamedevServices";
import ServiceMarketplace from "./gamedev/ServiceMarketplace";

import useWindowSize from "../helpers/useWindowSize";

export default function GamedevMarketplace(props) {
    const windowSize = useWindowSize();

    const [activeTab, setActiveTab] = useState('marketplace');

    /*
    useEffect(() => {
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);

		//console.log(url, params);

		const tabName = params.get('tab');

		if(tabName && tabName === 'services') {
			setActiveTab('services');
		} else {
			setActiveTab('marketplace');
        }
    }, []);
    */

    return(
        <div className="row gamedevact-edit-screen-container">
            <div className="col-12 text-left">
                <div id="title" className="mt-4">
                    Marketplace
                </div>
            </div>

            <div className="mt-4 w-100">
                <div className="row col-12 w-100 gamedev-marketplace-center-subtitle">
                    <div
                        className={`${windowSize.width < 576 ? 'text-center' : 'text-left'} ${activeTab === 'marketplace' ? "highlight" : "faded"} subtitle`}
                    >
                        <div
                            onClick={() => setActiveTab('marketplace')}
                            className="d-inline-block enable-click"
                        >
                            Marketplace
                        </div>
                    </div>
                    <div
                        className={`${windowSize.width < 576 ? 'text-center' : 'text-left'} ${activeTab === 'services' ? "highlight" : "faded"} ml-3 subtitle`}
                    >
                        <div
                            onClick={() => setActiveTab('services')}
                            className="d-inline-block enable-click"
                        >
                            Current Services
                        </div>
                    </div>
                </div>
            </div>

            {activeTab === 'marketplace' && (
                <div className="col-12 mx-auto mt-3 mb-5">
                    <ServiceMarketplace/>
                </div>
            )}

            {activeTab === 'services' && (
                <div className="col-12 mx-auto mt-3 mb-5">
                    <GamedevServices/>
                </div>
            )}
        </div>
    );
}
