import React from "react";
import {connect} from "react-redux";
import {Form, InputGroup, Dropdown} from "react-bootstrap";

import QUDOServer from "../../helpers/QUDOServerConnection";

import ServiceCard from "../provider/serviceCard";
import ServiceDetailsModal from '../components/modals/ServiceDetailsModal';

const arrowDown = require("../../images/definitive/icn-down.png");

class ServiceMarketplace extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            services: [],
            searchTerm: "",
            filter: "All Service Providers"
        }
    }

    componentDidMount() {
        this.getServices();
    }

    getServices() {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/services/getexistent`)
        .then(result => {
            //console.log(result.data);
            this.setState({
                services: result.data
            });
        });
    }

    search = async (event) => {
        event.preventDefault();
        await this.setState({
            searchTerm: event.target.value
        });
    }

    inspectService = async (service) => {
        await this.setState({
            selectedService: service === this.state.selectedService ? null : service
        });
    }

    filter = async (event) => {
        await this.setState({
            filter: event
        });
    }

    filterAndSearch = (services) => {
        if(Array.isArray(services)) {
            return services.map(i => {
                return (
                    (
                        i.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())
                        || (i.display_name && i.display_name.toLowerCase().includes(this.state.searchTerm.toLowerCase()))
                    ) && (
                        <ServiceCard
                            key={i.name}
                            service={i}
                            highlight={this.state.selectedService}
                            onClick={this.inspectService}
                            showProvider={true}
                            className="col-12 col-lg-6"
                        />
                    )
                );
            });
        }
    }

	render() {
        return (
            <>
                <Form className="row">
                    <InputGroup className="col-12 col-md-6 mb-2">
                        <Form.Control 
                            id="achievementsFilterField"
                            value={this.state.searchTerm}
                            placeholder="search" 
                            onChange={this.search}
                        />
                    </InputGroup>

                    <div className="col-12 col-md-6 mb-2">
                        <Dropdown id="achievementsFilterField">
                            <Dropdown.Toggle as="div" className="my-auto" style={{width: "100%", paddingLeft: "1rem"}}>
                                <div className="d-inline-block">{this.state.filter}</div>
                                <img src={arrowDown} className="d-inline-block edit-profile-dropdown-arrow" alt="arrow down"/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{width: "100%"}}>
                                <Dropdown.Item onSelect={this.filter} eventKey="All Service Providers" id="edit-profile-filter-items">
                                    {/* Select a Service Provider */}
                                    All Service Providers
                                </Dropdown.Item>
                                {Object.keys(this.state.services).map(i => 
                                    <Dropdown.Item key={i} onSelect={this.filter} eventKey={i} id="edit-profile-filter-items">{i}</Dropdown.Item>
                                )}
                            </Dropdown.Menu >
                        </Dropdown>
                    </div>
                </Form>

                <div className="row mt-3">
                    {Object.keys(this.state.services).length > 0 ? (
                        Object.keys(this.state.services).map(i => {
                            return (
                                (this.state.filter === "All Service Providers" || i === this.state.filter)
                                && this.state.services[i].length > 0
                                && this.filterAndSearch(this.state.services[i])
                            );
                        })
                    ) : (
                        <div id="title" className='col-12 mt-2'>
                            No services available at the moment
                        </div>
                    )}
                </div>

                {/* This statement looks redundant, but it forces the re-reader everytime the modal opens */}
                {this.state.selectedService &&
                <>
                    <ServiceDetailsModal
                        service={this.state.selectedService.name}
                        show={this.state.selectedService}
                        setShow={() => {
                            this.setState({selectedService: null});
                        }}
                    />
                </>
                }
            </>
        );
	}
}

function mapStateToProps(state) {
	return { 
        balance: state.balance
	}
}

export default connect(mapStateToProps, null)(ServiceMarketplace);
