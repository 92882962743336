import { NumberedRange } from "components/components/forms/range";
import { NameInput } from "components/components/forms/text";
import React, { useEffect, useState } from "react";

export default function SteamReviewForm({ setData, defaultReward, challenge }) {

    const [steamId, setSteamId] = useState('');
    const [reward, setReward] = useState(defaultReward);
    const [minMinutes, setMinMinutes] = useState(0);

    useEffect(() => {
        setData({
            steamId, reward, minMinutes
        });
    }, [steamId, reward, minMinutes]);

    useEffect(() => {
        if (challenge) {
            setSteamId(challenge.conditions.steamreview.data.steamId);
            setReward(challenge.reward);
            setMinMinutes(challenge.conditions.gameplaytime.data.minMinutes);
        }
    }, [challenge])

    return (
        <>
            <div className="mb-4">
                If you have a game on Steam,
                give rewards to the QUDO community by setting up a Steam Review challenge.
                <br/>
                You need to provide the game SteamID and you can setup a minimum play time.
            </div>

            <NameInput
                initial={steamId}
                set={setSteamId}
                title="Game Steam ID"
                placeholder={'xxxxxxx'}
                ignoreValidation={true}
            />
            <NumberedRange
                max={60 * 10}
                min={0}
                initial={0}
                step={1}
                title="Time Played"
                unit="min"
                classes="yellow-qudo"
                setValue={setMinMinutes}
                value={minMinutes}
            />
            <NumberedRange
                max={100}
                min={0.0010}
                set={0.0001}
                initial={defaultReward}
                title="Reward"
                unit="QUDO"
                classes="yellow-qudo"
                setValue={setReward}
                value={reward}
            />
        </>
    );
}