import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import moment from 'moment';

import './bugReportsModal.scss';
import {ButtonInput} from '../../forms/buttons';
import ChatMessagesModal from '../chatMessagesModal/chatMessagesModal';

export default function BugReportModal(props)
{
    const [showMessages, setShowMessages] = useState(false);

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.hide}
                centered
            >
                <Modal.Body>
                    <div className="pb-2 row">
                        <div className="col-2">
                            <img
                                style={{maxWidth: '95%'}}
                                src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${props.gameName}`}
                                alt="game"
                            />
                        </div>
                        <div className="col-7 align-bottom">
                            {props.gameDisplayName ? props.gameDisplayName : props.gameName}
                        </div>
                        <div className="col-3 text-right align-bottom date">
                            {moment(props.reportTimestamp).format('YYYY/MM/DD')}
                        </div>
                    </div>
                    <div className={`pb-2 mt-3 pt-2 row bordered ${!props.closed ? 'neutral' : props.accepted ? 'accepted' : 'rejected' }`}>
                        <div className="col-7 text-left">
                            <div className="bug-title">
                                {props.title}
                            </div>
                            <div className="bug-title">
                                {props.category}
                            </div>
                        </div>
                        {(props.closed && props.accepted) && (
                            <div className="col-5 text-right">
                                <div className="reward-amount">
                                    {props.reward.toFixed(4)}
                                </div>
                                <div className="reward-amount qudo">
                                    QUDO
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row mb-2">
                        <div className="col-12 title-description">
                            Bug Description
                        </div>
                        <div className="col-12 description">
                            {props.description}
                        </div>
                    </div>
                    {(props.closed && props.gamedevFeedback) && (
                        <div className="row">
                            <div className="col-12 title-description">
                                {props.accepted ? "Game Developer Feedback" : "Reason for Rejection"}
                            </div>
                            <div className="col-12 description">
                                {props.gamedevFeedback}
                            </div>
                        </div>
                    )}
                    {(!props.closed || true) && (
                        <div className="col-12">
                            <ButtonInput
                                trigger
                                label={"Messages"}
                                set={setShowMessages}
                                style={{backgroundColor: "#fbd731", maxWidth: '175px', margin: 'auto'}}
                            />
                        </div>
                    )}
                    <div className={`d-flex position-relative align-middle bordered ${!props.closed ? 'neutral' : props.accepted ? 'accepted' : 'rejected'}`} 
                    style={{ margin: "1rem -4rem 0rem" }}>
                        <div className={`text-center col-12 pt-3 bug-status ${!props.closed ? 'neutral' : props.accepted ? 'accepted' : 'rejected'}`}>
                            {!props.closed ? 'Awaiting' : props.accepted ? 'Accepted' : 'Rejected'}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {showMessages && (
                <ChatMessagesModal
                    show={showMessages}
                    setShow={setShowMessages}
                    reportID={props.reportID}
                />
            )}
        </>
    )   
}