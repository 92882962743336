import React, {useState, useEffect} from 'react';
import moment from "moment";
import {Modal} from "react-bootstrap";

import useWindowSize from "../../../helpers/useWindowSize";
import QUDOServer from "../../../helpers/QUDOServerConnection";

import ServiceCard from "../../provider/serviceCard";
import ServiceDetailsModal from "../modals/ServiceDetailsModal";

const fallBack = require("../../../images/fallbackAvatar.png");

export default function ProviderDetailsModal(props) {
    const windowSize = useWindowSize();

    const [provider, setProvider] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [serviceDetails, setServiceDetails] = useState(null);
	//const [showFullDescription, setShowFullDescription] = useState(true);

    useEffect(() => {
		if(props.provider) {
            QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/providerinfo?provider=${props.provider}`)
            .then(response => {
                //console.log(response.data);
                setProvider(response.data);
            })
            .catch(err => {
                //console.log(err);
                setErrorMessage('This service provider was not found in the QUDO ecosystem.');
            });
        }
    }, [props.provider]);

    /*
	useEffect(() => {
		if(document.getElementById('provider_description')) {
			const descriptionHeigth = document.getElementById('provider_description').clientHeight;

			if(descriptionHeigth > 350) {
				setShowFullDescription(false);
			} else {
				setShowFullDescription(true);
			}
		}
	}, [provider]);
    */

    function cleanOnHide() {
        props.setShow(false);

        setProvider(null);
        setErrorMessage(null);
        setServiceDetails(null);
        //setShowFullDescription(true);
    }

    return (
        <>
        <Modal show={props.show} onHide={cleanOnHide}>
			<Modal.Body
				id="gamedetails-modal"
				style={{
                    opacity: 1,
                    justifyContent: 'initial',
					paddingTop: '3rem',
					paddingBottom: '1rem',
					paddingLeft: '2.5%',
					paddingRight: '2.5%'
				}}
			>
                <Modal.Header
                    closeButton
                    className='modal-header-position'
                />

				<div className="row with-scroll">
					{windowSize && windowSize.width < 768 ? (
						<div className="col-12 mb-4">
							<div className="text-center modal-title-big m-0">
                                {provider ? (
                                    provider.display_name ? provider.display_name : provider.username
                                    //provider.display_name ? provider.display_name + ' (aka ' + provider.username + ')' : provider.username
                                ) : props.provider}
							</div>
						</div>
					) : null}

					<div className={`col-12 col-md-4 text-center`}>
                        <img
                            src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbyprovider?provider=${props.provider}&rand=${Date.now()}`}
                            onError={i => i.target.src = fallBack}
                            className="details-modal-image img-fluid mb-4"
                            style={{maxHeight: '250px'}}
                            alt="Service Provider Banner"
                        />
					</div>

					<div className="col-12 col-md-8">
						{windowSize && windowSize.width >= 768 ? (
							<div className="modal-title-big m-0">
                                {provider ? (
                                    provider.display_name ? provider.display_name : provider.username
                                    //provider.display_name ? provider.display_name + ' (aka ' + provider.username + ')' : provider.username
                                ) : props.provider}
							</div>
						) : null}

                        {errorMessage &&
						<div className={`edit-screen-text-area mt-2`}>
                            {errorMessage}
						</div>
                        }

                        <div className="mb-4">
                            {provider && provider.dateJoined &&
                            <>
                                <div className={`section-title mt-4 mb-1`}>
                                    Joined QUDO on:
                                </div>
                                <div className={`edit-screen-text-area`}>
                                    {moment(provider.dateJoined).format("MMMM DD, YYYY")}
                                </div>
                            </>
                            }

                            {provider && provider.website &&
                            <>
                                <div className={`section-title mt-4 mb-1`}>
                                    Website
                                </div>
                                <a
                                    style={{ color: "black" }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={provider.website}
                                >
                                    {provider.website}
                                </a>
                            </>
                            }
                        </div>
                    </div>

                    {provider && provider.description &&
					<div className={`col-12 mb-4 ${windowSize && windowSize.width < 768 ? '' : 'mt-4'}`}>
						<div className="section-title mb-1">
							Description
						</div>
						<div id="provider_description" className={`edit-screen-text-area`}>
                            {provider.description}
						</div>
                        {/*}
						<div id="provider_description" className={`edit-screen-text-area`}>
							{showFullDescription ? provider.description : String(provider.description).slice(0, 250) + '...'}
						</div>
                        {*/}

						{/*
                        !showFullDescription && (
							<div className="text-center">
								<div
									className="mb-2 enable-click section-title"
									style={{display: 'inline-block'}}
									onClick={() => setShowFullDescription(true)}
								>
									Read more...
								</div>
							</div>
						)
                        */}
					</div>
                    }

                    {provider && provider.services && provider.services.length > 0 &&
					<div className="col-12 my-2">
						<div className="section-title mb-3">
							Available Services
						</div>
                        <div className="row">
                            {provider.services.map(service => {
                                return (
                                    <ServiceCard
                                        key={service.name}
                                        service={service}
                                        onClick={() => setServiceDetails(service.name)}
                                        className="col-12 col-lg-6"
                                    />
                                );
                            })}
                        </div>
                    </div>
                    }
                </div>
            </Modal.Body>
        </Modal>

        {serviceDetails &&
            <ServiceDetailsModal
                service={serviceDetails}
                show={serviceDetails}
                setShow={setServiceDetails}
            />
        }
        </>
    );
}
