import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';

export default function SpendingsPerVersion(props)
{
    const allBugs = props.bugReports;
    const [chartReady, setChartReady] = useState(false);
    const colors = ['#fcd902', '#ededed', '#282725', '#b2b2b2', '#b2b2b2', 'grey', 'black', 'yellow'];

    useEffect(() =>
    {
        if (allBugs)
        {
            setChartReady(true);
        }
    }, [props.bugReports])

    useEffect(() =>
    {
        if (props.bugReports)
        {   
            chart();
        }
    }, [chartReady]);

    const setupValues = availableVersions =>
    {
        const values = [];
        let total = 0;

        availableVersions.forEach((version, index) =>
        {

            const spending = allBugs.filter(bug => bug.gameVersion === version && bug.closed).reduce((acc, bug) => 
            {
                return acc + bug.reward;
            }, 0);

            total += spending;
            
            const objectToPush = {
                y: spending,
                color: colors[index],
                name: version
            };
            
            values.push(objectToPush);
        });

        return [values, total];
    }

    const availableVersions = () =>
    {
        const tempVersions = [];

        if (allBugs && allBugs.length > 0)
        {
            allBugs.forEach(bug =>
            {
                if (!tempVersions.some(version => version === bug.gameVersion))
                {
                    tempVersions.push(bug.gameVersion);
                }
            });
        }

        return tempVersions;
    }

    const chart = () => 
    {
        const versionsFound = availableVersions();
        const [values, total] = setupValues(versionsFound);
        let title = `<strong>${total}</strong><br>QUDO`;
    
        return Highcharts.chart('spendingsPerVersion', {
            chart: {
                type: 'pie',
                backgroundColor: null
            },
            title: {
                text: title,
                align: 'center',
                verticalAlign: 'middle',
                y: 10,
                style: {
                    color: '#fcd902',
                    fontSize: '25px'
                }
            },
            subtitle: {
                text: 'Total Spending',
                verticalAlign: 'middle',
                y: 50,
                style: {
                    color: '#000000',
                    fontSize: '17px'
                }
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    innerSize: '75%',
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [{
                name: 'Bugs',
                data: values,
                dataLabels: {
                    enabled: false
                }
            }]
        });
	};

    const noData = (
        <div className="text-align-center border-bottom custom-height">
            No values to be shown
        </div>
    );

    return (
        <>
            <div className="borders mt-4">
                <div className="mb-3">
                    <div className="col-12 header pt-4">
                        <strong>Spendings per Version</strong>
                    </div>
                </div>
                {chartReady ?
                    <div id="spendingsPerVersion"></div>
                :
                    noData
                }
            </div>
        </>
    )
}