import React, {useState} from "react";

import QUDOServer from "../../helpers/QUDOServerConnection";

import {ImageUploadModal} from "../../components/components/modals/ImageUploadModal";

const fallBackAvatar = require("../../images/definitive/icon-avatar.png");

export default function AvatarUpload(props) {
  const [update, setUpdate] = useState(Date.now());

  const [upload, setUpload] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  async function saveImage(image) {
    //console.log(image);

    const body = new FormData();

    body.append("avatar", image);
    //body.append("username", props.info.username);

    const config = {
        withCredentials: true
    }

    await QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/profile/avatar/replace/${props.info.type}`, body, config);

    setUploadedImage(URL.createObjectURL(image));
  }

  async function deleteAvatar() {
    const body = new FormData();

    const config = {
        withCredentials: true
    }

    await QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/profile/avatar/delete`, body, config);

    setUpdate(Date.now());
  }

  return(
    <>
    <div className="w-100 text-center">
      <div style={{fontWeight: "bold"}}>
        Profile Avatar
      </div>
      <div>
        <img
          alt="users avatar"
          src={uploadedImage || `${process.env.REACT_APP_QUDO_SERVER}/profile/avatar?rand=${update}`}
          onError={(i) => (i.target.src = fallBackAvatar)}
          className="rounded-circle my-3 img-fluid"
          style={{ maxWidth: "240px" }}
        />
      </div>
      <div>
        <div
          onClick={() => setUpload(true)}
          className="edit-profile-save-btn mb-1"
          style={{position: 'initial', margin: 'auto', maxWidth: '300px', backgroundColor: '#b2b2b2'}}
        >
          Change Avatar
        </div>
        <div
          onClick={() => deleteAvatar()}
          className="edit-profile-save-btn mb-1"
          style={{position: 'initial', margin: 'auto', maxWidth: '300px', backgroundColor: 'rgb(244, 67, 54)'}}
        >
          Delete Avatar
        </div>
      </div>
    </div>

    {upload && (
      <ImageUploadModal
        title='Profile Avatar'
        show={upload}
        hide={() => setUpload(false)}
        saveImage={saveImage}
        saveFormat={'image/png'}
        //maxSizeMB={2}
        maxWidth={1024}
        maxHeight={1024}
        aspectRatios={[
          {
            name: '1:1',
            ratio: Number(1/1)
          }
        ]}
      />
    )}
    </>
  );
}
