import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import GuildCard from './guildCard';
import GuildList from './guildList';

export default function UserGuildSettings() {
    const currentUser = useSelector(state => state.info);
    const [showGuildList, setShowGuildList] = useState(false);

    if(
        currentUser && (
            currentUser.type === process.env.REACT_APP_USER_TYPE
            || currentUser.type === process.env.REACT_APP_GAMEDEV_TYPE
            || currentUser.type === process.env.REACT_APP_PROVIDER_TYPE
        )
    ) {
        return(
            <div>
                {currentUser && currentUser.guild ? (
                    <>
                        <GuildCard
                            guild={currentUser.guild.username}
                            guildName={currentUser.guild.display_name ? currentUser.guild.display_name : currentUser.guild.username}
                            memberCount={Array.isArray(currentUser.guild.members) ? currentUser.guild.members.length : '???'}
                            showJoinButton={false}
                            showLeaveButton={true}
                            pendingApproval={false}
                        />
                    </>
                ) : (
                    <>
                    {currentUser && currentUser.pendingGuildRequest ? (
                        <GuildCard
                            guild={currentUser.pendingGuildRequest.guild.username}
                            guildName={currentUser.pendingGuildRequest.guild.display_name ? currentUser.pendingGuildRequest.guild.display_name : currentUser.pendingGuildRequest.guild.username}
                            memberCount={Array.isArray(currentUser.pendingGuildRequest.guild.members) ? currentUser.pendingGuildRequest.guild.members.length : '???'}
                            showJoinButton={false}
                            showLeaveButton={false}
                            pendingApproval={true}
                        />
                    ) : (
                        <>
                        <div className='mb-3'>
                            You haven't joined a Gaming Guild yet
                        </div>
                        <div>
                            {showGuildList ? (
                                <GuildList filter={true}/>
                            ) : (
                                <div
                                    onClick={() => setShowGuildList(true)}
                                    className='enable-click align-self-center text-center py-3'
                                    style={{
                                        color: '#fcd902',
                                        backgroundColor: '#282725',
                                        borderRadius: '10px'
                                    }}
                                >
                                    JOIN A GUILD
                                </div>
                            )}
                        </div>
                        </>
                    )}
                    </>
                )}
            </div>
        );
    } else {
        return null;
    }
}
