import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

//import useDebounce from '../helpers/useDebounce';
import useWindowSize from '../helpers/useWindowSize';
import { ButtonInput } from "../components/components/forms/buttons";

import Service from "./provider/service";
import ServicesList from "./provider/servicesList";
import ProviderTitleContainer from "./provider/providerTitleContainer/providerTitleContainer";
import NavigationProvider from "./provider/navigation/navigationProvider";
import OverallBalanceProvider from './provider/overallBalance/overallBalanceProvider';
import QUDOServer from "../helpers/QUDOServerConnection";
import IncomeServicesProvider from './provider/earnings/incomeService/IncomeServiceProvider';

function LeftColumn(props) {
    function editService(service) {
        if(service === props.service) {
            props.setService(null);
        } else {
            props.setService(service);
        }

        //props.refresh();
    }

    return (
        <ServicesList 
            refresh={props.refresh}
            refreshKeys={props.refreshKeys}
            editService={(service) => editService(service)}
            highlight={props.service}
            key={props.refreshKeys}
            service={props.service}
            newStyle={true}
        />
    );
}

function RightColumn(props) {
    return (
        <Service
            key={Math.random()}
            refresh={props.refresh}
            service={props.service}
            setService={props.setService}
        />
    );
}

export default function ProviderActivity(props) {
    const windowSize = useWindowSize();
    const isMobile = (windowSize && windowSize.width < 992);

    const info = useSelector(state => state.info);
    const balance = useSelector(state => state.balance);

    const [modal, showModal] = useState(false);
    const [editing, setEditing] = useState("");
    //const [activepage, setActivePage] = useState("about");
    const [refreshKeys, setRefreshKeys] = useState(Math.random());

    const [service, setService] = useState();
    //const [loading, setLoading] = useState(false);
    //const [canAddServices, setCanAddServices] = useState(true);

    const [balances, setBalances] = useState();
    const [hiredServices, setHiredServices] = useState();

    function refresh() {
        setRefreshKeys(Math.random());
        setService(null);
        showModal(false);
    }

    useEffect(() =>
    {
        getBalanceHistory();
        getHiredServices();
    }, []);

    const getBalanceHistory = async () =>
    {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/api/history/balance`, {
            username: info.username
        })
        .then(resp =>
        {
            if (!resp.errorMessage)
            {
                setBalances(resp.data.balances);
            }
        })
        .catch(err =>
        {
            setBalances([]);
        }); 
    }

    const getHiredServices = async () =>
    {

        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/provider/services/hired`, {
            withCredentials: true
        })
        .then(result => 
        {
            if (!result.errorMessage)
            {
                setHiredServices(result.data);
            }
        });
    }

    return(
        <div className="gamedevact-edit-screen-container row">
            <div className="row col-12 mb-3">
                <ProviderTitleContainer username={info.username} displayName={info.display_name ? info.display_name : info.username}/>
            </div>
            <div className="row col-12 mb-3">
                <NavigationProvider/>
            </div>
            <div className="col-12 row mb-5 mx-auto">
                <div className="col-12 col-lg-4 mb-5">
                    <div className="edit-screen-menu">
                        {/* Products */}
                        Services
                    </div>

                    {isMobile && (
                        <div className="card-body pt-2 pb-4">
                            <ButtonInput
                                label="Add New Service"
                                set={showModal}
                                validColor="#fbd731"
                                trigger
                            />
                        </div>
                    )}

                    <LeftColumn
                        service={service}
                        setService={setService}
                        refresh={refresh}
                        refreshKeys={refreshKeys}
                        setEditing={setEditing}
                    />
                </div>

                {isMobile ? (
                    <Modal
                        show={modal || service}
                        onHide={() => {
                            setService(null);
                            showModal(false);
                        }}
                    >
                        <Modal.Body>
                            <Modal.Header
                                closeButton
                                className='modal-header-position'
                            />

                            <div className="pt-3 pb-4">
                                <RightColumn
                                    service={service}
                                    setService={setService}
                                    refresh={refresh}
                                />
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : (
                    <div className="col-lg-4">
                        <RightColumn 
                            service={service}
                            setService={setService}
                            refresh={refresh}
                        />
                    </div>
                )}
                <div className="col-lg-4 col-12">
                    <div className="mb-5">
                        <OverallBalanceProvider balances={balances} balance={balance}/>
                    </div>
                    <IncomeServicesProvider hiredServices={hiredServices} />
                </div>
            </div>
        </div>
    );
}
