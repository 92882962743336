import React, {useState, useEffect} from 'react';
import SocketIOClient from 'socket.io-client';
import {useSelector} from 'react-redux';
import {Modal} from "react-bootstrap";

import './chatMessagesModal.scss';
import {MessageModal} from "../modals.js";
import {ButtonInput} from "../../forms/buttons";

import QUDOServer from '../../../../helpers/QUDOServerConnection';

export default function ChatMessagesModal(props) {
    const currentUser = useSelector(state => state.info);

    const [message, setMessage] = useState('');
    //const [loading, setLoading] = useState(false);
    const [messageList, setMessageList] = useState([]);
	const [modalMessage, setModalMessage] = useState('');

    useEffect(() => {
        if(currentUser) {
            getMessages();
            return socketSetup();
        }
    }, []);

    function socketSetup() {
        let socket_url = '';
        let socket_path = '';

        let secure = false;
        let rejectUnauthorized = false;

        if(String(process.env.REACT_APP_QUDO_SERVER).includes('/api')) {
            socket_url = String(process.env.REACT_APP_QUDO_SERVER).split('/api')[0];
            socket_path = '/api/socket.io';
        } else {
            socket_url = String(process.env.REACT_APP_QUDO_SERVER);
            socket_path = '/socket.io';
        }

        if(String(process.env.REACT_APP_QUDO_SERVER).includes('https://')) {
            secure = true;
            rejectUnauthorized = true;
        }

        const newSocket = SocketIOClient(socket_url, {
            path: socket_path,
            secure: secure,
            rejectUnauthorized: rejectUnauthorized,
            forceNew: true,
            reconnect: true,
            withCredentials: true
        });

        if(props.reportID) {
            newSocket.emit('bug_report_chat_connection', {
                reportID: props.reportID
            });

            newSocket.on('new_bug_report_message', (data) => {
                setMessageList((list) => [...list, data]);
                scrollToLastMessage();
            });
        } else if(props.serviceContractID) {
            newSocket.emit('hired_service_chat_connection', {
                serviceContractID: props.serviceContractID
            });

            newSocket.on('new_hired_service_message', (data) => {
                setMessageList((list) => [...list, data]);
                scrollToLastMessage();
            });
        }

        return function() {
            newSocket.close();
        }
    }

    function getMessages() {
        let URL = '';

        if(props.reportID) {
            URL = `${process.env.REACT_APP_QUDO_SERVER}/bugreport/messages?reportID=${props.reportID}`;
        } else if(props.serviceContractID) {
            URL = `${process.env.REACT_APP_QUDO_SERVER}/service/messages?serviceContractID=${props.serviceContractID}`;
        }

        if(URL) {
            //setLoading(true);

            QUDOServer.get(URL, {withCredentials: true})
            .then(response => {
                if(Array.isArray(response.data)) {
                    setMessageList(response.data);
                    scrollToLastMessage();
                }
            })
            .catch(error => {
                //console.log(error);
                setModalMessage(error.errorMessage);
            })
            .finally(() => {
                //setLoading(false);
            });
        }
    }

    function sendMessage() {
        if(!message) {
            return;
        }

        let URL = '';
        let RequestBody = null;

        if(props.reportID) {
            URL = `${process.env.REACT_APP_QUDO_SERVER}/bugreport/messages/send`;

            RequestBody = {
                reportID: props.reportID,
                message: message
            }
        } else if(props.serviceContractID) {
            URL = `${process.env.REACT_APP_QUDO_SERVER}/service/messages/send`;

            RequestBody = {
                serviceContractID: props.serviceContractID,
                message: message
            }
        }

        if(URL && RequestBody) {
            QUDOServer.post(URL, RequestBody, {withCredentials: true})
            .then(response => {
                //getMessages();
                setMessage('');
            })
            .catch(error => {
                //console.log(error);
                setModalMessage(error.errorMessage);
            });
        }
    }

    function scrollToLastMessage() {
        setTimeout(() => {
            var divToScrollTo = document.getElementById('last_message');

            if(divToScrollTo) {
                divToScrollTo.scrollIntoView();
            }
        }, 500);
    }

    return(
        <>
        <Modal
            show={props.show}
            onHide={props.setShow}
            centered={true}
        >
            <Modal.Body>
                <Modal.Header
                    closeButton
                    className='modal-header-position'
                />
                <div className='modal-title-big m-0'>
                    Messages
                </div>
                <div className='scroll-65 m-2' style={{minHeight: '350px', maxHeight: '500px'}}>
                    {(Array.isArray(messageList) && messageList.length > 0) ? (
                        <div>
                            {messageList.map((msg, index) => {
                                const msgSent = msg.from._id === currentUser._id;

                                return(
                                    <div
                                        id={(index === messageList.length - 1) ? 'last_message' : ''}
                                        style={msgSent ? {textAlign: 'right'} : {textAlign: 'left'}}
                                    >
                                        <div className='d-inline-block'>
                                            <div className={msgSent ? 'message-sent' : 'message-received'}>
                                                {msg.message}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div
                            id={'last_message'}
                            style={{textAlign: 'left'}}
                        >
                            <div className='d-inline-block'>
                                <div className={'message-empty'}>
                                    No messages were sent yet!
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    <div className='row'>
                        <div className='col-10'>
                            <textarea
                                placeholder='Enter your message here...'
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className='input-field-custom w-100'
                                style={{minHeight: '5rem'}}
                            />
                        </div>
                        <div className='col-2 m-auto pl-0'>
                            <ButtonInput
                                trigger
                                label={"Send"}
                                set={sendMessage}
                                style={{backgroundColor: "#fbd731", marginTop: '0px'}}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        {modalMessage && (
		    <MessageModal
			    show={modalMessage} 
				message={modalMessage}
				hide={() => setModalMessage('')}
			/>
        )}
        </>
    );
}
