import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import WalletMigration from './walletMigration';

export default function WalletMigrationCard(props) {
	const info = useSelector(state => state.info);

    const [migrationModal, setMigrationModal] = useState(false);

    if(info && info.qudoManaged) {
        return(
            <>
                <div
                    className='card roundcard'
                    //className='card roundcard shadow'
                    style={{color: '#fcd902', backgroundColor: '#282725', overflow: 'hidden'}}
                >
                    <div className="card-body p-4">
                        <div style={{fontWeight: 'bold'}}>
                            Migrate your TELOS account
                        </div>

                        <hr style={{width: '150%', marginLeft: '-15%', borderColor: 'gray'}}/>

                        <div style={{fontSize: '0.9rem'}}>
                            <div>
                                Unlock the full potential of blockchain technology by signing your own
                                transactions and becoming the true owner of your TELOS account
                            </div>
                        </div>

                        <div className='mt-4'>
                            <button
                                type="button"
                                className="std-button mb-0"
                                style={{backgroundColor: '#00cc69'}}
                                onClick={() => setMigrationModal(!migrationModal)}
                            >
                                <div
                                    className="text-center"
                                    style={{color: 'white', fontSize: '0.9rem'}}
                                >
                                    I'm ready to take control of my account
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

                {migrationModal && (
                    <WalletMigration
                        show={migrationModal}
                        hide={() => setMigrationModal(!migrationModal)}
                    />
                )}
            </>
        );
    } else {
        return null;
    }
}
