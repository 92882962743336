import React from "react"
import { Modal, Button } from "react-bootstrap"
import { Link } from "react-router-dom"

export function LoginMessageModal({show, setShow, message}) {
    return (
        <Modal
            show={show}
            onHide={() => setShow(null)}
            dialogClassName="meta-box scroll-100"
        >
            <Modal.Body className="">
                <div className="row meta-body">
                    <div className="col-12 login-main-text">
                        {message}
                    </div>
                    {message?.includes('verify your email') &&
                    <div className="col-12 login-second-text">
                        Check your Spam folder too!
                    </div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export function topMetaModal(setActivePage) {
    return (
        <div className="row meta-header text-center d-flex align-items-center p-0">
            <div
                className="meta-header-button"
                onClick={() => setActivePage("login")}
            >
                Sign In
            </div>
            <div className="meta-header-vertical-rule"></div>
            <div
                className="meta-header-button"
                onClick={() => setActivePage("register")}
            >
                Register
            </div>
        </div>
    )
}

export function MetaModal({show, setShow, logout, airdrop, showAirdrop}) {
    return (
        <Modal
            show={show}
            onHide={() => setShow(null)}
            dialogClassName="meta-box">
            <Modal.Body className="scroll-100">
                {showAirdrop && (
                    <div className="row mb-2">
                        <Button
                            //type="submit"
                            type="button"
                            className="form-logout-btn"
                            onClick={() => airdrop()}
                        >
                            QUDO Airdrop
                        </Button>
                    </div>
                )}

                <Link to={process.env.REACT_APP_USER_EDIT_PROFILE}>
                    <div
                        className="row"
                        style={{cursor: "pointer"}}
                    >
                        <Button
                            //type="submit"
                            type="button"
                            className="form-logout-btn"
                            onClick={() => {window.scrollTo(0, 0)}}
                        >
                            Edit Profile
                        </Button>
                    </div>
                </Link>

                <div className="row mt-2">
                    <Button
                        //type="submit"
                        type="button"
                        className="form-logout-btn"
                        onClick={() => logout()}
                    >
                        Logout
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
