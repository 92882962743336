import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

//import * as actions from "actions";

import QUDOServer from "helpers/QUDOServerConnection";
import { sendFacebookPixelEvent } from "helpers/FacebookPixel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import { ButtonInput } from "components/components/forms/buttons";
import { DropdownInput } from "components/components/forms/dropdowns";
import { UsernameInput, PasswordInput, EmailInput, AccNameInput, ReferralInput } from "components/components/forms/text";

import { MessageModal } from "components/components/modals/modals";
import ReCAPTCHA from "react-google-recaptcha";
const envelopeImage = require("images/envelope.svg");

//const randomizeIcon = require("../../../images/definitive/QUDO_RandomizeIcon.png");

export function AccountNameFields(props) {
	const [message, setMessage] = useState('');

	const [updateAccountname, setUpdateAccountname] = useState(false);
	const [randomizeAccountName, setRandomizeAccountName] = useState(false);

	//When checkboxes change re-check accountname validity
	useEffect(() => {
		setUpdateAccountname(!updateAccountname);
	}, [props.newAccount]);

	//Reset button state (button not doing this for some reason)
	useEffect(() => {
		if (randomizeAccountName) {
			setRandomizeAccountName(false);
		}
	}, [randomizeAccountName]);

	//Check when the parent component forces the account name randomization
	useEffect(() => {
		if (props.randomizeAccountName) {
			setRandomizeAccountName(true);
			props.setRandomizeAccountName(false);
		}
	}, [props.randomizeAccountName]);

	//Show a warning when user says he wants to use his own TELOS account
	useEffect(() => {
		if (!props.newAccount) {
			setMessage(
				'ATTENTION: You must have an already existing TELOS account and have permissions to use it!'
			);
		}
	}, [props.newAccount]);

	return (
		<div className="my-3" style={{ display: process.env.REACT_APP_TELOS_ENVIRONMENT !== 'testnet' ? "inherit" : "none" }}>
			<div className="row">
				<div
					className="col-12"
					style={{ marginBottom: '1rem' }}
				>
					<h5>
						Do you have a TELOS account?
					</h5>
				</div>

				<div
					className="col-12"
					style={{ marginBottom: '1rem' }}
				>
					<div
						className="row"
						onClick={() => props.setNewAccount(!props.newAccount)}
					>
						<input
							type="checkbox"
							className="col-2 my-auto"
							checked={props.newAccount}
						// onChange={() => props.setNewAccount(!props.newAccount)}
						/>
						<div className="col-9">
							No, create a new TELOS account
						</div>
					</div>
				</div>

				<div
					className="col-12"
					style={{ marginBottom: '1rem' }}
				>
					<div
						className="row"
						onClick={() => props.setNewAccount(!props.newAccount)}
					>
						<input
							type="checkbox"
							className="col-2 my-auto"
							checked={!props.newAccount}
						// onChange={() => props.setNewAccount(!props.newAccount)}
						/>
						<div className="col-9">
							Yes, I will add my own TELOS account
						</div>
					</div>
				</div>
			</div>

			<div className="row">
				<div className={`${props.newAccount ? "col-8" : "col-12"}`}>
					<AccNameInput
						set={props.set}
						setValid={props.setValid}
						username={props.username}
						newAccount={props.newAccount}
						checkAccountnameValidity={updateAccountname}
						randomizeAccountName={randomizeAccountName}
					/>
				</div>

				{props.newAccount && (
					<div className="col-4 p-0 text-center">
						<ButtonInput
							trigger
							initial={false}
							set={setRandomizeAccountName}
							className="form-submit-btn text-center mt-0 mx-0"
							style={{ padding: '0px' }}
							label={
								<div>
									{/*}
									<img
										alt="Randomize TELOS account name"
										src={randomizeIcon}
										style={{maxWidth: '35px', maxHeight: '35px'}}
									/>
									{*/}
									<div>
										Randomize
									</div>
								</div>
							}
						/>
					</div>
				)}
			</div>

			<MessageModal
				show={message}
				message={message}
				hide={() => setMessage('')}
			/>
		</div>
	);
}

export default function Register(props) {
	const availableTypes = [
		{ label: "Gamer", key: process.env.REACT_APP_USER_TYPE },
		{ label: "Game Developer", key: process.env.REACT_APP_GAMEDEV_TYPE },
		{ label: "Service Provider", key: process.env.REACT_APP_PROVIDER_TYPE },
		{ label: "Gaming Guild", key: process.env.REACT_APP_GUILD_TYPE }
	];

	const currentUser = useSelector(state => state.info);

	const [disabled, setDisabled] = useState(true);
	const [type, setType] = useState(availableTypes[0].key);
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConf, setPasswordConf] = useState("");
	const [passwordsMismatchMsg, setPasswordsMismatchMsg] = useState(null);
	const [accName, setAccName] = useState("");
	const [randomizeAccountName, setRandomizeAccountName] = useState(false);
	const [newAccName, setNewAccName] = useState(true);
	const [validAccName, setValidAccName] = useState(false);
	const [updateUsername, setUpdateUsername] = useState(true);

	const [waiting, setWaiting] = useState(false);
	const [referralMsg, setReferralMsg] = useState("");
	const [forceCheck, setForceCheck] = useState(0);

	// const [displayName, setDisplayName] = useState("");
	// const [validDisplayName, setValidDisplayName] = useState(false);
	const [newsletter, setNewsletter] = useState(false);
	const [mandatoryNewsletter, setMandatoryNewsletter] = useState(false);
	// const [minimumAge, setMinimumAge] = useState(false);
	const [referralUsername, setReferralUsername] = useState("");
	const [termsAndConditions, setTermsAndConditions] = useState(false);
	const [showNewsletterErrors, setShowNewsletterErrors] = useState(false);
	const [telegramWidgetLoaded, setTelegramWidgetLoaded] = useState(false);
	const [telegramWidgetLoading, setTelegramWidgetLoading] = useState(true);
	const [visiblePart, setVisiblePart] = useState(0);
	const [telegramUser, setTelegramUser] = useState({});
	const [adSource, setAdSource] = useState("");

	useEffect(() => {
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);

		const register = params.get('register') || true;
		const userType = params.get('type');
		const referral = params.get('referral');
		const adSource = params.get('adsource');

		if (register) {
			if (userType) {
				const validType = availableTypes.find(e => {
					return e.key === userType;
				});

				if (validType) {
					setType(userType);
				}
			}

			if (referral) {
				setReferralUsername(referral);
			}

			if (adSource) {
				setAdSource(adSource);
			}
		}
	}, []);

	//When user type changes re-check username validity
	useEffect(() => {
		//console.log(type);
		setUpdateUsername(!updateUsername);
	}, [type]);

	useEffect(() => {
		console.log("Registering- adSource: ", adSource)
	}, [adSource]);

	useEffect(() => {
		console.log("Telegram widget", (telegramWidgetLoaded ? "loaded" : "loading"))
	}, [telegramWidgetLoaded]);

	useEffect(() => {
		if (passwordConf && (password !== passwordConf)) {
			setPasswordsMismatchMsg("Passwords don't match");
		} else {
			setPasswordsMismatchMsg("");
		}


		if (password === passwordConf && username && password && email && !referralMsg) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [username, password, passwordConf, email, accName, referralMsg]);


	function close() {
		//These fields need to be manually reset
		// setMinimumAge(false);
		setNewsletter(false);
		setMandatoryNewsletter(false);
		setTermsAndConditions(false);
		setReferralUsername('');
		setType(availableTypes[0].key);
	}

	// Special function to change which part of the registration is shown
	function changeVisiblePart(increment = true) {
		const maxParts = 4;

		//Special rules for the 1st part going to 2nd part
		if (visiblePart === 1 && increment) {
			let error = 0;
			if (!username) {
				console.log("Missing username")
				error = 1;
			} else if (!email) {
				console.log("Missing email")
				error = 2;
			} else if (!password) {
				console.log("Missing password")
				error = 3;
			} else if (password !== passwordConf) {
				console.log("Passwords don't match")
				error = 4;
			} else if (process.env.REACT_APP_TELOS_ENVIRONMENT !== 'testnet' && (!accName || !validAccName)) {
				console.log("Invalid account name")
				error = 5;
			}
			if (error) {
				setForceCheck(forceCheck + 1);
				if (password && (password !== passwordConf)) {
					setPasswordsMismatchMsg("Passwords don't match");
				} else {
					setPasswordsMismatchMsg("");
				}
				return;
			}
		}


		let newPart = visiblePart + (increment ? 1 : -1);
		if (newPart < 0 || newPart >= maxParts) {
			return;
		}
		setVisiblePart(newPart);
	}

	async function F_randomizeAccountName() {
		const url = process.env.REACT_APP_QUDO_SERVER + '/api/randomaccname';
		const maxTries = 6;
		let _username = undefined;

		for (let tries = 0; tries < maxTries; tries++) {
			const sent_username = tries < 3 ? username : username.substring(0, 5).slice(0, 2 - tries);
			await QUDOServer.get(url, {
				params: { username: sent_username },
				headers: { "Content-Type": "application/json" },
				json: true,
				withCredentials: true
			})
				.then(data => {
					console.log("Randomized username: ", data.data.username)
					setAccName(data.data.username);
					setValidAccName(true);
					tries = maxTries;
					_username = data.data.username;
				})
				.catch(error => {
					console.log(error);
				});
		}
		return _username;
	}

	function TelegramLogin() {
		useEffect(() => {
			// Define the function
			window.onTelegramAuth = (user) => {
				if (global.isDev) {
					console.log('Logged in as ' + user.first_name + ' ' + user.last_name + ' (' + user.id + (user.username ? ', @' + user.username : '') + ')');
				}
				setTelegramUser(user); // {authdate: timestamp, first_name: string, hash: string, id: number, last_name: string, username: string, photo_url: string}n
				console.log("Open Telegram bot link: https://t.me/qudocoin")
				changeVisiblePart();
				//window.open('https://t.me/qudocoin', '_blank')
			};

			const botName = process.env.REACT_APP_TELEGRAM_BOT
			if (global.isDev) console.log("TelegramLogin useEffect on ", botName);
			// Create script element
			const script = document.createElement('script');
			script.src = 'https://telegram.org/js/telegram-widget.js?22';
			script.async = true;
			script.dataset.telegramLogin = botName;
			script.dataset.userpic = 'false';
			script.dataset.size = 'large';
			script.dataset.radius = '5';
			script.dataset.onauth = 'onTelegramAuth(user)';
			script.dataset.requestAccess = 'write';


			const _iFrame = document.getElementById('telegram-login').appendChild(script);
			// Append the script to the body
			//document.body.appendChild(script);
			if (global.isDev) console.log(script);
			if (_iFrame) {
				setTelegramWidgetLoaded(true);
			}
			setTelegramWidgetLoading(false);
			// Clean up on component unmount
			return () => {
				if (script) {
					console.log("TelegramLogin useEffect cleanup")
					document.getElementById('telegram-login').removeChild(script);
					//document.body.removeChild(script);
				}
			};
		}, []);

		return <div id="telegram-login" style={{}}></div>;
	}

	function Trouble() {
		const stageMessage = { 0: "Telegram login", 1: "Registration Fields", 2: "Email Are You Sure", 3: "Checkboxes", 99: "Email Verification" };
		const [showTrouble, setShowTrouble] = useState(false);
		const [recaptchaToken, setRecaptchaToken] = useState('');

		const showCaptcha = (!recaptchaToken && visiblePart === 99);
		const smsStyles = "smsstyles " + (showTrouble ? (showCaptcha ? 'troubledisplay' : 'smsdisplay') : 'smshidden');
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

		const subject = encodeURI('Registration Support: ' + stageMessage[visiblePart]);

		return (
			<>
				<div style={{ display: "flex", justifyContent: "center" }}>
					<div className="my-2 enable-click user-select-none" style={{ color: "#9e9e9e" }}
						onClick={() => setShowTrouble((prev) => !prev)}>
						Having trouble?
						{showTrouble ? (
							<FontAwesomeIcon icon={faChevronUp} className='ml-1' />
						) : (
							<FontAwesomeIcon icon={faChevronDown} className='ml-1' />
						)}
					</div>
				</div>
				<div className={smsStyles}>
					{(showCaptcha) ?
						<>
							{!isSafari || (isSafari && showTrouble) &&
								<ReCAPTCHA
									onChange={setRecaptchaToken}
									sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY}
								/>
							}
						</>
						:
						<div className="bold-link" style={{ marginTop: "8px", marginBottom: "-4px", fontSize: "12px" }}>
							Contact us by <a href="https://t.me/qudocoin">Telegram</a>&nbsp;
							{visiblePart === 99 &&
								<>
									or <a href={"mailto:qudo.support@blockbastards.io?subject=" + subject}>Email</a>
								</>
							}.
						</div>
					}

				</div>
			</>

		);
	}

	function antiSpamSubmit() {
		setWaiting(true);
	}

	useEffect(() => {
		if(waiting){
			submit().finally(() => {setWaiting(false);});
		}
	}, [waiting]);

	async function submit() {
		if (global.isDev) {
			console.log("[DEV] Registering - submit")
			setVisiblePart(99);
			return;
		}

		sendFacebookPixelEvent('trackCustom', 'CompleteRegistration', null);

		let _accName = accName;
		let _validAccName = validAccName;

		// Do not show the testnet account name randomization on testnet, so we have this here to create the username due to async nature of the function and useState
		if (process.env.REACT_APP_TELOS_ENVIRONMENT === 'testnet' && !_validAccName) {
			const maxAttempts = 3;
			let attempts = 0;
			while (attempts < maxAttempts){
				try{
					const result = await F_randomizeAccountName();
					_accName = result;
					_validAccName = true;
					setAccName(result);
					setValidAccName(true);
					console.log("Registering- testnet name: ", _accName, _validAccName)
					break;
				} catch (error){
					attempts++;
					console.log(`[${attempts}|${maxAttempts}] Error randomizing account name [${_accName} from ${username}]: ${error}`)
				}
			if(!_validAccName){
				console.log("Invalid account name after 3 attempts")
				const bcn = process.env.REACT_APP_BLOCKCHAIN_NAME ? process.env.REACT_APP_BLOCKCHAIN_NAME : "Telos";
				props.failure(`Couldn't find a valid ${bcn} account for your username (${username}). Try again.`);
				return;
			}
			//await F_randomizeAccountName().then((result) => {
			//	accName = result;
			//	validAccName = true;
			//	console.log("Registering- testnet name: ", accName, validAccName)
			//})
			}
		}

		if (!_validAccName) {
			console.log("Invalid account name")
			return;
		}

		if (!termsAndConditions || !mandatoryNewsletter) {
			console.log("Must accept terms and conditions")
			setShowNewsletterErrors(true);
			return;
		}

		let body = {
			type: type,
			email: email,
			username: username,
			password: password,
			accountName: _accName,
			newAccountName: newAccName,
			referralUsername: referralUsername,
			newsletter: newsletter,
			deviceID: process.env.REACT_APP_WEBAPPUUID,
			telegramUser: JSON.stringify(telegramUser),
			adSource: adSource
		}

		console.log("Registering- body.user-n-ad: ", body.username, " - ", body.accountName, " - ", body.adSource)
		await QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/auth/register`, body, { //auth/register
			headers: { "Content-Type": "application/json" },
			json: true,
			withCredentials: true
		})
		.then((result) => {
			//These fields need to be manually reset
			setNewsletter(false);
			setMandatoryNewsletter(false);
			setTermsAndConditions(false);
			setType(availableTypes[0].key);

			//dispatch(actions.fetchUser());
			// TODO Redirect to main QUDO page
			setVisiblePart(99);

			props.success("Almost there! You just need to verify your email.");
		})
		.catch((error) => {
			//These fields need to be manually reset
			// setMinimumAge(false);
			setNewsletter(false);
			setMandatoryNewsletter(false);
			setTermsAndConditions(false);
			setType(availableTypes[0].key);
			let errorMessage = error.errorMessage;
			if (error.errorMessage === "This telegram user ID is either invalid or already being used by another account") {
				errorMessage = "This Telegram account is already asign to another QUDO account. Contact us via our telegram (QUDO) for quick support."
			}
			props.failure(errorMessage);
		});
	}

	if (currentUser) {
		return null;
	}

	return (
		<Modal
			show={true}
			onHide={close}
			dialogClassName="meta-box"
		>
			<Modal.Body className="scroll-95 pt-0">
				<div className="row meta-body">
					{(visiblePart === 0 || visiblePart === 1) && (
						<div className="col-12 login-main-text t_registerTitle">
							Welcome to the
							<br />
							QUDO World!
						</div>
					)}

					{(visiblePart === 0) && (
						<>
							<div className="col-12 text-center">
								<div className="text-left mb-2">
									Please validate your Telegram account
									<strong> and comeback to register</strong>
								</div>
								<div id="telegram_support_widget" style={{ marginBottom: "24px", marginTop: "28px" }}>
									<TelegramLogin />
									{telegramWidgetLoading ?
										<div>
											<div className="loading mx-auto my-4"></div>
										</div>
										:
										<>
											{(telegramWidgetLoaded === false) && (
												<div style={{ fontSize: "14px", marginBottom: "-0px", height: "42px", display: "flex", alignItems: "center", justifyContent: "center" }}>
													Tap the 3 dots <FontAwesomeIcon icon={faEllipsisV} className="mx-1" />  and choose "Open in browser"
												</div>
											)}
										</>
									}
								</div>
							</div>
						</>
					)}

					<div className="col-12">
						{visiblePart === 1 && (<>
							<DropdownInput
								label="Are you a..."
								initial={availableTypes.find(e => e.key === type).label}
								choices={availableTypes}
								set={setType}
							/>

							<UsernameInput
								type={type}
								unique={true}
								set={setUsername}
								setRandomizeAccountName={setRandomizeAccountName}
								checkUsernameValidity={updateUsername}
								forceCheck={forceCheck}
								value={username}
								onBlur={() => {
									//If the TELOS account name is empty or invalid, automatically fill it
									if (!accName && newAccName) {
										setRandomizeAccountName(true);
									}
								}}
							/>

							{/*}
                            <DisplayNameInput
                                placeholder="Display name (optional)"
                                value={displayName}
                                set={setDisplayName}
                                setValid={setValidDisplayName}
                                userType={type}
                                checkDisplayNameValidity={updateUsername}
                            />
                            {*/}

							<EmailInput
								set={setEmail}
								validate={true}
								userType={type}
								value={email}
								forceCheck={forceCheck}
							/>

							<PasswordInput
								key={1}
								placeholder="Password"
								value={password}
								set={setPassword}
								forceCheck={forceCheck}
								validate={true}
							/>

							<PasswordInput
								key={2}
								placeholder="Confirm password"
								value={passwordConf}
								set={setPasswordConf}
								forceInvalid={passwordsMismatchMsg}
								forceCheck={forceCheck}
							/>

							<div className="mt-3">
								<ButtonInput
									trigger
									set={() => { changeVisiblePart(true) }}
									//disabled={disabled}
									label="Next"
									className="form-submit-btn text-center my-0"
								/>
							</div>
						</>)}

						{visiblePart === 2 && (<>
							<div>
								<h5 className="mb-2">
									WARNING!
								</h5>
							</div>

							<div className="my-4">
								<p>Please make sure that <strong>{email}</strong> is a <strong>valid email.</strong></p>
								<p>
									You'll need this email to validate your account
									and complete the <strong>Notcoin task.</strong>
								</p>
							</div>

							<div className="mt-3" style={{ display: "flex" }}>
								<ButtonInput
									trigger
									set={() => { changeVisiblePart(false) }}
									//disabled={disabled}
									style={{ width: "auto" }}
									label="Back"
									className="form-submit-btn text-center my-0"
								/>
								<ButtonInput
									trigger
									set={() => changeVisiblePart(true)}
									disabled={disabled}
									style={{ marginLeft: "8px" }}
									label="Continue"
									className="form-submit-btn text-center my-0"
								/>
							</div>

						</>)}

						{visiblePart === 3 && (<>
							<AccountNameFields
								set={setAccName}
								username={username}
								newAccount={newAccName}
								setNewAccount={setNewAccName}
								setValid={setValidAccName}
								randomizeAccountName={randomizeAccountName}
								setRandomizeAccountName={setRandomizeAccountName}
							/>

							{type !== process.env.REACT_APP_GUILD_TYPE && !adSource && (
								<div>
									<h5 className="mb-2" style={{ marginTop: "8px" }}>
										Referral
									</h5>
									<ReferralInput
										placeholder="Referral username (optional)"
										value={referralUsername}
										set={setReferralUsername}
										forceInvalid={referralMsg}
										setForceInvalid={setReferralMsg}
									/>
								</div>
							)}

							<div>
								<h5 className="mb-2">
									Terms and Conditions
								</h5>
								<div
									className="row"
									onClick={() => setTermsAndConditions(!termsAndConditions)}
								>
									<input
										type="checkbox"
										className="col-2 my-auto"
										checked={termsAndConditions}
									/>
									<div className="col-10">
										I agree with the {" "}
										<a
											target="_blank"
											rel="noopener noreferrer"
											href="https://qudo.io/documents/terms_and_conditions.html"
											className="d-inline-block"
										>
											Terms and Conditions
										</a>
										{" "} and the {" "}
										<a
											target="_blank"
											rel="noopener noreferrer"
											href="https://qudo.io/documents/privacy_policy.html"
											className="d-inline-block"
										>
											Privacy Policy.
										</a>
									</div>
								</div>
								{showNewsletterErrors && !termsAndConditions && (
									<div
										className="mt-1"
										style={{ fontSize: '0.85rem', color: 'red' }}
									>
										You must agree to create a QUDO account.
									</div>
								)}
							</div>

							<div className="my-4">
								<h5 className="mb-2">
									Contact Permission
								</h5>
								<div
									className="row"
									onClick={() => setMandatoryNewsletter(!mandatoryNewsletter)}
								>
									<input
										type="checkbox"
										className="col-2 my-auto"
										checked={mandatoryNewsletter}
									/>
									<div className="col-10">
										I consent to account-related communications and major announcements.
									</div>
								</div>
								{showNewsletterErrors && !mandatoryNewsletter && (
									<div
										className="mt-1"
										style={{ fontSize: '0.85rem', color: 'red' }}
									>
										You must agree to create a QUDO account
									</div>
								)}
								<div
									className="row mt-2"
									onClick={() => setNewsletter(!newsletter)}
								>
									<input
										type="checkbox"
										className="col-2 my-auto"
										checked={newsletter}
									/>
									<div className="col-10">
										I want to receive updates about <b>Airdrops</b>, Giveaways, Challenges and News. (Optional)
									</div>
								</div>
							</div>

							<div className="mt-3" style={{ display: "flex" }}>
								<ButtonInput
									trigger
									set={() => { changeVisiblePart(false) }}
									//disabled={disabled}
									style={{ width: "auto" }}
									label="Back"
									className="form-submit-btn text-center my-0"
								/>
								{waiting ? (
									<div className="loading mx-auto my-4"></div>
								) : (
									<ButtonInput
										trigger
										set={antiSpamSubmit}
										disabled={waiting}
										style={{ marginLeft: "8px" }}
										label="Register"
										className="form-submit-btn text-center my-0"
									/>
								)}
							</div>

						</>)}

						{visiblePart === 99 && (<>
							<div style={{ textAlign: "center" }}>
								<h2 style={{ marginTop: "32px" }}>
									Almost there!<br /> 👏
								</h2>
								<div style={{ display: "flex", justifyContent: "center", margin: "32px", marginTop: "72px" }}>
									<img src={envelopeImage} width="112" height="112" alt="Envelope" />
								</div>
								<div style={{ marginBottom: "32px", fontSize: "20px" }}>
									You just need to open your email and complete the verification process.
								</div>

							</div>
						</>)}

						<Trouble />

					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}