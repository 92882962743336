import React, {useState, useEffect} from 'react';
import {Modal} from "react-bootstrap";
import {ClipLoader} from "react-spinners";
import {useSelector, useDispatch} from 'react-redux';
import {arrayToHex} from "eosjs/dist/eosjs-serialize";

import * as actions from "../../../actions";

import QUDOServer from '../../../helpers/QUDOServerConnection';
import {ServiceTypes} from '../../../helpers/serviceTypes';

import {MessageModal} from "./modals.js";
import WalletImportTutorial from "../../common/walletMigration/walletImportTutorial";

import {ButtonInput} from '../forms/buttons';
import {DropdownInput} from '../forms/dropdowns';

const send = require("../../../images/definitive/icn-send-qudos.png");

export default function HireServiceModal(props) {
    const dispatch = useDispatch();

	const ual = useSelector(state => state.ual);
	const balances = useSelector(state => state.balance);
	const currentUser = useSelector(state => state.info);

    const [walletBalance, setWalletBalance] = useState(0);
    const [savingsBalance, setSavingsBalance] = useState(0);

    const [paymentWallet, setPaymentWallet] = useState(null);
    const [paymentWalletValue, setPaymentWalletValue] = useState(0);
    const [paymentWalletValid, setPaymentWalletValid] = useState(false);

    const [updateUser, setUpdateUser] = useState(false);
	const [modalMessage, setModalMessage] = useState('');

	const [hireLoading, setHireLoading] = useState(false);
	const [tutorialModal, setTutorialModal] = useState(false);

    useEffect(() => {
        if(balances) {
            if(balances.balance) {
                const split = balances.balance.split(' ');

                if(balances.stake) {
                    const stakeSplit = balances.stake.split(' ');
                    setWalletBalance(parseFloat(split[0]) - parseFloat(stakeSplit[0]));
                } else {
                    setWalletBalance(parseFloat(split[0]));
                }
            }

            if(balances.savings) {
                const split = balances.savings.split(' ');
                setSavingsBalance(parseFloat(split[0]));
            }
        }
    }, [balances]);

    useEffect(() => {
        if(paymentWallet === 'wallet' || paymentWallet === 'savings') {
            setPaymentWalletValid(true);

            if(paymentWallet === 'wallet') {
                setPaymentWalletValue(walletBalance);
            }

            if(paymentWallet === 'savings') {
                setPaymentWalletValue(savingsBalance);
            }
        } else {
            setPaymentWalletValid(false);
        }
    }, [paymentWallet, walletBalance, savingsBalance]);

    async function hireService() {
        if(!props.service || !props.service.name) {
            return;
        }

        if(paymentWallet !== 'wallet' && paymentWallet !== 'savings') {
            return;
        }

        if(!paymentWalletValid || Number(paymentWalletValue) < Number(props.service.price)) {
            return;
        }

        setHireLoading(true);

        //var memo = '';
        var actionName = '';
        var destinationContract = '';

		var signatureData = null;

        if(paymentWallet === 'wallet') {
            destinationContract = process.env.REACT_APP_TOKENCONTRACT;
            actionName = "transfer";
            //memo = "Hire service using the user's wallet";
        } else {
            destinationContract = process.env.REACT_APP_APPCONTRACT;
            actionName = "usesavings";
            //memo = "Withdraw from savings to hire service";
        }

		if(!currentUser.qudoManaged) {
            try {
				if(!ual.activeUser) {
                    ual.logout();
                    ual.restart();
                    ual.showModal();
                    setHireLoading(false);
					return;
				} else {
					const signedData = await ual.activeUser.signTransaction({
						actions: [{
							account: destinationContract,
							name: actionName,
							authorization: [{
								actor: currentUser.account_name,
								permission: "active"
							}],
							data: {
								from: currentUser.account_name,
								to: props.service.provider.account_name,
								quantity: `${Number(props.service.price).toFixed(4)} ${process.env.REACT_APP_TOKENNAME}`,
                                memo: 'Hired service: ' + props.service.account_name
							}
						}]
					}, {
						blocksBehind: 3,
						expireSeconds: 30,
						broadcast: false
					});

					signatureData = {
						signatures: signedData.transaction.signatures,
						packed_trx: arrayToHex(signedData.transaction.serializedTransaction)
					}
				}
            } catch(error) {
                //console.log(error);
                setModalMessage(error.toString());
                setHireLoading(false);
				return;
            }
        }

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/service/hire`, {
            payWithWallet: paymentWallet === 'wallet',
            service: props.service.name,
			signature: signatureData,
            //memo: memo
        }, {
            withCredentials: true,
        })
        .then(response => {
            setUpdateUser(true);
            setModalMessage("Success! Check your email.");
        })
        .catch(error => {
            setUpdateUser(false);
            setModalMessage(error.errorMessage);
        })
        .finally(() => {
            setHireLoading(false);
        });
    }

    if(props.service) {
        return(
            <div>
            <Modal show={props.show} onHide={props.setShow}>
                <Modal.Body style={{backgroundColor: '#282725'}}>
                    <Modal.Header
                        closeButton
                        className='modal-header-position dark'
                    />

                    <div className="card-body mt-2 hire-service-modal">
                        <div className="title">
                            {props.service.display_name ? props.service.display_name : props.service.name}
                        </div>

                        <div className="mt-3 hire-service-modal">
                            <div className="mb-2 subtitle">
                                Service Type
                            </div>

                            <div style={{fontWeight: 'initial'}}>
                                {ServiceTypes[props.service.type]}
                            </div>
                        </div>

                        {props.service.provider && (
                        <div className="mt-3 hire-service-modal">
                            <div className="subtitle mb-2">
                                Service Provider
                            </div>

                            <div style={{fontWeight: 'initial'}}>
                                {
                                    props.service.provider.display_name
                                    ? props.service.provider.display_name
                                    : props.service.provider.username
                                }
                            </div>
                        </div>
                        )}

                        <div className="mt-3 hire-service-modal">
                            <div className="subtitle mb-2">
                                Average Estimated Completion Time
                            </div>

                            <div style={{fontWeight: 'initial'}}>
							    {props.service.estimatedTime} {Number(props.service.estimatedTime) !== 1 ? 'hours' : 'hour'}
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-1">
                                <img src={send} className="mr-1" alt="send"/>
                            </div>

                            <div className="col-9">
                                Get Service
                            </div>
                        </div>

                        {!currentUser.qudoManaged && !ual.activeUser ? (
                            <>
                                <div className="mt-3 hire-service-modal">
                                    <div style={{fontWeight: 'initial'}}>
                                        Please sign in with one of the available wallets to get services in the QUDO marketplace
                                    </div>
                                    <div className="mt-3">
                                        <button
                                            type="button"
                                            className="std-button-active"
                                            onClick={() => {
                                                ual.logout();
                                                ual.restart();
                                                ual.showModal();
                                            }}
                                        >
                                            <div className="text-center">
                                                SIGN IN
                                            </div>
                                        </button>
                                        <button
                                            type="button"
                                            className="std-button mb-0"
                                            style={{backgroundColor: '#00cc69'}}
                                            onClick={() => setTutorialModal(!tutorialModal)}
                                        >
                                            <div className="text-center">
                                                HOW TO SIGN IN
                                            </div>
                                        </button>

                                        {tutorialModal && (
                                            <WalletImportTutorial
                                                show={tutorialModal}
                                                hide={() => setTutorialModal(!tutorialModal)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                            <div className="mt-3">
                                <DropdownInput
                                    dark="true"
                                    label="Wallet"
                                    initial='Choose a payment option...'
                                    set={setPaymentWallet}
                                    choices={[
                                        {key: 'wallet', label: 'My Wallet'},
                                        {key: 'savings', label: 'Pocket Change Wallet'}
                                    ]}
                                />
                            </div>

                            {paymentWalletValid && (
                                <div className="my-2 hire-service-modal">
                                    <div className="subtitle mb-2">
                                        Current Balance
                                    </div>

                                    <div style={{fontWeight: 'initial', fontSize: '1.5rem'}}>
                                        {`${String(paymentWalletValue)} QUDO`}
                                    </div>
                                </div>
                            )}

                            {hireLoading ? (
                                <div className="row mt-4 mb-2">
                                    <div className="col-12">
                                        <div className="text-center">
                                            <ClipLoader color="#fcd902"/>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row mt-4">
                                    <div className="button-hire min-w-60 dark">
                                        <span className="price">PRICE</span> <span className="numbers">{props.service.price}</span> QUDO
                                    </div>
                                    <div className="button-hire min-w-40">
                                        <ButtonInput
                                            trigger
                                            //label="Hire"
                                            label="Get Service"
                                            disabled={!paymentWalletValid || Number(paymentWalletValue) < Number(props.service.price)}
                                            set={() => hireService()}
                                            className="editgamebtn button-hire min-w-40 mt-0"
                                            style={
                                                !paymentWalletValid || Number(paymentWalletValue) < Number(props.service.price)
                                                ? {backgroundColor: "#ededed", cursor: 'initial'} : {}
                                            }
                                        />
                                    </div>
                                </div>
                            )}

                            {paymentWalletValid && Number(paymentWalletValue) < Number(props.service.price) && (
                                <div className="mt-2 text-center" style={{color: 'red', fontSize: '0.9rem'}}>
                                    This wallet doesn't have enough funds to hire this service
                                </div>
                            )}
                            </>
                        )}
                    </div>
                </Modal.Body>
            </Modal>

			<MessageModal
				show={modalMessage}
				message={modalMessage}
				hide={() => {
                    if(updateUser) {
                        setUpdateUser(false);
                        dispatch(actions.fetchUser());
                    }

                    setModalMessage('');
                }}
			/>
            </div>
        );
    }
}
