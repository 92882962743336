import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import QUDOServer from "../../helpers/QUDOServerConnection";

export default function DeleteAccount(props){
    
    const currentUser = props.info;
    const fetchNewInfo = props.fetchNewInfo;

    let subject = encodeURI("QUDO Account Deletion Request") // + currentUser.username;

    const [errorMessage, setErrorMessage] = useState("");
    const [showTrouble, setShowTrouble] = useState(false);


    async function notifyAPI(){ // UNUSED
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/accounts/markfor_delition`, {
            username: currentUser.username
        })
        .then(resp =>{setErrorMessage(""); console.log(resp);})
        .catch(err =>{setErrorMessage(err.errorMessage);console.log(err)}); 
    }

    const handleCLick = (event) => {
        //notifyAPI();
    }

    return(
        <>
        <div style={{/* display: "flex", justifyContent: "center" */ }}>
            <div className="my-2 enable-click user-select-none" style={{ color: "#9e9e9e" }}
                onClick={() => setShowTrouble((prev) => !prev)}>
                {/*"Having trouble?"*/}
                Are you unhappy?
                {showTrouble ? (
                    <FontAwesomeIcon icon={faChevronUp} className='ml-1' />
                ) : (
                    <FontAwesomeIcon icon={faChevronDown} className='ml-1' />
                )}
            </div>
        </div>
        <div className={"smsstyles " + (showTrouble ? 'smsdisplay' : 'smshidden')}>
            <div className="bold-link" style={{ marginTop: "8px", marginBottom: "-4px", fontSize: "12px" }}>
                <a href={"mailto:qudo.support@blockbastards.io?subject="+subject} onClick={handleCLick}>I want to delete my account.</a>
            </div>
        </div>
        </>
    )
}